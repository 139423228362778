.P2E,
.P2P,
.P2PG,
.P2PS,
.P2PTXT,
.P2PX,
.P3NGUIN,
.PAB_BSC_,
.PABLO,
.PAC,
.PACA,
.PACE,
.PACIFIC,
.PACK,
.PACOCA,
.PACT,
.PAD,
.PADDY,
.PADX,
.PAF,
.PAG,
.PAGE,
.PAHOO,
.PAI,
.PAID,
.PAINT,
.PAJEET,
.PAK,
.PAL,
.PALG,
.PALLA,
.PALLAS,
.PALS,
.PAMM,
.PAMP,
.PAMPTHER,
.PAN,
.PAND,
.PANDA,
.PANDAVS,
.PANDO,
.PANFT,
.PANGOLIN,
.PANIC,
.PANTHER,
.PAPA,
.PAPACAKE,
.PAPADOGE,
.PAPEL,
.PAPER,
.PAPP,
.PAPPAY,
.PAR,
.PARA,
.PARAGON,
.PARAS,
.PARETO,
.PARK,
.PARMA,
.PARR,
.PART,
.PDO,
.PARTIAL,
.PARTY,
.PASC,
.PASS,
.PASSIVE,
.PASTA,
.PASTRYPUNKS,
.PASV,
.PAT,
.PATH,
.PAVAX,
.PAVIA,
.PAW,
.PAWG,
.PAWN,
.PAWS,
.PAWTH,
.PAXCURVE,
.PAXEX,
.PAXG,
.PAXGHALF,
.PAXW,
.PAY,
.PAYB,
.PAYGO,
.PAYN,
.PAYT,
.PAYX,
.PAYZ,
.PAZ,
.PAZZI,
.PB,
.PBASE,
.PBC,
.PBENGALS,
.PBK,
.PBL,
.PBLC,
.PBOM,
.PBQ,
.PBR,
.pBREW,
.PBS,
.PBT,
.pBTC,
.pBTC35A,
.PBX,
.PC,
.PCAPS,
.PCB,
.PCC,
.PCE,
.PCH,
.PCHART,
.PCHF,
.PCI,
.PCKT,
.PCL,
.PCM,
.PCN,
.PCNT,
.PCO,
.PCR,
.PCS,
.PCT,
.PCTO,
.PCX,
.pDAI,
.PDAO,
.PDATA,
.PDD,
.PDEX,
.PDF,
.PDG,
.PDI,
.PDog,
.PDOG,
.PDRY,
.PDSHARE,
.PDT,
.PDX,
.PEA,
.PEACE,
.PEACE_DOGE,
.Peace_doge_X,
.PEAK,
.PEAKAVAX,
.PEANUT,
.PEANUTS,
.PEAQ,
.PEAR,
.PEARL,
.PEAX,
.PEBBLE,
.PEC,
.PECO,
.PEDESTAL,
.PEE,
.PEECAT,
.PEECH,
.PEECOIN,
.PEEPO,
.PEEPS,
.PEFI,
.PEG,
.PEGS,
.PEKC,
.PEL,
.PELE,
.PEM,
.PEN,
.PENDLE,
.PENG,
.PENKY,
.PENT,
.PENTA,
.PEO,
.PEOPLE,
.PEOS,
.PEPE,
.PEPECASH,
.PEPEVR,
.PEPR,
.PEPS,
.PERA,
.PERC,
.PERI,
.PERK,
.PERL,
.PERP,
.PERRA,
.PERRY,
.PERSIA,
.PERX,
.PES,
.PESA,
.PESS,
.PESSY,
.PET,
.PETA,
.PETAL,
.PETB,
.PETC,
.PETG,
.pETH18C,
.PETH,
.PETN,
.PETO,
.PETS,
.PEX,
.PEXO,
.PFARM,
.PFE,
.PFI,
.PFID,
.PFL,
.PFR,
.PFT,
.PFTM,
.PFW,
.PFY,
.PGEM,
.PGEN,
.PGF7T,
.PGIRL,
.PGK,
.PGN,
.PGNT,
.PGO,
.PGOLD,
.PGPAY,
.PGS,
.PGT,
.PGTS,
.PGX,
.PHA,
.PHAE,
.PHAT,
.PHB,
.pHBD,
.PHC,
.PHCR,
.PHI,
.PHIBA,
.PHIFIV2,
.PHIT,
.PHL,
.PHM,
.PHNX,
.PHO,
.PHOENIX,
.PHON,
.PHONON,
.PHOON,
.PHOTON,
.PHR,
.PHT,
.PHTG,
.PHTR,
.PHV,
.PHX,
.PI,
.PIA,
.PIASA,
.PIB,
.PICA,
.PICIPO,
.PICKLE,
.PID,
.PIE,
.PIF,
.PIG,
.PIGGY,
.PIGI,
.PIGS,
.PIGX,
.PIKA,
.PIKACHU,
.PILOT,
.PIN,
.PINE,
.PI_NETWORK_DEFI,
.PING,
.PINK,
.PINKE,
.PINKINU,
.PINKM,
.PINKPANDA,
.PINKSALE,
.PINT,
.PINU,
.PIP,
.PIPI,
.PIPL,
.PIPT,
.PIR,
.PIRA,
.PIRATE,
.PIRATEBOY,
.PirateCoin☠,
.PIRATES,
.PIRL,
.PIS,
.PIST,
.PIT,
.PITC￼,
.PITCH,
.PITQD,
.PITTYS,
.PIVX,
.PIX,
.PIXEL,
.PIXELSQUID,
.PIXEOS,
.PIZA,
.PIZZA,
.PIZZAB,
.PJM,
.PKB,
.PKC,
.PKD,
.PKEX,
.PKF,
.PKG,
.PKMON,
.PKN,
.PKOIN,
.PKR,
.PKT,
.PLA,
.PLAAS,
.PLACE,
.PLANETINU,
.PLANETS,
.PLANETVERSE,
.PLASTIK,
.PLAT,
.PLATA,
.PLATER,
.PLATO,
.PLAY,
.PLAYA,
.PLAYER,
.PLAYMATES,
.PLAYR,
.PLAYTRK,
.PLBT,
.PLC,
.PLCU,
.PLD,
.PLE,
.PLEB,
.PLENA,
.PLENTY,
.PLENTYCOIN,
.PLEX,
.PLF,
.PLG,
.PLGR,
.PLI,
.PLIBRA,
.PLM,
.PLN,
.PLNC,
.PLNX,
.PLOCK,
.PLOT,
.PLR,
.PLS,
.PLSPAD,
.PLT,
.PLTC,
.PLTS,
.PLU,
.PLUG,
.PLUGCN,
.PLUM,
.PLUNA,
.PLURA,
.PLUS,
.PLUS1,
.PLUT,
.PLUTO,
.PLX,
.PLY,
.PM,
.PMA,
.PMD,
.PMEER,
.PMGT,
.PML,
.PMLG,
.PMN,
.PMNT,
.PMON,
.PMP,
.PMX,
.PN,
.pNAUT,
.PND,
.PNDMLV,
.PNDR,
.PNFT,
.PNG,
.PNIX,
.PNIXS,
.PNK,
.PRIVBEAR,
.PRIVBULL,
.PRIVHALF,
.PRIVHEDGE,
.PYPL,
.PNL,
.PNODE,
.PNS,
.PNST,
.PNT,
.PNTQ,
.PNX,
.PNY,
.PO,
.POA,
.POC,
.POCHI,
.POCO,
.POCROC,
.POD,
.PODO,
.POE,
.POFI,
.POG,
.POGO,
.POK,
.POKE,
.POKELON,
.POKER,
.POKERFI,
.POKI,
.POKT,
.POL,
.POLA,
.POLAR,
.POLARIS,
.POLC,
.POLE,
.POLI,
.POLICEDOGE,
.POLIS,
.POLK,
.POLL,
.POLLY,
.POLO,
.POLP,
.POLR,
.POLS,
.POLT,
.POLVEN,
.POLX,
.POLY,
.POLYAKITA,
.POLYCUB,
.POLYDOGE,
.PolyMoon,
.POLYPAD,
.POLYS,
.POLYX,
.POM,
.POME,
.POMI,
.POND,
.PONYO,
.PONZI,
.PONZU,
.POO,
.POOCOIN,
.POODL,
.POOF,
.POOL,
.POOLZ,
.POOP,
.POOR,
.POORDOGE,
.POOROCKET,
.POP,
.POP\!,
.POPDOGE,
.POPE,
.POPIT,
.POPK,
.POPS,
.POR,
.PORNROCKET,
.PORT,
.PORTAL,
.PORTO,
.PORTX,
.POS,
.PoSH,
.POSI,
.POSS,
.POST,
.POSW,
.pot,
.POT,
.POTATO,
.POTS,
.POTTERINU,
.POW,
.POW3R,
.POWER,
.POWERINU,
.POWERZILLA,
.POWR,
.POX,
.PPAD,
.PPAY,
.PPBLZ,
.PPC,
.PPD,
.PPDEX,
.PPEGG,
.PPI,
.PPN,
.PPOLL,
.PPP,
.PPT,
.PPT7,
.PPUG,
.PPUNKS,
.PPY,
.PQBERT,
.PQT,
.PRA,
.PRAGMA,
.PRAMS,
.PRARE,
.PRB,
.PRBLY,
.PRC,
.PRCH,
.PRCY,
.PRDS,
.PRDX,
.PRDZ,
.PRE,
.PRED,
.PREMIA,
.PREMIO,
.PRG,
.PRGC,
.PRIA,
.PRIDE,
.PRIMATE,
.PRIME,
.PRIMO,
.PrinceFloki,
.PRINCESS,
.PRINT,
.PRINTS,
.PRISM,
.PRIV,
.PRIX,
.PRJ,
.PRL,
.PRM,
.PRNT,
.PRO,
.PROB,
.PROC,
.PROFIT,
.PROGE,
.PROM,
.PROMISE,
.PROPEL,
.PROPHET,
.PROPS,
.PROS,
.PROT,
.PROTEUS,
.PROTO,
.PROUD,
.PROVE,
.PRP,
.PRQ,
.PRQBOOST,
.PRS,
.PRSG,
.PRSN,
.PRT,
.PRTY,
.PRUDE,
.PRV,
.PRVS,
.PRX,
.PRXY,
.PRY,
.PRYZ,
.PRZ,
.PS1,
.PSB,
.PSC,
.PSDN,
.PSG,
.PSHARE,
.PSHIB,
.PSHIBAX,
.PSHP,
.PSI,
.PSIX,
.PSK,
.PSL,
.PSLIP,
.PSM,
.PSN,
.PSOL,
.PSP,
.PSQ,
.PSR,
.PST,
.PSTAKE,
.PSTM,
.PSTN,
.PSW,
.PSWAMP,
.PSWAP,
.PSY,
.Psyduck,
.PSYS,
.PTA,
.PTD,
.PTE,
.PTERIA,
.PTF,
.PTI,
.PTL,
.PTM,
.PTN,
.PTO,
.PTON,
.PTOY,
.PTP,
.PTPA,
.PTR,
.PTRST,
.PTS,
.PTT,
.PTU,
.PTX,
.PUB,
.PUBE,
.PUD,
.PUFF,
.PUFFS,
.PUFFSANTA,
.PUG,
.PUGL,
.PUGT,
.PULI,
.PULSAR,
.PULSE,
.PULSEDOGE,
.PULSEFEG,
.PULSEMOON,
.PULV,
.PUM,
.PUML,
.PUMP,
.PUMPIT,
.PUMPKIN,
.PUN,
.PUNCH,
.PUNDIX,
.PUNK,
.PUNK_BASIC,
.PUNKS,
.PUNT,
.PUP,
.PUPDOGE,
.PUPPY,
.PUPS,
.PUR,
.PURA,
.PURE,
.PUREX,
.PURPLEFLOKI,
.PURR,
.PURRX,
.PURSE,
.pUSD,
.PUSD,
.PUSH,
.PUSS,
.PUSSC,
.PUSSY,
.PUT,
.PUX,
.PVC,
.PVERSE,
.PVG,
.PVM,
.PVN,
.PVT,
.PVU,
.PW,
.PWAR,
.PWARS,
.PWAY,
.PWC,
.PWG,
.pWINGS,
.PWR,
.PWRB,
.PWT,
.PXC,
.PXG,
.PXI,
.PXL,
.PXLC,
.PXP,
.PXT,
.PXU,
.PXUSD_MAR2021,
.PYBC,
.PYD,
.PYE,
.PYLNT,
.PYLON,
.PYM,
.PYN,
.PYO,
.PYQ,
.PYR,
.PYRA,
.PYRAM,
.PYRK,
.PYRO,
.PYX,
.PZAP,
.PZM,
.PZS,
.Q8E,
.QA,
.QAC,
.QANX,
.QAO,
.QASH,
.QBC,
.QBE,
.QBIC,
.QBIT,
.QBT,
.QBX,
.QBZ,
.QC,
.QCH,
.QCORE,
.QCX,
.QDAO,
.QDEFI,
.QDROP,
.QDT,
.QDX,
.QFI,
.QHC,
.QI,
.QIN,
.QKC,
.QLC,
.QM,
.QMALL,
.QNC,
.QNO,
.QNT,
.QNTU,
.QOM,
.QOOB,
.QORT,
.QPY,
.QQQ,
.QQQF,
.QRDO,
.QRK,
.QRL,
.QRT,
.QRX,
.QSD,
.QSP,
.QTA,
.QTBK,
.QTC,
.QTCON,
.QTF,
.QTO,
.QTUM,
.QTV,
.QTZ,
.QUA,
.QUACK,
.QUAD,
.QUAI,
.QUAM,
.QUAN,
.QUANTIC,
.QUARTZ,
.QUB,
.QUBE,
.QUEEN,
.QUEENSHIBA,
.QUICK,
.QUID,
.QUIDD,
.QUIK,
.QUIN,
.QUINT,
.QUIPU,
.QUN,
.QUO,
.QUOT,
.QUOTH,
.QURA,
.QURO,
.QUROZ,
.QUSD,
.QWC,
.QWLA,
.QWT,
.QZA,
.R1,
.R2R,
.R3FI,
.R34P,
.RA,
.RABBIT,
.RABID,
.RAC,
.RACA,
.RACE,
.RACEFI,
.RACERR,
.RACEX,
.RACING,
.RAD,
.RADAR,
.RADDIT,
.RADIO,
.RADR,
.RAE,
.RAFF,
.RAGE,
.RAGNA,
.RAHO,
.RAI,
.RAID,
.RAIDER,
.RAIL,
.RAIN,
.RAINBOW,
.RAINI,
.RAISE,
.RAJAINU,
.RAK,
.RAKU,
.RAKUC,
.RAL,
.RALPH,
.RAM,
.RAMEN,
.RAMMUS,
.RAMP,
.RANGO,
.RANK,
.RANKER,
.RAP,
.RARA,
.RARE,
.RARI,
.RARX,
.RAS,
.RASKO,
.RASTA,
.RAT,
.RATING,
.RATIO,
.RATOKEN,
.RATRACE,
.RATS,
.RAUX,
.RAV,
.RAVE,
.RAVEN,
.RAY,
.RAYONS,
.RAZE,
.RAZOR,
.RB,
.RBASE,
.RBBT,
.RBC,
.RBD,
.RBET,
.RBI,
.RBIES,
.RBIF,
.RBIS,
.RBIZ,
.RBLS,
.RBLX,
.RBMC,
.RBN,
.RBO,
.RBOYS,
.RBS,
.RBSHIB,
.RBT,
.RBTC,
.RBUNNY,
.RBW,
.RBX,
.RBXSamurai,
.RBY,
.RC,
.RC2,
.RC20,
.RCADE,
.RCC,
.RCCC,
.RCG,
.RCH,
.RCKC,
.RCKT,
.RCN,
.RCNT,
.RCT,
.RCUBEV2,
.RD,
.RD2E,
.RDAI,
.RDC,
.RDD,
.RDL,
.RDN,
.RDOGE,
.RDPX,
.RDR,
.RDT,
.REACT,
.READ,
.REAK,
.REAL,
.REALM,
.REALTOKEN_8342_SCHAEFER_HWY_DETROIT_MI,
.REALTOKEN_25097_ANDOVER_DR_DEARBORN_MI,
.REALTOKEN_5942_AUDUBON_RD_DETROIT_MI,
.REALTOKEN_16200_FULLERTON_AVE_DETROIT_MI,
.REALTOKEN_18276_APPOLINE_ST_DETROIT_MI,
.REALTOKEN_20200_LESURE_ST_DETROIT_MI,
.REALTOKEN_15634_LIBERAL_ST_DETROIT_MI,
.REALTOKEN_18900_MANSFIELD_ST_DETROIT_MI,
.REALTOKEN_9336_PATTON_ST_DETROIT_MI,
.REALTOKEN_10024_10028_APPOLINE_ST_DETROIT_MI,
.REAP,
.REAPER,
.REAU,
.REB,
.REB2,
.REBASE,
.REBASEAPY,
.REBL,
.REC,
.RECH,
.RED,
.REDBUFF,
.REDC,
.REDDOGE,
.REDFEG,
.REDFLOKI,
.REDI,
.REDINU,
.REDKISHU,
.REDPANDA,
.REDPILL,
.REDSHIBA,
.REDZILLA,
.REEC,
.REEF,
.REEFER,
.REF,
.REFI,
.REFLECTO,
.REFLEX_V2,
.REG,
.REGEN,
.REGI,
.REGU,
.REI,
.REIGN,
.REL,
.RELAY,
.RELI,
.REM,
.REMIT,
.REN,
.RENA,
.RENASCENT,
.RENBTC,
.RENBTCCURVE,
.RENDOGE,
.RENFIL,
.RENS,
.RENT,
.RENZEC,
.REOSC,
.REP,
.REPO,
.REQ,
.RES,
.RESQ,
.REST,
.RESTAURANTS,
.RET,
.RETA,
.RETH,
.RETH2,
.rETH_h,
.RETIRE,
.RETO,
.RETRO,
.REU,
.REUM,
.REV,
.REV3L,
.REVA,
.REVO,
.REVU,
.REVV,
.REW,
.REWARDS,
.REX,
.RFC,
.RFCTR,
.RFF,
.RFG,
.RFI,
.RFOX,
.RFR,
.RFRUNK,
.RFT,
.RFUEL,
.RFX,
.RGEN,
.RGK,
.rGLD,
.RGOLD,
.RGP,
.RGSI,
.RGT,
.RHEA,
.RHINO,
.RHO,
.RHOBUSD,
.RHOUSDC,
.RHOUSDT,
.RHP,
.RHT,
.RHYTHM,
.RI,
.RIA,
.RIB,
.RIBT,
.RIC,
.RICE,
.RICH,
.RichDoge,
.RICI,
.RICK,
.RICKMORTYDOXX,
.RIDE,
.RIDERS,
.RIDES,
.RIDGE,
.RIF,
.RIFF,
.RIFI,
.RIFT,
.RIGEL,
.RIGY,
.RIK,
.RIKEN,
.RIL,
.RIM,
.RIMAU,
.RIN,
.RING,
.RINGX,
.RIO,
.RIOT,
.RIP,
.RIPH,
.RIPR,
.RISE,
.RISEUP,
.RISKMOON,
.RISQ,
.RIT20,
.RITE,
.RITO,
.RIV,
.RIV2,
.RIVRFLOKI,
.RIYA,
.RIZE,
.RKF,
.RKITTY,
.RKN,
.RKT,
.RKTN,
.RKTV,
.RLB,
.RLC,
.RLD,
.RLE,
.RLM,
.RLOKI,
.RLOOP,
.RLQ,
.RLR,
.RLT,
.RLX,
.RLY,
.RM,
.RMARS,
.RMBL,
.RMESH,
.RMG,
.RMOG,
.RMOON,
.RMPL,
.RMRK,
.RMT,
.RMTX,
.RMX,
.RNB,
.RNBW,
.RND,
.RNDM,
.RNDR,
.RNG,
.RNK,
.RNO,
.RNT,
.RNTB,
.RNX,
.ROAD,
.ROAR,
.ROBBIN,
.ROBBY,
.ROBET,
.ROBIN,
.ROBO,
.ROBODOGE,
.ROBOSHIB,
.ROC,
.ROCK,
.ROCK2,
.ROCKET,
.RocketBUSD,
.ROCKETSHIB,
.ROCKI,
.ROCKS,
.ROCKSTAR,
.ROCKY,
.ROCO,
.ROD,
.ROF,
.ROFI,
.ROG,
.ROGAN,
.ROGE,
.ROGER,
.ROGUE,
.ROI,
.ROK,
.ROKK,
.ROLL,
.ROM,
.ROME,
.ROMEODOGE,
.RON,
.RONINGMZ,
.ROO,
.ROOBEE,
.ROOK,
.ROOM,
.ROOT,
.ROPE,
.ROS,
.ROSE,
.ROSN,
.ROSS,
.ROT,
.ROTO,
.ROTTEN,
.ROTTS,
.ROUL,
.ROUSH,
.ROUTE,
.ROVER,
.ROX,
.ROY,
.ROYA,
.ROYAL,
.ROYALADA,
.ROZ,
.RPC,
.RPD,
.RPEPE,
.RPG,
.RPI,
.RPL,
.RPM,
.RPR,
.RPS,
.RPST,
.RPT,
.RPTC,
.RPTR,
.RPZX,
.RR,
.RRB,
.RRC,
.RRT,
.RS,
.RSC,
.RSCT,
.RSHIB,
.RSK,
.RSP,
.RSPT,
.RSR,
.RSS3,
.RST,
.RSTR,
.RSUN,
.RSV,
.RSX,
.RTAU,
.RTAX3,
.RTC,
.RTD,
.RTE,
.RTF,
.RTG,
.RTGN,
.RTH,
.RTK,
.RTKN,
.RTM,
.RTO,
.RTT,
.RTTV2,
.RU,
.RUBX,
.RUBY,
.RUC,
.RUDOLPH,
.RUFF,
.RUFFY,
.RUG,
.RUGBID,
.RUGBUST,
.RUGPULL,
.RUGZ,
.RULER,
.RUN,
.RUNDOGE,
.RUNE,
.RUNES,
.RUNGEM,
.RUNNERS,
.RUNNOW,
.RUNx,
.RUP,
.RUPEE,
.RUPT,
.RUPX,
.RUSD,
.RUSH,
.RUTC,
.RUYI,
.RUZE,
.RVC,
.RVE,
.RVEL,
.RVF,
.RVL,
.RVLNG,
.RVLT,
.RVLVR,
.RVMT,
.RVN,
.RVP,
.RVR,
.RVRS,
.RVST,
.RVT,
.RVX,
.RVZ,
.RWD,
.RWN,
.RWS,
.RWSC,
.RX,
.RXC,
.RXCG,
.RXS,
.RYA,
.RYIP,
.RYIU,
.RYM,
.RYMA,
.RYO,
.RYOMA,
.RYOSHI,
.RYOSHIMOTO,
.RYU,
.RYUJI,
.RYZEINU,
.RZN,
.RZRV {
  display: inline-block;
  background: url('../img/tokens_logo_PtoEndR.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.P2E {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.P2P {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.P2PG {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.P2PS {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.P2PTXT {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.P2PX {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.P3NGUIN {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.PAB_BSC_ {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.PABLO {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.PAC {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.PACA {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.PACE {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.PACIFIC {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.PACK {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.PACOCA {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.PACT {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.PAD {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.PADDY {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.PADX {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.PAF {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.PAG {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.PAGE {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.PAHOO {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.PAI {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.PAID {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.PAINT {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.PAJEET {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.PAK {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.PAL {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.PALG {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.PALLA {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.PALLAS {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.PALS {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.PAMM {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.PAMP {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.PAMPTHER {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.PAN {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.PAND {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.PANDA {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.PANDAVS {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.PANDO {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.PANFT {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.PANGOLIN {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.PANIC {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.PANTHER {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.PAPA {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.PAPACAKE {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.PAPADOGE {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.PAPEL {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.PAPER {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.PAPP {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.PAPPAY {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.PAR {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.PARA {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.PARAGON {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.PARAS {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.PARETO {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.PARK {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.PARMA {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.PARR {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.PART {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.PDO {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.PARTIAL {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.PARTY {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.PASC {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.PASS {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.PASSIVE {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.PASTA {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.PASTRYPUNKS {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.PASV {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.PAT {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.PATH {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.PAVAX {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.PAVIA {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.PAW {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.PAWG {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.PAWN {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.PAWS {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.PAWTH {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.PAXCURVE {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.PAXEX {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.PAXG {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.PAXGHALF {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.PAXW {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.PAY {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.PAYB {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.PAYGO {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.PAYN {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.PAYT {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.PAYX {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.PAYZ {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.PAZ {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.PAZZI {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.PB {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.PBASE {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.PBC {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.PBENGALS {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.PBK {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.PBL {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.PBLC {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.PBOM {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.PBQ {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.PBR {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.pBREW {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.PBS {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.PBT {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.pBTC {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.pBTC35A {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.PBX {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.PC {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.PCAPS {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.PCB {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.PCC {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.PCE {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.PCH {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.PCHART {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.PCHF {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.PCI {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.PCKT {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.PCL {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.PCM {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.PCN {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.PCNT {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.PCO {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.PCR {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.PCS {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.PCT {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.PCTO {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.PCX {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.pDAI {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.PDAO {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.PDATA {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.PDD {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.PDEX {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.PDF {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.PDG {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.PDI {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.PDog {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.PDOG {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.PDRY {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.PDSHARE {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.PDT {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.PDX {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.PEA {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.PEACE {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.PEACE_DOGE {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.Peace_doge_X {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.PEAK {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.PEAKAVAX {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.PEANUT {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.PEANUTS {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.PEAQ {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.PEAR {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.PEARL {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.PEAX {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.PEBBLE {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.PEC {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.PECO {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.PEDESTAL {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.PEE {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.PEECAT {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.PEECH {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.PEECOIN {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.PEEPO {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.PEEPS {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.PEFI {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.PEG {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.PEGS {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.PEKC {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.PEL {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.PELE {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.PEM {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.PEN {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.PENDLE {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.PENG {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.PENKY {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.PENT {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.PENTA {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.PEO {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.PEOPLE {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.PEOS {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.PEPE {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.PEPECASH {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.PEPEVR {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.PEPR {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.PEPS {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.PERA {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.PERC {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.PERI {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.PERK {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.PERL {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.PERP {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.PERRA {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.PERRY {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.PERSIA {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.PERX {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.PES {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.PESA {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.PESS {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.PESSY {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.PET {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.PETA {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.PETAL {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.PETB {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.PETC {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.PETG {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.pETH18C {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.PETH {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.PETN {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.PETO {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.PETS {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.PEX {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.PEXO {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.PFARM {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.PFE {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.PFI {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.PFID {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.PFL {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.PFR {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.PFT {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.PFTM {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.PFW {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.PFY {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.PGEM {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.PGEN {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.PGF7T {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.PGIRL {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.PGK {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.PGN {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.PGNT {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.PGO {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.PGOLD {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.PGPAY {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.PGS {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.PGT {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.PGTS {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.PGX {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.PHA {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.PHAE {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.PHAT {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.PHB {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.pHBD {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.PHC {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.PHCR {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.PHI {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.PHIBA {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.PHIFIV2 {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.PHIT {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.PHL {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.PHM {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.PHNX {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.PHO {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.PHOENIX {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.PHON {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.PHONON {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.PHOON {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.PHOTON {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.PHR {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.PHT {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.PHTG {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.PHTR {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.PHV {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.PHX {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.PI {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.PIA {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.PIASA {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.PIB {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.PICA {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.PICIPO {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.PICKLE {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.PID {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.PIE {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.PIF {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.PIG {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.PIGGY {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.PIGI {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.PIGS {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.PIGX {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.PIKA {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.PIKACHU {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.PILOT {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.PIN {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.PINE {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.PI_NETWORK_DEFI {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.PING {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.PINK {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.PINKE {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.PINKINU {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.PINKM {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.PINKPANDA {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.PINKSALE {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.PINT {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.PINU {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.PIP {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.PIPI {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.PIPL {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.PIPT {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.PIR {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.PIRA {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.PIRATE {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.PIRATEBOY {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.PirateCoin☠ {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.PIRATES {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.PIRL {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.PIS {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.PIST {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.PIT {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.PITC￼ {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.PITCH {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.PITQD {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.PITTYS {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.PIVX {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.PIX {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.PIXEL {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.PIXELSQUID {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.PIXEOS {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.PIZA {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.PIZZA {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.PIZZAB {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.PJM {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.PKB {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.PKC {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.PKD {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.PKEX {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.PKF {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.PKG {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.PKMON {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.PKN {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.PKOIN {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.PKR {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.PKT {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.PLA {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.PLAAS {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.PLACE {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.PLANETINU {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.PLANETS {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.PLANETVERSE {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.PLASTIK {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.PLAT {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.PLATA {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.PLATER {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.PLATO {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.PLAY {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.PLAYA {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.PLAYER {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.PLAYMATES {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.PLAYR {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.PLAYTRK {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.PLBT {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.PLC {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.PLCU {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.PLD {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.PLE {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.PLEB {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.PLENA {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.PLENTY {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.PLENTYCOIN {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.PLEX {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.PLF {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.PLG {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.PLGR {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.PLI {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.PLIBRA {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.PLM {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.PLN {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.PLNC {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.PLNX {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.PLOCK {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.PLOT {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.PLR {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.PLS {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.PLSPAD {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.PLT {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.PLTC {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.PLTS {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.PLU {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.PLUG {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.PLUGCN {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.PLUM {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.PLUNA {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.PLURA {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.PLUS {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.PLUS1 {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.PLUT {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.PLUTO {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.PLX {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.PLY {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.PM {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.PMA {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.PMD {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.PMEER {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.PMGT {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.PML {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.PMLG {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.PMN {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.PMNT {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.PMON {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.PMP {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.PMX {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.PN {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.pNAUT {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.PND {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.PNDMLV {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.PNDR {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.PNFT {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.PNG {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.PNIX {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.PNIXS {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.PNK {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.PRIVBEAR {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.PRIVBULL {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.PRIVHALF {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.PRIVHEDGE {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.PYPL {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.PNL {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.PNODE {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.PNS {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.PNST {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.PNT {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.PNTQ {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.PNX {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.PNY {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.PO {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.POA {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.POC {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.POCHI {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.POCO {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.POCROC {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.POD {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.PODO {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.POE {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.POFI {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.POG {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.POGO {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.POK {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.POKE {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.POKELON {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.POKER {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.POKERFI {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.POKI {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.POKT {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.POL {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.POLA {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.POLAR {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.POLARIS {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.POLC {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.POLE {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.POLI {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.POLICEDOGE {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.POLIS {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.POLK {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.POLL {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.POLLY {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.POLO {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.POLP {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.POLR {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.POLS {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.POLT {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.POLVEN {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.POLX {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.POLY {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.POLYAKITA {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.POLYCUB {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.POLYDOGE {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.PolyMoon {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.POLYPAD {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.POLYS {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.POLYX {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.POM {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.POME {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.POMI {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.POND {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.PONYO {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.PONZI {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.PONZU {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.POO {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.POOCOIN {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.POODL {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.POOF {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.POOL {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.POOLZ {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.POOP {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.POOR {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.POORDOGE {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.POOROCKET {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.POP {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.POP\! {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.POPDOGE {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.POPE {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.POPIT {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.POPK {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.POPS {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.POR {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.PORNROCKET {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.PORT {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.PORTAL {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.PORTO {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.PORTX {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.POS {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.PoSH {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.POSI {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.POSS {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.POST {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.POSW {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.pot {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.POT {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.POTATO {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.POTS {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.POTTERINU {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.POW {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.POW3R {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.POWER {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.POWERINU {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.POWERZILLA {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.POWR {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.POX {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.PPAD {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.PPAY {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.PPBLZ {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.PPC {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.PPD {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.PPDEX {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.PPEGG {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.PPI {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.PPN {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.PPOLL {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.PPP {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.PPT {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.PPT7 {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.PPUG {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.PPUNKS {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.PPY {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.PQBERT {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.PQT {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.PRA {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.PRAGMA {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.PRAMS {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.PRARE {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.PRB {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.PRBLY {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.PRC {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.PRCH {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.PRCY {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.PRDS {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.PRDX {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.PRDZ {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.PRE {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.PRED {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.PREMIA {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.PREMIO {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.PRG {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.PRGC {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.PRIA {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.PRIDE {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.PRIMATE {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.PRIME {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.PRIMO {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.PrinceFloki {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.PRINCESS {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.PRINT {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.PRINTS {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.PRISM {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.PRIV {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.PRIX {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.PRJ {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.PRL {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.PRM {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.PRNT {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.PRO {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.PROB {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.PROC {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.PROFIT {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.PROGE {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.PROM {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.PROMISE {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.PROPEL {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.PROPHET {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.PROPS {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.PROS {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.PROT {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.PROTEUS {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.PROTO {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.PROUD {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.PROVE {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.PRP {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.PRQ {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.PRQBOOST {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.PRS {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.PRSG {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.PRSN {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.PRT {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.PRTY {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.PRUDE {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.PRV {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.PRVS {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.PRX {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.PRXY {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.PRY {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.PRYZ {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.PRZ {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.PS1 {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.PSB {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.PSC {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.PSDN {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.PSG {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.PSHARE {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.PSHIB {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.PSHIBAX {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.PSHP {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.PSI {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.PSIX {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.PSK {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.PSL {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.PSLIP {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.PSM {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.PSN {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.PSOL {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.PSP {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.PSQ {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.PSR {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.PST {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.PSTAKE {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.PSTM {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.PSTN {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.PSW {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.PSWAMP {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.PSWAP {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.PSY {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.Psyduck {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.PSYS {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.PTA {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.PTD {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.PTE {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.PTERIA {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.PTF {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.PTI {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.PTL {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.PTM {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.PTN {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.PTO {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.PTON {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.PTOY {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.PTP {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.PTPA {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.PTR {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.PTRST {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.PTS {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.PTT {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.PTU {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.PTX {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.PUB {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.PUBE {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.PUD {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.PUFF {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.PUFFS {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.PUFFSANTA {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.PUG {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.PUGL {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.PUGT {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.PULI {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.PULSAR {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.PULSE {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.PULSEDOGE {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.PULSEFEG {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.PULSEMOON {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.PULV {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.PUM {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.PUML {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.PUMP {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.PUMPIT {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.PUMPKIN {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.PUN {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.PUNCH {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.PUNDIX {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.PUNK {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.PUNK_BASIC {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.PUNKS {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.PUNT {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.PUP {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.PUPDOGE {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.PUPPY {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.PUPS {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.PUR {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.PURA {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.PURE {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.PUREX {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.PURPLEFLOKI {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.PURR {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.PURRX {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.PURSE {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.pUSD {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.PUSD {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.PUSH {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.PUSS {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.PUSSC {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.PUSSY {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.PUT {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.PUX {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.PVC {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.PVERSE {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.PVG {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.PVM {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.PVN {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.PVT {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.PVU {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.PW {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.PWAR {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.PWARS {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.PWAY {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.PWC {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.PWG {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.pWINGS {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.PWR {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.PWRB {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.PWT {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}

.PXC {
  width: 16px;
  height: 16px;
  background-position: -208px -416px;
}

.PXG {
  width: 16px;
  height: 16px;
  background-position: -224px -416px;
}

.PXI {
  width: 16px;
  height: 16px;
  background-position: -240px -416px;
}

.PXL {
  width: 16px;
  height: 16px;
  background-position: -256px -416px;
}

.PXLC {
  width: 16px;
  height: 16px;
  background-position: -272px -416px;
}

.PXP {
  width: 16px;
  height: 16px;
  background-position: -288px -416px;
}

.PXT {
  width: 16px;
  height: 16px;
  background-position: -304px -416px;
}

.PXU {
  width: 16px;
  height: 16px;
  background-position: -320px -416px;
}

.PXUSD_MAR2021 {
  width: 16px;
  height: 16px;
  background-position: -336px -416px;
}

.PYBC {
  width: 16px;
  height: 16px;
  background-position: -352px -416px;
}

.PYD {
  width: 16px;
  height: 16px;
  background-position: -368px -416px;
}

.PYE {
  width: 16px;
  height: 16px;
  background-position: -384px -416px;
}

.PYLNT {
  width: 16px;
  height: 16px;
  background-position: -400px -416px;
}

.PYLON {
  width: 16px;
  height: 16px;
  background-position: -416px -416px;
}

.PYM {
  width: 16px;
  height: 16px;
  background-position: -432px -0;
}

.PYN {
  width: 16px;
  height: 16px;
  background-position: -432px -16px;
}

.PYO {
  width: 16px;
  height: 16px;
  background-position: -432px -32px;
}

.PYQ {
  width: 16px;
  height: 16px;
  background-position: -432px -48px;
}

.PYR {
  width: 16px;
  height: 16px;
  background-position: -432px -64px;
}

.PYRA {
  width: 16px;
  height: 16px;
  background-position: -432px -80px;
}

.PYRAM {
  width: 16px;
  height: 16px;
  background-position: -432px -96px;
}

.PYRK {
  width: 16px;
  height: 16px;
  background-position: -432px -112px;
}

.PYRO {
  width: 16px;
  height: 16px;
  background-position: -432px -128px;
}

.PYX {
  width: 16px;
  height: 16px;
  background-position: -432px -144px;
}

.PZAP {
  width: 16px;
  height: 16px;
  background-position: -432px -160px;
}

.PZM {
  width: 16px;
  height: 16px;
  background-position: -432px -176px;
}

.PZS {
  width: 16px;
  height: 16px;
  background-position: -432px -192px;
}

.Q8E {
  width: 16px;
  height: 16px;
  background-position: -432px -208px;
}

.QA {
  width: 16px;
  height: 16px;
  background-position: -432px -224px;
}

.QAC {
  width: 16px;
  height: 16px;
  background-position: -432px -240px;
}

.QANX {
  width: 16px;
  height: 16px;
  background-position: -432px -256px;
}

.QAO {
  width: 16px;
  height: 16px;
  background-position: -432px -272px;
}

.QASH {
  width: 16px;
  height: 16px;
  background-position: -432px -288px;
}

.QBC {
  width: 16px;
  height: 16px;
  background-position: -432px -304px;
}

.QBE {
  width: 16px;
  height: 16px;
  background-position: -432px -320px;
}

.QBIC {
  width: 16px;
  height: 16px;
  background-position: -432px -336px;
}

.QBIT {
  width: 16px;
  height: 16px;
  background-position: -432px -352px;
}

.QBT {
  width: 16px;
  height: 16px;
  background-position: -432px -368px;
}

.QBX {
  width: 16px;
  height: 16px;
  background-position: -432px -384px;
}

.QBZ {
  width: 16px;
  height: 16px;
  background-position: -432px -400px;
}

.QC {
  width: 16px;
  height: 16px;
  background-position: -432px -416px;
}

.QCH {
  width: 16px;
  height: 16px;
  background-position: -0 -432px;
}

.QCORE {
  width: 16px;
  height: 16px;
  background-position: -16px -432px;
}

.QCX {
  width: 16px;
  height: 16px;
  background-position: -32px -432px;
}

.QDAO {
  width: 16px;
  height: 16px;
  background-position: -48px -432px;
}

.QDEFI {
  width: 16px;
  height: 16px;
  background-position: -64px -432px;
}

.QDROP {
  width: 16px;
  height: 16px;
  background-position: -80px -432px;
}

.QDT {
  width: 16px;
  height: 16px;
  background-position: -96px -432px;
}

.QDX {
  width: 16px;
  height: 16px;
  background-position: -112px -432px;
}

.QFI {
  width: 16px;
  height: 16px;
  background-position: -128px -432px;
}

.QHC {
  width: 16px;
  height: 16px;
  background-position: -144px -432px;
}

.QI {
  width: 16px;
  height: 16px;
  background-position: -160px -432px;
}

.QIN {
  width: 16px;
  height: 16px;
  background-position: -176px -432px;
}

.QKC {
  width: 16px;
  height: 16px;
  background-position: -192px -432px;
}

.QLC {
  width: 16px;
  height: 16px;
  background-position: -208px -432px;
}

.QM {
  width: 16px;
  height: 16px;
  background-position: -224px -432px;
}

.QMALL {
  width: 16px;
  height: 16px;
  background-position: -240px -432px;
}

.QNC {
  width: 16px;
  height: 16px;
  background-position: -256px -432px;
}

.QNO {
  width: 16px;
  height: 16px;
  background-position: -272px -432px;
}

.QNT {
  width: 16px;
  height: 16px;
  background-position: -288px -432px;
}

.QNTU {
  width: 16px;
  height: 16px;
  background-position: -304px -432px;
}

.QOM {
  width: 16px;
  height: 16px;
  background-position: -320px -432px;
}

.QOOB {
  width: 16px;
  height: 16px;
  background-position: -336px -432px;
}

.QORT {
  width: 16px;
  height: 16px;
  background-position: -352px -432px;
}

.QPY {
  width: 16px;
  height: 16px;
  background-position: -368px -432px;
}

.QQQ {
  width: 16px;
  height: 16px;
  background-position: -384px -432px;
}

.QQQF {
  width: 16px;
  height: 16px;
  background-position: -400px -432px;
}

.QRDO {
  width: 16px;
  height: 16px;
  background-position: -416px -432px;
}

.QRK {
  width: 16px;
  height: 16px;
  background-position: -432px -432px;
}

.QRL {
  width: 16px;
  height: 16px;
  background-position: -448px -0;
}

.QRT {
  width: 16px;
  height: 16px;
  background-position: -448px -16px;
}

.QRX {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}

.QSD {
  width: 16px;
  height: 16px;
  background-position: -448px -48px;
}

.QSP {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}

.QTA {
  width: 16px;
  height: 16px;
  background-position: -448px -80px;
}

.QTBK {
  width: 16px;
  height: 16px;
  background-position: -448px -96px;
}

.QTC {
  width: 16px;
  height: 16px;
  background-position: -448px -112px;
}

.QTCON {
  width: 16px;
  height: 16px;
  background-position: -448px -128px;
}

.QTF {
  width: 16px;
  height: 16px;
  background-position: -448px -144px;
}

.QTO {
  width: 16px;
  height: 16px;
  background-position: -448px -160px;
}

.QTUM {
  width: 16px;
  height: 16px;
  background-position: -448px -176px;
}

.QTV {
  width: 16px;
  height: 16px;
  background-position: -448px -192px;
}

.QTZ {
  width: 16px;
  height: 16px;
  background-position: -448px -208px;
}

.QUA {
  width: 16px;
  height: 16px;
  background-position: -448px -224px;
}

.QUACK {
  width: 16px;
  height: 16px;
  background-position: -448px -240px;
}

.QUAD {
  width: 16px;
  height: 16px;
  background-position: -448px -256px;
}

.QUAI {
  width: 16px;
  height: 16px;
  background-position: -448px -272px;
}

.QUAM {
  width: 16px;
  height: 16px;
  background-position: -448px -288px;
}

.QUAN {
  width: 16px;
  height: 16px;
  background-position: -448px -304px;
}

.QUANTIC {
  width: 16px;
  height: 16px;
  background-position: -448px -320px;
}

.QUARTZ {
  width: 16px;
  height: 16px;
  background-position: -448px -336px;
}

.QUB {
  width: 16px;
  height: 16px;
  background-position: -448px -352px;
}

.QUBE {
  width: 16px;
  height: 16px;
  background-position: -448px -368px;
}

.QUEEN {
  width: 16px;
  height: 16px;
  background-position: -448px -384px;
}

.QUEENSHIBA {
  width: 16px;
  height: 16px;
  background-position: -448px -400px;
}

.QUICK {
  width: 16px;
  height: 16px;
  background-position: -448px -416px;
}

.QUID {
  width: 16px;
  height: 16px;
  background-position: -448px -432px;
}

.QUIDD {
  width: 16px;
  height: 16px;
  background-position: -0 -448px;
}

.QUIK {
  width: 16px;
  height: 16px;
  background-position: -16px -448px;
}

.QUIN {
  width: 16px;
  height: 16px;
  background-position: -32px -448px;
}

.QUINT {
  width: 16px;
  height: 16px;
  background-position: -48px -448px;
}

.QUIPU {
  width: 16px;
  height: 16px;
  background-position: -64px -448px;
}

.QUN {
  width: 16px;
  height: 16px;
  background-position: -80px -448px;
}

.QUO {
  width: 16px;
  height: 16px;
  background-position: -96px -448px;
}

.QUOT {
  width: 16px;
  height: 16px;
  background-position: -112px -448px;
}

.QUOTH {
  width: 16px;
  height: 16px;
  background-position: -128px -448px;
}

.QURA {
  width: 16px;
  height: 16px;
  background-position: -144px -448px;
}

.QURO {
  width: 16px;
  height: 16px;
  background-position: -160px -448px;
}

.QUROZ {
  width: 16px;
  height: 16px;
  background-position: -176px -448px;
}

.QUSD {
  width: 16px;
  height: 16px;
  background-position: -192px -448px;
}

.QWC {
  width: 16px;
  height: 16px;
  background-position: -208px -448px;
}

.QWLA {
  width: 16px;
  height: 16px;
  background-position: -224px -448px;
}

.QWT {
  width: 16px;
  height: 16px;
  background-position: -240px -448px;
}

.QZA {
  width: 16px;
  height: 16px;
  background-position: -256px -448px;
}

.R1 {
  width: 16px;
  height: 16px;
  background-position: -272px -448px;
}

.R2R {
  width: 16px;
  height: 16px;
  background-position: -288px -448px;
}

.R3FI {
  width: 16px;
  height: 16px;
  background-position: -304px -448px;
}

.R34P {
  width: 16px;
  height: 16px;
  background-position: -320px -448px;
}

.RA {
  width: 16px;
  height: 16px;
  background-position: -336px -448px;
}

.RABBIT {
  width: 16px;
  height: 16px;
  background-position: -352px -448px;
}

.RABID {
  width: 16px;
  height: 16px;
  background-position: -368px -448px;
}

.RAC {
  width: 16px;
  height: 16px;
  background-position: -384px -448px;
}

.RACA {
  width: 16px;
  height: 16px;
  background-position: -400px -448px;
}

.RACE {
  width: 16px;
  height: 16px;
  background-position: -416px -448px;
}

.RACEFI {
  width: 16px;
  height: 16px;
  background-position: -432px -448px;
}

.RACERR {
  width: 16px;
  height: 16px;
  background-position: -448px -448px;
}

.RACEX {
  width: 16px;
  height: 16px;
  background-position: -464px -0;
}

.RACING {
  width: 16px;
  height: 16px;
  background-position: -464px -16px;
}

.RAD {
  width: 16px;
  height: 16px;
  background-position: -464px -32px;
}

.RADAR {
  width: 16px;
  height: 16px;
  background-position: -464px -48px;
}

.RADDIT {
  width: 16px;
  height: 16px;
  background-position: -464px -64px;
}

.RADIO {
  width: 16px;
  height: 16px;
  background-position: -464px -80px;
}

.RADR {
  width: 16px;
  height: 16px;
  background-position: -464px -96px;
}

.RAE {
  width: 16px;
  height: 16px;
  background-position: -464px -112px;
}

.RAFF {
  width: 16px;
  height: 16px;
  background-position: -464px -128px;
}

.RAGE {
  width: 16px;
  height: 16px;
  background-position: -464px -144px;
}

.RAGNA {
  width: 16px;
  height: 16px;
  background-position: -464px -160px;
}

.RAHO {
  width: 16px;
  height: 16px;
  background-position: -464px -176px;
}

.RAI {
  width: 16px;
  height: 16px;
  background-position: -464px -192px;
}

.RAID {
  width: 16px;
  height: 16px;
  background-position: -464px -208px;
}

.RAIDER {
  width: 16px;
  height: 16px;
  background-position: -464px -224px;
}

.RAIL {
  width: 16px;
  height: 16px;
  background-position: -464px -240px;
}

.RAIN {
  width: 16px;
  height: 16px;
  background-position: -464px -256px;
}

.RAINBOW {
  width: 16px;
  height: 16px;
  background-position: -464px -272px;
}

.RAINI {
  width: 16px;
  height: 16px;
  background-position: -464px -288px;
}

.RAISE {
  width: 16px;
  height: 16px;
  background-position: -464px -304px;
}

.RAJAINU {
  width: 16px;
  height: 16px;
  background-position: -464px -320px;
}

.RAK {
  width: 16px;
  height: 16px;
  background-position: -464px -336px;
}

.RAKU {
  width: 16px;
  height: 16px;
  background-position: -464px -352px;
}

.RAKUC {
  width: 16px;
  height: 16px;
  background-position: -464px -368px;
}

.RAL {
  width: 16px;
  height: 16px;
  background-position: -464px -384px;
}

.RALPH {
  width: 16px;
  height: 16px;
  background-position: -464px -400px;
}

.RAM {
  width: 16px;
  height: 16px;
  background-position: -464px -416px;
}

.RAMEN {
  width: 16px;
  height: 16px;
  background-position: -464px -432px;
}

.RAMMUS {
  width: 16px;
  height: 16px;
  background-position: -464px -448px;
}

.RAMP {
  width: 16px;
  height: 16px;
  background-position: -0 -464px;
}

.RANGO {
  width: 16px;
  height: 16px;
  background-position: -16px -464px;
}

.RANK {
  width: 16px;
  height: 16px;
  background-position: -32px -464px;
}

.RANKER {
  width: 16px;
  height: 16px;
  background-position: -48px -464px;
}

.RAP {
  width: 16px;
  height: 16px;
  background-position: -64px -464px;
}

.RARA {
  width: 16px;
  height: 16px;
  background-position: -80px -464px;
}

.RARE {
  width: 16px;
  height: 16px;
  background-position: -96px -464px;
}

.RARI {
  width: 16px;
  height: 16px;
  background-position: -112px -464px;
}

.RARX {
  width: 16px;
  height: 16px;
  background-position: -128px -464px;
}

.RAS {
  width: 16px;
  height: 16px;
  background-position: -144px -464px;
}

.RASKO {
  width: 16px;
  height: 16px;
  background-position: -160px -464px;
}

.RASTA {
  width: 16px;
  height: 16px;
  background-position: -176px -464px;
}

.RAT {
  width: 16px;
  height: 16px;
  background-position: -192px -464px;
}

.RATING {
  width: 16px;
  height: 16px;
  background-position: -208px -464px;
}

.RATIO {
  width: 16px;
  height: 16px;
  background-position: -224px -464px;
}

.RATOKEN {
  width: 16px;
  height: 16px;
  background-position: -240px -464px;
}

.RATRACE {
  width: 16px;
  height: 16px;
  background-position: -256px -464px;
}

.RATS {
  width: 16px;
  height: 16px;
  background-position: -272px -464px;
}

.RAUX {
  width: 16px;
  height: 16px;
  background-position: -288px -464px;
}

.RAV {
  width: 16px;
  height: 16px;
  background-position: -304px -464px;
}

.RAVE {
  width: 16px;
  height: 16px;
  background-position: -320px -464px;
}

.RAVEN {
  width: 16px;
  height: 16px;
  background-position: -336px -464px;
}

.RAY {
  width: 16px;
  height: 16px;
  background-position: -352px -464px;
}

.RAYONS {
  width: 16px;
  height: 16px;
  background-position: -368px -464px;
}

.RAZE {
  width: 16px;
  height: 16px;
  background-position: -384px -464px;
}

.RAZOR {
  width: 16px;
  height: 16px;
  background-position: -400px -464px;
}

.RB {
  width: 16px;
  height: 16px;
  background-position: -416px -464px;
}

.RBASE {
  width: 16px;
  height: 16px;
  background-position: -432px -464px;
}

.RBBT {
  width: 16px;
  height: 16px;
  background-position: -448px -464px;
}

.RBC {
  width: 16px;
  height: 16px;
  background-position: -464px -464px;
}

.RBD {
  width: 16px;
  height: 16px;
  background-position: -480px -0;
}

.RBET {
  width: 16px;
  height: 16px;
  background-position: -480px -16px;
}

.RBI {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}

.RBIES {
  width: 16px;
  height: 16px;
  background-position: -480px -48px;
}

.RBIF {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}

.RBIS {
  width: 16px;
  height: 16px;
  background-position: -480px -80px;
}

.RBIZ {
  width: 16px;
  height: 16px;
  background-position: -480px -96px;
}

.RBLS {
  width: 16px;
  height: 16px;
  background-position: -480px -112px;
}

.RBLX {
  width: 16px;
  height: 16px;
  background-position: -480px -128px;
}

.RBMC {
  width: 16px;
  height: 16px;
  background-position: -480px -144px;
}

.RBN {
  width: 16px;
  height: 16px;
  background-position: -480px -160px;
}

.RBO {
  width: 16px;
  height: 16px;
  background-position: -480px -176px;
}

.RBOYS {
  width: 16px;
  height: 16px;
  background-position: -480px -192px;
}

.RBS {
  width: 16px;
  height: 16px;
  background-position: -480px -208px;
}

.RBSHIB {
  width: 16px;
  height: 16px;
  background-position: -480px -224px;
}

.RBT {
  width: 16px;
  height: 16px;
  background-position: -480px -240px;
}

.RBTC {
  width: 16px;
  height: 16px;
  background-position: -480px -256px;
}

.RBUNNY {
  width: 16px;
  height: 16px;
  background-position: -480px -272px;
}

.RBW {
  width: 16px;
  height: 16px;
  background-position: -480px -288px;
}

.RBX {
  width: 16px;
  height: 16px;
  background-position: -480px -304px;
}

.RBXSamurai {
  width: 16px;
  height: 16px;
  background-position: -480px -320px;
}

.RBY {
  width: 16px;
  height: 16px;
  background-position: -480px -336px;
}

.RC {
  width: 16px;
  height: 16px;
  background-position: -480px -352px;
}

.RC2 {
  width: 16px;
  height: 16px;
  background-position: -480px -368px;
}

.RC20 {
  width: 16px;
  height: 16px;
  background-position: -480px -384px;
}

.RCADE {
  width: 16px;
  height: 16px;
  background-position: -480px -400px;
}

.RCC {
  width: 16px;
  height: 16px;
  background-position: -480px -416px;
}

.RCCC {
  width: 16px;
  height: 16px;
  background-position: -480px -432px;
}

.RCG {
  width: 16px;
  height: 16px;
  background-position: -480px -448px;
}

.RCH {
  width: 16px;
  height: 16px;
  background-position: -480px -464px;
}

.RCKC {
  width: 16px;
  height: 16px;
  background-position: -0 -480px;
}

.RCKT {
  width: 16px;
  height: 16px;
  background-position: -16px -480px;
}

.RCN {
  width: 16px;
  height: 16px;
  background-position: -32px -480px;
}

.RCNT {
  width: 16px;
  height: 16px;
  background-position: -48px -480px;
}

.RCT {
  width: 16px;
  height: 16px;
  background-position: -64px -480px;
}

.RCUBEV2 {
  width: 16px;
  height: 16px;
  background-position: -80px -480px;
}

.RD {
  width: 16px;
  height: 16px;
  background-position: -96px -480px;
}

.RD2E {
  width: 16px;
  height: 16px;
  background-position: -112px -480px;
}

.RDAI {
  width: 16px;
  height: 16px;
  background-position: -128px -480px;
}

.RDC {
  width: 16px;
  height: 16px;
  background-position: -144px -480px;
}

.RDD {
  width: 16px;
  height: 16px;
  background-position: -160px -480px;
}

.RDL {
  width: 16px;
  height: 16px;
  background-position: -176px -480px;
}

.RDN {
  width: 16px;
  height: 16px;
  background-position: -192px -480px;
}

.RDOGE {
  width: 16px;
  height: 16px;
  background-position: -208px -480px;
}

.RDPX {
  width: 16px;
  height: 16px;
  background-position: -224px -480px;
}

.RDR {
  width: 16px;
  height: 16px;
  background-position: -240px -480px;
}

.RDT {
  width: 16px;
  height: 16px;
  background-position: -256px -480px;
}

.REACT {
  width: 16px;
  height: 16px;
  background-position: -272px -480px;
}

.READ {
  width: 16px;
  height: 16px;
  background-position: -288px -480px;
}

.REAK {
  width: 16px;
  height: 16px;
  background-position: -304px -480px;
}

.REAL {
  width: 16px;
  height: 16px;
  background-position: -320px -480px;
}

.REALM {
  width: 16px;
  height: 16px;
  background-position: -336px -480px;
}

.REALTOKEN_8342_SCHAEFER_HWY_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -352px -480px;
}

.REALTOKEN_25097_ANDOVER_DR_DEARBORN_MI {
  width: 16px;
  height: 16px;
  background-position: -368px -480px;
}

.REALTOKEN_5942_AUDUBON_RD_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -384px -480px;
}

.REALTOKEN_16200_FULLERTON_AVE_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -400px -480px;
}

.REALTOKEN_18276_APPOLINE_ST_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -416px -480px;
}

.REALTOKEN_20200_LESURE_ST_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -432px -480px;
}

.REALTOKEN_15634_LIBERAL_ST_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -448px -480px;
}

.REALTOKEN_18900_MANSFIELD_ST_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -464px -480px;
}

.REALTOKEN_9336_PATTON_ST_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -480px -480px;
}

.REALTOKEN_10024_10028_APPOLINE_ST_DETROIT_MI {
  width: 16px;
  height: 16px;
  background-position: -496px -0;
}

.REAP {
  width: 16px;
  height: 16px;
  background-position: -496px -16px;
}

.REAPER {
  width: 16px;
  height: 16px;
  background-position: -496px -32px;
}

.REAU {
  width: 16px;
  height: 16px;
  background-position: -496px -48px;
}

.REB {
  width: 16px;
  height: 16px;
  background-position: -496px -64px;
}

.REB2 {
  width: 16px;
  height: 16px;
  background-position: -496px -80px;
}

.REBASE {
  width: 16px;
  height: 16px;
  background-position: -496px -96px;
}

.REBASEAPY {
  width: 16px;
  height: 16px;
  background-position: -496px -112px;
}

.REBL {
  width: 16px;
  height: 16px;
  background-position: -496px -128px;
}

.REC {
  width: 16px;
  height: 16px;
  background-position: -496px -144px;
}

.RECH {
  width: 16px;
  height: 16px;
  background-position: -496px -160px;
}

.RED {
  width: 16px;
  height: 16px;
  background-position: -496px -176px;
}

.REDBUFF {
  width: 16px;
  height: 16px;
  background-position: -496px -192px;
}

.REDC {
  width: 16px;
  height: 16px;
  background-position: -496px -208px;
}

.REDDOGE {
  width: 16px;
  height: 16px;
  background-position: -496px -224px;
}

.REDFEG {
  width: 16px;
  height: 16px;
  background-position: -496px -240px;
}

.REDFLOKI {
  width: 16px;
  height: 16px;
  background-position: -496px -256px;
}

.REDI {
  width: 16px;
  height: 16px;
  background-position: -496px -272px;
}

.REDINU {
  width: 16px;
  height: 16px;
  background-position: -496px -288px;
}

.REDKISHU {
  width: 16px;
  height: 16px;
  background-position: -496px -304px;
}

.REDPANDA {
  width: 16px;
  height: 16px;
  background-position: -496px -320px;
}

.REDPILL {
  width: 16px;
  height: 16px;
  background-position: -496px -336px;
}

.REDSHIBA {
  width: 16px;
  height: 16px;
  background-position: -496px -352px;
}

.REDZILLA {
  width: 16px;
  height: 16px;
  background-position: -496px -368px;
}

.REEC {
  width: 16px;
  height: 16px;
  background-position: -496px -384px;
}

.REEF {
  width: 16px;
  height: 16px;
  background-position: -496px -400px;
}

.REEFER {
  width: 16px;
  height: 16px;
  background-position: -496px -416px;
}

.REF {
  width: 16px;
  height: 16px;
  background-position: -496px -432px;
}

.REFI {
  width: 16px;
  height: 16px;
  background-position: -496px -448px;
}

.REFLECTO {
  width: 16px;
  height: 16px;
  background-position: -496px -464px;
}

.REFLEX_V2 {
  width: 16px;
  height: 16px;
  background-position: -496px -480px;
}

.REG {
  width: 16px;
  height: 16px;
  background-position: -0 -496px;
}

.REGEN {
  width: 16px;
  height: 16px;
  background-position: -16px -496px;
}

.REGI {
  width: 16px;
  height: 16px;
  background-position: -32px -496px;
}

.REGU {
  width: 16px;
  height: 16px;
  background-position: -48px -496px;
}

.REI {
  width: 16px;
  height: 16px;
  background-position: -64px -496px;
}

.REIGN {
  width: 16px;
  height: 16px;
  background-position: -80px -496px;
}

.REL {
  width: 16px;
  height: 16px;
  background-position: -96px -496px;
}

.RELAY {
  width: 16px;
  height: 16px;
  background-position: -112px -496px;
}

.RELI {
  width: 16px;
  height: 16px;
  background-position: -128px -496px;
}

.REM {
  width: 16px;
  height: 16px;
  background-position: -144px -496px;
}

.REMIT {
  width: 16px;
  height: 16px;
  background-position: -160px -496px;
}

.REN {
  width: 16px;
  height: 16px;
  background-position: -176px -496px;
}

.RENA {
  width: 16px;
  height: 16px;
  background-position: -192px -496px;
}

.RENASCENT {
  width: 16px;
  height: 16px;
  background-position: -208px -496px;
}

.RENBTC {
  width: 16px;
  height: 16px;
  background-position: -224px -496px;
}

.RENBTCCURVE {
  width: 16px;
  height: 16px;
  background-position: -240px -496px;
}

.RENDOGE {
  width: 16px;
  height: 16px;
  background-position: -256px -496px;
}

.RENFIL {
  width: 16px;
  height: 16px;
  background-position: -272px -496px;
}

.RENS {
  width: 16px;
  height: 16px;
  background-position: -288px -496px;
}

.RENT {
  width: 16px;
  height: 16px;
  background-position: -304px -496px;
}

.RENZEC {
  width: 16px;
  height: 16px;
  background-position: -320px -496px;
}

.REOSC {
  width: 16px;
  height: 16px;
  background-position: -336px -496px;
}

.REP {
  width: 16px;
  height: 16px;
  background-position: -352px -496px;
}

.REPO {
  width: 16px;
  height: 16px;
  background-position: -368px -496px;
}

.REQ {
  width: 16px;
  height: 16px;
  background-position: -384px -496px;
}

.RES {
  width: 16px;
  height: 16px;
  background-position: -400px -496px;
}

.RESQ {
  width: 16px;
  height: 16px;
  background-position: -416px -496px;
}

.REST {
  width: 16px;
  height: 16px;
  background-position: -432px -496px;
}

.RESTAURANTS {
  width: 16px;
  height: 16px;
  background-position: -448px -496px;
}

.RET {
  width: 16px;
  height: 16px;
  background-position: -464px -496px;
}

.RETA {
  width: 16px;
  height: 16px;
  background-position: -480px -496px;
}

.RETH {
  width: 16px;
  height: 16px;
  background-position: -496px -496px;
}

.RETH2 {
  width: 16px;
  height: 16px;
  background-position: -512px -0;
}

.rETH_h {
  width: 16px;
  height: 16px;
  background-position: -512px -16px;
}

.RETIRE {
  width: 16px;
  height: 16px;
  background-position: -512px -32px;
}

.RETO {
  width: 16px;
  height: 16px;
  background-position: -512px -48px;
}

.RETRO {
  width: 16px;
  height: 16px;
  background-position: -512px -64px;
}

.REU {
  width: 16px;
  height: 16px;
  background-position: -512px -80px;
}

.REUM {
  width: 16px;
  height: 16px;
  background-position: -512px -96px;
}

.REV {
  width: 16px;
  height: 16px;
  background-position: -512px -112px;
}

.REV3L {
  width: 16px;
  height: 16px;
  background-position: -512px -128px;
}

.REVA {
  width: 16px;
  height: 16px;
  background-position: -512px -144px;
}

.REVO {
  width: 16px;
  height: 16px;
  background-position: -512px -160px;
}

.REVU {
  width: 16px;
  height: 16px;
  background-position: -512px -176px;
}

.REVV {
  width: 16px;
  height: 16px;
  background-position: -512px -192px;
}

.REW {
  width: 16px;
  height: 16px;
  background-position: -512px -208px;
}

.REWARDS {
  width: 16px;
  height: 16px;
  background-position: -512px -224px;
}

.REX {
  width: 16px;
  height: 16px;
  background-position: -512px -240px;
}

.RFC {
  width: 16px;
  height: 16px;
  background-position: -512px -256px;
}

.RFCTR {
  width: 16px;
  height: 16px;
  background-position: -512px -272px;
}

.RFF {
  width: 16px;
  height: 16px;
  background-position: -512px -288px;
}

.RFG {
  width: 16px;
  height: 16px;
  background-position: -512px -304px;
}

.RFI {
  width: 16px;
  height: 16px;
  background-position: -512px -320px;
}

.RFOX {
  width: 16px;
  height: 16px;
  background-position: -512px -336px;
}

.RFR {
  width: 16px;
  height: 16px;
  background-position: -512px -352px;
}

.RFRUNK {
  width: 16px;
  height: 16px;
  background-position: -512px -368px;
}

.RFT {
  width: 16px;
  height: 16px;
  background-position: -512px -384px;
}

.RFUEL {
  width: 16px;
  height: 16px;
  background-position: -512px -400px;
}

.RFX {
  width: 16px;
  height: 16px;
  background-position: -512px -416px;
}

.RGEN {
  width: 16px;
  height: 16px;
  background-position: -512px -432px;
}

.RGK {
  width: 16px;
  height: 16px;
  background-position: -512px -448px;
}

.rGLD {
  width: 16px;
  height: 16px;
  background-position: -512px -464px;
}

.RGOLD {
  width: 16px;
  height: 16px;
  background-position: -512px -480px;
}

.RGP {
  width: 16px;
  height: 16px;
  background-position: -512px -496px;
}

.RGSI {
  width: 16px;
  height: 16px;
  background-position: -0 -512px;
}

.RGT {
  width: 16px;
  height: 16px;
  background-position: -16px -512px;
}

.RHEA {
  width: 16px;
  height: 16px;
  background-position: -32px -512px;
}

.RHINO {
  width: 16px;
  height: 16px;
  background-position: -48px -512px;
}

.RHO {
  width: 16px;
  height: 16px;
  background-position: -64px -512px;
}

.RHOBUSD {
  width: 16px;
  height: 16px;
  background-position: -80px -512px;
}

.RHOUSDC {
  width: 16px;
  height: 16px;
  background-position: -96px -512px;
}

.RHOUSDT {
  width: 16px;
  height: 16px;
  background-position: -112px -512px;
}

.RHP {
  width: 16px;
  height: 16px;
  background-position: -128px -512px;
}

.RHT {
  width: 16px;
  height: 16px;
  background-position: -144px -512px;
}

.RHYTHM {
  width: 16px;
  height: 16px;
  background-position: -160px -512px;
}

.RI {
  width: 16px;
  height: 16px;
  background-position: -176px -512px;
}

.RIA {
  width: 16px;
  height: 16px;
  background-position: -192px -512px;
}

.RIB {
  width: 16px;
  height: 16px;
  background-position: -208px -512px;
}

.RIBT {
  width: 16px;
  height: 16px;
  background-position: -224px -512px;
}

.RIC {
  width: 16px;
  height: 16px;
  background-position: -240px -512px;
}

.RICE {
  width: 16px;
  height: 16px;
  background-position: -256px -512px;
}

.RICH {
  width: 16px;
  height: 16px;
  background-position: -272px -512px;
}

.RichDoge {
  width: 16px;
  height: 16px;
  background-position: -288px -512px;
}

.RICI {
  width: 16px;
  height: 16px;
  background-position: -304px -512px;
}

.RICK {
  width: 16px;
  height: 16px;
  background-position: -320px -512px;
}

.RICKMORTYDOXX {
  width: 16px;
  height: 16px;
  background-position: -336px -512px;
}

.RIDE {
  width: 16px;
  height: 16px;
  background-position: -352px -512px;
}

.RIDERS {
  width: 16px;
  height: 16px;
  background-position: -368px -512px;
}

.RIDES {
  width: 16px;
  height: 16px;
  background-position: -384px -512px;
}

.RIDGE {
  width: 16px;
  height: 16px;
  background-position: -400px -512px;
}

.RIF {
  width: 16px;
  height: 16px;
  background-position: -416px -512px;
}

.RIFF {
  width: 16px;
  height: 16px;
  background-position: -432px -512px;
}

.RIFI {
  width: 16px;
  height: 16px;
  background-position: -448px -512px;
}

.RIFT {
  width: 16px;
  height: 16px;
  background-position: -464px -512px;
}

.RIGEL {
  width: 16px;
  height: 16px;
  background-position: -480px -512px;
}

.RIGY {
  width: 16px;
  height: 16px;
  background-position: -496px -512px;
}

.RIK {
  width: 16px;
  height: 16px;
  background-position: -512px -512px;
}

.RIKEN {
  width: 16px;
  height: 16px;
  background-position: -528px -0;
}

.RIL {
  width: 16px;
  height: 16px;
  background-position: -528px -16px;
}

.RIM {
  width: 16px;
  height: 16px;
  background-position: -528px -32px;
}

.RIMAU {
  width: 16px;
  height: 16px;
  background-position: -528px -48px;
}

.RIN {
  width: 16px;
  height: 16px;
  background-position: -528px -64px;
}

.RING {
  width: 16px;
  height: 16px;
  background-position: -528px -80px;
}

.RINGX {
  width: 16px;
  height: 16px;
  background-position: -528px -96px;
}

.RIO {
  width: 16px;
  height: 16px;
  background-position: -528px -112px;
}

.RIOT {
  width: 16px;
  height: 16px;
  background-position: -528px -128px;
}

.RIP {
  width: 16px;
  height: 16px;
  background-position: -528px -144px;
}

.RIPH {
  width: 16px;
  height: 16px;
  background-position: -528px -160px;
}

.RIPR {
  width: 16px;
  height: 16px;
  background-position: -528px -176px;
}

.RISE {
  width: 16px;
  height: 16px;
  background-position: -528px -192px;
}

.RISEUP {
  width: 16px;
  height: 16px;
  background-position: -528px -208px;
}

.RISKMOON {
  width: 16px;
  height: 16px;
  background-position: -528px -224px;
}

.RISQ {
  width: 16px;
  height: 16px;
  background-position: -528px -240px;
}

.RIT20 {
  width: 16px;
  height: 16px;
  background-position: -528px -256px;
}

.RITE {
  width: 16px;
  height: 16px;
  background-position: -528px -272px;
}

.RITO {
  width: 16px;
  height: 16px;
  background-position: -528px -288px;
}

.RIV {
  width: 16px;
  height: 16px;
  background-position: -528px -304px;
}

.RIV2 {
  width: 16px;
  height: 16px;
  background-position: -528px -320px;
}

.RIVRFLOKI {
  width: 16px;
  height: 16px;
  background-position: -528px -336px;
}

.RIYA {
  width: 16px;
  height: 16px;
  background-position: -528px -352px;
}

.RIZE {
  width: 16px;
  height: 16px;
  background-position: -528px -368px;
}

.RKF {
  width: 16px;
  height: 16px;
  background-position: -528px -384px;
}

.RKITTY {
  width: 16px;
  height: 16px;
  background-position: -528px -400px;
}

.RKN {
  width: 16px;
  height: 16px;
  background-position: -528px -416px;
}

.RKT {
  width: 16px;
  height: 16px;
  background-position: -528px -432px;
}

.RKTN {
  width: 16px;
  height: 16px;
  background-position: -528px -448px;
}

.RKTV {
  width: 16px;
  height: 16px;
  background-position: -528px -464px;
}

.RLB {
  width: 16px;
  height: 16px;
  background-position: -528px -480px;
}

.RLC {
  width: 16px;
  height: 16px;
  background-position: -528px -496px;
}

.RLD {
  width: 16px;
  height: 16px;
  background-position: -528px -512px;
}

.RLE {
  width: 16px;
  height: 16px;
  background-position: -0 -528px;
}

.RLM {
  width: 16px;
  height: 16px;
  background-position: -16px -528px;
}

.RLOKI {
  width: 16px;
  height: 16px;
  background-position: -32px -528px;
}

.RLOOP {
  width: 16px;
  height: 16px;
  background-position: -48px -528px;
}

.RLQ {
  width: 16px;
  height: 16px;
  background-position: -64px -528px;
}

.RLR {
  width: 16px;
  height: 16px;
  background-position: -80px -528px;
}

.RLT {
  width: 16px;
  height: 16px;
  background-position: -96px -528px;
}

.RLX {
  width: 16px;
  height: 16px;
  background-position: -112px -528px;
}

.RLY {
  width: 16px;
  height: 16px;
  background-position: -128px -528px;
}

.RM {
  width: 16px;
  height: 16px;
  background-position: -144px -528px;
}

.RMARS {
  width: 16px;
  height: 16px;
  background-position: -160px -528px;
}

.RMBL {
  width: 16px;
  height: 16px;
  background-position: -176px -528px;
}

.RMESH {
  width: 16px;
  height: 16px;
  background-position: -192px -528px;
}

.RMG {
  width: 16px;
  height: 16px;
  background-position: -208px -528px;
}

.RMOG {
  width: 16px;
  height: 16px;
  background-position: -224px -528px;
}

.RMOON {
  width: 16px;
  height: 16px;
  background-position: -240px -528px;
}

.RMPL {
  width: 16px;
  height: 16px;
  background-position: -256px -528px;
}

.RMRK {
  width: 16px;
  height: 16px;
  background-position: -272px -528px;
}

.RMT {
  width: 16px;
  height: 16px;
  background-position: -288px -528px;
}

.RMTX {
  width: 16px;
  height: 16px;
  background-position: -304px -528px;
}

.RMX {
  width: 16px;
  height: 16px;
  background-position: -320px -528px;
}

.RNB {
  width: 16px;
  height: 16px;
  background-position: -336px -528px;
}

.RNBW {
  width: 16px;
  height: 16px;
  background-position: -352px -528px;
}

.RND {
  width: 16px;
  height: 16px;
  background-position: -368px -528px;
}

.RNDM {
  width: 16px;
  height: 16px;
  background-position: -384px -528px;
}

.RNDR {
  width: 16px;
  height: 16px;
  background-position: -400px -528px;
}

.RNG {
  width: 16px;
  height: 16px;
  background-position: -416px -528px;
}

.RNK {
  width: 16px;
  height: 16px;
  background-position: -432px -528px;
}

.RNO {
  width: 16px;
  height: 16px;
  background-position: -448px -528px;
}

.RNT {
  width: 16px;
  height: 16px;
  background-position: -464px -528px;
}

.RNTB {
  width: 16px;
  height: 16px;
  background-position: -480px -528px;
}

.RNX {
  width: 16px;
  height: 16px;
  background-position: -496px -528px;
}

.ROAD {
  width: 16px;
  height: 16px;
  background-position: -512px -528px;
}

.ROAR {
  width: 16px;
  height: 16px;
  background-position: -528px -528px;
}

.ROBBIN {
  width: 16px;
  height: 16px;
  background-position: -544px -0;
}

.ROBBY {
  width: 16px;
  height: 16px;
  background-position: -544px -16px;
}

.ROBET {
  width: 16px;
  height: 16px;
  background-position: -544px -32px;
}

.ROBIN {
  width: 16px;
  height: 16px;
  background-position: -544px -48px;
}

.ROBO {
  width: 16px;
  height: 16px;
  background-position: -544px -64px;
}

.ROBODOGE {
  width: 16px;
  height: 16px;
  background-position: -544px -80px;
}

.ROBOSHIB {
  width: 16px;
  height: 16px;
  background-position: -544px -96px;
}

.ROC {
  width: 16px;
  height: 16px;
  background-position: -544px -112px;
}

.ROCK {
  width: 16px;
  height: 16px;
  background-position: -544px -128px;
}

.ROCK2 {
  width: 16px;
  height: 16px;
  background-position: -544px -144px;
}

.ROCKET {
  width: 16px;
  height: 16px;
  background-position: -544px -160px;
}

.RocketBUSD {
  width: 16px;
  height: 16px;
  background-position: -544px -176px;
}

.ROCKETSHIB {
  width: 16px;
  height: 16px;
  background-position: -544px -192px;
}

.ROCKI {
  width: 16px;
  height: 16px;
  background-position: -544px -208px;
}

.ROCKS {
  width: 16px;
  height: 16px;
  background-position: -544px -224px;
}

.ROCKSTAR {
  width: 16px;
  height: 16px;
  background-position: -544px -240px;
}

.ROCKY {
  width: 16px;
  height: 16px;
  background-position: -544px -256px;
}

.ROCO {
  width: 16px;
  height: 16px;
  background-position: -544px -272px;
}

.ROD {
  width: 16px;
  height: 16px;
  background-position: -544px -288px;
}

.ROF {
  width: 16px;
  height: 16px;
  background-position: -544px -304px;
}

.ROFI {
  width: 16px;
  height: 16px;
  background-position: -544px -320px;
}

.ROG {
  width: 16px;
  height: 16px;
  background-position: -544px -336px;
}

.ROGAN {
  width: 16px;
  height: 16px;
  background-position: -544px -352px;
}

.ROGE {
  width: 16px;
  height: 16px;
  background-position: -544px -368px;
}

.ROGER {
  width: 16px;
  height: 16px;
  background-position: -544px -384px;
}

.ROGUE {
  width: 16px;
  height: 16px;
  background-position: -544px -400px;
}

.ROI {
  width: 16px;
  height: 16px;
  background-position: -544px -416px;
}

.ROK {
  width: 16px;
  height: 16px;
  background-position: -544px -432px;
}

.ROKK {
  width: 16px;
  height: 16px;
  background-position: -544px -448px;
}

.ROLL {
  width: 16px;
  height: 16px;
  background-position: -544px -464px;
}

.ROM {
  width: 16px;
  height: 16px;
  background-position: -544px -480px;
}

.ROME {
  width: 16px;
  height: 16px;
  background-position: -544px -496px;
}

.ROMEODOGE {
  width: 16px;
  height: 16px;
  background-position: -544px -512px;
}

.RON {
  width: 16px;
  height: 16px;
  background-position: -544px -528px;
}

.RONINGMZ {
  width: 16px;
  height: 16px;
  background-position: -0 -544px;
}

.ROO {
  width: 16px;
  height: 16px;
  background-position: -16px -544px;
}

.ROOBEE {
  width: 16px;
  height: 16px;
  background-position: -32px -544px;
}

.ROOK {
  width: 16px;
  height: 16px;
  background-position: -48px -544px;
}

.ROOM {
  width: 16px;
  height: 16px;
  background-position: -64px -544px;
}

.ROOT {
  width: 16px;
  height: 16px;
  background-position: -80px -544px;
}

.ROPE {
  width: 16px;
  height: 16px;
  background-position: -96px -544px;
}

.ROS {
  width: 16px;
  height: 16px;
  background-position: -112px -544px;
}

.ROSE {
  width: 16px;
  height: 16px;
  background-position: -128px -544px;
}

.ROSN {
  width: 16px;
  height: 16px;
  background-position: -144px -544px;
}

.ROSS {
  width: 16px;
  height: 16px;
  background-position: -160px -544px;
}

.ROT {
  width: 16px;
  height: 16px;
  background-position: -176px -544px;
}

.ROTO {
  width: 16px;
  height: 16px;
  background-position: -192px -544px;
}

.ROTTEN {
  width: 16px;
  height: 16px;
  background-position: -208px -544px;
}

.ROTTS {
  width: 16px;
  height: 16px;
  background-position: -224px -544px;
}

.ROUL {
  width: 16px;
  height: 16px;
  background-position: -240px -544px;
}

.ROUSH {
  width: 16px;
  height: 16px;
  background-position: -256px -544px;
}

.ROUTE {
  width: 16px;
  height: 16px;
  background-position: -272px -544px;
}

.ROVER {
  width: 16px;
  height: 16px;
  background-position: -288px -544px;
}

.ROX {
  width: 16px;
  height: 16px;
  background-position: -304px -544px;
}

.ROY {
  width: 16px;
  height: 16px;
  background-position: -320px -544px;
}

.ROYA {
  width: 16px;
  height: 16px;
  background-position: -336px -544px;
}

.ROYAL {
  width: 16px;
  height: 16px;
  background-position: -352px -544px;
}

.ROYALADA {
  width: 16px;
  height: 16px;
  background-position: -368px -544px;
}

.ROZ {
  width: 16px;
  height: 16px;
  background-position: -384px -544px;
}

.RPC {
  width: 16px;
  height: 16px;
  background-position: -400px -544px;
}

.RPD {
  width: 16px;
  height: 16px;
  background-position: -416px -544px;
}

.RPEPE {
  width: 16px;
  height: 16px;
  background-position: -432px -544px;
}

.RPG {
  width: 16px;
  height: 16px;
  background-position: -448px -544px;
}

.RPI {
  width: 16px;
  height: 16px;
  background-position: -464px -544px;
}

.RPL {
  width: 16px;
  height: 16px;
  background-position: -480px -544px;
}

.RPM {
  width: 16px;
  height: 16px;
  background-position: -496px -544px;
}

.RPR {
  width: 16px;
  height: 16px;
  background-position: -512px -544px;
}

.RPS {
  width: 16px;
  height: 16px;
  background-position: -528px -544px;
}

.RPST {
  width: 16px;
  height: 16px;
  background-position: -544px -544px;
}

.RPT {
  width: 16px;
  height: 16px;
  background-position: -560px -0;
}

.RPTC {
  width: 16px;
  height: 16px;
  background-position: -560px -16px;
}

.RPTR {
  width: 16px;
  height: 16px;
  background-position: -560px -32px;
}

.RPZX {
  width: 16px;
  height: 16px;
  background-position: -560px -48px;
}

.RR {
  width: 16px;
  height: 16px;
  background-position: -560px -64px;
}

.RRB {
  width: 16px;
  height: 16px;
  background-position: -560px -80px;
}

.RRC {
  width: 16px;
  height: 16px;
  background-position: -560px -96px;
}

.RRT {
  width: 16px;
  height: 16px;
  background-position: -560px -112px;
}

.RS {
  width: 16px;
  height: 16px;
  background-position: -560px -128px;
}

.RSC {
  width: 16px;
  height: 16px;
  background-position: -560px -144px;
}

.RSCT {
  width: 16px;
  height: 16px;
  background-position: -560px -160px;
}

.RSHIB {
  width: 16px;
  height: 16px;
  background-position: -560px -176px;
}

.RSK {
  width: 16px;
  height: 16px;
  background-position: -560px -192px;
}

.RSP {
  width: 16px;
  height: 16px;
  background-position: -560px -208px;
}

.RSPT {
  width: 16px;
  height: 16px;
  background-position: -560px -224px;
}

.RSR {
  width: 16px;
  height: 16px;
  background-position: -560px -240px;
}

.RSS3 {
  width: 16px;
  height: 16px;
  background-position: -560px -256px;
}

.RST {
  width: 16px;
  height: 16px;
  background-position: -560px -272px;
}

.RSTR {
  width: 16px;
  height: 16px;
  background-position: -560px -288px;
}

.RSUN {
  width: 16px;
  height: 16px;
  background-position: -560px -304px;
}

.RSV {
  width: 16px;
  height: 16px;
  background-position: -560px -320px;
}

.RSX {
  width: 16px;
  height: 16px;
  background-position: -560px -336px;
}

.RTAU {
  width: 16px;
  height: 16px;
  background-position: -560px -352px;
}

.RTAX3 {
  width: 16px;
  height: 16px;
  background-position: -560px -368px;
}

.RTC {
  width: 16px;
  height: 16px;
  background-position: -560px -384px;
}

.RTD {
  width: 16px;
  height: 16px;
  background-position: -560px -400px;
}

.RTE {
  width: 16px;
  height: 16px;
  background-position: -560px -416px;
}

.RTF {
  width: 16px;
  height: 16px;
  background-position: -560px -432px;
}

.RTG {
  width: 16px;
  height: 16px;
  background-position: -560px -448px;
}

.RTGN {
  width: 16px;
  height: 16px;
  background-position: -560px -464px;
}

.RTH {
  width: 16px;
  height: 16px;
  background-position: -560px -480px;
}

.RTK {
  width: 16px;
  height: 16px;
  background-position: -560px -496px;
}

.RTKN {
  width: 16px;
  height: 16px;
  background-position: -560px -512px;
}

.RTM {
  width: 16px;
  height: 16px;
  background-position: -560px -528px;
}

.RTO {
  width: 16px;
  height: 16px;
  background-position: -560px -544px;
}

.RTT {
  width: 16px;
  height: 16px;
  background-position: -0 -560px;
}

.RTTV2 {
  width: 16px;
  height: 16px;
  background-position: -16px -560px;
}

.RU {
  width: 16px;
  height: 16px;
  background-position: -32px -560px;
}

.RUBX {
  width: 16px;
  height: 16px;
  background-position: -48px -560px;
}

.RUBY {
  width: 16px;
  height: 16px;
  background-position: -64px -560px;
}

.RUC {
  width: 16px;
  height: 16px;
  background-position: -80px -560px;
}

.RUDOLPH {
  width: 16px;
  height: 16px;
  background-position: -96px -560px;
}

.RUFF {
  width: 16px;
  height: 16px;
  background-position: -112px -560px;
}

.RUFFY {
  width: 16px;
  height: 16px;
  background-position: -128px -560px;
}

.RUG {
  width: 16px;
  height: 16px;
  background-position: -144px -560px;
}

.RUGBID {
  width: 16px;
  height: 16px;
  background-position: -160px -560px;
}

.RUGBUST {
  width: 16px;
  height: 16px;
  background-position: -176px -560px;
}

.RUGPULL {
  width: 16px;
  height: 16px;
  background-position: -192px -560px;
}

.RUGZ {
  width: 16px;
  height: 16px;
  background-position: -208px -560px;
}

.RULER {
  width: 16px;
  height: 16px;
  background-position: -224px -560px;
}

.RUN {
  width: 16px;
  height: 16px;
  background-position: -240px -560px;
}

.RUNDOGE {
  width: 16px;
  height: 16px;
  background-position: -256px -560px;
}

.RUNE {
  width: 16px;
  height: 16px;
  background-position: -272px -560px;
}

.RUNES {
  width: 16px;
  height: 16px;
  background-position: -288px -560px;
}

.RUNGEM {
  width: 16px;
  height: 16px;
  background-position: -304px -560px;
}

.RUNNERS {
  width: 16px;
  height: 16px;
  background-position: -320px -560px;
}

.RUNNOW {
  width: 16px;
  height: 16px;
  background-position: -336px -560px;
}

.RUNx {
  width: 16px;
  height: 16px;
  background-position: -352px -560px;
}

.RUP {
  width: 16px;
  height: 16px;
  background-position: -368px -560px;
}

.RUPEE {
  width: 16px;
  height: 16px;
  background-position: -384px -560px;
}

.RUPT {
  width: 16px;
  height: 16px;
  background-position: -400px -560px;
}

.RUPX {
  width: 16px;
  height: 16px;
  background-position: -416px -560px;
}

.RUSD {
  width: 16px;
  height: 16px;
  background-position: -432px -560px;
}

.RUSH {
  width: 16px;
  height: 16px;
  background-position: -448px -560px;
}

.RUTC {
  width: 16px;
  height: 16px;
  background-position: -464px -560px;
}

.RUYI {
  width: 16px;
  height: 16px;
  background-position: -480px -560px;
}

.RUZE {
  width: 16px;
  height: 16px;
  background-position: -496px -560px;
}

.RVC {
  width: 16px;
  height: 16px;
  background-position: -512px -560px;
}

.RVE {
  width: 16px;
  height: 16px;
  background-position: -528px -560px;
}

.RVEL {
  width: 16px;
  height: 16px;
  background-position: -544px -560px;
}

.RVF {
  width: 16px;
  height: 16px;
  background-position: -560px -560px;
}

.RVL {
  width: 16px;
  height: 16px;
  background-position: -576px -0;
}

.RVLNG {
  width: 16px;
  height: 16px;
  background-position: -576px -16px;
}

.RVLT {
  width: 16px;
  height: 16px;
  background-position: -576px -32px;
}

.RVLVR {
  width: 16px;
  height: 16px;
  background-position: -576px -48px;
}

.RVMT {
  width: 16px;
  height: 16px;
  background-position: -576px -64px;
}

.RVN {
  width: 16px;
  height: 16px;
  background-position: -576px -80px;
}

.RVP {
  width: 16px;
  height: 16px;
  background-position: -576px -96px;
}

.RVR {
  width: 16px;
  height: 16px;
  background-position: -576px -112px;
}

.RVRS {
  width: 16px;
  height: 16px;
  background-position: -576px -128px;
}

.RVST {
  width: 16px;
  height: 16px;
  background-position: -576px -144px;
}

.RVT {
  width: 16px;
  height: 16px;
  background-position: -576px -160px;
}

.RVX {
  width: 16px;
  height: 16px;
  background-position: -576px -176px;
}

.RVZ {
  width: 16px;
  height: 16px;
  background-position: -576px -192px;
}

.RWD {
  width: 16px;
  height: 16px;
  background-position: -576px -208px;
}

.RWN {
  width: 16px;
  height: 16px;
  background-position: -576px -224px;
}

.RWS {
  width: 16px;
  height: 16px;
  background-position: -576px -240px;
}

.RWSC {
  width: 16px;
  height: 16px;
  background-position: -576px -256px;
}

.RX {
  width: 16px;
  height: 16px;
  background-position: -576px -272px;
}

.RXC {
  width: 16px;
  height: 16px;
  background-position: -576px -288px;
}

.RXCG {
  width: 16px;
  height: 16px;
  background-position: -576px -304px;
}

.RXS {
  width: 16px;
  height: 16px;
  background-position: -576px -320px;
}

.RYA {
  width: 16px;
  height: 16px;
  background-position: -576px -336px;
}

.RYIP {
  width: 16px;
  height: 16px;
  background-position: -576px -352px;
}

.RYIU {
  width: 16px;
  height: 16px;
  background-position: -576px -368px;
}

.RYM {
  width: 16px;
  height: 16px;
  background-position: -576px -384px;
}

.RYMA {
  width: 16px;
  height: 16px;
  background-position: -576px -400px;
}

.RYO {
  width: 16px;
  height: 16px;
  background-position: -576px -416px;
}

.RYOMA {
  width: 16px;
  height: 16px;
  background-position: -576px -432px;
}

.RYOSHI {
  width: 16px;
  height: 16px;
  background-position: -576px -448px;
}

.RYOSHIMOTO {
  width: 16px;
  height: 16px;
  background-position: -576px -464px;
}

.RYU {
  width: 16px;
  height: 16px;
  background-position: -576px -480px;
}

.RYUJI {
  width: 16px;
  height: 16px;
  background-position: -576px -496px;
}

.RYZEINU {
  width: 16px;
  height: 16px;
  background-position: -576px -512px;
}

.RZN {
  width: 16px;
  height: 16px;
  background-position: -576px -528px;
}

.RZRV {
  width: 16px;
  height: 16px;
  background-position: -576px -544px;
}
