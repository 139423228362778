.n0NE,
.n0XBTC,
.n0XMR,
.n0XPAD,
.n0XZX,
.n1AI,
.n1ART,
.n1BIT,
.n1BOX,
.n1CT,
.n1DOGE,
.n1EARTH,
.n1ECO,
.n1FLR,
.n1GOLD,
.n1INCH,
.n1INCHUP,
.n1INCHDOWN,
.n1INCHPUNCH,
.n1MCT,
.n1MIL,
.n1MT,
.n1NFT,
.n1PECO,
.n1PEGG,
.n1SG,
.n1SOL,
.n1ST,
.n1SWAP,
.n1TO11,
.n1TRC,
.n1_UP,
.n1UP,
.n1WO,
.n1X2,
.n2BASED,
.n2CHAINLINKS,
.n2CRZ,
.n2GIVE,
.n2GOSHI,
.n2KEY,
.n2LC,
.n2OMB,
.n2SHARES,
.n2XDN,
.n3COMMAS,
.n3CRV,
.n3CS,
.n3D,
.n3DC,
.n3DOG,
.n3SHARE,
.n3OMB,
.n3ULL,
.n3WEB,
.n3XT,
.n4ART,
.n4B,
.n4INT,
.n4JNET,
.n4MOVE,
.n4MW,
.n4PLAY,
.n4STC,
.n5IRE,
.n5STAR,
.n5TABLE,
.n7ADD,
.n7E,
.n7SHARE,
.n7UP,
.n8BIT,
.n8DAO,
.n8FI,
.n8PAY,
.n10SET,
.n18C,
.n42,
.n50X,
.n99DEFI,
.n100K,
.n100X,
.n300,
.n420X,
.n452B,
.n611,
.n777,
.n808TA,
.n867,
.n888,
.n999,
.n1337,
.n2022,
.n2022M,
.n2030FLOKI,
.A_BEE,
.A_O_T,
.A,
.A1A,
.A2A,
.A4,
.A5T,
.A8,
.A86,
.AAA,
.AAB,
.AABC,
.AAC,
.AADA,
.AAG,
.AALTO,
.AAMMUSDT,
.AAMMWBTC,
.AANG,
.AMC,
.ABNB,
.AMD,
.AAPL,
.AAPT,
.AAPX,
.AART,
.AAT,
.aAVAXb,
.AAVAXC,
.AAVE,
.AAVEDOWN,
.ADADOWN,
.AAVEUP,
.ADAUP,
.ABA,
.ABAKE,
.ABAT,
.ABB,
.ABBC,
.ABBY,
.ABC,
.ABC123,
.ABCD,
.ABCO,
.ABDT,
.ABET,
.ABEY,
.ABI,
.ABIC,
.ABIT,
.ABL,
.ABLE,
.aBLOCK,
.aBNBb,
.aBNBc,
.ABOAT,
.ABP,
.ABR,
.ABS,
.ABST,
.ABT,
.ABTC,
.ABTM,
.ABU,
.ABUSD,
.ABX,
.ABY,
.ABYS,
.ABYSS,
.ABZ,
.AC,
.AC3,
.ACA,
.ACAB,
.ACAD,
.ACADA,
.ACAP,
.ACAT,
.ACC,
.ACCEL,
.ACCG,
.ACDC,
.ACDX,
.ACE,
.ACED,
.ACES,
.ACH,
.ACITY,
.ACK,
.ACM,
.ACMD,
.ACOIN,
.ACP,
.ACPT,
.ACQ,
.ACRE,
.ACRYL,
.ACS,
.ACSI,
.ACT,
.ACTN,
.ACU,
.ACX,
.ACXT,
.ACY,
.ACYC,
.AD,
.AD2,
.AD3,
.ADA,
.ADAB,
.ALTBULL,
.ALGOBULL,
.ADABULL,
.ALTBEAR,
.ALTMOON,
.ALGOBEAR,
.ADABEAR,
.ADABOY,
.ADACASH,
.ADAFI,
.ADAFLECT,
.ALTHALF,
.ADAHEDGE,
.ADAHALF,
.ALGOHALF,
.ALGOHEDGE,
.ADAI,
.ADAL,
.ADAM,
.ADANA,
.ADAO,
.ADAPAD,
.ADAT,
.ADAX,
.ADB,
.ADC,
.ADCO,
.ADD,
.ADDR,
.ADE,
.ADEL,
.ADEN,
.ADENA,
.ADGNZ,
.ADH,
.ADI,
.ADINU,
.ADK,
.ADL,
.ADM,
.ADMC,
.ADN,
.ADOG,
.ADOGE,
.ADOTB,
.ADP,
.ADR,
.ADREAM,
.ADS,
.ADT,
.ADTX,
.ADV2,
.ADVAR,
.ADVN,
.ADX,
.ADXX,
.ADZ,
.AE,
.AEC,
.AEG,
.AEL,
.AELIN,
.AEM,
.AEN,
.aENJ,
.AENJ,
.AENS,
.AEON,
.AER,
.AERGO,
.AERO,
.AERO_V2,
.AES,
.AET,
.AETERNA,
.aETH,
.AETH,
.aETHb,
.aETHc,
.AEVO,
.AEXP,
.AFARM,
.AFC,
.AFDLT,
.AFEN,
.AFFINITY,
.AFIN,
.AFIT,
.AFK,
.AFLOKI,
.AFM,
.AFO,
.AFP,
.AF_PRESALEDAO,
.AFR,
.AFRICA,
.AFRO,
.AfroX,
.AFT,
.aFTMb,
.AFTMC,
.AFTRBCK,
.AFU,
.AFYON,
.AG,
.AG8,
.AGA,
.AGAIN,
.AGAR,
.AGC,
.AGD,
.AGE,
.AGENTSHIBAINU,
.AGEUR,
.AGF,
.AGFI,
.AGGL,
.AGIX,
.AGL,
.AGLD,
.AGLT,
.AGLYPH,
.AGM,
.AGN,
.AGO,
.AGOLP,
.AGORA,
.AGOV,
.AGRI,
.AGRO,
.AGRS,
.AGS,
.AGSPAD,
.AGT,
.AGV,
.AGVC,
.AGVE,
.AGX,
.AHA,
.AHE,
.AHF,
.AHOUSE,
.AHT,
.AI,
.AIB,
.AIC,
.AICO,
.AID,
.AIDI,
.AIDOC,
.AIDUS,
.AIE,
.AIM,
.AIME,
.AIMS,
.AIMX,
.AIN,
.AINU,
.AION,
.AIOZ,
.AIP,
.AIQ,
.AIR,
.AIRI,
.AIRMOON,
.AIRPAY,
.AIRT,
.AIRX,
.AIT,
.AITECH,
.AITRA,
.AIV,
.AIX,
.AIZA,
.AJE,
.AJRC,
.AK12,
.AKA,
.AKAMARU,
.AKC,
.AKIHIKO,
.AKIRA,
.AKITA,
.AKITAX,
.AKL,
.AKM,
.AKN,
.AKNC,
.AKONG,
.AKREP,
.AKRO,
.AKSMB,
.AKT,
.AKTA,
.AKTIO,
.ALA,
.ALANO,
.ALB,
.ALBT,
.ALC,
.ALCUP,
.ALCX,
.ALUSD,
.ALD,
.aLEND,
.ALEPH,
.ALF,
.ALG,
.ALGA,
.ALGB,
.ALGO,
.ALGOBLK,
.ALGOP,
.ALGOPAD,
.ALGX,
.ALI,
.ALIAS,
.ALICE,
.ALICN,
.ALIEN,
.ALINK,
.ALIS,
.ALIST,
.ALIX,
.ALK,
.ALKOM,
.ALL,
.ALLBI,
.ALLEY,
.ALLN,
.ALLOWANCE,
.ALLOY,
.ALLUO,
.ALLWIN,
.ALM,
.ALMX,
.ALN,
.ALOHA,
.ALOT,
.ALP,
.ALPA,
.ALPACA,
.ALPH,
.ALPHA,
.ALPHASHIB,
.ALPHR,
.ALPINE,
.AMZN,
.ANGLE,
.ARKK,
.ATOMBEAR,
.ATOMBULL,
.ATOMHALF,
.ATOMHEDGE,
.BABA,
.BALBEAR,
.BALBULL,
.BALHALF,
.BCHDOOM,
.BCHDOWN,
.BCHHALF,
.BCHMOON,
.BCHUP,
.BEARSHIT,
.BILI,
.BNBDOOM,
.BNBDOWN,
.BNBHALF,
.BNBHEDGE,
.BNBMOON,
.BNBUP,
.BSVMOON,
.BTCDOWN,
.BTCUP,
.BTMXBEAR,
.BTMXBULL,
.BTMXHALF,
.BTMXHEDGE,
.BULLSHIT,
.BYND,
.COMPBEAR,
.COMPBULL,
.COMPHEDGE,
.CUSDTBEAR,
.CUSDTBULL,
.CUSDTHALF,
.CUSDTHEDGE,
.ALPY,
.ALT,
.ALTB,
.ALTER,
.ALTERA,
.ALTRU,
.ALTS,
.ALTX,
.ALU,
.ALUCARD,
.ALV,
.ALVN,
.ALX,
.ALY,
.ALYA,
.AM,
.AMA,
.AMANA,
.AMAS,
.aMATICb,
.AMATICC,
.AMAZINGTEAM,
.AMB,
.AMBROS,
.AMDG,
.AME,
.AMF,
.AMG,
.AMGO,
.AMH,
.AMI,
.AMIO,
.AMIX,
.AMKR,
.AMLT,
.AMM,
.AMMO,
.AMN,
.AMO,
.AMON,
.AMOR,
.AMP,
.AMPL,
.AMPLE,
.AMPT,
.AMS,
.AMSTAFF,
.AMT,
.AMU,
.AMY,
.AMZ,
.AMZE,
.ANA,
.ANC,
.ANCE,
.ANCHOR,
.ANCT,
.ANCW,
.ANDX,
.ANFT,
.ANG,
.ANGEL,
.ANGL,
.AngrySquid,
.ANI,
.ANIA,
.ANIM,
.ANJ,
.ANJI,
.ANK,
.ANKR,
.ANM,
.ANML,
.ANN,
.ANO,
.ANODE,
.ANOM,
.ANON,
.ANONFLOKI,
.ANONS,
.ANORTIS,
.ANPAN,
.ANRX,
.ANS,
.ANSR,
.ANT,
.ANTA,
.ANTE,
.ANTEX,
.ANTG,
.ANTI,
.ANTIS,
.ANUBI,
.ANV,
.ANW,
.ANX,
.ANY,
.ANYAN,
.ANYP,
.AOA,
.AOE,
.AOF,
.AOG,
.AOK,
.AOM,
.AORA,
.AOS,
.AOST,
.AOW,
.AP3,
.APA,
.APACHE,
.APAD,
.APAY,
.APC,
.APE,
.APEBORG,
.APEBOYS,
.APEBUSD,
.APECOIN,
.APED,
.APEF,
.APEFUND,
.APEIN,
.APENOUT,
.APES,
.APEU,
.APE_X,
.APEX,
.APG,
.APH,
.API,
.API3,
.APIDAI,
.APIX,
.APL,
.APLP,
.APM,
.APN,
.APOC,
.APOLLO,
.APP,
.APPC,
.APPLE,
.APPLEB,
.APR,
.APRIL,
.APT,
.APU,
.APW,
.APX,
.APXP,
.APY,
.APYS,
.AQR,
.AQS,
.AQT,
.AQU,
.AQUA,
.AQUAGOAT,
.AQUAGOAT_old_,
.AQUAPIG,
.AQUARI,
.AR,
.ARA,
.ARATA,
.ARAW,
.ARAZM,
.ARB,
.ARBIS,
.ARBS,
.ARBYS,
.ARC,
.ARCANE,
.ARCANELEAGUE,
.ARCANINEINU,
.ARCC,
.ARCG,
.ARCHA,
.ARCHE,
.ARCO,
.ARCONA,
.ARCT,
.ARCX,
.ARD,
.ARDN,
.ARDR,
.ARDX,
.ARE,
.AREN,
.ARENA,
.AREP,
.AREPA,
.ARES,
.ARF,
.ARG,
.ARGO,
.ARGON,
.ARGP,
.ARGUS,
.ARI,
.ARI10,
.ARIA20,
.ARIGATO,
.ARION,
.ARIX,
.ARK,
.ARKER,
.ARKN,
.ARKS,
.ARM,
.ARMA,
.ARMD,
.ARMOR,
.ARMR,
.ARN,
.ARNO,
.ARNX,
.arNXM,
.ARO,
.ARPA,
.ARQ,
.ARR,
.ARRB,
.ARRO,
.ARRR,
.ART,
.ARTE,
.ARTEM,
.ARTEON,
.ARTEQ,
.ARTEX,
.ARTH,
.ARTHX,
.ARTI,
.ARTIC,
.ARTICUNO,
.ARTII,
.ARTIS,
.ARTK,
.ARTL,
.ARTM,
.ARTR,
.ARTRINO,
.ARTS,
.ARTX,
.ARTY,
.ARV,
.ARVO,
.ARW,
.ARX,
.ARXO,
.ARY,
.ARYA,
.ARZ,
.ASA,
.ASAC,
.ASAFE,
.ASAP,
.ASB,
.ASC,
.ASD,
.ASDEX,
.ASET,
.ASG,
.ASGARD,
.ASGARDV2,
.ASH,
.ASHARE,
.ASHIB,
.ASHIT,
.ASI,
.ASIA,
.ASIMI,
.ASIX,
.ASIX\+,
.ASK,
.ASKO,
.ASLA,
.ASM,
.ASND,
.ASNT,
.ASNX,
.ASP,
.ASPEN,
.ASPO,
.ASR,
.ASS,
.ASSA,
.ASSET,
.ASSY,
.AST,
.ASTA,
.ASTAKE,
.ASTAR,
.ASTO,
.ASTR,
.ASTRA,
.ASTRO,
.ASTROGOLD,
.ASUKA,
.ASUNA,
.ASUNAINU,
.ASUSD,
.ASV,
.ASVA,
.ASW,
.ASWAP,
.ASY,
.AT,
.ATA,
.ATB,
.ATC,
.ATCC,
.ATD,
.ATEM,
.ATF,
.ATFI,
.ATH,
.ATHEN,
.ATIS,
.ATK,
.ATL,
.ATLAS,
.ATLO,
.ATLS,
.ATLX,
.ATM,
.ATM\$,
.ATMC,
.ATMI,
.ATMN,
.ATMOS,
.ATMSSFT,
.ATMUP,
.ATN,
.ATNIR,
.ATOLO,
.ATOM,
.ATOMIC,
.ATP,
.ATPAD,
.ATPC,
.ATR,
.ATRI,
.ATS,
.ATT,
.ATTN,
.ATTR,
.ATUSD,
.ATX,
.ATYNE,
.AU,
.AUC,
.AUCTION,
.AUDIO,
.AUDIT,
.AUDT,
.AUDX,
.AUMI,
.AUN,
.AUNIT,
.AUR,
.AURA,
.AUREUSRH,
.AURO,
.AURORA,
.AURUM,
.AURY,
.AUSCM,
.AUSD,
.AUSDC,
.AUSDT,
.AUST,
.AUT,
.AUTO,
.AUTZ,
.AUV,
.AUX,
.AV,
.AVA,
.Avado,
.AVAI,
.AVAK,
.AVAL,
.AVAMIM,
.AVAO,
.AVAPAY,
.AVAT,
.AVATAR,
.AVAX,
.AVAXD,
.AVAXUP,
.AVC,
.AVDO,
.AVE,
.AVEC,
.AVERSE,
.AVG,
.AVH,
.AVI,
.AVIAN,
.AVINOC,
.AVL,
.AVME,
.AVN,
.AVNGRS,
.AVO,
.AVR,
.AVS,
.AVT,
.AVTIME,
.AVX,
.AVXL,
.AVXT,
.AWAR,
.AWBTC,
.AWC,
.AWF,
.AWG,
.AWO,
.AWOOL,
.AWS,
.aWSB,
.AWX,
.AXA,
.AXAI,
.AXC,
.AXE,
.AXEL,
.AXI,
.AXIA,
.AXIAL,
.AXIEBCH,
.AXIN,
.AXIOM,
.AXIS,
.AXL,
.AXN,
.AXO,
.AXPR,
.AXS,
.AXSD,
.AXUS,
.AXW,
.AXZ,
.AYA,
.AYFI,
.AZ,
.AZART,
.AZBI,
.AZERO,
.AZIT,
.AZRX,
.AZT,
.AZUKI,
.AZUM,
.AZW,
.AZX,
.AZZR,
.B1P,
.B1TC,
.B2G,
.B2M,
.B2P,
.B2U,
.B2X,
.B3X,
.B8,
.B20,
.B21,
.B26,
.B91,
.B360,
.B\@,
.BA,
.BAAS,
.BAB,
.BABEL,
.BABI,
.BABIL,
.BABL,
.BABY,
.BABYADA,
.BABYAETH,
.BABYBITC,
.BABYBNB,
.BABYBOO,
.BABYBUSD,
.BABYCARE,
.BABYCASPER,
.BABYCATE,
.BABYCATGIRL,
.BABYCATS,
.BABYCHEDDA,
.BABYCLIFF,
.BABYCUBAN,
.BABYDB,
.BabyDeFido,
.BABYDINGER,
.BabyDoge,
.BABYDOGE,
.BABYDOGECASH,
.BABYDOGEINU,
.babyDogeKing,
.BABYDOGEMM,
.BABYDOGEZILLA,
.BABYDOUG,
.BABYDRIP,
.BABYEGG,
.BABYETH,
.BabyETHV2,
.BabyFB,
.BABYFD,
.BABYFLOKI,
.BABYFLOKICOIN,
.BABYFLOKIZILLA,
.BABYFROG,
.BABYHARMONY,
.BABYKITTY,
.BABYLILFLOKI,
.BABYLONDON,
.BABYLOWB,
.BABYMETA,
.BABYMUSK,
.BABYOKX,
.BABYPIG,
.BABYPOO,
.BABYQUICK,
.BABYSAITAMA,
.BABYSHIB,
.BABYSHIBA,
.BABYSHIBAINU,
.BABYSHINJA,
.BabySun,
.BABYTETHER,
.BABYTIGER,
.BABYTK,
.BABYUNI,
.BABYUSDT,
.BABYVIZSLA,
.BABYWKD,
.BABYWOLF,
.BABYX,
.BABYXRP,
.BABYXTAG,
.BABY_YOOSHI,
.BABYZOROINU,
.BAC,
.BACK,
.BackstabDoge,
.BACON,
.BADGER,
.BAE,
.BAEPAY,
.BAETH,
.BAFE,
.BAFI,
.BAG,
.BAGEL,
.BAGS,
.BAIR,
.BAIT,
.BAJA,
.BAK,
.BAKE,
.BAKECOIN,
.BAKED,
.BAKEDCAKE,
.BAKER,
.BAKEUP,
.BAKS,
.BAL,
.BALA,
.BALI,
.BALL,
.BALLBAG,
.BALLS,
.BALLZ,
.BALN,
.BALPAC,
.BALPHA,
.BAMB,
.BAMBI,
.BAMBOO,
.BAMI,
.BAMP,
.BAMUSDT,
.BAN,
.BANA,
.BANAN,
.BANANA,
.BANCA,
.BAND,
.BANDEX,
.BANG,
.BANK,
.BANK\$,
.BANKER,
.BANKETH,
.BANKR,
.BANKWUPT,
.BAO,
.BAoE,
.BAPE,
.BAR,
.BARG,
.BARK,
.BARMY,
.BARREL,
.BART,
.BAS,
.BASE,
.BASED,
.BASHTANK,
.BASI,
.BASIC,
.BASID,
.BASIS,
.BASK,
.BAST,
.BASTILLE,
.BASX,
.BAT,
.BATDOGE,
.BATH,
.BATMAN,
.BATMOON,
.BATTLE,
.BAU,
.BAW,
.BAX,
.BAXS,
.BAY,
.BAZT,
.BB,
.BB1,
.BB_A_DAI,
.BBANK,
.BB_A_USDC,
.BBC,
.BBC_DAO,
.BBCLIFF,
.BBETH,
.BBF,
.BBFEG,
.BBFT,
.BBGC,
.BBH,
.BBI,
.BBK,
.BBKFI,
.BBL,
.BBN,
.BBNANA,
.BBND,
.BBNFT,
.BBO,
.BBOO,
.BBOTS,
.BBOXER,
.BBP,
.BBQ,
.BBR,
.BBS,
.BBT,
.BBTC,
.BBW,
.BBY,
.BBYCAT,
.BBYSTC,
.BBYXRP,
.BC,
.BC2_1,
.BCA,
.BCAC,
.BCAKE,
.BCAPS,
.BCARD,
.BCARE,
.BCAT,
.BCB,
.BCC,
.BCCX,
.BCD,
.BCDN,
.BCDT,
.BCEO,
.BCF,
.BCG,
.BCH,
.BCHB,
.BVOL,
.BSVBEAR,
.BCHBEAR,
.BNBBULL,
.BNBBEAR,
.BCHBULL,
.BSVBULL,
.BCHC,
.BCHEC,
.BCHI,
.bCHICKS,
.BCI,
.BCIO,
.BCITY,
.BCK,
.BCM,
.BCMC,
.BCMC1,
.BCN,
.BCNA,
.BCND,
.BCNT,
.BCNX,
.BCO,
.BCOIN,
.BCP,
.BCPT,
.BCR,
.BCS,
.BCSS,
.BCT,
.BCTR,
.BCTZ,
.BCU,
.BCUBE,
.BCUG,
.BCURVE,
.BCV,
.BCVT,
.BCW,
.BCX,
.BCY,
.BCZ,
.BCZERO,
.BDA,
.BDAO,
.BDAY,
.BDC,
.BDCC,
.BDE,
.BDECO,
.BDG,
.BDK,
.BDL,
.BDO,
.BDOG,
.BDOGE,
.BDOGEX,
.BDOT,
.BDP,
.BDPI,
.BDRM,
.BDS,
.BDT,
.BDX,
.BDY,
.BE,
.BEACH,
.BEAGLE,
.BEAGLECAKE,
.BEAK,
.BEAM,
.BEAN,
.BEANS,
.BEAR,
.BULL,
.BEAST,
.BEAT,
.BEBOP_INU,
.BEC,
.BECN,
.BECO,
.BECOIN,
.BED,
.BEDOGE,
.BEE,
.BEEINU,
.BEER,
.BEET,
.BEETS,
.BEFTM,
.BEFX,
.BEL,
.BELA,
.BELAXI,
.BELKA,
.BELL,
.BELLY,
.BELT,
.BEM,
.BEMT,
.BEN,
.BEND,
.BENJI,
.BENT,
.BENX,
.BENZ,
.BEONE,
.BEPIS,
.BEPR,
.BEPRO,
.BERAGE,
.BERN,
.BERRY,
.BERSERK,
.BES,
.BEST,
.BET,
.BETA,
.BETC,
.BETH,
.BETHER,
.BETIFY,
.BETS,
.BETU,
.BEX,
.BEXT,
.BEZ,
.BEZOGE,
.BF,
.BFC,
.BFF,
.BFG,
.BFHT,
.BFI,
.BFIC,
.BFIRE,
.BFK,
.BFL,
.BFLOKI,
.BFLY,
.BFORCE,
.BFORM,
.BFR,
.BFS,
.BFT,
.BFTM,
.BFU,
.BFX,
.BFYC,
.BG,
.BGA,
.BGAME,
.BGB,
.BGBP,
.BGC,
.BGG,
.BGH,
.BGL,
.BGLD,
.BGLG,
.BGO,
.BGOF,
.BGOV,
.BGS,
.BGTT,
.BGX,
.BHAO,
.BHAT,
.BHAX,
.BHC,
.BHD,
.BHIBA,
.BHIG,
.BHO,
.BHP,
.BHSC,
.BHT,
.BHUNT,
.BI,
.BIA,
.BIBA,
.BIC,
.BICAS,
.BICO,
.BID,
.BIDCOM,
.BIDEN,
.Bidog,
.BIDOS,
.BIDR,
.BIFI,
.BIG,
.BIGB,
.BIGETH,
.BIGGLES,
.BIGO,
.BIGSB,
.BIGUP,
.BiHODL,
.BIKE,
.BIKI,
.BIKINI,
.BILL,
.BIMP,
.BIN,
.BIND,
.BingDwenDwen,
.BINGO,
.BINGUS,
.BINO,
.BINOM,
.BINOSAURS,
.BINS,
.BINU,
.BIO,
.BIOFI,
.BIOS,
.BIOT,
.BIOTECH,
.BIP,
.BIR,
.BIRB,
.BIRD,
.BIRDBRO,
.BIRDS,
.BIS,
.BISHUFI,
.BISON,
.BIST,
.BIT,
.BITANT,
.BITB,
.BITBITBITBIT,
.BITUSD,
.BITBTC,
.BITSILVER,
.BITGOLD,
.BITC,
.BITCAR,
.BITCCA,
.BITCI,
.BITCNY,
.BITCOIN,
.BITD,
.BITE,
.BITEUR,
.BITG,
.BITGATTI,
.BITH,
.BNBALLBI,
.BITMETA,
.BITN,
.BITO,
.BITORB,
.BITRA,
.BITS,
.BITSU,
.BITSZ,
.BITT,
.BITTO,
.BitUp,
.BITV,
.BITX,
.BIUT,
.BIVE,
.BIX,
.BIXB,
.BIZZ,
.BIZZNERD,
.BK,
.BKBT,
.BKF,
.BKING,
.BKISHU,
.BKK,
.BKKG,
.BKN,
.BKR,
.BKRW,
.BKS,
.BKT,
.BKX,
.BKY,
.BLAC,
.BLACK,
.BLACKFRIDAY,
.BLADE,
.BLANC,
.BLANK,
.BLAST,
.BLAZAR,
.BLAZE,
.BLAZR,
.BLC,
.BLCC,
.BLCR,
.BLCT,
.BLD,
.BLE,
.BLES,
.BLET,
.BLEU,
.BLF,
.BLFI,
.BLG,
.BLGC,
.BLH,
.BLI,
.BLIGHT,
.BLIN,
.BLINK,
.BLINU,
.BLITZ,
.BLIZZ,
.BLK,
.BLKC,
.BLKS,
.BLL,
.BLMV2,
.BLN,
.BLOC,
.BLOCK,
.BLOCKIFY,
.BLOCKS,
.Blockunison,
.BLOCS,
.BLOGGER,
.BLOK,
.BLOOD,
.BLOODY,
.BLOODYSHIBA,
.BLOOM,
.BLOS,
.BLOSM,
.BLOVELY,
.BLOW,
.BLOWF,
.BLOX,
.BLP,
.BLS,
.BLST,
.BLT,
.BLTG,
.BLTV,
.BLU,
.BLUC,
.BLUE,
.BLUEFLOKI,
.BLUEMEL,
.BLUES,
.BlueSparrow,
.BLUESPARROW,
.bLUNA,
.BLUR,
.BLURT,
.BLUX,
.BLV,
.BLVR,
.BLWA,
.BLX,
.BLXM,
.BLY,
.BLZ,
.BLZN,
.BLZZ,
.BM,
.BMARS,
.BMB,
.BMBO,
.BMC,
.BMCC,
.BMCH,
.BME,
.BMEX,
.BMF,
.BMG,
.BMH,
.BMI,
.BMIC,
.BMJ,
.BML,
.BMM,
.BMN,
.BMND,
.BMON,
.BMP,
.BMT,
.BMW,
.BMX,
.BMXX,
.BNA,
.BNANA,
.BNB,
.BNBBACK,
.BNBCH,
.BNBD,
.BNBDRAGON,
.BNBEER,
.BNBEN,
.BNBFAN,
.BNBG,
.BNBH,
.BNBTC,
.BNBTIGER,
.BNBX,
.BNC,
.BND,
.BNF,
.BNFI,
.BNFT,
.BNFTT,
.BNFY,
.BNGT,
.BNK,
.BNKR,
.BNKY,
.BNM,
.BNN,
.BNODE,
.BNOX,
.BNP,
.BNPL,
.BNS,
.BNSD,
.BNT,
.BNTX,
.BNTY,
.BNU,
.bnUSD,
.BNV,
.BNX,
.BNY,
.BNZ,
.BOA,
.BOAT,
.BOB,
.BOBA,
.BOBC,
.BOBO,
.BOBT,
.BOC,
.BODAV2,
.BODO,
.BOE,
.BOFB,
.BOG,
.BOGDANOFF,
.BOGE,
.BOJI,
.BOK,
.BOKU,
.BOLD,
.BOLE,
.BOLI,
.BOLLY,
.BOLT,
.BOLTT,
.BOM,
.BOMB,
.BON,
.BOND,
.BONDLY,
.BONE,
.BONES,
.BONFIRE,
.BONO,
.BonusCake,
.BonusFloki,
.BonusSquid,
.BONUZ,
.BOO,
.BOOB,
.BOOBIES,
.BOOBS,
.BOOCAKE,
.BOOFI,
.BOOKSHIB,
.BOOLISH,
.BOOM,
.BOOMB,
.BOOMC,
.BOOMSHIBA,
.BOOST,
.BOOST2,
.BOOT,
.BOOZE,
.BOP,
.BOR,
.BORA,
.BORED,
.BORING,
.BORK,
.BORX,
.BOS,
.BOSO,
.BOSON,
.BOSS,
.BOST,
.BOT,
.BOTTO,
.BOTX,
.BOUJE,
.BOUNTIE,
.BOUTS,
.BOX,
.BOXCH,
.BOXER,
.BOXERDOGE,
.BOXX,
.BOYZ,
.BP,
.BPAD,
.BPAKC,
.BPANDA,
.BPAY,
.BPC,
.BPCN,
.BPENG,
.BPET,
.BPKR,
.BPLC,
.BPLUS,
.BPM,
.BPOP,
.BPP,
.BPR,
.BPRCY,
.bPRIVA,
.BPRO,
.BPS,
.BPT,
.BPTC,
.BPUNKS,
.BPX,
.BQ,
.BQCC,
.BQQQ,
.BQT,
.BQTX,
.BR,
.BR2_0,
.BR34P,
.BRAIN,
.BRAINS,
.BRAND,
.BRAT,
.BRAVE,
.BRAVO,
.BRAWL,
.BRB,
.BRC,
.BRCP,
.BRD,
.BRDG,
.BRE,
.BREAST,
.BREE,
.BREED,
.BREW,
.BREWLABS,
.BRG,
.BRG\.X,
.BRGB,
.BRI,
.BRIA,
.BRIBE,
.BRICK,
.BRICKS,
.BRIDGE,
.BRIG,
.BRIGADEIRO,
.BRIGHT,
.BRIK,
.BRISE,
.BRIT,
.BRK,
.BRKI,
.BRKL,
.BRKT,
.BRL,
.BRLC,
.BRN,
.BRNG,
.BRO,
.BROWN,
.BRR,
.BRRR,
.BRS,
.BRT,
.BRTK,
.BRTR,
.BRTX,
.BRUH,
.BRUSH,
.BRW,
.BRWL,
.BRY,
.BRZ,
.BRZE,
.BRZX,
.BSAFE,
.BSAFU,
.BSAMO,
.BSB,
.BSBT,
.BSC,
.BSC33,
.BSCAKE,
.BSCB,
.BSCF,
.BSCGIRL,
.BSCGOLD,
.BSCM,
.BSCPAD,
.BSCS,
.BSCTRUST,
.BSCV,
.BSCWIN,
.BSCX,
.BSD,
.BSDS,
.BSE,
.BSF,
.BSFM,
.BSG,
.BSGG,
.BSH,
.BSHARE,
.BSHIB,
.BSHIBA,
.BSHIBR,
.BSI,
.BSJ,
.BSK_BAA025,
.BSKT,
.BSL,
.BSM,
.BSMB,
.BSN,
.BSOV,
.BSP,
.BSPAY,
.BSPT,
.BSR,
.BST,
.BST1,
.BSTN,
.BSTS,
.BSTv2,
.BSTY,
.BSV,
.BSVG,
.BSW,
.BSWAP,
.BSX,
.BSY,
.BSYS,
.BT,
.BTA,
.BTAD,
.BTAP,
.BTB,
.BTBS,
.BTC\.b,
.BTC,
.BTC1D3L,
.BTC2,
.BTC2X_FLI,
.BTC\+\+,
.BTCA,
.BTCB,
.BTCBAM,
.BTCBR,
.BTCBZ,
.BTCDOM,
.BTCETH7525,
.BTCF,
.BTCG,
.BTCHG,
.BTCI,
.BTCIX,
.BTCK,
.BTCL,
.BTCM,
.BTCMT,
.BTCMZ,
.BTCN,
.BTCP,
.BTCPX,
.BTCR,
.BTCRED,
.BTCS,
.BTCSHORT,
.BTCST,
.BTCT,
.BTCU,
.BTCV,
.BTCX,
.BTCZ,
.BTD,
.BTDMD,
.BTDX,
.BTE,
.BTF,
.BTFA,
.BTG,
.BTGN,
.BTH,
.BTI,
.BTKC,
.BTL,
.BTM,
.BTN,
.BTNA,
.BTNT,
.BTNYX,
.BTO,
.BTP,
.BTPL,
.BTR,
.BTRFLY,
.BTRI,
.BTRL,
.BTRN,
.BTRS,
.BTRST,
.BTRY,
.BTS,
.BTSA,
.BTSC,
.BTSE,
.BTSG,
.BTSL,
.BTSUCN,
.BTT,
.BTTOLD,
.BTTR,
.BTTXT,
.BTU,
.BTV,
.BTW,
.BTX,
.BTXC,
.BTXN,
.BTY,
.BTYM,
.BTZ,
.BTZC,
.BU,
.BUB,
.BUBBLEGUM,
.BUBO,
.BUC,
.BUCK,
.BUCKS,
.BUDA,
.BUDG,
.BUFF,
.BUFFALO,
.BUFFS,
.BUFFSHIBA,
.BUFFYOOSHI,
.BUFLOKI,
.BUGG,
.BUGS,
.BUIDL,
.BUILD,
.BUKH,
.BUL,
.BULB,
.BULK,
.BULLAF,
.BULLD,
.BULLDOG,
.BULLETH,
.BULLION,
.BULLISH,
.BULLISHC,
.BULLS,
.BULLY,
.BULT,
.BUMBA,
.BUMN,
.BUMP,
.BUN,
.BUNCHIE,
.BUND,
.BUNI,
.BUNKER,
.BUNNY,
.BUNNYCAKE,
.BUNNYGIRL,
.BUNNYROCKET,
.BUNNYZILLA,
.BUP,
.BUR,
.BURD,
.BURGER,
.Burn1Coin,
.BURN,
.BURNDOGE,
.BURNROCKET,
.BURNS,
.BurnX,
.BURNX20,
.BURP,
.BURROW,
.BUSD,
.BUSDX,
.BUSINESSES,
.BUSM,
.BUST,
.BUSY,
.BUT,
.BUTTER,
.BUU,
.BUX,
.BUY,
.BUZZ,
.BV,
.BVA,
.BVC,
.BVER,
.BVERSE,
.BVK,
.BVL,
.BVND,
.BVR,
.BWB,
.BWC,
.BWF,
.BWH,
.BWI,
.BWJ,
.BWO,
.BWR,
.BWRX,
.BWS,
.BWT,
.BWX,
.BX,
.BXA,
.BXC,
.BXH,
.BXIOT,
.BXK,
.BXMI,
.BXR,
.BXS,
.BXT,
.BXTB,
.BXX,
.BXY,
.BYB,
.BYCO,
.BYG,
.BYK,
.BYN,
.BYRON,
.BYT,
.BYTE,
.BYTN,
.BYTS,
.BYTZ,
.byYam,
.BZ,
.BZAR,
.BZC,
.BZE,
.BZH,
.BZKY,
.BZN,
.BZNT,
.BZRX,
.BZSC,
.BZX,
.BZZ,
.BZZONE,
.c0,
.C,
.C0C0,
.C2,
.C2O,
.C2P,
.C3,
.C4G3,
.C4T,
.C8,
.C20,
.C24,
.C98,
.CA,
.CAB,
.CABIN,
.CABO,
.CAC,
.CACTI,
.CACTT,
.CACTUS,
.CAD,
.CADC,
.CADE,
.CADINU,
.CADX,
.Caelum,
.CAESAR,
.CAF,
.CAG,
.CAGE,
.CAI,
.CAIRO,
.CAJ,
.CAKE,
.CAKEBAKER,
.CAKEBANK,
.CAKECRYPT,
.CAKEGAME,
.CAKEGIRL,
.CAKEPUNKS,
.CAKESWAP,
.CAKEUP,
.CAKEZILLA,
.CAKF,
.CAKITA,
.CAL,
.CALCIFIRE,
.CALI,
.CALIT,
.CALL,
.CALO,
.CAM,
.CAMBU,
.CAMO,
.CAMP,
.CAN,
.CANDLE,
.Candy,
.CANDY,
.CANDYDEX,
.CANDYLAD,
.CANN,
.CANNA,
.CANU,
.CAP,
.CAP9,
.CAPP,
.CAPS,
.CAPT,
.CAPY,
.CAR,
.CARAT,
.CARB,
.CARBO,
.CARBON,
.C_ARCADE,
.CARD,
.CARDS,
.CARE,
.CARES,
.CARGO,
.CARM,
.CARMA,
.CAROM,
.CARR,
.CARROT,
.CARS,
.CART,
.CAS,
.CASECLOSED,
.CASH,
.CASH2,
.CASHDOG,
.CASHIO,
.CASIO,
.CASS,
.CAST,
.CAT,
.CAT\+,
.CATBOY,
.CATBREAD,
.CATCHY,
.CATCOIN,
.CATE,
.CATFISH,
.CATGE,
.CATGIRL,
.CATO,
.CATPAY,
.CATS,
.CATT,
.CATX,
.CATZ,
.CAVA,
.CAVE,
.CAVO,
.CAW,
.CAX,
.CAZ,
.CB,
.CBA,
.CBANK,
.CBAT,
.CBC,
.CBD,
.CBET,
.CBEX,
.CBG,
.CBIX,
.CBIX_P,
.CBK,
.CBLT,
.CBM,
.CBN,
.CBOMBER,
.CBP,
.CBR,
.CBRL,
.CBS,
.CBSN,
.CBT,
.CBU,
.CBUCKS,
.CBUNNY,
.CBX,
.CC,
.CC10,
.CCA,
.CCAKE,
.CCAR,
.CCASH,
.CCAT,
.CCBCH,
.CCC,
.CCD,
.CCDOGE,
.CCE,
.CCF,
.CCG,
.CCH,
.CCL,
.CCM,
.CCN,
.CCO,
.CCOIN,
.CCOM,
.CCOMP,
.CCP,
.CCRB,
.CCT,
.CCURVE,
.CCV2,
.CCX,
.CCXX,
.CCY,
.CD,
.CDAG,
.CDAI,
.CDASH,
.CDB,
.CDC,
.CDDSP,
.CDEX,
.CDK,
.CDL,
.CDM,
.CDONK,
.CDS,
.CDT,
.CDTC,
.CDX,
.CDZ,
.CDZC,
.CE,
.CEC_CEG,
.CEDEX,
.CEDS,
.CEEK,
.CEL,
.CELC,
.CELEB,
.CELES,
.CELF,
.CELL,
.CELO,
.CELR,
.CELT,
.CEN,
.CENNZ,
.CENS,
.CENT,
.CENX,
.CER,
.CERBERUS,
.CERBY,
.CERE,
.CERES,
.CERT,
.CET,
.CETH,
.CEUR,
.CEV,
.CEVO,
.CEX,
.CEXY,
.CF,
.CFA,
.CFAR,
.CFB,
.CFC,
.CFF,
.CFG,
.CFi,
.CFL,
.CFL365,
.CFLIX,
.CFloki_X,
.CFLT,
.CFRESH,
.CFS,
.CFT,
.CFTI,
.CFUN,
.CFX,
.CFXQ,
.CFXT,
.CFY,
.CG,
.CGANG,
.CGAR,
.CGAZ,
.CGC,
.CGEN,
.CGG,
.CGO,
.CGOLD,
.CRIME,
.CGRESS,
.CGS,
.CGT,
.CGU,
.CGX,
.CHA,
.CHAD,
.CHADLINK,
.CHADS,
.CHAIN,
.CHAINCADE,
.CHAKRA,
.CHAL,
.CHAMP,
.CHAMPINU,
.CHANE,
.CHANGE,
.CHAOS,
.CHAR,
.CHARGE,
.CHARGED,
.CHARIX_TOKEN,
.CHARIZARD,
.CHARM,
.CHART,
.CHAT,
.CHB,
.CHBT,
.CHC,
.CHD,
.CHE,
.CHECK,
.CHECKR,
.CHECOIN,
.CHEDDA,
.CHEE,
.CHEEMS,
.CHEEMSBILLIONAIRE,
.CHEERS,
.CHEESE,
.CHEESUS,
.CHEEZ,
.CHEFCAKE,
.CHEQ,
.CHER,
.CHERRY,
.CHES,
.CHESS,
.CHEX,
.CHFT,
.CHFU,
.CHFX,
.CHG,
.CHH,
.CHI,
.CHIBA,
.CHIBI,
.CHIBIKI,
.CHICK,
.CHICKS,
.CHIH,
.CHIHUA,
.CHIK,
.CHIKA,
.CHIKUINU,
.CHIM,
.CHIMOM,
.CHIMPY,
.CHINAZILLA,
.CHINU,
.CHIP,
.CHIPS,
.CHIRO,
.CHIRP,
.CHIV,
.CHIWA,
.CHIZ,
.CHKN,
.CHL,
.CHLI,
.CHLT,
.CHM,
.CHMB,
.CHMP,
.CHN,
.CHND,
.CHNG,
.CHO,
.CHOICE,
.CHONK,
.CHOP,
.CHOPPER,
.CHORD,
.CHORSE,
.CHOW,
.CHP,
.CHPZ,
.CHR,
.CHRONO,
.CHS,
.CHSB,
.CHT,
.CHTRv2,
.CHTS,
.CHTT,
.CHTX,
.CHUBS,
.CHUM,
.CHURCH,
.CHX,
.CHY,
.CHZ,
.CHZ006,
.CI,
.CIA,
.CIC,
.CICX,
.CID,
.CIF,
.CIFI,
.CIND,
.CINO,
.CINU,
.CIOTX,
.CIPHC,
.CIPX,
.CIR,
.CIRQ,
.CIRRUS,
.CIRUS,
.CISLA,
.CIT,
.CITIZEN,
.CITY,
.CIV,
.CIX100,
.CJ,
.CJET,
.CJP,
.CJT,
.CKB,
.CKC,
.CKEY,
.CKG,
.CKP,
.CKT,
.CKUSD,
.CL,
.cLA,
.CLA,
.CLAIM,
.CLAM,
.CLAP,
.CLASH,
.CLASS,
.CLAVIS,
.CLAW,
.CLB,
.CLBK,
.CLBR,
.CLC,
.CLCT,
.CLD,
.CLEAN,
.CLEAR,
.CLEG,
.CLEX,
.CLG,
.CLH,
.CLIFF,
.CLIMB,
.CLION,
.CLIQ,
.CLIST,
.CLIT\$,
.CLL,
.CLM,
.CLN,
.CLNY,
.CLO,
.CLOAK,
.CLONEX,
.CLOUD,
.CLOUD9,
.CLOUT,
.CLR,
.CLS,
.CLSL,
.CLT,
.CLU,
.CLUB,
.CLUNA,
.CLV,
.CLVX,
.CLX,
.CLXY,
.CLY,
.CM,
.CMA,
.CMALL,
.CMB,
.CMC,
.CMCT,
.CMCX,
.CMD,
.CMDX,
.CMERGE,
.CMETA,
.CMF,
.CMFI,
.CMI,
.CMID,
.CMIT,
.CMK,
.CMLCOIN,
.CMLT,
.CMM,
.CMN,
.CMP,
.CMS,
.CMSN,
.CMT,
.CMTR,
.CMX,
.CNAME,
.CNB,
.CNC,
.CNCT,
.CND,
.CNDL,
.CNET,
.cNETA,
.CNF,
.CNFI,
.CNFT,
.CNG,
.CNHT,
.CNJ,
.CNMC,
.CNN,
.CNNC,
.CNNS,
.CNOW,
.CNR,
.CNRG,
.CNS,
.CNT,
.CNTM,
.CNTR,
.CNTX,
.CNUS,
.CNV,
.CNX,
.CNYT,
.CNYX,
.CNZ,
.CO,
.CO2,
.CO2B,
.COA,
.COAL,
.COAPE,
.COB,
.COBAN,
.COBRA,
.COC,
.COCK,
.COCKTAIL,
.COCO,
.COCOS,
.COD,
.CODE7,
.CODEO,
.CODEX,
.CODI,
.CODY,
.COFFEE,
.COFFIN,
.COFI,
.COFLOKI,
.COGE,
.COGI,
.COIIN,
.COIL,
.COIN,
.COINS,
.COINSCOPE,
.COKE,
.COL,
.COLA,
.COLD,
.COLDKOALA,
.COLI,
.COLL,
.COLLAR,
.COLLG,
.COLON,
.COLOS,
.COLX,
.COM,
.COMB,
.COMBO,
.COMET,
.COMFI,
.COMFY,
.COMIC,
.COMOS,
.COMP,
.COMT,
.CON,
.CONC,
.CONDOMS,
.CONE,
.CONEGAME,
.CONI,
.CONJ,
.CONQ,
.CONST,
.CONT,
.CONV,
.CONX,
.COO,
.COOGA,
.COOHA,
.COOK,
.COOM,
.COP,
.COPE,
.COPI,
.COPPER,
.COPS,
.COPYCAT,
.COR,
.CORA,
.CORAL,
.CORD,
.CORE,
.COREDAO,
.CORGI,
.CORGIB,
.CORIS,
.CORK,
.CORN,
.CORNX,
.CORSACV2,
.CORX,
.COS,
.COSBY,
.COSG,
.COSHI,
.COSM,
.COSMIC,
.COSMIK,
.COT,
.COTI,
.COTK,
.COTN,
.COU,
.COUNTY,
.COURSE,
.COUSD,
.COUSINDOGE,
.COV,
.COVA,
.COVAL,
.COVDR,
.COVER,
.COVID19,
.COVID_DOGE,
.COVIDTOKEN,
.COVN,
.COW,
.COWS,
.COX,
.COZOM,
.COZP,
.CP,
.CP3R,
.CPAC,
.CPAD,
.CPAN,
.CPAY,
.CPC,
.CPD,
.CPE,
.CPET,
.CPH,
.CPHR,
.CPHX,
.CPI,
.CPIE,
.CPL,
.CPLAY,
.CPMT,
.CPN,
.CPO,
.CPOO,
.CPOOL,
.CPOS,
.CPR,
.CPRO,
.CPRX,
.CPS,
.CPT,
.CPTB,
.CPTE,
.CPTINU,
.CPTL,
.CPU,
.CPX,
.CPZ,
.CQT,
.CRA,
.CRAB,
.CRACE,
.CRAD,
.CRAFT,
.CRAM,
.CRAVE,
.CRB,
.CRBN,
.CRBRUS,
.CRC,
.CRCT,
.CRD,
.CRDAO,
.CRDC,
.CRDN,
.CRDT,
.CRE,
.CRE8,
.CRE8R,
.CREA,
.CREAL,
.CREAM,
.CRED,
.CREDA,
.CREDI,
.CREDIT,
.CREDITS,
.CREDO,
.CREED,
.CREO,
.CREP,
.CRETH2,
.CREVA,
.CREX,
.CRF,
.CRFI,
.CRIB,
.CRIC,
.CRICKET,
.CRK,
.CRL,
.CRM,
.CRN,
.CRO,
.CROAT,
.CROBLANC,
.CROC,
.CROCASH,
.CROCKET,
.CROCRO,
.CROD,
.CROGE,
.CROISSANT,
.CROKI,
.CROLAMBOS,
.CRON,
.CRONA,
.CRONO,
.CRONOAPE,
.CROP,
.CROSS,
.CROTAMA,
.CROW,
.CROWN,
.CROX,
.CRP,
.CRPRO,
.CRPT,
.CRS,
.CRT,
.CRTS,
.CRU,
.CRUDE,
.CRUNCH,
.CRUSADER,
.CRUSH,
.CRUX,
.CRV,
.CRVT,
.CRW,
.CRWNY,
.CRX,
.CRYB,
.CRYN,
.CRYP,
.CRYPL,
.CRYPT,
.CRYPTOBALL,
.CRYPTOGRAM,
.CRYSTAL,
.CRYSTL,
.CRYT,
.CRYY,
.CS,
.CSAI,
.CSB,
.CSC,
.CSCJ,
.CSE,
.CSF,
.CSHARE,
.CSHIP,
.CSM,
.CSMC,
.CSMS,
.CSNO,
.CSNP,
.CSO,
.CSOV,
.CSP,
.CSPC,
.CSPD,
.CSPN,
.CSPR,
.CSPRO,
.CSR,
.CSS,
.CST,
.CSTL,
.CSTO,
.CSTR,
.CSWAP,
.CSX,
.CT,
.CTASK,
.CTAT,
.CTAX,
.CTB,
.CTC,
.CTCN,
.CTCO,
.CTD,
.CTF,
.CTFT,
.CTH,
.CTHAI,
.CTI,
.CTIC3,
.CTK,
.CTL,
.CTLX,
.CTN,
.CTNT,
.CTO,
.CTOGER,
.CTOK,
.CTP,
.CTPL,
.CTR,
.CTRAIN,
.CTRFI,
.CTRIBE,
.CTRO,
.CTRS,
.CTRT,
.CTS,
.CTSC,
.CTSI,
.CTT,
.CTX,
.CTXC,
.CTZN,
.CU,
.CUAG,
.CUAN,
.CUB,
.CUBE,
.CUBO,
.CUDOS,
.CUE,
.CUEX,
.CUFFIES,
.CUJO,
.CULT,
.CULTOS,
.CUM,
.CUMINU,
.CUMMIES,
.CUMSTAR,
.CUNI,
.CUP,
.CUR,
.CURE,
.CURRY,
.CUSD,
.CUSDC,
.CUSDT,
.CUST,
.CUT,
.CV,
.CVA,
.CVAG,
.CVC,
.CVCC,
.CVD19,
.CVIP,
.CVN,
.CVNT,
.CVNX,
.CVOL,
.CVP,
.CVR,
.CVSHOT,
.CVT,
.CVX,
.CVXCRV,
.CVZ,
.CW,
.CWAP,
.CWAR,
.CWBTC,
.CWD,
.CWE,
.CWEB,
.CWN,
.CWOLF,
.CWS,
.CWT,
.CWV,
.CX1,
.CXC,
.CXD,
.CXGEM,
.CXN,
.CXO,
.CXP,
.CXPAD,
.CXRBN,
.CXT,
.CYB,
.CYBER,
.CYBER_NEKO,
.CYBG,
.CYBR,
.CybrrrDOGE,
.CYC,
.CYCE,
.CYCLE,
.CYCLUB,
.CYF,
.CYFM,
.CYL,
.CYM,
.CYMT,
.CYN,
.CYNC,
.CYO,
.CYOP,
.CYR,
.CYS,
.CYSHI,
.CYT,
.CYTR,
.CZ,
.CZATS,
.CZF,
.CZR,
.CZRX,
.CZUSD,
.CZZ {
  display: inline-block;
  background: url('../img/tokens_logo_0toEndC.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.n0NE {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.n0XBTC {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.n0XMR {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.n0XPAD {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.n0XZX {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.n1AI {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.n1ART {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.n1BIT {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.n1BOX {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.n1CT {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.n1DOGE {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.n1EARTH {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.n1ECO {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.n1FLR {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.n1GOLD {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.n1INCH {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.n1INCHUP {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.n1INCHDOWN {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.n1INCHPUNCH {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.n1MCT {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.n1MIL {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.n1MT {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.n1NFT {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.n1PECO {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.n1PEGG {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.n1SG {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.n1SOL {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.n1ST {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.n1SWAP {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.n1TO11 {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.n1TRC {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.n1_UP {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.n1UP {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.n1WO {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.n1X2 {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.n2BASED {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.n2CHAINLINKS {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.n2CRZ {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.n2GIVE {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.n2GOSHI {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.n2KEY {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.n2LC {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.n2OMB {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.n2SHARES {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.n2XDN {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.n3COMMAS {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.n3CRV {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.n3CS {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.n3D {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.n3DC {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.n3DOG {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.n3SHARE {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.n3OMB {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.n3ULL {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.n3WEB {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.n3XT {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.n4ART {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.n4B {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.n4INT {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.n4JNET {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.n4MOVE {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.n4MW {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.n4PLAY {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.n4STC {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.n5IRE {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.n5STAR {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.n5TABLE {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.n7ADD {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.n7E {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.n7SHARE {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.n7UP {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.n8BIT {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.n8DAO {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.n8FI {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.n8PAY {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.n10SET {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.n18C {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.n42 {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.n50X {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.n99DEFI {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.n100K {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.n100X {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.n300 {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.n420X {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.n452B {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.n611 {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.n777 {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.n808TA {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.n867 {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.n888 {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.n999 {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.n1337 {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.n2022 {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.n2022M {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.n2030FLOKI {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.A_BEE {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.A_O_T {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.A {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.A1A {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.A2A {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.A4 {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.A5T {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.A8 {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.A86 {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.AAA {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.AAB {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.AABC {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.AAC {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.AADA {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.AAG {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.AALTO {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.AAMMUSDT {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.AAMMWBTC {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.AANG {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.AMC {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.ABNB {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.AMD {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.AAPL {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.AAPT {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.AAPX {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.AART {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.AAT {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.aAVAXb {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.AAVAXC {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.AAVE {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.AAVEDOWN {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.ADADOWN {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.AAVEUP {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.ADAUP {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.ABA {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.ABAKE {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.ABAT {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.ABB {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.ABBC {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.ABBY {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.ABC {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.ABC123 {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.ABCD {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.ABCO {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.ABDT {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.ABET {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.ABEY {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.ABI {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.ABIC {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.ABIT {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.ABL {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.ABLE {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.aBLOCK {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.aBNBb {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.aBNBc {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.ABOAT {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.ABP {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.ABR {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.ABS {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.ABST {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.ABT {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.ABTC {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.ABTM {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.ABU {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.ABUSD {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.ABX {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.ABY {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.ABYS {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.ABYSS {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.ABZ {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.AC {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.AC3 {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.ACA {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.ACAB {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.ACAD {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.ACADA {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.ACAP {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.ACAT {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.ACC {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.ACCEL {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.ACCG {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.ACDC {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.ACDX {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.ACE {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.ACED {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.ACES {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.ACH {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.ACITY {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.ACK {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.ACM {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.ACMD {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.ACOIN {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.ACP {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.ACPT {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.ACQ {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.ACRE {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.ACRYL {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.ACS {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.ACSI {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.ACT {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.ACTN {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.ACU {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.ACX {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.ACXT {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.ACY {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.ACYC {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.AD {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.AD2 {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.AD3 {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.ADA {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.ADAB {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.ALTBULL {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.ALGOBULL {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.ADABULL {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.ALTBEAR {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.ALTMOON {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.ALGOBEAR {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.ADABEAR {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.ADABOY {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.ADACASH {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.ADAFI {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.ADAFLECT {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.ALTHALF {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.ADAHEDGE {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.ADAHALF {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.ALGOHALF {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.ALGOHEDGE {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.ADAI {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.ADAL {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.ADAM {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.ADANA {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.ADAO {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.ADAPAD {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.ADAT {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.ADAX {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.ADB {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.ADC {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.ADCO {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.ADD {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.ADDR {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.ADE {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.ADEL {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.ADEN {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.ADENA {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.ADGNZ {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.ADH {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.ADI {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.ADINU {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.ADK {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.ADL {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.ADM {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.ADMC {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.ADN {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.ADOG {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.ADOGE {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.ADOTB {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.ADP {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.ADR {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.ADREAM {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.ADS {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.ADT {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.ADTX {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.ADV2 {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.ADVAR {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.ADVN {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.ADX {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.ADXX {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.ADZ {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.AE {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.AEC {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.AEG {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.AEL {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.AELIN {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.AEM {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.AEN {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.aENJ {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.AENJ {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.AENS {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.AEON {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.AER {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.AERGO {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.AERO {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.AERO_V2 {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.AES {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.AET {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.AETERNA {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.aETH {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.AETH {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.aETHb {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.aETHc {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.AEVO {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.AEXP {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.AFARM {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.AFC {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.AFDLT {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.AFEN {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.AFFINITY {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.AFIN {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.AFIT {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.AFK {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.AFLOKI {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.AFM {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.AFO {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.AFP {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.AF_PRESALEDAO {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.AFR {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.AFRICA {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.AFRO {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.AfroX {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.AFT {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.aFTMb {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.AFTMC {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.AFTRBCK {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.AFU {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.AFYON {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.AG {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.AG8 {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.AGA {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.AGAIN {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.AGAR {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.AGC {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.AGD {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.AGE {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.AGENTSHIBAINU {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.AGEUR {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.AGF {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.AGFI {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.AGGL {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.AGIX {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.AGL {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.AGLD {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.AGLT {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.AGLYPH {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.AGM {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.AGN {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.AGO {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.AGOLP {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.AGORA {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.AGOV {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.AGRI {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.AGRO {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.AGRS {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.AGS {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.AGSPAD {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.AGT {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.AGV {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.AGVC {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.AGVE {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.AGX {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.AHA {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.AHE {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.AHF {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.AHOUSE {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.AHT {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.AI {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.AIB {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.AIC {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.AICO {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.AID {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.AIDI {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.AIDOC {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.AIDUS {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.AIE {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.AIM {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.AIME {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.AIMS {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.AIMX {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.AIN {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.AINU {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.AION {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.AIOZ {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.AIP {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.AIQ {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.AIR {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.AIRI {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.AIRMOON {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.AIRPAY {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.AIRT {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.AIRX {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.AIT {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.AITECH {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.AITRA {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.AIV {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.AIX {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.AIZA {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.AJE {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.AJRC {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.AK12 {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.AKA {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.AKAMARU {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.AKC {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.AKIHIKO {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.AKIRA {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.AKITA {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.AKITAX {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.AKL {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.AKM {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.AKN {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.AKNC {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.AKONG {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.AKREP {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.AKRO {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.AKSMB {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.AKT {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.AKTA {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.AKTIO {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.ALA {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.ALANO {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.ALB {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.ALBT {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.ALC {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.ALCUP {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.ALCX {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.ALUSD {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.ALD {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.aLEND {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.ALEPH {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.ALF {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.ALG {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.ALGA {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.ALGB {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.ALGO {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.ALGOBLK {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.ALGOP {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.ALGOPAD {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.ALGX {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.ALI {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.ALIAS {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.ALICE {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.ALICN {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.ALIEN {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.ALINK {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.ALIS {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.ALIST {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.ALIX {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.ALK {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.ALKOM {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.ALL {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.ALLBI {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.ALLEY {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.ALLN {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.ALLOWANCE {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.ALLOY {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.ALLUO {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.ALLWIN {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.ALM {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.ALMX {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.ALN {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.ALOHA {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.ALOT {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.ALP {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.ALPA {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.ALPACA {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.ALPH {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.ALPHA {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.ALPHASHIB {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.ALPHR {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.ALPINE {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.AMZN {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.ANGLE {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.ARKK {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.ATOMBEAR {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.ATOMBULL {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.ATOMHALF {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.ATOMHEDGE {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.BABA {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.BALBEAR {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.BALBULL {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.BALHALF {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.BCHDOOM {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.BCHDOWN {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.BCHHALF {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.BCHMOON {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.BCHUP {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.BEARSHIT {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.BILI {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.BNBDOOM {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.BNBDOWN {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.BNBHALF {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.BNBHEDGE {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.BNBMOON {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.BNBUP {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.BSVMOON {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.BTCDOWN {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.BTCUP {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.BTMXBEAR {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.BTMXBULL {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.BTMXHALF {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.BTMXHEDGE {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.BULLSHIT {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.BYND {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.COMPBEAR {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.COMPBULL {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.COMPHEDGE {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.CUSDTBEAR {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.CUSDTBULL {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.CUSDTHALF {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.CUSDTHEDGE {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.ALPY {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.ALT {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.ALTB {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.ALTER {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.ALTERA {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.ALTRU {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.ALTS {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.ALTX {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.ALU {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.ALUCARD {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.ALV {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.ALVN {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.ALX {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.ALY {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.ALYA {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.AM {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.AMA {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.AMANA {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.AMAS {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.aMATICb {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.AMATICC {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.AMAZINGTEAM {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.AMB {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.AMBROS {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.AMDG {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.AME {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.AMF {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.AMG {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.AMGO {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.AMH {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.AMI {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.AMIO {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.AMIX {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.AMKR {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.AMLT {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.AMM {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.AMMO {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.AMN {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.AMO {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.AMON {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.AMOR {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.AMP {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.AMPL {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.AMPLE {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.AMPT {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.AMS {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.AMSTAFF {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.AMT {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.AMU {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.AMY {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.AMZ {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.AMZE {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.ANA {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.ANC {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.ANCE {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.ANCHOR {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.ANCT {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.ANCW {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.ANDX {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.ANFT {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.ANG {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.ANGEL {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.ANGL {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.AngrySquid {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.ANI {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.ANIA {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.ANIM {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.ANJ {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.ANJI {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.ANK {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.ANKR {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.ANM {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.ANML {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.ANN {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.ANO {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.ANODE {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.ANOM {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.ANON {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.ANONFLOKI {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.ANONS {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.ANORTIS {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.ANPAN {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.ANRX {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.ANS {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.ANSR {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.ANT {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.ANTA {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.ANTE {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.ANTEX {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.ANTG {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.ANTI {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.ANTIS {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.ANUBI {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.ANV {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.ANW {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.ANX {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.ANY {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.ANYAN {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.ANYP {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.AOA {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.AOE {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.AOF {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.AOG {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.AOK {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.AOM {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.AORA {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.AOS {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.AOST {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.AOW {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.AP3 {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.APA {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.APACHE {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.APAD {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.APAY {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.APC {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.APE {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.APEBORG {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.APEBOYS {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.APEBUSD {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.APECOIN {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.APED {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.APEF {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.APEFUND {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.APEIN {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.APENOUT {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.APES {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.APEU {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.APE_X {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.APEX {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.APG {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.APH {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.API {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.API3 {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.APIDAI {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.APIX {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.APL {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.APLP {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.APM {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.APN {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.APOC {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.APOLLO {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.APP {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.APPC {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.APPLE {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.APPLEB {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.APR {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.APRIL {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.APT {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.APU {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.APW {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.APX {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.APXP {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.APY {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.APYS {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.AQR {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.AQS {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.AQT {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.AQU {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.AQUA {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.AQUAGOAT {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.AQUAGOAT_old_ {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.AQUAPIG {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.AQUARI {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.AR {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.ARA {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.ARATA {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.ARAW {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.ARAZM {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.ARB {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.ARBIS {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.ARBS {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.ARBYS {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.ARC {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.ARCANE {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.ARCANELEAGUE {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.ARCANINEINU {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.ARCC {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.ARCG {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.ARCHA {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.ARCHE {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.ARCO {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.ARCONA {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.ARCT {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.ARCX {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.ARD {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.ARDN {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.ARDR {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.ARDX {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.ARE {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.AREN {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.ARENA {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.AREP {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.AREPA {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.ARES {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.ARF {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.ARG {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.ARGO {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.ARGON {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.ARGP {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.ARGUS {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.ARI {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.ARI10 {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.ARIA20 {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.ARIGATO {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.ARION {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.ARIX {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.ARK {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.ARKER {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.ARKN {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.ARKS {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.ARM {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.ARMA {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.ARMD {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.ARMOR {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.ARMR {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.ARN {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.ARNO {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.ARNX {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.arNXM {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.ARO {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.ARPA {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.ARQ {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}

.ARR {
  width: 16px;
  height: 16px;
  background-position: -208px -416px;
}

.ARRB {
  width: 16px;
  height: 16px;
  background-position: -224px -416px;
}

.ARRO {
  width: 16px;
  height: 16px;
  background-position: -240px -416px;
}

.ARRR {
  width: 16px;
  height: 16px;
  background-position: -256px -416px;
}

.ART {
  width: 16px;
  height: 16px;
  background-position: -272px -416px;
}

.ARTE {
  width: 16px;
  height: 16px;
  background-position: -288px -416px;
}

.ARTEM {
  width: 16px;
  height: 16px;
  background-position: -304px -416px;
}

.ARTEON {
  width: 16px;
  height: 16px;
  background-position: -320px -416px;
}

.ARTEQ {
  width: 16px;
  height: 16px;
  background-position: -336px -416px;
}

.ARTEX {
  width: 16px;
  height: 16px;
  background-position: -352px -416px;
}

.ARTH {
  width: 16px;
  height: 16px;
  background-position: -368px -416px;
}

.ARTHX {
  width: 16px;
  height: 16px;
  background-position: -384px -416px;
}

.ARTI {
  width: 16px;
  height: 16px;
  background-position: -400px -416px;
}

.ARTIC {
  width: 16px;
  height: 16px;
  background-position: -416px -416px;
}

.ARTICUNO {
  width: 16px;
  height: 16px;
  background-position: -432px -0;
}

.ARTII {
  width: 16px;
  height: 16px;
  background-position: -432px -16px;
}

.ARTIS {
  width: 16px;
  height: 16px;
  background-position: -432px -32px;
}

.ARTK {
  width: 16px;
  height: 16px;
  background-position: -432px -48px;
}

.ARTL {
  width: 16px;
  height: 16px;
  background-position: -432px -64px;
}

.ARTM {
  width: 16px;
  height: 16px;
  background-position: -432px -80px;
}

.ARTR {
  width: 16px;
  height: 16px;
  background-position: -432px -96px;
}

.ARTRINO {
  width: 16px;
  height: 16px;
  background-position: -432px -112px;
}

.ARTS {
  width: 16px;
  height: 16px;
  background-position: -432px -128px;
}

.ARTX {
  width: 16px;
  height: 16px;
  background-position: -432px -144px;
}

.ARTY {
  width: 16px;
  height: 16px;
  background-position: -432px -160px;
}

.ARV {
  width: 16px;
  height: 16px;
  background-position: -432px -176px;
}

.ARVO {
  width: 16px;
  height: 16px;
  background-position: -432px -192px;
}

.ARW {
  width: 16px;
  height: 16px;
  background-position: -432px -208px;
}

.ARX {
  width: 16px;
  height: 16px;
  background-position: -432px -224px;
}

.ARXO {
  width: 16px;
  height: 16px;
  background-position: -432px -240px;
}

.ARY {
  width: 16px;
  height: 16px;
  background-position: -432px -256px;
}

.ARYA {
  width: 16px;
  height: 16px;
  background-position: -432px -272px;
}

.ARZ {
  width: 16px;
  height: 16px;
  background-position: -432px -288px;
}

.ASA {
  width: 16px;
  height: 16px;
  background-position: -432px -304px;
}

.ASAC {
  width: 16px;
  height: 16px;
  background-position: -432px -320px;
}

.ASAFE {
  width: 16px;
  height: 16px;
  background-position: -432px -336px;
}

.ASAP {
  width: 16px;
  height: 16px;
  background-position: -432px -352px;
}

.ASB {
  width: 16px;
  height: 16px;
  background-position: -432px -368px;
}

.ASC {
  width: 16px;
  height: 16px;
  background-position: -432px -384px;
}

.ASD {
  width: 16px;
  height: 16px;
  background-position: -432px -400px;
}

.ASDEX {
  width: 16px;
  height: 16px;
  background-position: -432px -416px;
}

.ASET {
  width: 16px;
  height: 16px;
  background-position: -0 -432px;
}

.ASG {
  width: 16px;
  height: 16px;
  background-position: -16px -432px;
}

.ASGARD {
  width: 16px;
  height: 16px;
  background-position: -32px -432px;
}

.ASGARDV2 {
  width: 16px;
  height: 16px;
  background-position: -48px -432px;
}

.ASH {
  width: 16px;
  height: 16px;
  background-position: -64px -432px;
}

.ASHARE {
  width: 16px;
  height: 16px;
  background-position: -80px -432px;
}

.ASHIB {
  width: 16px;
  height: 16px;
  background-position: -96px -432px;
}

.ASHIT {
  width: 16px;
  height: 16px;
  background-position: -112px -432px;
}

.ASI {
  width: 16px;
  height: 16px;
  background-position: -128px -432px;
}

.ASIA {
  width: 16px;
  height: 16px;
  background-position: -144px -432px;
}

.ASIMI {
  width: 16px;
  height: 16px;
  background-position: -160px -432px;
}

.ASIX {
  width: 16px;
  height: 16px;
  background-position: -176px -432px;
}

.ASIX\+ {
  width: 16px;
  height: 16px;
  background-position: -192px -432px;
}

.ASK {
  width: 16px;
  height: 16px;
  background-position: -208px -432px;
}

.ASKO {
  width: 16px;
  height: 16px;
  background-position: -224px -432px;
}

.ASLA {
  width: 16px;
  height: 16px;
  background-position: -240px -432px;
}

.ASM {
  width: 16px;
  height: 16px;
  background-position: -256px -432px;
}

.ASND {
  width: 16px;
  height: 16px;
  background-position: -272px -432px;
}

.ASNT {
  width: 16px;
  height: 16px;
  background-position: -288px -432px;
}

.ASNX {
  width: 16px;
  height: 16px;
  background-position: -304px -432px;
}

.ASP {
  width: 16px;
  height: 16px;
  background-position: -320px -432px;
}

.ASPEN {
  width: 16px;
  height: 16px;
  background-position: -336px -432px;
}

.ASPO {
  width: 16px;
  height: 16px;
  background-position: -352px -432px;
}

.ASR {
  width: 16px;
  height: 16px;
  background-position: -368px -432px;
}

.ASS {
  width: 16px;
  height: 16px;
  background-position: -384px -432px;
}

.ASSA {
  width: 16px;
  height: 16px;
  background-position: -400px -432px;
}

.ASSET {
  width: 16px;
  height: 16px;
  background-position: -416px -432px;
}

.ASSY {
  width: 16px;
  height: 16px;
  background-position: -432px -432px;
}

.AST {
  width: 16px;
  height: 16px;
  background-position: -448px -0;
}

.ASTA {
  width: 16px;
  height: 16px;
  background-position: -448px -16px;
}

.ASTAKE {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}

.ASTAR {
  width: 16px;
  height: 16px;
  background-position: -448px -48px;
}

.ASTO {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}

.ASTR {
  width: 16px;
  height: 16px;
  background-position: -448px -80px;
}

.ASTRA {
  width: 16px;
  height: 16px;
  background-position: -448px -96px;
}

.ASTRO {
  width: 16px;
  height: 16px;
  background-position: -448px -112px;
}

.ASTROGOLD {
  width: 16px;
  height: 16px;
  background-position: -448px -128px;
}

.ASUKA {
  width: 16px;
  height: 16px;
  background-position: -448px -144px;
}

.ASUNA {
  width: 16px;
  height: 16px;
  background-position: -448px -160px;
}

.ASUNAINU {
  width: 16px;
  height: 16px;
  background-position: -448px -176px;
}

.ASUSD {
  width: 16px;
  height: 16px;
  background-position: -448px -192px;
}

.ASV {
  width: 16px;
  height: 16px;
  background-position: -448px -208px;
}

.ASVA {
  width: 16px;
  height: 16px;
  background-position: -448px -224px;
}

.ASW {
  width: 16px;
  height: 16px;
  background-position: -448px -240px;
}

.ASWAP {
  width: 16px;
  height: 16px;
  background-position: -448px -256px;
}

.ASY {
  width: 16px;
  height: 16px;
  background-position: -448px -272px;
}

.AT {
  width: 16px;
  height: 16px;
  background-position: -448px -288px;
}

.ATA {
  width: 16px;
  height: 16px;
  background-position: -448px -304px;
}

.ATB {
  width: 16px;
  height: 16px;
  background-position: -448px -320px;
}

.ATC {
  width: 16px;
  height: 16px;
  background-position: -448px -336px;
}

.ATCC {
  width: 16px;
  height: 16px;
  background-position: -448px -352px;
}

.ATD {
  width: 16px;
  height: 16px;
  background-position: -448px -368px;
}

.ATEM {
  width: 16px;
  height: 16px;
  background-position: -448px -384px;
}

.ATF {
  width: 16px;
  height: 16px;
  background-position: -448px -400px;
}

.ATFI {
  width: 16px;
  height: 16px;
  background-position: -448px -416px;
}

.ATH {
  width: 16px;
  height: 16px;
  background-position: -448px -432px;
}

.ATHEN {
  width: 16px;
  height: 16px;
  background-position: -0 -448px;
}

.ATIS {
  width: 16px;
  height: 16px;
  background-position: -16px -448px;
}

.ATK {
  width: 16px;
  height: 16px;
  background-position: -32px -448px;
}

.ATL {
  width: 16px;
  height: 16px;
  background-position: -48px -448px;
}

.ATLAS {
  width: 16px;
  height: 16px;
  background-position: -64px -448px;
}

.ATLO {
  width: 16px;
  height: 16px;
  background-position: -80px -448px;
}

.ATLS {
  width: 16px;
  height: 16px;
  background-position: -96px -448px;
}

.ATLX {
  width: 16px;
  height: 16px;
  background-position: -112px -448px;
}

.ATM {
  width: 16px;
  height: 16px;
  background-position: -128px -448px;
}

.ATM\$ {
  width: 16px;
  height: 16px;
  background-position: -144px -448px;
}

.ATMC {
  width: 16px;
  height: 16px;
  background-position: -160px -448px;
}

.ATMI {
  width: 16px;
  height: 16px;
  background-position: -176px -448px;
}

.ATMN {
  width: 16px;
  height: 16px;
  background-position: -192px -448px;
}

.ATMOS {
  width: 16px;
  height: 16px;
  background-position: -208px -448px;
}

.ATMSSFT {
  width: 16px;
  height: 16px;
  background-position: -224px -448px;
}

.ATMUP {
  width: 16px;
  height: 16px;
  background-position: -240px -448px;
}

.ATN {
  width: 16px;
  height: 16px;
  background-position: -256px -448px;
}

.ATNIR {
  width: 16px;
  height: 16px;
  background-position: -272px -448px;
}

.ATOLO {
  width: 16px;
  height: 16px;
  background-position: -288px -448px;
}

.ATOM {
  width: 16px;
  height: 16px;
  background-position: -304px -448px;
}

.ATOMIC {
  width: 16px;
  height: 16px;
  background-position: -320px -448px;
}

.ATP {
  width: 16px;
  height: 16px;
  background-position: -336px -448px;
}

.ATPAD {
  width: 16px;
  height: 16px;
  background-position: -352px -448px;
}

.ATPC {
  width: 16px;
  height: 16px;
  background-position: -368px -448px;
}

.ATR {
  width: 16px;
  height: 16px;
  background-position: -384px -448px;
}

.ATRI {
  width: 16px;
  height: 16px;
  background-position: -400px -448px;
}

.ATS {
  width: 16px;
  height: 16px;
  background-position: -416px -448px;
}

.ATT {
  width: 16px;
  height: 16px;
  background-position: -432px -448px;
}

.ATTN {
  width: 16px;
  height: 16px;
  background-position: -448px -448px;
}

.ATTR {
  width: 16px;
  height: 16px;
  background-position: -464px -0;
}

.ATUSD {
  width: 16px;
  height: 16px;
  background-position: -464px -16px;
}

.ATX {
  width: 16px;
  height: 16px;
  background-position: -464px -32px;
}

.ATYNE {
  width: 16px;
  height: 16px;
  background-position: -464px -48px;
}

.AU {
  width: 16px;
  height: 16px;
  background-position: -464px -64px;
}

.AUC {
  width: 16px;
  height: 16px;
  background-position: -464px -80px;
}

.AUCTION {
  width: 16px;
  height: 16px;
  background-position: -464px -96px;
}

.AUDIO {
  width: 16px;
  height: 16px;
  background-position: -464px -112px;
}

.AUDIT {
  width: 16px;
  height: 16px;
  background-position: -464px -128px;
}

.AUDT {
  width: 16px;
  height: 16px;
  background-position: -464px -144px;
}

.AUDX {
  width: 16px;
  height: 16px;
  background-position: -464px -160px;
}

.AUMI {
  width: 16px;
  height: 16px;
  background-position: -464px -176px;
}

.AUN {
  width: 16px;
  height: 16px;
  background-position: -464px -192px;
}

.AUNIT {
  width: 16px;
  height: 16px;
  background-position: -464px -208px;
}

.AUR {
  width: 16px;
  height: 16px;
  background-position: -464px -224px;
}

.AURA {
  width: 16px;
  height: 16px;
  background-position: -464px -240px;
}

.AUREUSRH {
  width: 16px;
  height: 16px;
  background-position: -464px -256px;
}

.AURO {
  width: 16px;
  height: 16px;
  background-position: -464px -272px;
}

.AURORA {
  width: 16px;
  height: 16px;
  background-position: -464px -288px;
}

.AURUM {
  width: 16px;
  height: 16px;
  background-position: -464px -304px;
}

.AURY {
  width: 16px;
  height: 16px;
  background-position: -464px -320px;
}

.AUSCM {
  width: 16px;
  height: 16px;
  background-position: -464px -336px;
}

.AUSD {
  width: 16px;
  height: 16px;
  background-position: -464px -352px;
}

.AUSDC {
  width: 16px;
  height: 16px;
  background-position: -464px -368px;
}

.AUSDT {
  width: 16px;
  height: 16px;
  background-position: -464px -384px;
}

.AUST {
  width: 16px;
  height: 16px;
  background-position: -464px -400px;
}

.AUT {
  width: 16px;
  height: 16px;
  background-position: -464px -416px;
}

.AUTO {
  width: 16px;
  height: 16px;
  background-position: -464px -432px;
}

.AUTZ {
  width: 16px;
  height: 16px;
  background-position: -464px -448px;
}

.AUV {
  width: 16px;
  height: 16px;
  background-position: -0 -464px;
}

.AUX {
  width: 16px;
  height: 16px;
  background-position: -16px -464px;
}

.AV {
  width: 16px;
  height: 16px;
  background-position: -32px -464px;
}

.AVA {
  width: 16px;
  height: 16px;
  background-position: -48px -464px;
}

.Avado {
  width: 16px;
  height: 16px;
  background-position: -64px -464px;
}

.AVAI {
  width: 16px;
  height: 16px;
  background-position: -80px -464px;
}

.AVAK {
  width: 16px;
  height: 16px;
  background-position: -96px -464px;
}

.AVAL {
  width: 16px;
  height: 16px;
  background-position: -112px -464px;
}

.AVAMIM {
  width: 16px;
  height: 16px;
  background-position: -128px -464px;
}

.AVAO {
  width: 16px;
  height: 16px;
  background-position: -144px -464px;
}

.AVAPAY {
  width: 16px;
  height: 16px;
  background-position: -160px -464px;
}

.AVAT {
  width: 16px;
  height: 16px;
  background-position: -176px -464px;
}

.AVATAR {
  width: 16px;
  height: 16px;
  background-position: -192px -464px;
}

.AVAX {
  width: 16px;
  height: 16px;
  background-position: -208px -464px;
}

.AVAXD {
  width: 16px;
  height: 16px;
  background-position: -224px -464px;
}

.AVAXUP {
  width: 16px;
  height: 16px;
  background-position: -240px -464px;
}

.AVC {
  width: 16px;
  height: 16px;
  background-position: -256px -464px;
}

.AVDO {
  width: 16px;
  height: 16px;
  background-position: -272px -464px;
}

.AVE {
  width: 16px;
  height: 16px;
  background-position: -288px -464px;
}

.AVEC {
  width: 16px;
  height: 16px;
  background-position: -304px -464px;
}

.AVERSE {
  width: 16px;
  height: 16px;
  background-position: -320px -464px;
}

.AVG {
  width: 16px;
  height: 16px;
  background-position: -336px -464px;
}

.AVH {
  width: 16px;
  height: 16px;
  background-position: -352px -464px;
}

.AVI {
  width: 16px;
  height: 16px;
  background-position: -368px -464px;
}

.AVIAN {
  width: 16px;
  height: 16px;
  background-position: -384px -464px;
}

.AVINOC {
  width: 16px;
  height: 16px;
  background-position: -400px -464px;
}

.AVL {
  width: 16px;
  height: 16px;
  background-position: -416px -464px;
}

.AVME {
  width: 16px;
  height: 16px;
  background-position: -432px -464px;
}

.AVN {
  width: 16px;
  height: 16px;
  background-position: -448px -464px;
}

.AVNGRS {
  width: 16px;
  height: 16px;
  background-position: -464px -464px;
}

.AVO {
  width: 16px;
  height: 16px;
  background-position: -480px -0;
}

.AVR {
  width: 16px;
  height: 16px;
  background-position: -480px -16px;
}

.AVS {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}

.AVT {
  width: 16px;
  height: 16px;
  background-position: -480px -48px;
}

.AVTIME {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}

.AVX {
  width: 16px;
  height: 16px;
  background-position: -480px -80px;
}

.AVXL {
  width: 16px;
  height: 16px;
  background-position: -480px -96px;
}

.AVXT {
  width: 16px;
  height: 16px;
  background-position: -480px -112px;
}

.AWAR {
  width: 16px;
  height: 16px;
  background-position: -480px -128px;
}

.AWBTC {
  width: 16px;
  height: 16px;
  background-position: -480px -144px;
}

.AWC {
  width: 16px;
  height: 16px;
  background-position: -480px -160px;
}

.AWF {
  width: 16px;
  height: 16px;
  background-position: -480px -176px;
}

.AWG {
  width: 16px;
  height: 16px;
  background-position: -480px -192px;
}

.AWO {
  width: 16px;
  height: 16px;
  background-position: -480px -208px;
}

.AWOOL {
  width: 16px;
  height: 16px;
  background-position: -480px -224px;
}

.AWS {
  width: 16px;
  height: 16px;
  background-position: -480px -240px;
}

.aWSB {
  width: 16px;
  height: 16px;
  background-position: -480px -256px;
}

.AWX {
  width: 16px;
  height: 16px;
  background-position: -480px -272px;
}

.AXA {
  width: 16px;
  height: 16px;
  background-position: -480px -288px;
}

.AXAI {
  width: 16px;
  height: 16px;
  background-position: -480px -304px;
}

.AXC {
  width: 16px;
  height: 16px;
  background-position: -480px -320px;
}

.AXE {
  width: 16px;
  height: 16px;
  background-position: -480px -336px;
}

.AXEL {
  width: 16px;
  height: 16px;
  background-position: -480px -352px;
}

.AXI {
  width: 16px;
  height: 16px;
  background-position: -480px -368px;
}

.AXIA {
  width: 16px;
  height: 16px;
  background-position: -480px -384px;
}

.AXIAL {
  width: 16px;
  height: 16px;
  background-position: -480px -400px;
}

.AXIEBCH {
  width: 16px;
  height: 16px;
  background-position: -480px -416px;
}

.AXIN {
  width: 16px;
  height: 16px;
  background-position: -480px -432px;
}

.AXIOM {
  width: 16px;
  height: 16px;
  background-position: -480px -448px;
}

.AXIS {
  width: 16px;
  height: 16px;
  background-position: -480px -464px;
}

.AXL {
  width: 16px;
  height: 16px;
  background-position: -0 -480px;
}

.AXN {
  width: 16px;
  height: 16px;
  background-position: -16px -480px;
}

.AXO {
  width: 16px;
  height: 16px;
  background-position: -32px -480px;
}

.AXPR {
  width: 16px;
  height: 16px;
  background-position: -48px -480px;
}

.AXS {
  width: 16px;
  height: 16px;
  background-position: -64px -480px;
}

.AXSD {
  width: 16px;
  height: 16px;
  background-position: -80px -480px;
}

.AXUS {
  width: 16px;
  height: 16px;
  background-position: -96px -480px;
}

.AXW {
  width: 16px;
  height: 16px;
  background-position: -112px -480px;
}

.AXZ {
  width: 16px;
  height: 16px;
  background-position: -128px -480px;
}

.AYA {
  width: 16px;
  height: 16px;
  background-position: -144px -480px;
}

.AYFI {
  width: 16px;
  height: 16px;
  background-position: -160px -480px;
}

.AZ {
  width: 16px;
  height: 16px;
  background-position: -176px -480px;
}

.AZART {
  width: 16px;
  height: 16px;
  background-position: -192px -480px;
}

.AZBI {
  width: 16px;
  height: 16px;
  background-position: -208px -480px;
}

.AZERO {
  width: 16px;
  height: 16px;
  background-position: -224px -480px;
}

.AZIT {
  width: 16px;
  height: 16px;
  background-position: -240px -480px;
}

.AZRX {
  width: 16px;
  height: 16px;
  background-position: -256px -480px;
}

.AZT {
  width: 16px;
  height: 16px;
  background-position: -272px -480px;
}

.AZUKI {
  width: 16px;
  height: 16px;
  background-position: -288px -480px;
}

.AZUM {
  width: 16px;
  height: 16px;
  background-position: -304px -480px;
}

.AZW {
  width: 16px;
  height: 16px;
  background-position: -320px -480px;
}

.AZX {
  width: 16px;
  height: 16px;
  background-position: -336px -480px;
}

.AZZR {
  width: 16px;
  height: 16px;
  background-position: -352px -480px;
}

.B1P {
  width: 16px;
  height: 16px;
  background-position: -368px -480px;
}

.B1TC {
  width: 16px;
  height: 16px;
  background-position: -384px -480px;
}

.B2G {
  width: 16px;
  height: 16px;
  background-position: -400px -480px;
}

.B2M {
  width: 16px;
  height: 16px;
  background-position: -416px -480px;
}

.B2P {
  width: 16px;
  height: 16px;
  background-position: -432px -480px;
}

.B2U {
  width: 16px;
  height: 16px;
  background-position: -448px -480px;
}

.B2X {
  width: 16px;
  height: 16px;
  background-position: -464px -480px;
}

.B3X {
  width: 16px;
  height: 16px;
  background-position: -480px -480px;
}

.B8 {
  width: 16px;
  height: 16px;
  background-position: -496px -0;
}

.B20 {
  width: 16px;
  height: 16px;
  background-position: -496px -16px;
}

.B21 {
  width: 16px;
  height: 16px;
  background-position: -496px -32px;
}

.B26 {
  width: 16px;
  height: 16px;
  background-position: -496px -48px;
}

.B91 {
  width: 16px;
  height: 16px;
  background-position: -496px -64px;
}

.B360 {
  width: 16px;
  height: 16px;
  background-position: -496px -80px;
}

.B\@ {
  width: 16px;
  height: 16px;
  background-position: -496px -96px;
}

.BA {
  width: 16px;
  height: 16px;
  background-position: -496px -112px;
}

.BAAS {
  width: 16px;
  height: 16px;
  background-position: -496px -128px;
}

.BAB {
  width: 16px;
  height: 16px;
  background-position: -496px -144px;
}

.BABEL {
  width: 16px;
  height: 16px;
  background-position: -496px -160px;
}

.BABI {
  width: 16px;
  height: 16px;
  background-position: -496px -176px;
}

.BABIL {
  width: 16px;
  height: 16px;
  background-position: -496px -192px;
}

.BABL {
  width: 16px;
  height: 16px;
  background-position: -496px -208px;
}

.BABY {
  width: 16px;
  height: 16px;
  background-position: -496px -224px;
}

.BABYADA {
  width: 16px;
  height: 16px;
  background-position: -496px -240px;
}

.BABYAETH {
  width: 16px;
  height: 16px;
  background-position: -496px -256px;
}

.BABYBITC {
  width: 16px;
  height: 16px;
  background-position: -496px -272px;
}

.BABYBNB {
  width: 16px;
  height: 16px;
  background-position: -496px -288px;
}

.BABYBOO {
  width: 16px;
  height: 16px;
  background-position: -496px -304px;
}

.BABYBUSD {
  width: 16px;
  height: 16px;
  background-position: -496px -320px;
}

.BABYCARE {
  width: 16px;
  height: 16px;
  background-position: -496px -336px;
}

.BABYCASPER {
  width: 16px;
  height: 16px;
  background-position: -496px -352px;
}

.BABYCATE {
  width: 16px;
  height: 16px;
  background-position: -496px -368px;
}

.BABYCATGIRL {
  width: 16px;
  height: 16px;
  background-position: -496px -384px;
}

.BABYCATS {
  width: 16px;
  height: 16px;
  background-position: -496px -400px;
}

.BABYCHEDDA {
  width: 16px;
  height: 16px;
  background-position: -496px -416px;
}

.BABYCLIFF {
  width: 16px;
  height: 16px;
  background-position: -496px -432px;
}

.BABYCUBAN {
  width: 16px;
  height: 16px;
  background-position: -496px -448px;
}

.BABYDB {
  width: 16px;
  height: 16px;
  background-position: -496px -464px;
}

.BabyDeFido {
  width: 16px;
  height: 16px;
  background-position: -496px -480px;
}

.BABYDINGER {
  width: 16px;
  height: 16px;
  background-position: -0 -496px;
}

.BabyDoge {
  width: 16px;
  height: 16px;
  background-position: -16px -496px;
}

.BABYDOGE {
  width: 16px;
  height: 16px;
  background-position: -32px -496px;
}

.BABYDOGECASH {
  width: 16px;
  height: 16px;
  background-position: -48px -496px;
}

.BABYDOGEINU {
  width: 16px;
  height: 16px;
  background-position: -64px -496px;
}

.babyDogeKing {
  width: 16px;
  height: 16px;
  background-position: -80px -496px;
}

.BABYDOGEMM {
  width: 16px;
  height: 16px;
  background-position: -96px -496px;
}

.BABYDOGEZILLA {
  width: 16px;
  height: 16px;
  background-position: -112px -496px;
}

.BABYDOUG {
  width: 16px;
  height: 16px;
  background-position: -128px -496px;
}

.BABYDRIP {
  width: 16px;
  height: 16px;
  background-position: -144px -496px;
}

.BABYEGG {
  width: 16px;
  height: 16px;
  background-position: -160px -496px;
}

.BABYETH {
  width: 16px;
  height: 16px;
  background-position: -176px -496px;
}

.BabyETHV2 {
  width: 16px;
  height: 16px;
  background-position: -192px -496px;
}

.BabyFB {
  width: 16px;
  height: 16px;
  background-position: -208px -496px;
}

.BABYFD {
  width: 16px;
  height: 16px;
  background-position: -224px -496px;
}

.BABYFLOKI {
  width: 16px;
  height: 16px;
  background-position: -240px -496px;
}

.BABYFLOKICOIN {
  width: 16px;
  height: 16px;
  background-position: -256px -496px;
}

.BABYFLOKIZILLA {
  width: 16px;
  height: 16px;
  background-position: -272px -496px;
}

.BABYFROG {
  width: 16px;
  height: 16px;
  background-position: -288px -496px;
}

.BABYHARMONY {
  width: 16px;
  height: 16px;
  background-position: -304px -496px;
}

.BABYKITTY {
  width: 16px;
  height: 16px;
  background-position: -320px -496px;
}

.BABYLILFLOKI {
  width: 16px;
  height: 16px;
  background-position: -336px -496px;
}

.BABYLONDON {
  width: 16px;
  height: 16px;
  background-position: -352px -496px;
}

.BABYLOWB {
  width: 16px;
  height: 16px;
  background-position: -368px -496px;
}

.BABYMETA {
  width: 16px;
  height: 16px;
  background-position: -384px -496px;
}

.BABYMUSK {
  width: 16px;
  height: 16px;
  background-position: -400px -496px;
}

.BABYOKX {
  width: 16px;
  height: 16px;
  background-position: -416px -496px;
}

.BABYPIG {
  width: 16px;
  height: 16px;
  background-position: -432px -496px;
}

.BABYPOO {
  width: 16px;
  height: 16px;
  background-position: -448px -496px;
}

.BABYQUICK {
  width: 16px;
  height: 16px;
  background-position: -464px -496px;
}

.BABYSAITAMA {
  width: 16px;
  height: 16px;
  background-position: -480px -496px;
}

.BABYSHIB {
  width: 16px;
  height: 16px;
  background-position: -496px -496px;
}

.BABYSHIBA {
  width: 16px;
  height: 16px;
  background-position: -512px -0;
}

.BABYSHIBAINU {
  width: 16px;
  height: 16px;
  background-position: -512px -16px;
}

.BABYSHINJA {
  width: 16px;
  height: 16px;
  background-position: -512px -32px;
}

.BabySun {
  width: 16px;
  height: 16px;
  background-position: -512px -48px;
}

.BABYTETHER {
  width: 16px;
  height: 16px;
  background-position: -512px -64px;
}

.BABYTIGER {
  width: 16px;
  height: 16px;
  background-position: -512px -80px;
}

.BABYTK {
  width: 16px;
  height: 16px;
  background-position: -512px -96px;
}

.BABYUNI {
  width: 16px;
  height: 16px;
  background-position: -512px -112px;
}

.BABYUSDT {
  width: 16px;
  height: 16px;
  background-position: -512px -128px;
}

.BABYVIZSLA {
  width: 16px;
  height: 16px;
  background-position: -512px -144px;
}

.BABYWKD {
  width: 16px;
  height: 16px;
  background-position: -512px -160px;
}

.BABYWOLF {
  width: 16px;
  height: 16px;
  background-position: -512px -176px;
}

.BABYX {
  width: 16px;
  height: 16px;
  background-position: -512px -192px;
}

.BABYXRP {
  width: 16px;
  height: 16px;
  background-position: -512px -208px;
}

.BABYXTAG {
  width: 16px;
  height: 16px;
  background-position: -512px -224px;
}

.BABY_YOOSHI {
  width: 16px;
  height: 16px;
  background-position: -512px -240px;
}

.BABYZOROINU {
  width: 16px;
  height: 16px;
  background-position: -512px -256px;
}

.BAC {
  width: 16px;
  height: 16px;
  background-position: -512px -272px;
}

.BACK {
  width: 16px;
  height: 16px;
  background-position: -512px -288px;
}

.BackstabDoge {
  width: 16px;
  height: 16px;
  background-position: -512px -304px;
}

.BACON {
  width: 16px;
  height: 16px;
  background-position: -512px -320px;
}

.BADGER {
  width: 16px;
  height: 16px;
  background-position: -512px -336px;
}

.BAE {
  width: 16px;
  height: 16px;
  background-position: -512px -352px;
}

.BAEPAY {
  width: 16px;
  height: 16px;
  background-position: -512px -368px;
}

.BAETH {
  width: 16px;
  height: 16px;
  background-position: -512px -384px;
}

.BAFE {
  width: 16px;
  height: 16px;
  background-position: -512px -400px;
}

.BAFI {
  width: 16px;
  height: 16px;
  background-position: -512px -416px;
}

.BAG {
  width: 16px;
  height: 16px;
  background-position: -512px -432px;
}

.BAGEL {
  width: 16px;
  height: 16px;
  background-position: -512px -448px;
}

.BAGS {
  width: 16px;
  height: 16px;
  background-position: -512px -464px;
}

.BAIR {
  width: 16px;
  height: 16px;
  background-position: -512px -480px;
}

.BAIT {
  width: 16px;
  height: 16px;
  background-position: -512px -496px;
}

.BAJA {
  width: 16px;
  height: 16px;
  background-position: -0 -512px;
}

.BAK {
  width: 16px;
  height: 16px;
  background-position: -16px -512px;
}

.BAKE {
  width: 16px;
  height: 16px;
  background-position: -32px -512px;
}

.BAKECOIN {
  width: 16px;
  height: 16px;
  background-position: -48px -512px;
}

.BAKED {
  width: 16px;
  height: 16px;
  background-position: -64px -512px;
}

.BAKEDCAKE {
  width: 16px;
  height: 16px;
  background-position: -80px -512px;
}

.BAKER {
  width: 16px;
  height: 16px;
  background-position: -96px -512px;
}

.BAKEUP {
  width: 16px;
  height: 16px;
  background-position: -112px -512px;
}

.BAKS {
  width: 16px;
  height: 16px;
  background-position: -128px -512px;
}

.BAL {
  width: 16px;
  height: 16px;
  background-position: -144px -512px;
}

.BALA {
  width: 16px;
  height: 16px;
  background-position: -160px -512px;
}

.BALI {
  width: 16px;
  height: 16px;
  background-position: -176px -512px;
}

.BALL {
  width: 16px;
  height: 16px;
  background-position: -192px -512px;
}

.BALLBAG {
  width: 16px;
  height: 16px;
  background-position: -208px -512px;
}

.BALLS {
  width: 16px;
  height: 16px;
  background-position: -224px -512px;
}

.BALLZ {
  width: 16px;
  height: 16px;
  background-position: -240px -512px;
}

.BALN {
  width: 16px;
  height: 16px;
  background-position: -256px -512px;
}

.BALPAC {
  width: 16px;
  height: 16px;
  background-position: -272px -512px;
}

.BALPHA {
  width: 16px;
  height: 16px;
  background-position: -288px -512px;
}

.BAMB {
  width: 16px;
  height: 16px;
  background-position: -304px -512px;
}

.BAMBI {
  width: 16px;
  height: 16px;
  background-position: -320px -512px;
}

.BAMBOO {
  width: 16px;
  height: 16px;
  background-position: -336px -512px;
}

.BAMI {
  width: 16px;
  height: 16px;
  background-position: -352px -512px;
}

.BAMP {
  width: 16px;
  height: 16px;
  background-position: -368px -512px;
}

.BAMUSDT {
  width: 16px;
  height: 16px;
  background-position: -384px -512px;
}

.BAN {
  width: 16px;
  height: 16px;
  background-position: -400px -512px;
}

.BANA {
  width: 16px;
  height: 16px;
  background-position: -416px -512px;
}

.BANAN {
  width: 16px;
  height: 16px;
  background-position: -432px -512px;
}

.BANANA {
  width: 16px;
  height: 16px;
  background-position: -448px -512px;
}

.BANCA {
  width: 16px;
  height: 16px;
  background-position: -464px -512px;
}

.BAND {
  width: 16px;
  height: 16px;
  background-position: -480px -512px;
}

.BANDEX {
  width: 16px;
  height: 16px;
  background-position: -496px -512px;
}

.BANG {
  width: 16px;
  height: 16px;
  background-position: -512px -512px;
}

.BANK {
  width: 16px;
  height: 16px;
  background-position: -528px -0;
}

.BANK\$ {
  width: 16px;
  height: 16px;
  background-position: -528px -16px;
}

.BANKER {
  width: 16px;
  height: 16px;
  background-position: -528px -32px;
}

.BANKETH {
  width: 16px;
  height: 16px;
  background-position: -528px -48px;
}

.BANKR {
  width: 16px;
  height: 16px;
  background-position: -528px -64px;
}

.BANKWUPT {
  width: 16px;
  height: 16px;
  background-position: -528px -80px;
}

.BAO {
  width: 16px;
  height: 16px;
  background-position: -528px -96px;
}

.BAoE {
  width: 16px;
  height: 16px;
  background-position: -528px -112px;
}

.BAPE {
  width: 16px;
  height: 16px;
  background-position: -528px -128px;
}

.BAR {
  width: 16px;
  height: 16px;
  background-position: -528px -144px;
}

.BARG {
  width: 16px;
  height: 16px;
  background-position: -528px -160px;
}

.BARK {
  width: 16px;
  height: 16px;
  background-position: -528px -176px;
}

.BARMY {
  width: 16px;
  height: 16px;
  background-position: -528px -192px;
}

.BARREL {
  width: 16px;
  height: 16px;
  background-position: -528px -208px;
}

.BART {
  width: 16px;
  height: 16px;
  background-position: -528px -224px;
}

.BAS {
  width: 16px;
  height: 16px;
  background-position: -528px -240px;
}

.BASE {
  width: 16px;
  height: 16px;
  background-position: -528px -256px;
}

.BASED {
  width: 16px;
  height: 16px;
  background-position: -528px -272px;
}

.BASHTANK {
  width: 16px;
  height: 16px;
  background-position: -528px -288px;
}

.BASI {
  width: 16px;
  height: 16px;
  background-position: -528px -304px;
}

.BASIC {
  width: 16px;
  height: 16px;
  background-position: -528px -320px;
}

.BASID {
  width: 16px;
  height: 16px;
  background-position: -528px -336px;
}

.BASIS {
  width: 16px;
  height: 16px;
  background-position: -528px -352px;
}

.BASK {
  width: 16px;
  height: 16px;
  background-position: -528px -368px;
}

.BAST {
  width: 16px;
  height: 16px;
  background-position: -528px -384px;
}

.BASTILLE {
  width: 16px;
  height: 16px;
  background-position: -528px -400px;
}

.BASX {
  width: 16px;
  height: 16px;
  background-position: -528px -416px;
}

.BAT {
  width: 16px;
  height: 16px;
  background-position: -528px -432px;
}

.BATDOGE {
  width: 16px;
  height: 16px;
  background-position: -528px -448px;
}

.BATH {
  width: 16px;
  height: 16px;
  background-position: -528px -464px;
}

.BATMAN {
  width: 16px;
  height: 16px;
  background-position: -528px -480px;
}

.BATMOON {
  width: 16px;
  height: 16px;
  background-position: -528px -496px;
}

.BATTLE {
  width: 16px;
  height: 16px;
  background-position: -528px -512px;
}

.BAU {
  width: 16px;
  height: 16px;
  background-position: -0 -528px;
}

.BAW {
  width: 16px;
  height: 16px;
  background-position: -16px -528px;
}

.BAX {
  width: 16px;
  height: 16px;
  background-position: -32px -528px;
}

.BAXS {
  width: 16px;
  height: 16px;
  background-position: -48px -528px;
}

.BAY {
  width: 16px;
  height: 16px;
  background-position: -64px -528px;
}

.BAZT {
  width: 16px;
  height: 16px;
  background-position: -80px -528px;
}

.BB {
  width: 16px;
  height: 16px;
  background-position: -96px -528px;
}

.BB1 {
  width: 16px;
  height: 16px;
  background-position: -112px -528px;
}

.BB_A_DAI {
  width: 16px;
  height: 16px;
  background-position: -128px -528px;
}

.BBANK {
  width: 16px;
  height: 16px;
  background-position: -144px -528px;
}

.BB_A_USDC {
  width: 16px;
  height: 16px;
  background-position: -160px -528px;
}

.BBC {
  width: 16px;
  height: 16px;
  background-position: -176px -528px;
}

.BBC_DAO {
  width: 16px;
  height: 16px;
  background-position: -192px -528px;
}

.BBCLIFF {
  width: 16px;
  height: 16px;
  background-position: -208px -528px;
}

.BBETH {
  width: 16px;
  height: 16px;
  background-position: -224px -528px;
}

.BBF {
  width: 16px;
  height: 16px;
  background-position: -240px -528px;
}

.BBFEG {
  width: 16px;
  height: 16px;
  background-position: -256px -528px;
}

.BBFT {
  width: 16px;
  height: 16px;
  background-position: -272px -528px;
}

.BBGC {
  width: 16px;
  height: 16px;
  background-position: -288px -528px;
}

.BBH {
  width: 16px;
  height: 16px;
  background-position: -304px -528px;
}

.BBI {
  width: 16px;
  height: 16px;
  background-position: -320px -528px;
}

.BBK {
  width: 16px;
  height: 16px;
  background-position: -336px -528px;
}

.BBKFI {
  width: 16px;
  height: 16px;
  background-position: -352px -528px;
}

.BBL {
  width: 16px;
  height: 16px;
  background-position: -368px -528px;
}

.BBN {
  width: 16px;
  height: 16px;
  background-position: -384px -528px;
}

.BBNANA {
  width: 16px;
  height: 16px;
  background-position: -400px -528px;
}

.BBND {
  width: 16px;
  height: 16px;
  background-position: -416px -528px;
}

.BBNFT {
  width: 16px;
  height: 16px;
  background-position: -432px -528px;
}

.BBO {
  width: 16px;
  height: 16px;
  background-position: -448px -528px;
}

.BBOO {
  width: 16px;
  height: 16px;
  background-position: -464px -528px;
}

.BBOTS {
  width: 16px;
  height: 16px;
  background-position: -480px -528px;
}

.BBOXER {
  width: 16px;
  height: 16px;
  background-position: -496px -528px;
}

.BBP {
  width: 16px;
  height: 16px;
  background-position: -512px -528px;
}

.BBQ {
  width: 16px;
  height: 16px;
  background-position: -528px -528px;
}

.BBR {
  width: 16px;
  height: 16px;
  background-position: -544px -0;
}

.BBS {
  width: 16px;
  height: 16px;
  background-position: -544px -16px;
}

.BBT {
  width: 16px;
  height: 16px;
  background-position: -544px -32px;
}

.BBTC {
  width: 16px;
  height: 16px;
  background-position: -544px -48px;
}

.BBW {
  width: 16px;
  height: 16px;
  background-position: -544px -64px;
}

.BBY {
  width: 16px;
  height: 16px;
  background-position: -544px -80px;
}

.BBYCAT {
  width: 16px;
  height: 16px;
  background-position: -544px -96px;
}

.BBYSTC {
  width: 16px;
  height: 16px;
  background-position: -544px -112px;
}

.BBYXRP {
  width: 16px;
  height: 16px;
  background-position: -544px -128px;
}

.BC {
  width: 16px;
  height: 16px;
  background-position: -544px -144px;
}

.BC2_1 {
  width: 16px;
  height: 16px;
  background-position: -544px -160px;
}

.BCA {
  width: 16px;
  height: 16px;
  background-position: -544px -176px;
}

.BCAC {
  width: 16px;
  height: 16px;
  background-position: -544px -192px;
}

.BCAKE {
  width: 16px;
  height: 16px;
  background-position: -544px -208px;
}

.BCAPS {
  width: 16px;
  height: 16px;
  background-position: -544px -224px;
}

.BCARD {
  width: 16px;
  height: 16px;
  background-position: -544px -240px;
}

.BCARE {
  width: 16px;
  height: 16px;
  background-position: -544px -256px;
}

.BCAT {
  width: 16px;
  height: 16px;
  background-position: -544px -272px;
}

.BCB {
  width: 16px;
  height: 16px;
  background-position: -544px -288px;
}

.BCC {
  width: 16px;
  height: 16px;
  background-position: -544px -304px;
}

.BCCX {
  width: 16px;
  height: 16px;
  background-position: -544px -320px;
}

.BCD {
  width: 16px;
  height: 16px;
  background-position: -544px -336px;
}

.BCDN {
  width: 16px;
  height: 16px;
  background-position: -544px -352px;
}

.BCDT {
  width: 16px;
  height: 16px;
  background-position: -544px -368px;
}

.BCEO {
  width: 16px;
  height: 16px;
  background-position: -544px -384px;
}

.BCF {
  width: 16px;
  height: 16px;
  background-position: -544px -400px;
}

.BCG {
  width: 16px;
  height: 16px;
  background-position: -544px -416px;
}

.BCH {
  width: 16px;
  height: 16px;
  background-position: -544px -432px;
}

.BCHB {
  width: 16px;
  height: 16px;
  background-position: -544px -448px;
}

.BVOL {
  width: 16px;
  height: 16px;
  background-position: -544px -464px;
}

.BSVBEAR {
  width: 16px;
  height: 16px;
  background-position: -544px -480px;
}

.BCHBEAR {
  width: 16px;
  height: 16px;
  background-position: -544px -496px;
}

.BNBBULL {
  width: 16px;
  height: 16px;
  background-position: -544px -512px;
}

.BNBBEAR {
  width: 16px;
  height: 16px;
  background-position: -544px -528px;
}

.BCHBULL {
  width: 16px;
  height: 16px;
  background-position: -0 -544px;
}

.BSVBULL {
  width: 16px;
  height: 16px;
  background-position: -16px -544px;
}

.BCHC {
  width: 16px;
  height: 16px;
  background-position: -32px -544px;
}

.BCHEC {
  width: 16px;
  height: 16px;
  background-position: -48px -544px;
}

.BCHI {
  width: 16px;
  height: 16px;
  background-position: -64px -544px;
}

.bCHICKS {
  width: 16px;
  height: 16px;
  background-position: -80px -544px;
}

.BCI {
  width: 16px;
  height: 16px;
  background-position: -96px -544px;
}

.BCIO {
  width: 16px;
  height: 16px;
  background-position: -112px -544px;
}

.BCITY {
  width: 16px;
  height: 16px;
  background-position: -128px -544px;
}

.BCK {
  width: 16px;
  height: 16px;
  background-position: -144px -544px;
}

.BCM {
  width: 16px;
  height: 16px;
  background-position: -160px -544px;
}

.BCMC {
  width: 16px;
  height: 16px;
  background-position: -176px -544px;
}

.BCMC1 {
  width: 16px;
  height: 16px;
  background-position: -192px -544px;
}

.BCN {
  width: 16px;
  height: 16px;
  background-position: -208px -544px;
}

.BCNA {
  width: 16px;
  height: 16px;
  background-position: -224px -544px;
}

.BCND {
  width: 16px;
  height: 16px;
  background-position: -240px -544px;
}

.BCNT {
  width: 16px;
  height: 16px;
  background-position: -256px -544px;
}

.BCNX {
  width: 16px;
  height: 16px;
  background-position: -272px -544px;
}

.BCO {
  width: 16px;
  height: 16px;
  background-position: -288px -544px;
}

.BCOIN {
  width: 16px;
  height: 16px;
  background-position: -304px -544px;
}

.BCP {
  width: 16px;
  height: 16px;
  background-position: -320px -544px;
}

.BCPT {
  width: 16px;
  height: 16px;
  background-position: -336px -544px;
}

.BCR {
  width: 16px;
  height: 16px;
  background-position: -352px -544px;
}

.BCS {
  width: 16px;
  height: 16px;
  background-position: -368px -544px;
}

.BCSS {
  width: 16px;
  height: 16px;
  background-position: -384px -544px;
}

.BCT {
  width: 16px;
  height: 16px;
  background-position: -400px -544px;
}

.BCTR {
  width: 16px;
  height: 16px;
  background-position: -416px -544px;
}

.BCTZ {
  width: 16px;
  height: 16px;
  background-position: -432px -544px;
}

.BCU {
  width: 16px;
  height: 16px;
  background-position: -448px -544px;
}

.BCUBE {
  width: 16px;
  height: 16px;
  background-position: -464px -544px;
}

.BCUG {
  width: 16px;
  height: 16px;
  background-position: -480px -544px;
}

.BCURVE {
  width: 16px;
  height: 16px;
  background-position: -496px -544px;
}

.BCV {
  width: 16px;
  height: 16px;
  background-position: -512px -544px;
}

.BCVT {
  width: 16px;
  height: 16px;
  background-position: -528px -544px;
}

.BCW {
  width: 16px;
  height: 16px;
  background-position: -544px -544px;
}

.BCX {
  width: 16px;
  height: 16px;
  background-position: -560px -0;
}

.BCY {
  width: 16px;
  height: 16px;
  background-position: -560px -16px;
}

.BCZ {
  width: 16px;
  height: 16px;
  background-position: -560px -32px;
}

.BCZERO {
  width: 16px;
  height: 16px;
  background-position: -560px -48px;
}

.BDA {
  width: 16px;
  height: 16px;
  background-position: -560px -64px;
}

.BDAO {
  width: 16px;
  height: 16px;
  background-position: -560px -80px;
}

.BDAY {
  width: 16px;
  height: 16px;
  background-position: -560px -96px;
}

.BDC {
  width: 16px;
  height: 16px;
  background-position: -560px -112px;
}

.BDCC {
  width: 16px;
  height: 16px;
  background-position: -560px -128px;
}

.BDE {
  width: 16px;
  height: 16px;
  background-position: -560px -144px;
}

.BDECO {
  width: 16px;
  height: 16px;
  background-position: -560px -160px;
}

.BDG {
  width: 16px;
  height: 16px;
  background-position: -560px -176px;
}

.BDK {
  width: 16px;
  height: 16px;
  background-position: -560px -192px;
}

.BDL {
  width: 16px;
  height: 16px;
  background-position: -560px -208px;
}

.BDO {
  width: 16px;
  height: 16px;
  background-position: -560px -224px;
}

.BDOG {
  width: 16px;
  height: 16px;
  background-position: -560px -240px;
}

.BDOGE {
  width: 16px;
  height: 16px;
  background-position: -560px -256px;
}

.BDOGEX {
  width: 16px;
  height: 16px;
  background-position: -560px -272px;
}

.BDOT {
  width: 16px;
  height: 16px;
  background-position: -560px -288px;
}

.BDP {
  width: 16px;
  height: 16px;
  background-position: -560px -304px;
}

.BDPI {
  width: 16px;
  height: 16px;
  background-position: -560px -320px;
}

.BDRM {
  width: 16px;
  height: 16px;
  background-position: -560px -336px;
}

.BDS {
  width: 16px;
  height: 16px;
  background-position: -560px -352px;
}

.BDT {
  width: 16px;
  height: 16px;
  background-position: -560px -368px;
}

.BDX {
  width: 16px;
  height: 16px;
  background-position: -560px -384px;
}

.BDY {
  width: 16px;
  height: 16px;
  background-position: -560px -400px;
}

.BE {
  width: 16px;
  height: 16px;
  background-position: -560px -416px;
}

.BEACH {
  width: 16px;
  height: 16px;
  background-position: -560px -432px;
}

.BEAGLE {
  width: 16px;
  height: 16px;
  background-position: -560px -448px;
}

.BEAGLECAKE {
  width: 16px;
  height: 16px;
  background-position: -560px -464px;
}

.BEAK {
  width: 16px;
  height: 16px;
  background-position: -560px -480px;
}

.BEAM {
  width: 16px;
  height: 16px;
  background-position: -560px -496px;
}

.BEAN {
  width: 16px;
  height: 16px;
  background-position: -560px -512px;
}

.BEANS {
  width: 16px;
  height: 16px;
  background-position: -560px -528px;
}

.BEAR {
  width: 16px;
  height: 16px;
  background-position: -560px -544px;
}

.BULL {
  width: 16px;
  height: 16px;
  background-position: -0 -560px;
}

.BEAST {
  width: 16px;
  height: 16px;
  background-position: -16px -560px;
}

.BEAT {
  width: 16px;
  height: 16px;
  background-position: -32px -560px;
}

.BEBOP_INU {
  width: 16px;
  height: 16px;
  background-position: -48px -560px;
}

.BEC {
  width: 16px;
  height: 16px;
  background-position: -64px -560px;
}

.BECN {
  width: 16px;
  height: 16px;
  background-position: -80px -560px;
}

.BECO {
  width: 16px;
  height: 16px;
  background-position: -96px -560px;
}

.BECOIN {
  width: 16px;
  height: 16px;
  background-position: -112px -560px;
}

.BED {
  width: 16px;
  height: 16px;
  background-position: -128px -560px;
}

.BEDOGE {
  width: 16px;
  height: 16px;
  background-position: -144px -560px;
}

.BEE {
  width: 16px;
  height: 16px;
  background-position: -160px -560px;
}

.BEEINU {
  width: 16px;
  height: 16px;
  background-position: -176px -560px;
}

.BEER {
  width: 16px;
  height: 16px;
  background-position: -192px -560px;
}

.BEET {
  width: 16px;
  height: 16px;
  background-position: -208px -560px;
}

.BEETS {
  width: 16px;
  height: 16px;
  background-position: -224px -560px;
}

.BEFTM {
  width: 16px;
  height: 16px;
  background-position: -240px -560px;
}

.BEFX {
  width: 16px;
  height: 16px;
  background-position: -256px -560px;
}

.BEL {
  width: 16px;
  height: 16px;
  background-position: -272px -560px;
}

.BELA {
  width: 16px;
  height: 16px;
  background-position: -288px -560px;
}

.BELAXI {
  width: 16px;
  height: 16px;
  background-position: -304px -560px;
}

.BELKA {
  width: 16px;
  height: 16px;
  background-position: -320px -560px;
}

.BELL {
  width: 16px;
  height: 16px;
  background-position: -336px -560px;
}

.BELLY {
  width: 16px;
  height: 16px;
  background-position: -352px -560px;
}

.BELT {
  width: 16px;
  height: 16px;
  background-position: -368px -560px;
}

.BEM {
  width: 16px;
  height: 16px;
  background-position: -384px -560px;
}

.BEMT {
  width: 16px;
  height: 16px;
  background-position: -400px -560px;
}

.BEN {
  width: 16px;
  height: 16px;
  background-position: -416px -560px;
}

.BEND {
  width: 16px;
  height: 16px;
  background-position: -432px -560px;
}

.BENJI {
  width: 16px;
  height: 16px;
  background-position: -448px -560px;
}

.BENT {
  width: 16px;
  height: 16px;
  background-position: -464px -560px;
}

.BENX {
  width: 16px;
  height: 16px;
  background-position: -480px -560px;
}

.BENZ {
  width: 16px;
  height: 16px;
  background-position: -496px -560px;
}

.BEONE {
  width: 16px;
  height: 16px;
  background-position: -512px -560px;
}

.BEPIS {
  width: 16px;
  height: 16px;
  background-position: -528px -560px;
}

.BEPR {
  width: 16px;
  height: 16px;
  background-position: -544px -560px;
}

.BEPRO {
  width: 16px;
  height: 16px;
  background-position: -560px -560px;
}

.BERAGE {
  width: 16px;
  height: 16px;
  background-position: -576px -0;
}

.BERN {
  width: 16px;
  height: 16px;
  background-position: -576px -16px;
}

.BERRY {
  width: 16px;
  height: 16px;
  background-position: -576px -32px;
}

.BERSERK {
  width: 16px;
  height: 16px;
  background-position: -576px -48px;
}

.BES {
  width: 16px;
  height: 16px;
  background-position: -576px -64px;
}

.BEST {
  width: 16px;
  height: 16px;
  background-position: -576px -80px;
}

.BET {
  width: 16px;
  height: 16px;
  background-position: -576px -96px;
}

.BETA {
  width: 16px;
  height: 16px;
  background-position: -576px -112px;
}

.BETC {
  width: 16px;
  height: 16px;
  background-position: -576px -128px;
}

.BETH {
  width: 16px;
  height: 16px;
  background-position: -576px -144px;
}

.BETHER {
  width: 16px;
  height: 16px;
  background-position: -576px -160px;
}

.BETIFY {
  width: 16px;
  height: 16px;
  background-position: -576px -176px;
}

.BETS {
  width: 16px;
  height: 16px;
  background-position: -576px -192px;
}

.BETU {
  width: 16px;
  height: 16px;
  background-position: -576px -208px;
}

.BEX {
  width: 16px;
  height: 16px;
  background-position: -576px -224px;
}

.BEXT {
  width: 16px;
  height: 16px;
  background-position: -576px -240px;
}

.BEZ {
  width: 16px;
  height: 16px;
  background-position: -576px -256px;
}

.BEZOGE {
  width: 16px;
  height: 16px;
  background-position: -576px -272px;
}

.BF {
  width: 16px;
  height: 16px;
  background-position: -576px -288px;
}

.BFC {
  width: 16px;
  height: 16px;
  background-position: -576px -304px;
}

.BFF {
  width: 16px;
  height: 16px;
  background-position: -576px -320px;
}

.BFG {
  width: 16px;
  height: 16px;
  background-position: -576px -336px;
}

.BFHT {
  width: 16px;
  height: 16px;
  background-position: -576px -352px;
}

.BFI {
  width: 16px;
  height: 16px;
  background-position: -576px -368px;
}

.BFIC {
  width: 16px;
  height: 16px;
  background-position: -576px -384px;
}

.BFIRE {
  width: 16px;
  height: 16px;
  background-position: -576px -400px;
}

.BFK {
  width: 16px;
  height: 16px;
  background-position: -576px -416px;
}

.BFL {
  width: 16px;
  height: 16px;
  background-position: -576px -432px;
}

.BFLOKI {
  width: 16px;
  height: 16px;
  background-position: -576px -448px;
}

.BFLY {
  width: 16px;
  height: 16px;
  background-position: -576px -464px;
}

.BFORCE {
  width: 16px;
  height: 16px;
  background-position: -576px -480px;
}

.BFORM {
  width: 16px;
  height: 16px;
  background-position: -576px -496px;
}

.BFR {
  width: 16px;
  height: 16px;
  background-position: -576px -512px;
}

.BFS {
  width: 16px;
  height: 16px;
  background-position: -576px -528px;
}

.BFT {
  width: 16px;
  height: 16px;
  background-position: -576px -544px;
}

.BFTM {
  width: 16px;
  height: 16px;
  background-position: -576px -560px;
}

.BFU {
  width: 16px;
  height: 16px;
  background-position: -0 -576px;
}

.BFX {
  width: 16px;
  height: 16px;
  background-position: -16px -576px;
}

.BFYC {
  width: 16px;
  height: 16px;
  background-position: -32px -576px;
}

.BG {
  width: 16px;
  height: 16px;
  background-position: -48px -576px;
}

.BGA {
  width: 16px;
  height: 16px;
  background-position: -64px -576px;
}

.BGAME {
  width: 16px;
  height: 16px;
  background-position: -80px -576px;
}

.BGB {
  width: 16px;
  height: 16px;
  background-position: -96px -576px;
}

.BGBP {
  width: 16px;
  height: 16px;
  background-position: -112px -576px;
}

.BGC {
  width: 16px;
  height: 16px;
  background-position: -128px -576px;
}

.BGG {
  width: 16px;
  height: 16px;
  background-position: -144px -576px;
}

.BGH {
  width: 16px;
  height: 16px;
  background-position: -160px -576px;
}

.BGL {
  width: 16px;
  height: 16px;
  background-position: -176px -576px;
}

.BGLD {
  width: 16px;
  height: 16px;
  background-position: -192px -576px;
}

.BGLG {
  width: 16px;
  height: 16px;
  background-position: -208px -576px;
}

.BGO {
  width: 16px;
  height: 16px;
  background-position: -224px -576px;
}

.BGOF {
  width: 16px;
  height: 16px;
  background-position: -240px -576px;
}

.BGOV {
  width: 16px;
  height: 16px;
  background-position: -256px -576px;
}

.BGS {
  width: 16px;
  height: 16px;
  background-position: -272px -576px;
}

.BGTT {
  width: 16px;
  height: 16px;
  background-position: -288px -576px;
}

.BGX {
  width: 16px;
  height: 16px;
  background-position: -304px -576px;
}

.BHAO {
  width: 16px;
  height: 16px;
  background-position: -320px -576px;
}

.BHAT {
  width: 16px;
  height: 16px;
  background-position: -336px -576px;
}

.BHAX {
  width: 16px;
  height: 16px;
  background-position: -352px -576px;
}

.BHC {
  width: 16px;
  height: 16px;
  background-position: -368px -576px;
}

.BHD {
  width: 16px;
  height: 16px;
  background-position: -384px -576px;
}

.BHIBA {
  width: 16px;
  height: 16px;
  background-position: -400px -576px;
}

.BHIG {
  width: 16px;
  height: 16px;
  background-position: -416px -576px;
}

.BHO {
  width: 16px;
  height: 16px;
  background-position: -432px -576px;
}

.BHP {
  width: 16px;
  height: 16px;
  background-position: -448px -576px;
}

.BHSC {
  width: 16px;
  height: 16px;
  background-position: -464px -576px;
}

.BHT {
  width: 16px;
  height: 16px;
  background-position: -480px -576px;
}

.BHUNT {
  width: 16px;
  height: 16px;
  background-position: -496px -576px;
}

.BI {
  width: 16px;
  height: 16px;
  background-position: -512px -576px;
}

.BIA {
  width: 16px;
  height: 16px;
  background-position: -528px -576px;
}

.BIBA {
  width: 16px;
  height: 16px;
  background-position: -544px -576px;
}

.BIC {
  width: 16px;
  height: 16px;
  background-position: -560px -576px;
}

.BICAS {
  width: 16px;
  height: 16px;
  background-position: -576px -576px;
}

.BICO {
  width: 16px;
  height: 16px;
  background-position: -592px -0;
}

.BID {
  width: 16px;
  height: 16px;
  background-position: -592px -16px;
}

.BIDCOM {
  width: 16px;
  height: 16px;
  background-position: -592px -32px;
}

.BIDEN {
  width: 16px;
  height: 16px;
  background-position: -592px -48px;
}

.Bidog {
  width: 16px;
  height: 16px;
  background-position: -592px -64px;
}

.BIDOS {
  width: 16px;
  height: 16px;
  background-position: -592px -80px;
}

.BIDR {
  width: 16px;
  height: 16px;
  background-position: -592px -96px;
}

.BIFI {
  width: 16px;
  height: 16px;
  background-position: -592px -112px;
}

.BIG {
  width: 16px;
  height: 16px;
  background-position: -592px -128px;
}

.BIGB {
  width: 16px;
  height: 16px;
  background-position: -592px -144px;
}

.BIGETH {
  width: 16px;
  height: 16px;
  background-position: -592px -160px;
}

.BIGGLES {
  width: 16px;
  height: 16px;
  background-position: -592px -176px;
}

.BIGO {
  width: 16px;
  height: 16px;
  background-position: -592px -192px;
}

.BIGSB {
  width: 16px;
  height: 16px;
  background-position: -592px -208px;
}

.BIGUP {
  width: 16px;
  height: 16px;
  background-position: -592px -224px;
}

.BiHODL {
  width: 16px;
  height: 16px;
  background-position: -592px -240px;
}

.BIKE {
  width: 16px;
  height: 16px;
  background-position: -592px -256px;
}

.BIKI {
  width: 16px;
  height: 16px;
  background-position: -592px -272px;
}

.BIKINI {
  width: 16px;
  height: 16px;
  background-position: -592px -288px;
}

.BILL {
  width: 16px;
  height: 16px;
  background-position: -592px -304px;
}

.BIMP {
  width: 16px;
  height: 16px;
  background-position: -592px -320px;
}

.BIN {
  width: 16px;
  height: 16px;
  background-position: -592px -336px;
}

.BIND {
  width: 16px;
  height: 16px;
  background-position: -592px -352px;
}

.BingDwenDwen {
  width: 16px;
  height: 16px;
  background-position: -592px -368px;
}

.BINGO {
  width: 16px;
  height: 16px;
  background-position: -592px -384px;
}

.BINGUS {
  width: 16px;
  height: 16px;
  background-position: -592px -400px;
}

.BINO {
  width: 16px;
  height: 16px;
  background-position: -592px -416px;
}

.BINOM {
  width: 16px;
  height: 16px;
  background-position: -592px -432px;
}

.BINOSAURS {
  width: 16px;
  height: 16px;
  background-position: -592px -448px;
}

.BINS {
  width: 16px;
  height: 16px;
  background-position: -592px -464px;
}

.BINU {
  width: 16px;
  height: 16px;
  background-position: -592px -480px;
}

.BIO {
  width: 16px;
  height: 16px;
  background-position: -592px -496px;
}

.BIOFI {
  width: 16px;
  height: 16px;
  background-position: -592px -512px;
}

.BIOS {
  width: 16px;
  height: 16px;
  background-position: -592px -528px;
}

.BIOT {
  width: 16px;
  height: 16px;
  background-position: -592px -544px;
}

.BIOTECH {
  width: 16px;
  height: 16px;
  background-position: -592px -560px;
}

.BIP {
  width: 16px;
  height: 16px;
  background-position: -592px -576px;
}

.BIR {
  width: 16px;
  height: 16px;
  background-position: -0 -592px;
}

.BIRB {
  width: 16px;
  height: 16px;
  background-position: -16px -592px;
}

.BIRD {
  width: 16px;
  height: 16px;
  background-position: -32px -592px;
}

.BIRDBRO {
  width: 16px;
  height: 16px;
  background-position: -48px -592px;
}

.BIRDS {
  width: 16px;
  height: 16px;
  background-position: -64px -592px;
}

.BIS {
  width: 16px;
  height: 16px;
  background-position: -80px -592px;
}

.BISHUFI {
  width: 16px;
  height: 16px;
  background-position: -96px -592px;
}

.BISON {
  width: 16px;
  height: 16px;
  background-position: -112px -592px;
}

.BIST {
  width: 16px;
  height: 16px;
  background-position: -128px -592px;
}

.BIT {
  width: 16px;
  height: 16px;
  background-position: -144px -592px;
}

.BITANT {
  width: 16px;
  height: 16px;
  background-position: -160px -592px;
}

.BITB {
  width: 16px;
  height: 16px;
  background-position: -176px -592px;
}

.BITBITBITBIT {
  width: 16px;
  height: 16px;
  background-position: -192px -592px;
}

.BITUSD {
  width: 16px;
  height: 16px;
  background-position: -208px -592px;
}

.BITBTC {
  width: 16px;
  height: 16px;
  background-position: -224px -592px;
}

.BITSILVER {
  width: 16px;
  height: 16px;
  background-position: -240px -592px;
}

.BITGOLD {
  width: 16px;
  height: 16px;
  background-position: -256px -592px;
}

.BITC {
  width: 16px;
  height: 16px;
  background-position: -272px -592px;
}

.BITCAR {
  width: 16px;
  height: 16px;
  background-position: -288px -592px;
}

.BITCCA {
  width: 16px;
  height: 16px;
  background-position: -304px -592px;
}

.BITCI {
  width: 16px;
  height: 16px;
  background-position: -320px -592px;
}

.BITCNY {
  width: 16px;
  height: 16px;
  background-position: -336px -592px;
}

.BITCOIN {
  width: 16px;
  height: 16px;
  background-position: -352px -592px;
}

.BITD {
  width: 16px;
  height: 16px;
  background-position: -368px -592px;
}

.BITE {
  width: 16px;
  height: 16px;
  background-position: -384px -592px;
}

.BITEUR {
  width: 16px;
  height: 16px;
  background-position: -400px -592px;
}

.BITG {
  width: 16px;
  height: 16px;
  background-position: -416px -592px;
}

.BITGATTI {
  width: 16px;
  height: 16px;
  background-position: -432px -592px;
}

.BITH {
  width: 16px;
  height: 16px;
  background-position: -448px -592px;
}

.BNBALLBI {
  width: 16px;
  height: 16px;
  background-position: -464px -592px;
}

.BITMETA {
  width: 16px;
  height: 16px;
  background-position: -480px -592px;
}

.BITN {
  width: 16px;
  height: 16px;
  background-position: -496px -592px;
}

.BITO {
  width: 16px;
  height: 16px;
  background-position: -512px -592px;
}

.BITORB {
  width: 16px;
  height: 16px;
  background-position: -528px -592px;
}

.BITRA {
  width: 16px;
  height: 16px;
  background-position: -544px -592px;
}

.BITS {
  width: 16px;
  height: 16px;
  background-position: -560px -592px;
}

.BITSU {
  width: 16px;
  height: 16px;
  background-position: -576px -592px;
}

.BITSZ {
  width: 16px;
  height: 16px;
  background-position: -592px -592px;
}

.BITT {
  width: 16px;
  height: 16px;
  background-position: -608px -0;
}

.BITTO {
  width: 16px;
  height: 16px;
  background-position: -608px -16px;
}

.BitUp {
  width: 16px;
  height: 16px;
  background-position: -608px -32px;
}

.BITV {
  width: 16px;
  height: 16px;
  background-position: -608px -48px;
}

.BITX {
  width: 16px;
  height: 16px;
  background-position: -608px -64px;
}

.BIUT {
  width: 16px;
  height: 16px;
  background-position: -608px -80px;
}

.BIVE {
  width: 16px;
  height: 16px;
  background-position: -608px -96px;
}

.BIX {
  width: 16px;
  height: 16px;
  background-position: -608px -112px;
}

.BIXB {
  width: 16px;
  height: 16px;
  background-position: -608px -128px;
}

.BIZZ {
  width: 16px;
  height: 16px;
  background-position: -608px -144px;
}

.BIZZNERD {
  width: 16px;
  height: 16px;
  background-position: -608px -160px;
}

.BK {
  width: 16px;
  height: 16px;
  background-position: -608px -176px;
}

.BKBT {
  width: 16px;
  height: 16px;
  background-position: -608px -192px;
}

.BKF {
  width: 16px;
  height: 16px;
  background-position: -608px -208px;
}

.BKING {
  width: 16px;
  height: 16px;
  background-position: -608px -224px;
}

.BKISHU {
  width: 16px;
  height: 16px;
  background-position: -608px -240px;
}

.BKK {
  width: 16px;
  height: 16px;
  background-position: -608px -256px;
}

.BKKG {
  width: 16px;
  height: 16px;
  background-position: -608px -272px;
}

.BKN {
  width: 16px;
  height: 16px;
  background-position: -608px -288px;
}

.BKR {
  width: 16px;
  height: 16px;
  background-position: -608px -304px;
}

.BKRW {
  width: 16px;
  height: 16px;
  background-position: -608px -320px;
}

.BKS {
  width: 16px;
  height: 16px;
  background-position: -608px -336px;
}

.BKT {
  width: 16px;
  height: 16px;
  background-position: -608px -352px;
}

.BKX {
  width: 16px;
  height: 16px;
  background-position: -608px -368px;
}

.BKY {
  width: 16px;
  height: 16px;
  background-position: -608px -384px;
}

.BLAC {
  width: 16px;
  height: 16px;
  background-position: -608px -400px;
}

.BLACK {
  width: 16px;
  height: 16px;
  background-position: -608px -416px;
}

.BLACKFRIDAY {
  width: 16px;
  height: 16px;
  background-position: -608px -432px;
}

.BLADE {
  width: 16px;
  height: 16px;
  background-position: -608px -448px;
}

.BLANC {
  width: 16px;
  height: 16px;
  background-position: -608px -464px;
}

.BLANK {
  width: 16px;
  height: 16px;
  background-position: -608px -480px;
}

.BLAST {
  width: 16px;
  height: 16px;
  background-position: -608px -496px;
}

.BLAZAR {
  width: 16px;
  height: 16px;
  background-position: -608px -512px;
}

.BLAZE {
  width: 16px;
  height: 16px;
  background-position: -608px -528px;
}

.BLAZR {
  width: 16px;
  height: 16px;
  background-position: -608px -544px;
}

.BLC {
  width: 16px;
  height: 16px;
  background-position: -608px -560px;
}

.BLCC {
  width: 16px;
  height: 16px;
  background-position: -608px -576px;
}

.BLCR {
  width: 16px;
  height: 16px;
  background-position: -608px -592px;
}

.BLCT {
  width: 16px;
  height: 16px;
  background-position: -0 -608px;
}

.BLD {
  width: 16px;
  height: 16px;
  background-position: -16px -608px;
}

.BLE {
  width: 16px;
  height: 16px;
  background-position: -32px -608px;
}

.BLES {
  width: 16px;
  height: 16px;
  background-position: -48px -608px;
}

.BLET {
  width: 16px;
  height: 16px;
  background-position: -64px -608px;
}

.BLEU {
  width: 16px;
  height: 16px;
  background-position: -80px -608px;
}

.BLF {
  width: 16px;
  height: 16px;
  background-position: -96px -608px;
}

.BLFI {
  width: 16px;
  height: 16px;
  background-position: -112px -608px;
}

.BLG {
  width: 16px;
  height: 16px;
  background-position: -128px -608px;
}

.BLGC {
  width: 16px;
  height: 16px;
  background-position: -144px -608px;
}

.BLH {
  width: 16px;
  height: 16px;
  background-position: -160px -608px;
}

.BLI {
  width: 16px;
  height: 16px;
  background-position: -176px -608px;
}

.BLIGHT {
  width: 16px;
  height: 16px;
  background-position: -192px -608px;
}

.BLIN {
  width: 16px;
  height: 16px;
  background-position: -208px -608px;
}

.BLINK {
  width: 16px;
  height: 16px;
  background-position: -224px -608px;
}

.BLINU {
  width: 16px;
  height: 16px;
  background-position: -240px -608px;
}

.BLITZ {
  width: 16px;
  height: 16px;
  background-position: -256px -608px;
}

.BLIZZ {
  width: 16px;
  height: 16px;
  background-position: -272px -608px;
}

.BLK {
  width: 16px;
  height: 16px;
  background-position: -288px -608px;
}

.BLKC {
  width: 16px;
  height: 16px;
  background-position: -304px -608px;
}

.BLKS {
  width: 16px;
  height: 16px;
  background-position: -320px -608px;
}

.BLL {
  width: 16px;
  height: 16px;
  background-position: -336px -608px;
}

.BLMV2 {
  width: 16px;
  height: 16px;
  background-position: -352px -608px;
}

.BLN {
  width: 16px;
  height: 16px;
  background-position: -368px -608px;
}

.BLOC {
  width: 16px;
  height: 16px;
  background-position: -384px -608px;
}

.BLOCK {
  width: 16px;
  height: 16px;
  background-position: -400px -608px;
}

.BLOCKIFY {
  width: 16px;
  height: 16px;
  background-position: -416px -608px;
}

.BLOCKS {
  width: 16px;
  height: 16px;
  background-position: -432px -608px;
}

.Blockunison {
  width: 16px;
  height: 16px;
  background-position: -448px -608px;
}

.BLOCS {
  width: 16px;
  height: 16px;
  background-position: -464px -608px;
}

.BLOGGER {
  width: 16px;
  height: 16px;
  background-position: -480px -608px;
}

.BLOK {
  width: 16px;
  height: 16px;
  background-position: -496px -608px;
}

.BLOOD {
  width: 16px;
  height: 16px;
  background-position: -512px -608px;
}

.BLOODY {
  width: 16px;
  height: 16px;
  background-position: -528px -608px;
}

.BLOODYSHIBA {
  width: 16px;
  height: 16px;
  background-position: -544px -608px;
}

.BLOOM {
  width: 16px;
  height: 16px;
  background-position: -560px -608px;
}

.BLOS {
  width: 16px;
  height: 16px;
  background-position: -576px -608px;
}

.BLOSM {
  width: 16px;
  height: 16px;
  background-position: -592px -608px;
}

.BLOVELY {
  width: 16px;
  height: 16px;
  background-position: -608px -608px;
}

.BLOW {
  width: 16px;
  height: 16px;
  background-position: -624px -0;
}

.BLOWF {
  width: 16px;
  height: 16px;
  background-position: -624px -16px;
}

.BLOX {
  width: 16px;
  height: 16px;
  background-position: -624px -32px;
}

.BLP {
  width: 16px;
  height: 16px;
  background-position: -624px -48px;
}

.BLS {
  width: 16px;
  height: 16px;
  background-position: -624px -64px;
}

.BLST {
  width: 16px;
  height: 16px;
  background-position: -624px -80px;
}

.BLT {
  width: 16px;
  height: 16px;
  background-position: -624px -96px;
}

.BLTG {
  width: 16px;
  height: 16px;
  background-position: -624px -112px;
}

.BLTV {
  width: 16px;
  height: 16px;
  background-position: -624px -128px;
}

.BLU {
  width: 16px;
  height: 16px;
  background-position: -624px -144px;
}

.BLUC {
  width: 16px;
  height: 16px;
  background-position: -624px -160px;
}

.BLUE {
  width: 16px;
  height: 16px;
  background-position: -624px -176px;
}

.BLUEFLOKI {
  width: 16px;
  height: 16px;
  background-position: -624px -192px;
}

.BLUEMEL {
  width: 16px;
  height: 16px;
  background-position: -624px -208px;
}

.BLUES {
  width: 16px;
  height: 16px;
  background-position: -624px -224px;
}

.BlueSparrow {
  width: 16px;
  height: 16px;
  background-position: -624px -240px;
}

.BLUESPARROW {
  width: 16px;
  height: 16px;
  background-position: -624px -256px;
}

.bLUNA {
  width: 16px;
  height: 16px;
  background-position: -624px -272px;
}

.BLUR {
  width: 16px;
  height: 16px;
  background-position: -624px -288px;
}

.BLURT {
  width: 16px;
  height: 16px;
  background-position: -624px -304px;
}

.BLUX {
  width: 16px;
  height: 16px;
  background-position: -624px -320px;
}

.BLV {
  width: 16px;
  height: 16px;
  background-position: -624px -336px;
}

.BLVR {
  width: 16px;
  height: 16px;
  background-position: -624px -352px;
}

.BLWA {
  width: 16px;
  height: 16px;
  background-position: -624px -368px;
}

.BLX {
  width: 16px;
  height: 16px;
  background-position: -624px -384px;
}

.BLXM {
  width: 16px;
  height: 16px;
  background-position: -624px -400px;
}

.BLY {
  width: 16px;
  height: 16px;
  background-position: -624px -416px;
}

.BLZ {
  width: 16px;
  height: 16px;
  background-position: -624px -432px;
}

.BLZN {
  width: 16px;
  height: 16px;
  background-position: -624px -448px;
}

.BLZZ {
  width: 16px;
  height: 16px;
  background-position: -624px -464px;
}

.BM {
  width: 16px;
  height: 16px;
  background-position: -624px -480px;
}

.BMARS {
  width: 16px;
  height: 16px;
  background-position: -624px -496px;
}

.BMB {
  width: 16px;
  height: 16px;
  background-position: -624px -512px;
}

.BMBO {
  width: 16px;
  height: 16px;
  background-position: -624px -528px;
}

.BMC {
  width: 16px;
  height: 16px;
  background-position: -624px -544px;
}

.BMCC {
  width: 16px;
  height: 16px;
  background-position: -624px -560px;
}

.BMCH {
  width: 16px;
  height: 16px;
  background-position: -624px -576px;
}

.BME {
  width: 16px;
  height: 16px;
  background-position: -624px -592px;
}

.BMEX {
  width: 16px;
  height: 16px;
  background-position: -624px -608px;
}

.BMF {
  width: 16px;
  height: 16px;
  background-position: -0 -624px;
}

.BMG {
  width: 16px;
  height: 16px;
  background-position: -16px -624px;
}

.BMH {
  width: 16px;
  height: 16px;
  background-position: -32px -624px;
}

.BMI {
  width: 16px;
  height: 16px;
  background-position: -48px -624px;
}

.BMIC {
  width: 16px;
  height: 16px;
  background-position: -64px -624px;
}

.BMJ {
  width: 16px;
  height: 16px;
  background-position: -80px -624px;
}

.BML {
  width: 16px;
  height: 16px;
  background-position: -96px -624px;
}

.BMM {
  width: 16px;
  height: 16px;
  background-position: -112px -624px;
}

.BMN {
  width: 16px;
  height: 16px;
  background-position: -128px -624px;
}

.BMND {
  width: 16px;
  height: 16px;
  background-position: -144px -624px;
}

.BMON {
  width: 16px;
  height: 16px;
  background-position: -160px -624px;
}

.BMP {
  width: 16px;
  height: 16px;
  background-position: -176px -624px;
}

.BMT {
  width: 16px;
  height: 16px;
  background-position: -192px -624px;
}

.BMW {
  width: 16px;
  height: 16px;
  background-position: -208px -624px;
}

.BMX {
  width: 16px;
  height: 16px;
  background-position: -224px -624px;
}

.BMXX {
  width: 16px;
  height: 16px;
  background-position: -240px -624px;
}

.BNA {
  width: 16px;
  height: 16px;
  background-position: -256px -624px;
}

.BNANA {
  width: 16px;
  height: 16px;
  background-position: -272px -624px;
}

.BNB {
  width: 16px;
  height: 16px;
  background-position: -288px -624px;
}

.BNBBACK {
  width: 16px;
  height: 16px;
  background-position: -304px -624px;
}

.BNBCH {
  width: 16px;
  height: 16px;
  background-position: -320px -624px;
}

.BNBD {
  width: 16px;
  height: 16px;
  background-position: -336px -624px;
}

.BNBDRAGON {
  width: 16px;
  height: 16px;
  background-position: -352px -624px;
}

.BNBEER {
  width: 16px;
  height: 16px;
  background-position: -368px -624px;
}

.BNBEN {
  width: 16px;
  height: 16px;
  background-position: -384px -624px;
}

.BNBFAN {
  width: 16px;
  height: 16px;
  background-position: -400px -624px;
}

.BNBG {
  width: 16px;
  height: 16px;
  background-position: -416px -624px;
}

.BNBH {
  width: 16px;
  height: 16px;
  background-position: -432px -624px;
}

.BNBTC {
  width: 16px;
  height: 16px;
  background-position: -448px -624px;
}

.BNBTIGER {
  width: 16px;
  height: 16px;
  background-position: -464px -624px;
}

.BNBX {
  width: 16px;
  height: 16px;
  background-position: -480px -624px;
}

.BNC {
  width: 16px;
  height: 16px;
  background-position: -496px -624px;
}

.BND {
  width: 16px;
  height: 16px;
  background-position: -512px -624px;
}

.BNF {
  width: 16px;
  height: 16px;
  background-position: -528px -624px;
}

.BNFI {
  width: 16px;
  height: 16px;
  background-position: -544px -624px;
}

.BNFT {
  width: 16px;
  height: 16px;
  background-position: -560px -624px;
}

.BNFTT {
  width: 16px;
  height: 16px;
  background-position: -576px -624px;
}

.BNFY {
  width: 16px;
  height: 16px;
  background-position: -592px -624px;
}

.BNGT {
  width: 16px;
  height: 16px;
  background-position: -608px -624px;
}

.BNK {
  width: 16px;
  height: 16px;
  background-position: -624px -624px;
}

.BNKR {
  width: 16px;
  height: 16px;
  background-position: -640px -0;
}

.BNKY {
  width: 16px;
  height: 16px;
  background-position: -640px -16px;
}

.BNM {
  width: 16px;
  height: 16px;
  background-position: -640px -32px;
}

.BNN {
  width: 16px;
  height: 16px;
  background-position: -640px -48px;
}

.BNODE {
  width: 16px;
  height: 16px;
  background-position: -640px -64px;
}

.BNOX {
  width: 16px;
  height: 16px;
  background-position: -640px -80px;
}

.BNP {
  width: 16px;
  height: 16px;
  background-position: -640px -96px;
}

.BNPL {
  width: 16px;
  height: 16px;
  background-position: -640px -112px;
}

.BNS {
  width: 16px;
  height: 16px;
  background-position: -640px -128px;
}

.BNSD {
  width: 16px;
  height: 16px;
  background-position: -640px -144px;
}

.BNT {
  width: 16px;
  height: 16px;
  background-position: -640px -160px;
}

.BNTX {
  width: 16px;
  height: 16px;
  background-position: -640px -176px;
}

.BNTY {
  width: 16px;
  height: 16px;
  background-position: -640px -192px;
}

.BNU {
  width: 16px;
  height: 16px;
  background-position: -640px -208px;
}

.bnUSD {
  width: 16px;
  height: 16px;
  background-position: -640px -224px;
}

.BNV {
  width: 16px;
  height: 16px;
  background-position: -640px -240px;
}

.BNX {
  width: 16px;
  height: 16px;
  background-position: -640px -256px;
}

.BNY {
  width: 16px;
  height: 16px;
  background-position: -640px -272px;
}

.BNZ {
  width: 16px;
  height: 16px;
  background-position: -640px -288px;
}

.BOA {
  width: 16px;
  height: 16px;
  background-position: -640px -304px;
}

.BOAT {
  width: 16px;
  height: 16px;
  background-position: -640px -320px;
}

.BOB {
  width: 16px;
  height: 16px;
  background-position: -640px -336px;
}

.BOBA {
  width: 16px;
  height: 16px;
  background-position: -640px -352px;
}

.BOBC {
  width: 16px;
  height: 16px;
  background-position: -640px -368px;
}

.BOBO {
  width: 16px;
  height: 16px;
  background-position: -640px -384px;
}

.BOBT {
  width: 16px;
  height: 16px;
  background-position: -640px -400px;
}

.BOC {
  width: 16px;
  height: 16px;
  background-position: -640px -416px;
}

.BODAV2 {
  width: 16px;
  height: 16px;
  background-position: -640px -432px;
}

.BODO {
  width: 16px;
  height: 16px;
  background-position: -640px -448px;
}

.BOE {
  width: 16px;
  height: 16px;
  background-position: -640px -464px;
}

.BOFB {
  width: 16px;
  height: 16px;
  background-position: -640px -480px;
}

.BOG {
  width: 16px;
  height: 16px;
  background-position: -640px -496px;
}

.BOGDANOFF {
  width: 16px;
  height: 16px;
  background-position: -640px -512px;
}

.BOGE {
  width: 16px;
  height: 16px;
  background-position: -640px -528px;
}

.BOJI {
  width: 16px;
  height: 16px;
  background-position: -640px -544px;
}

.BOK {
  width: 16px;
  height: 16px;
  background-position: -640px -560px;
}

.BOKU {
  width: 16px;
  height: 16px;
  background-position: -640px -576px;
}

.BOLD {
  width: 16px;
  height: 16px;
  background-position: -640px -592px;
}

.BOLE {
  width: 16px;
  height: 16px;
  background-position: -640px -608px;
}

.BOLI {
  width: 16px;
  height: 16px;
  background-position: -640px -624px;
}

.BOLLY {
  width: 16px;
  height: 16px;
  background-position: -0 -640px;
}

.BOLT {
  width: 16px;
  height: 16px;
  background-position: -16px -640px;
}

.BOLTT {
  width: 16px;
  height: 16px;
  background-position: -32px -640px;
}

.BOM {
  width: 16px;
  height: 16px;
  background-position: -48px -640px;
}

.BOMB {
  width: 16px;
  height: 16px;
  background-position: -64px -640px;
}

.BON {
  width: 16px;
  height: 16px;
  background-position: -80px -640px;
}

.BOND {
  width: 16px;
  height: 16px;
  background-position: -96px -640px;
}

.BONDLY {
  width: 16px;
  height: 16px;
  background-position: -112px -640px;
}

.BONE {
  width: 16px;
  height: 16px;
  background-position: -128px -640px;
}

.BONES {
  width: 16px;
  height: 16px;
  background-position: -144px -640px;
}

.BONFIRE {
  width: 16px;
  height: 16px;
  background-position: -160px -640px;
}

.BONO {
  width: 16px;
  height: 16px;
  background-position: -176px -640px;
}

.BonusCake {
  width: 16px;
  height: 16px;
  background-position: -192px -640px;
}

.BonusFloki {
  width: 16px;
  height: 16px;
  background-position: -208px -640px;
}

.BonusSquid {
  width: 16px;
  height: 16px;
  background-position: -224px -640px;
}

.BONUZ {
  width: 16px;
  height: 16px;
  background-position: -240px -640px;
}

.BOO {
  width: 16px;
  height: 16px;
  background-position: -256px -640px;
}

.BOOB {
  width: 16px;
  height: 16px;
  background-position: -272px -640px;
}

.BOOBIES {
  width: 16px;
  height: 16px;
  background-position: -288px -640px;
}

.BOOBS {
  width: 16px;
  height: 16px;
  background-position: -304px -640px;
}

.BOOCAKE {
  width: 16px;
  height: 16px;
  background-position: -320px -640px;
}

.BOOFI {
  width: 16px;
  height: 16px;
  background-position: -336px -640px;
}

.BOOKSHIB {
  width: 16px;
  height: 16px;
  background-position: -352px -640px;
}

.BOOLISH {
  width: 16px;
  height: 16px;
  background-position: -368px -640px;
}

.BOOM {
  width: 16px;
  height: 16px;
  background-position: -384px -640px;
}

.BOOMB {
  width: 16px;
  height: 16px;
  background-position: -400px -640px;
}

.BOOMC {
  width: 16px;
  height: 16px;
  background-position: -416px -640px;
}

.BOOMSHIBA {
  width: 16px;
  height: 16px;
  background-position: -432px -640px;
}

.BOOST {
  width: 16px;
  height: 16px;
  background-position: -448px -640px;
}

.BOOST2 {
  width: 16px;
  height: 16px;
  background-position: -464px -640px;
}

.BOOT {
  width: 16px;
  height: 16px;
  background-position: -480px -640px;
}

.BOOZE {
  width: 16px;
  height: 16px;
  background-position: -496px -640px;
}

.BOP {
  width: 16px;
  height: 16px;
  background-position: -512px -640px;
}

.BOR {
  width: 16px;
  height: 16px;
  background-position: -528px -640px;
}

.BORA {
  width: 16px;
  height: 16px;
  background-position: -544px -640px;
}

.BORED {
  width: 16px;
  height: 16px;
  background-position: -560px -640px;
}

.BORING {
  width: 16px;
  height: 16px;
  background-position: -576px -640px;
}

.BORK {
  width: 16px;
  height: 16px;
  background-position: -592px -640px;
}

.BORX {
  width: 16px;
  height: 16px;
  background-position: -608px -640px;
}

.BOS {
  width: 16px;
  height: 16px;
  background-position: -624px -640px;
}

.BOSO {
  width: 16px;
  height: 16px;
  background-position: -640px -640px;
}

.BOSON {
  width: 16px;
  height: 16px;
  background-position: -656px -0;
}

.BOSS {
  width: 16px;
  height: 16px;
  background-position: -656px -16px;
}

.BOST {
  width: 16px;
  height: 16px;
  background-position: -656px -32px;
}

.BOT {
  width: 16px;
  height: 16px;
  background-position: -656px -48px;
}

.BOTTO {
  width: 16px;
  height: 16px;
  background-position: -656px -64px;
}

.BOTX {
  width: 16px;
  height: 16px;
  background-position: -656px -80px;
}

.BOUJE {
  width: 16px;
  height: 16px;
  background-position: -656px -96px;
}

.BOUNTIE {
  width: 16px;
  height: 16px;
  background-position: -656px -112px;
}

.BOUTS {
  width: 16px;
  height: 16px;
  background-position: -656px -128px;
}

.BOX {
  width: 16px;
  height: 16px;
  background-position: -656px -144px;
}

.BOXCH {
  width: 16px;
  height: 16px;
  background-position: -656px -160px;
}

.BOXER {
  width: 16px;
  height: 16px;
  background-position: -656px -176px;
}

.BOXERDOGE {
  width: 16px;
  height: 16px;
  background-position: -656px -192px;
}

.BOXX {
  width: 16px;
  height: 16px;
  background-position: -656px -208px;
}

.BOYZ {
  width: 16px;
  height: 16px;
  background-position: -656px -224px;
}

.BP {
  width: 16px;
  height: 16px;
  background-position: -656px -240px;
}

.BPAD {
  width: 16px;
  height: 16px;
  background-position: -656px -256px;
}

.BPAKC {
  width: 16px;
  height: 16px;
  background-position: -656px -272px;
}

.BPANDA {
  width: 16px;
  height: 16px;
  background-position: -656px -288px;
}

.BPAY {
  width: 16px;
  height: 16px;
  background-position: -656px -304px;
}

.BPC {
  width: 16px;
  height: 16px;
  background-position: -656px -320px;
}

.BPCN {
  width: 16px;
  height: 16px;
  background-position: -656px -336px;
}

.BPENG {
  width: 16px;
  height: 16px;
  background-position: -656px -352px;
}

.BPET {
  width: 16px;
  height: 16px;
  background-position: -656px -368px;
}

.BPKR {
  width: 16px;
  height: 16px;
  background-position: -656px -384px;
}

.BPLC {
  width: 16px;
  height: 16px;
  background-position: -656px -400px;
}

.BPLUS {
  width: 16px;
  height: 16px;
  background-position: -656px -416px;
}

.BPM {
  width: 16px;
  height: 16px;
  background-position: -656px -432px;
}

.BPOP {
  width: 16px;
  height: 16px;
  background-position: -656px -448px;
}

.BPP {
  width: 16px;
  height: 16px;
  background-position: -656px -464px;
}

.BPR {
  width: 16px;
  height: 16px;
  background-position: -656px -480px;
}

.BPRCY {
  width: 16px;
  height: 16px;
  background-position: -656px -496px;
}

.bPRIVA {
  width: 16px;
  height: 16px;
  background-position: -656px -512px;
}

.BPRO {
  width: 16px;
  height: 16px;
  background-position: -656px -528px;
}

.BPS {
  width: 16px;
  height: 16px;
  background-position: -656px -544px;
}

.BPT {
  width: 16px;
  height: 16px;
  background-position: -656px -560px;
}

.BPTC {
  width: 16px;
  height: 16px;
  background-position: -656px -576px;
}

.BPUNKS {
  width: 16px;
  height: 16px;
  background-position: -656px -592px;
}

.BPX {
  width: 16px;
  height: 16px;
  background-position: -656px -608px;
}

.BQ {
  width: 16px;
  height: 16px;
  background-position: -656px -624px;
}

.BQCC {
  width: 16px;
  height: 16px;
  background-position: -656px -640px;
}

.BQQQ {
  width: 16px;
  height: 16px;
  background-position: -0 -656px;
}

.BQT {
  width: 16px;
  height: 16px;
  background-position: -16px -656px;
}

.BQTX {
  width: 16px;
  height: 16px;
  background-position: -32px -656px;
}

.BR {
  width: 16px;
  height: 16px;
  background-position: -48px -656px;
}

.BR2_0 {
  width: 16px;
  height: 16px;
  background-position: -64px -656px;
}

.BR34P {
  width: 16px;
  height: 16px;
  background-position: -80px -656px;
}

.BRAIN {
  width: 16px;
  height: 16px;
  background-position: -96px -656px;
}

.BRAINS {
  width: 16px;
  height: 16px;
  background-position: -112px -656px;
}

.BRAND {
  width: 16px;
  height: 16px;
  background-position: -128px -656px;
}

.BRAT {
  width: 16px;
  height: 16px;
  background-position: -144px -656px;
}

.BRAVE {
  width: 16px;
  height: 16px;
  background-position: -160px -656px;
}

.BRAVO {
  width: 16px;
  height: 16px;
  background-position: -176px -656px;
}

.BRAWL {
  width: 16px;
  height: 16px;
  background-position: -192px -656px;
}

.BRB {
  width: 16px;
  height: 16px;
  background-position: -208px -656px;
}

.BRC {
  width: 16px;
  height: 16px;
  background-position: -224px -656px;
}

.BRCP {
  width: 16px;
  height: 16px;
  background-position: -240px -656px;
}

.BRD {
  width: 16px;
  height: 16px;
  background-position: -256px -656px;
}

.BRDG {
  width: 16px;
  height: 16px;
  background-position: -272px -656px;
}

.BRE {
  width: 16px;
  height: 16px;
  background-position: -288px -656px;
}

.BREAST {
  width: 16px;
  height: 16px;
  background-position: -304px -656px;
}

.BREE {
  width: 16px;
  height: 16px;
  background-position: -320px -656px;
}

.BREED {
  width: 16px;
  height: 16px;
  background-position: -336px -656px;
}

.BREW {
  width: 16px;
  height: 16px;
  background-position: -352px -656px;
}

.BREWLABS {
  width: 16px;
  height: 16px;
  background-position: -368px -656px;
}

.BRG {
  width: 16px;
  height: 16px;
  background-position: -384px -656px;
}

.BRG\.X {
  width: 16px;
  height: 16px;
  background-position: -400px -656px;
}

.BRGB {
  width: 16px;
  height: 16px;
  background-position: -416px -656px;
}

.BRI {
  width: 16px;
  height: 16px;
  background-position: -432px -656px;
}

.BRIA {
  width: 16px;
  height: 16px;
  background-position: -448px -656px;
}

.BRIBE {
  width: 16px;
  height: 16px;
  background-position: -464px -656px;
}

.BRICK {
  width: 16px;
  height: 16px;
  background-position: -480px -656px;
}

.BRICKS {
  width: 16px;
  height: 16px;
  background-position: -496px -656px;
}

.BRIDGE {
  width: 16px;
  height: 16px;
  background-position: -512px -656px;
}

.BRIG {
  width: 16px;
  height: 16px;
  background-position: -528px -656px;
}

.BRIGADEIRO {
  width: 16px;
  height: 16px;
  background-position: -544px -656px;
}

.BRIGHT {
  width: 16px;
  height: 16px;
  background-position: -560px -656px;
}

.BRIK {
  width: 16px;
  height: 16px;
  background-position: -576px -656px;
}

.BRISE {
  width: 16px;
  height: 16px;
  background-position: -592px -656px;
}

.BRIT {
  width: 16px;
  height: 16px;
  background-position: -608px -656px;
}

.BRK {
  width: 16px;
  height: 16px;
  background-position: -624px -656px;
}

.BRKI {
  width: 16px;
  height: 16px;
  background-position: -640px -656px;
}

.BRKL {
  width: 16px;
  height: 16px;
  background-position: -656px -656px;
}

.BRKT {
  width: 16px;
  height: 16px;
  background-position: -672px -0;
}

.BRL {
  width: 16px;
  height: 16px;
  background-position: -672px -16px;
}

.BRLC {
  width: 16px;
  height: 16px;
  background-position: -672px -32px;
}

.BRN {
  width: 16px;
  height: 16px;
  background-position: -672px -48px;
}

.BRNG {
  width: 16px;
  height: 16px;
  background-position: -672px -64px;
}

.BRO {
  width: 16px;
  height: 16px;
  background-position: -672px -80px;
}

.BROWN {
  width: 16px;
  height: 16px;
  background-position: -672px -96px;
}

.BRR {
  width: 16px;
  height: 16px;
  background-position: -672px -112px;
}

.BRRR {
  width: 16px;
  height: 16px;
  background-position: -672px -128px;
}

.BRS {
  width: 16px;
  height: 16px;
  background-position: -672px -144px;
}

.BRT {
  width: 16px;
  height: 16px;
  background-position: -672px -160px;
}

.BRTK {
  width: 16px;
  height: 16px;
  background-position: -672px -176px;
}

.BRTR {
  width: 16px;
  height: 16px;
  background-position: -672px -192px;
}

.BRTX {
  width: 16px;
  height: 16px;
  background-position: -672px -208px;
}

.BRUH {
  width: 16px;
  height: 16px;
  background-position: -672px -224px;
}

.BRUSH {
  width: 16px;
  height: 16px;
  background-position: -672px -240px;
}

.BRW {
  width: 16px;
  height: 16px;
  background-position: -672px -256px;
}

.BRWL {
  width: 16px;
  height: 16px;
  background-position: -672px -272px;
}

.BRY {
  width: 16px;
  height: 16px;
  background-position: -672px -288px;
}

.BRZ {
  width: 16px;
  height: 16px;
  background-position: -672px -304px;
}

.BRZE {
  width: 16px;
  height: 16px;
  background-position: -672px -320px;
}

.BRZX {
  width: 16px;
  height: 16px;
  background-position: -672px -336px;
}

.BSAFE {
  width: 16px;
  height: 16px;
  background-position: -672px -352px;
}

.BSAFU {
  width: 16px;
  height: 16px;
  background-position: -672px -368px;
}

.BSAMO {
  width: 16px;
  height: 16px;
  background-position: -672px -384px;
}

.BSB {
  width: 16px;
  height: 16px;
  background-position: -672px -400px;
}

.BSBT {
  width: 16px;
  height: 16px;
  background-position: -672px -416px;
}

.BSC {
  width: 16px;
  height: 16px;
  background-position: -672px -432px;
}

.BSC33 {
  width: 16px;
  height: 16px;
  background-position: -672px -448px;
}

.BSCAKE {
  width: 16px;
  height: 16px;
  background-position: -672px -464px;
}

.BSCB {
  width: 16px;
  height: 16px;
  background-position: -672px -480px;
}

.BSCF {
  width: 16px;
  height: 16px;
  background-position: -672px -496px;
}

.BSCGIRL {
  width: 16px;
  height: 16px;
  background-position: -672px -512px;
}

.BSCGOLD {
  width: 16px;
  height: 16px;
  background-position: -672px -528px;
}

.BSCM {
  width: 16px;
  height: 16px;
  background-position: -672px -544px;
}

.BSCPAD {
  width: 16px;
  height: 16px;
  background-position: -672px -560px;
}

.BSCS {
  width: 16px;
  height: 16px;
  background-position: -672px -576px;
}

.BSCTRUST {
  width: 16px;
  height: 16px;
  background-position: -672px -592px;
}

.BSCV {
  width: 16px;
  height: 16px;
  background-position: -672px -608px;
}

.BSCWIN {
  width: 16px;
  height: 16px;
  background-position: -672px -624px;
}

.BSCX {
  width: 16px;
  height: 16px;
  background-position: -672px -640px;
}

.BSD {
  width: 16px;
  height: 16px;
  background-position: -672px -656px;
}

.BSDS {
  width: 16px;
  height: 16px;
  background-position: -0 -672px;
}

.BSE {
  width: 16px;
  height: 16px;
  background-position: -16px -672px;
}

.BSF {
  width: 16px;
  height: 16px;
  background-position: -32px -672px;
}

.BSFM {
  width: 16px;
  height: 16px;
  background-position: -48px -672px;
}

.BSG {
  width: 16px;
  height: 16px;
  background-position: -64px -672px;
}

.BSGG {
  width: 16px;
  height: 16px;
  background-position: -80px -672px;
}

.BSH {
  width: 16px;
  height: 16px;
  background-position: -96px -672px;
}

.BSHARE {
  width: 16px;
  height: 16px;
  background-position: -112px -672px;
}

.BSHIB {
  width: 16px;
  height: 16px;
  background-position: -128px -672px;
}

.BSHIBA {
  width: 16px;
  height: 16px;
  background-position: -144px -672px;
}

.BSHIBR {
  width: 16px;
  height: 16px;
  background-position: -160px -672px;
}

.BSI {
  width: 16px;
  height: 16px;
  background-position: -176px -672px;
}

.BSJ {
  width: 16px;
  height: 16px;
  background-position: -192px -672px;
}

.BSK_BAA025 {
  width: 16px;
  height: 16px;
  background-position: -208px -672px;
}

.BSKT {
  width: 16px;
  height: 16px;
  background-position: -224px -672px;
}

.BSL {
  width: 16px;
  height: 16px;
  background-position: -240px -672px;
}

.BSM {
  width: 16px;
  height: 16px;
  background-position: -256px -672px;
}

.BSMB {
  width: 16px;
  height: 16px;
  background-position: -272px -672px;
}

.BSN {
  width: 16px;
  height: 16px;
  background-position: -288px -672px;
}

.BSOV {
  width: 16px;
  height: 16px;
  background-position: -304px -672px;
}

.BSP {
  width: 16px;
  height: 16px;
  background-position: -320px -672px;
}

.BSPAY {
  width: 16px;
  height: 16px;
  background-position: -336px -672px;
}

.BSPT {
  width: 16px;
  height: 16px;
  background-position: -352px -672px;
}

.BSR {
  width: 16px;
  height: 16px;
  background-position: -368px -672px;
}

.BST {
  width: 16px;
  height: 16px;
  background-position: -384px -672px;
}

.BST1 {
  width: 16px;
  height: 16px;
  background-position: -400px -672px;
}

.BSTN {
  width: 16px;
  height: 16px;
  background-position: -416px -672px;
}

.BSTS {
  width: 16px;
  height: 16px;
  background-position: -432px -672px;
}

.BSTv2 {
  width: 16px;
  height: 16px;
  background-position: -448px -672px;
}

.BSTY {
  width: 16px;
  height: 16px;
  background-position: -464px -672px;
}

.BSV {
  width: 16px;
  height: 16px;
  background-position: -480px -672px;
}

.BSVG {
  width: 16px;
  height: 16px;
  background-position: -496px -672px;
}

.BSW {
  width: 16px;
  height: 16px;
  background-position: -512px -672px;
}

.BSWAP {
  width: 16px;
  height: 16px;
  background-position: -528px -672px;
}

.BSX {
  width: 16px;
  height: 16px;
  background-position: -544px -672px;
}

.BSY {
  width: 16px;
  height: 16px;
  background-position: -560px -672px;
}

.BSYS {
  width: 16px;
  height: 16px;
  background-position: -576px -672px;
}

.BT {
  width: 16px;
  height: 16px;
  background-position: -592px -672px;
}

.BTA {
  width: 16px;
  height: 16px;
  background-position: -608px -672px;
}

.BTAD {
  width: 16px;
  height: 16px;
  background-position: -624px -672px;
}

.BTAP {
  width: 16px;
  height: 16px;
  background-position: -640px -672px;
}

.BTB {
  width: 16px;
  height: 16px;
  background-position: -656px -672px;
}

.BTBS {
  width: 16px;
  height: 16px;
  background-position: -672px -672px;
}

.BTC\.b {
  width: 16px;
  height: 16px;
  background-position: -688px -0;
}

.BTC {
  width: 16px;
  height: 16px;
  background-position: -688px -16px;
}

.BTC1D3L {
  width: 16px;
  height: 16px;
  background-position: -688px -32px;
}

.BTC2 {
  width: 16px;
  height: 16px;
  background-position: -688px -48px;
}

.BTC2X_FLI {
  width: 16px;
  height: 16px;
  background-position: -688px -64px;
}

.BTC\+\+ {
  width: 16px;
  height: 16px;
  background-position: -688px -80px;
}

.BTCA {
  width: 16px;
  height: 16px;
  background-position: -688px -96px;
}

.BTCB {
  width: 16px;
  height: 16px;
  background-position: -688px -112px;
}

.BTCBAM {
  width: 16px;
  height: 16px;
  background-position: -688px -128px;
}

.BTCBR {
  width: 16px;
  height: 16px;
  background-position: -688px -144px;
}

.BTCBZ {
  width: 16px;
  height: 16px;
  background-position: -688px -160px;
}

.BTCDOM {
  width: 16px;
  height: 16px;
  background-position: -688px -176px;
}

.BTCETH7525 {
  width: 16px;
  height: 16px;
  background-position: -688px -192px;
}

.BTCF {
  width: 16px;
  height: 16px;
  background-position: -688px -208px;
}

.BTCG {
  width: 16px;
  height: 16px;
  background-position: -688px -224px;
}

.BTCHG {
  width: 16px;
  height: 16px;
  background-position: -688px -240px;
}

.BTCI {
  width: 16px;
  height: 16px;
  background-position: -688px -256px;
}

.BTCIX {
  width: 16px;
  height: 16px;
  background-position: -688px -272px;
}

.BTCK {
  width: 16px;
  height: 16px;
  background-position: -688px -288px;
}

.BTCL {
  width: 16px;
  height: 16px;
  background-position: -688px -304px;
}

.BTCM {
  width: 16px;
  height: 16px;
  background-position: -688px -320px;
}

.BTCMT {
  width: 16px;
  height: 16px;
  background-position: -688px -336px;
}

.BTCMZ {
  width: 16px;
  height: 16px;
  background-position: -688px -352px;
}

.BTCN {
  width: 16px;
  height: 16px;
  background-position: -688px -368px;
}

.BTCP {
  width: 16px;
  height: 16px;
  background-position: -688px -384px;
}

.BTCPX {
  width: 16px;
  height: 16px;
  background-position: -688px -400px;
}

.BTCR {
  width: 16px;
  height: 16px;
  background-position: -688px -416px;
}

.BTCRED {
  width: 16px;
  height: 16px;
  background-position: -688px -432px;
}

.BTCS {
  width: 16px;
  height: 16px;
  background-position: -688px -448px;
}

.BTCSHORT {
  width: 16px;
  height: 16px;
  background-position: -688px -464px;
}

.BTCST {
  width: 16px;
  height: 16px;
  background-position: -688px -480px;
}

.BTCT {
  width: 16px;
  height: 16px;
  background-position: -688px -496px;
}

.BTCU {
  width: 16px;
  height: 16px;
  background-position: -688px -512px;
}

.BTCV {
  width: 16px;
  height: 16px;
  background-position: -688px -528px;
}

.BTCX {
  width: 16px;
  height: 16px;
  background-position: -688px -544px;
}

.BTCZ {
  width: 16px;
  height: 16px;
  background-position: -688px -560px;
}

.BTD {
  width: 16px;
  height: 16px;
  background-position: -688px -576px;
}

.BTDMD {
  width: 16px;
  height: 16px;
  background-position: -688px -592px;
}

.BTDX {
  width: 16px;
  height: 16px;
  background-position: -688px -608px;
}

.BTE {
  width: 16px;
  height: 16px;
  background-position: -688px -624px;
}

.BTF {
  width: 16px;
  height: 16px;
  background-position: -688px -640px;
}

.BTFA {
  width: 16px;
  height: 16px;
  background-position: -688px -656px;
}

.BTG {
  width: 16px;
  height: 16px;
  background-position: -688px -672px;
}

.BTGN {
  width: 16px;
  height: 16px;
  background-position: -0 -688px;
}

.BTH {
  width: 16px;
  height: 16px;
  background-position: -16px -688px;
}

.BTI {
  width: 16px;
  height: 16px;
  background-position: -32px -688px;
}

.BTKC {
  width: 16px;
  height: 16px;
  background-position: -48px -688px;
}

.BTL {
  width: 16px;
  height: 16px;
  background-position: -64px -688px;
}

.BTM {
  width: 16px;
  height: 16px;
  background-position: -80px -688px;
}

.BTN {
  width: 16px;
  height: 16px;
  background-position: -96px -688px;
}

.BTNA {
  width: 16px;
  height: 16px;
  background-position: -112px -688px;
}

.BTNT {
  width: 16px;
  height: 16px;
  background-position: -128px -688px;
}

.BTNYX {
  width: 16px;
  height: 16px;
  background-position: -144px -688px;
}

.BTO {
  width: 16px;
  height: 16px;
  background-position: -160px -688px;
}

.BTP {
  width: 16px;
  height: 16px;
  background-position: -176px -688px;
}

.BTPL {
  width: 16px;
  height: 16px;
  background-position: -192px -688px;
}

.BTR {
  width: 16px;
  height: 16px;
  background-position: -208px -688px;
}

.BTRFLY {
  width: 16px;
  height: 16px;
  background-position: -224px -688px;
}

.BTRI {
  width: 16px;
  height: 16px;
  background-position: -240px -688px;
}

.BTRL {
  width: 16px;
  height: 16px;
  background-position: -256px -688px;
}

.BTRN {
  width: 16px;
  height: 16px;
  background-position: -272px -688px;
}

.BTRS {
  width: 16px;
  height: 16px;
  background-position: -288px -688px;
}

.BTRST {
  width: 16px;
  height: 16px;
  background-position: -304px -688px;
}

.BTRY {
  width: 16px;
  height: 16px;
  background-position: -320px -688px;
}

.BTS {
  width: 16px;
  height: 16px;
  background-position: -336px -688px;
}

.BTSA {
  width: 16px;
  height: 16px;
  background-position: -352px -688px;
}

.BTSC {
  width: 16px;
  height: 16px;
  background-position: -368px -688px;
}

.BTSE {
  width: 16px;
  height: 16px;
  background-position: -384px -688px;
}

.BTSG {
  width: 16px;
  height: 16px;
  background-position: -400px -688px;
}

.BTSL {
  width: 16px;
  height: 16px;
  background-position: -416px -688px;
}

.BTSUCN {
  width: 16px;
  height: 16px;
  background-position: -432px -688px;
}

.BTT {
  width: 16px;
  height: 16px;
  background-position: -448px -688px;
}

.BTTOLD {
  width: 16px;
  height: 16px;
  background-position: -464px -688px;
}

.BTTR {
  width: 16px;
  height: 16px;
  background-position: -480px -688px;
}

.BTTXT {
  width: 16px;
  height: 16px;
  background-position: -496px -688px;
}

.BTU {
  width: 16px;
  height: 16px;
  background-position: -512px -688px;
}

.BTV {
  width: 16px;
  height: 16px;
  background-position: -528px -688px;
}

.BTW {
  width: 16px;
  height: 16px;
  background-position: -544px -688px;
}

.BTX {
  width: 16px;
  height: 16px;
  background-position: -560px -688px;
}

.BTXC {
  width: 16px;
  height: 16px;
  background-position: -576px -688px;
}

.BTXN {
  width: 16px;
  height: 16px;
  background-position: -592px -688px;
}

.BTY {
  width: 16px;
  height: 16px;
  background-position: -608px -688px;
}

.BTYM {
  width: 16px;
  height: 16px;
  background-position: -624px -688px;
}

.BTZ {
  width: 16px;
  height: 16px;
  background-position: -640px -688px;
}

.BTZC {
  width: 16px;
  height: 16px;
  background-position: -656px -688px;
}

.BU {
  width: 16px;
  height: 16px;
  background-position: -672px -688px;
}

.BUB {
  width: 16px;
  height: 16px;
  background-position: -688px -688px;
}

.BUBBLEGUM {
  width: 16px;
  height: 16px;
  background-position: -704px -0;
}

.BUBO {
  width: 16px;
  height: 16px;
  background-position: -704px -16px;
}

.BUC {
  width: 16px;
  height: 16px;
  background-position: -704px -32px;
}

.BUCK {
  width: 16px;
  height: 16px;
  background-position: -704px -48px;
}

.BUCKS {
  width: 16px;
  height: 16px;
  background-position: -704px -64px;
}

.BUDA {
  width: 16px;
  height: 16px;
  background-position: -704px -80px;
}

.BUDG {
  width: 16px;
  height: 16px;
  background-position: -704px -96px;
}

.BUFF {
  width: 16px;
  height: 16px;
  background-position: -704px -112px;
}

.BUFFALO {
  width: 16px;
  height: 16px;
  background-position: -704px -128px;
}

.BUFFS {
  width: 16px;
  height: 16px;
  background-position: -704px -144px;
}

.BUFFSHIBA {
  width: 16px;
  height: 16px;
  background-position: -704px -160px;
}

.BUFFYOOSHI {
  width: 16px;
  height: 16px;
  background-position: -704px -176px;
}

.BUFLOKI {
  width: 16px;
  height: 16px;
  background-position: -704px -192px;
}

.BUGG {
  width: 16px;
  height: 16px;
  background-position: -704px -208px;
}

.BUGS {
  width: 16px;
  height: 16px;
  background-position: -704px -224px;
}

.BUIDL {
  width: 16px;
  height: 16px;
  background-position: -704px -240px;
}

.BUILD {
  width: 16px;
  height: 16px;
  background-position: -704px -256px;
}

.BUKH {
  width: 16px;
  height: 16px;
  background-position: -704px -272px;
}

.BUL {
  width: 16px;
  height: 16px;
  background-position: -704px -288px;
}

.BULB {
  width: 16px;
  height: 16px;
  background-position: -704px -304px;
}

.BULK {
  width: 16px;
  height: 16px;
  background-position: -704px -320px;
}

.BULLAF {
  width: 16px;
  height: 16px;
  background-position: -704px -336px;
}

.BULLD {
  width: 16px;
  height: 16px;
  background-position: -704px -352px;
}

.BULLDOG {
  width: 16px;
  height: 16px;
  background-position: -704px -368px;
}

.BULLETH {
  width: 16px;
  height: 16px;
  background-position: -704px -384px;
}

.BULLION {
  width: 16px;
  height: 16px;
  background-position: -704px -400px;
}

.BULLISH {
  width: 16px;
  height: 16px;
  background-position: -704px -416px;
}

.BULLISHC {
  width: 16px;
  height: 16px;
  background-position: -704px -432px;
}

.BULLS {
  width: 16px;
  height: 16px;
  background-position: -704px -448px;
}

.BULLY {
  width: 16px;
  height: 16px;
  background-position: -704px -464px;
}

.BULT {
  width: 16px;
  height: 16px;
  background-position: -704px -480px;
}

.BUMBA {
  width: 16px;
  height: 16px;
  background-position: -704px -496px;
}

.BUMN {
  width: 16px;
  height: 16px;
  background-position: -704px -512px;
}

.BUMP {
  width: 16px;
  height: 16px;
  background-position: -704px -528px;
}

.BUN {
  width: 16px;
  height: 16px;
  background-position: -704px -544px;
}

.BUNCHIE {
  width: 16px;
  height: 16px;
  background-position: -704px -560px;
}

.BUND {
  width: 16px;
  height: 16px;
  background-position: -704px -576px;
}

.BUNI {
  width: 16px;
  height: 16px;
  background-position: -704px -592px;
}

.BUNKER {
  width: 16px;
  height: 16px;
  background-position: -704px -608px;
}

.BUNNY {
  width: 16px;
  height: 16px;
  background-position: -704px -624px;
}

.BUNNYCAKE {
  width: 16px;
  height: 16px;
  background-position: -704px -640px;
}

.BUNNYGIRL {
  width: 16px;
  height: 16px;
  background-position: -704px -656px;
}

.BUNNYROCKET {
  width: 16px;
  height: 16px;
  background-position: -704px -672px;
}

.BUNNYZILLA {
  width: 16px;
  height: 16px;
  background-position: -704px -688px;
}

.BUP {
  width: 16px;
  height: 16px;
  background-position: -0 -704px;
}

.BUR {
  width: 16px;
  height: 16px;
  background-position: -16px -704px;
}

.BURD {
  width: 16px;
  height: 16px;
  background-position: -32px -704px;
}

.BURGER {
  width: 16px;
  height: 16px;
  background-position: -48px -704px;
}

.Burn1Coin {
  width: 16px;
  height: 16px;
  background-position: -64px -704px;
}

.BURN {
  width: 16px;
  height: 16px;
  background-position: -80px -704px;
}

.BURNDOGE {
  width: 16px;
  height: 16px;
  background-position: -96px -704px;
}

.BURNROCKET {
  width: 16px;
  height: 16px;
  background-position: -112px -704px;
}

.BURNS {
  width: 16px;
  height: 16px;
  background-position: -128px -704px;
}

.BurnX {
  width: 16px;
  height: 16px;
  background-position: -144px -704px;
}

.BURNX20 {
  width: 16px;
  height: 16px;
  background-position: -160px -704px;
}

.BURP {
  width: 16px;
  height: 16px;
  background-position: -176px -704px;
}

.BURROW {
  width: 16px;
  height: 16px;
  background-position: -192px -704px;
}

.BUSD {
  width: 16px;
  height: 16px;
  background-position: -208px -704px;
}

.BUSDX {
  width: 16px;
  height: 16px;
  background-position: -224px -704px;
}

.BUSINESSES {
  width: 16px;
  height: 16px;
  background-position: -240px -704px;
}

.BUSM {
  width: 16px;
  height: 16px;
  background-position: -256px -704px;
}

.BUST {
  width: 16px;
  height: 16px;
  background-position: -272px -704px;
}

.BUSY {
  width: 16px;
  height: 16px;
  background-position: -288px -704px;
}

.BUT {
  width: 16px;
  height: 16px;
  background-position: -304px -704px;
}

.BUTTER {
  width: 16px;
  height: 16px;
  background-position: -320px -704px;
}

.BUU {
  width: 16px;
  height: 16px;
  background-position: -336px -704px;
}

.BUX {
  width: 16px;
  height: 16px;
  background-position: -352px -704px;
}

.BUY {
  width: 16px;
  height: 16px;
  background-position: -368px -704px;
}

.BUZZ {
  width: 16px;
  height: 16px;
  background-position: -384px -704px;
}

.BV {
  width: 16px;
  height: 16px;
  background-position: -400px -704px;
}

.BVA {
  width: 16px;
  height: 16px;
  background-position: -416px -704px;
}

.BVC {
  width: 16px;
  height: 16px;
  background-position: -432px -704px;
}

.BVER {
  width: 16px;
  height: 16px;
  background-position: -448px -704px;
}

.BVERSE {
  width: 16px;
  height: 16px;
  background-position: -464px -704px;
}

.BVK {
  width: 16px;
  height: 16px;
  background-position: -480px -704px;
}

.BVL {
  width: 16px;
  height: 16px;
  background-position: -496px -704px;
}

.BVND {
  width: 16px;
  height: 16px;
  background-position: -512px -704px;
}

.BVR {
  width: 16px;
  height: 16px;
  background-position: -528px -704px;
}

.BWB {
  width: 16px;
  height: 16px;
  background-position: -544px -704px;
}

.BWC {
  width: 16px;
  height: 16px;
  background-position: -560px -704px;
}

.BWF {
  width: 16px;
  height: 16px;
  background-position: -576px -704px;
}

.BWH {
  width: 16px;
  height: 16px;
  background-position: -592px -704px;
}

.BWI {
  width: 16px;
  height: 16px;
  background-position: -608px -704px;
}

.BWJ {
  width: 16px;
  height: 16px;
  background-position: -624px -704px;
}

.BWO {
  width: 16px;
  height: 16px;
  background-position: -640px -704px;
}

.BWR {
  width: 16px;
  height: 16px;
  background-position: -656px -704px;
}

.BWRX {
  width: 16px;
  height: 16px;
  background-position: -672px -704px;
}

.BWS {
  width: 16px;
  height: 16px;
  background-position: -688px -704px;
}

.BWT {
  width: 16px;
  height: 16px;
  background-position: -704px -704px;
}

.BWX {
  width: 16px;
  height: 16px;
  background-position: -720px -0;
}

.BX {
  width: 16px;
  height: 16px;
  background-position: -720px -16px;
}

.BXA {
  width: 16px;
  height: 16px;
  background-position: -720px -32px;
}

.BXC {
  width: 16px;
  height: 16px;
  background-position: -720px -48px;
}

.BXH {
  width: 16px;
  height: 16px;
  background-position: -720px -64px;
}

.BXIOT {
  width: 16px;
  height: 16px;
  background-position: -720px -80px;
}

.BXK {
  width: 16px;
  height: 16px;
  background-position: -720px -96px;
}

.BXMI {
  width: 16px;
  height: 16px;
  background-position: -720px -112px;
}

.BXR {
  width: 16px;
  height: 16px;
  background-position: -720px -128px;
}

.BXS {
  width: 16px;
  height: 16px;
  background-position: -720px -144px;
}

.BXT {
  width: 16px;
  height: 16px;
  background-position: -720px -160px;
}

.BXTB {
  width: 16px;
  height: 16px;
  background-position: -720px -176px;
}

.BXX {
  width: 16px;
  height: 16px;
  background-position: -720px -192px;
}

.BXY {
  width: 16px;
  height: 16px;
  background-position: -720px -208px;
}

.BYB {
  width: 16px;
  height: 16px;
  background-position: -720px -224px;
}

.BYCO {
  width: 16px;
  height: 16px;
  background-position: -720px -240px;
}

.BYG {
  width: 16px;
  height: 16px;
  background-position: -720px -256px;
}

.BYK {
  width: 16px;
  height: 16px;
  background-position: -720px -272px;
}

.BYN {
  width: 16px;
  height: 16px;
  background-position: -720px -288px;
}

.BYRON {
  width: 16px;
  height: 16px;
  background-position: -720px -304px;
}

.BYT {
  width: 16px;
  height: 16px;
  background-position: -720px -320px;
}

.BYTE {
  width: 16px;
  height: 16px;
  background-position: -720px -336px;
}

.BYTN {
  width: 16px;
  height: 16px;
  background-position: -720px -352px;
}

.BYTS {
  width: 16px;
  height: 16px;
  background-position: -720px -368px;
}

.BYTZ {
  width: 16px;
  height: 16px;
  background-position: -720px -384px;
}

.byYam {
  width: 16px;
  height: 16px;
  background-position: -720px -400px;
}

.BZ {
  width: 16px;
  height: 16px;
  background-position: -720px -416px;
}

.BZAR {
  width: 16px;
  height: 16px;
  background-position: -720px -432px;
}

.BZC {
  width: 16px;
  height: 16px;
  background-position: -720px -448px;
}

.BZE {
  width: 16px;
  height: 16px;
  background-position: -720px -464px;
}

.BZH {
  width: 16px;
  height: 16px;
  background-position: -720px -480px;
}

.BZKY {
  width: 16px;
  height: 16px;
  background-position: -720px -496px;
}

.BZN {
  width: 16px;
  height: 16px;
  background-position: -720px -512px;
}

.BZNT {
  width: 16px;
  height: 16px;
  background-position: -720px -528px;
}

.BZRX {
  width: 16px;
  height: 16px;
  background-position: -720px -544px;
}

.BZSC {
  width: 16px;
  height: 16px;
  background-position: -720px -560px;
}

.BZX {
  width: 16px;
  height: 16px;
  background-position: -720px -576px;
}

.BZZ {
  width: 16px;
  height: 16px;
  background-position: -720px -592px;
}

.BZZONE {
  width: 16px;
  height: 16px;
  background-position: -720px -608px;
}

.c0 {
  width: 16px;
  height: 16px;
  background-position: -720px -624px;
}

.C {
  width: 16px;
  height: 16px;
  background-position: -720px -640px;
}

.C0C0 {
  width: 16px;
  height: 16px;
  background-position: -720px -656px;
}

.C2 {
  width: 16px;
  height: 16px;
  background-position: -720px -672px;
}

.C2O {
  width: 16px;
  height: 16px;
  background-position: -720px -688px;
}

.C2P {
  width: 16px;
  height: 16px;
  background-position: -720px -704px;
}

.C3 {
  width: 16px;
  height: 16px;
  background-position: -0 -720px;
}

.C4G3 {
  width: 16px;
  height: 16px;
  background-position: -16px -720px;
}

.C4T {
  width: 16px;
  height: 16px;
  background-position: -32px -720px;
}

.C8 {
  width: 16px;
  height: 16px;
  background-position: -48px -720px;
}

.C20 {
  width: 16px;
  height: 16px;
  background-position: -64px -720px;
}

.C24 {
  width: 16px;
  height: 16px;
  background-position: -80px -720px;
}

.C98 {
  width: 16px;
  height: 16px;
  background-position: -96px -720px;
}

.CA {
  width: 16px;
  height: 16px;
  background-position: -112px -720px;
}

.CAB {
  width: 16px;
  height: 16px;
  background-position: -128px -720px;
}

.CABIN {
  width: 16px;
  height: 16px;
  background-position: -144px -720px;
}

.CABO {
  width: 16px;
  height: 16px;
  background-position: -160px -720px;
}

.CAC {
  width: 16px;
  height: 16px;
  background-position: -176px -720px;
}

.CACTI {
  width: 16px;
  height: 16px;
  background-position: -192px -720px;
}

.CACTT {
  width: 16px;
  height: 16px;
  background-position: -208px -720px;
}

.CACTUS {
  width: 16px;
  height: 16px;
  background-position: -224px -720px;
}

.CAD {
  width: 16px;
  height: 16px;
  background-position: -240px -720px;
}

.CADC {
  width: 16px;
  height: 16px;
  background-position: -256px -720px;
}

.CADE {
  width: 16px;
  height: 16px;
  background-position: -272px -720px;
}

.CADINU {
  width: 16px;
  height: 16px;
  background-position: -288px -720px;
}

.CADX {
  width: 16px;
  height: 16px;
  background-position: -304px -720px;
}

.Caelum {
  width: 16px;
  height: 16px;
  background-position: -320px -720px;
}

.CAESAR {
  width: 16px;
  height: 16px;
  background-position: -336px -720px;
}

.CAF {
  width: 16px;
  height: 16px;
  background-position: -352px -720px;
}

.CAG {
  width: 16px;
  height: 16px;
  background-position: -368px -720px;
}

.CAGE {
  width: 16px;
  height: 16px;
  background-position: -384px -720px;
}

.CAI {
  width: 16px;
  height: 16px;
  background-position: -400px -720px;
}

.CAIRO {
  width: 16px;
  height: 16px;
  background-position: -416px -720px;
}

.CAJ {
  width: 16px;
  height: 16px;
  background-position: -432px -720px;
}

.CAKE {
  width: 16px;
  height: 16px;
  background-position: -448px -720px;
}

.CAKEBAKER {
  width: 16px;
  height: 16px;
  background-position: -464px -720px;
}

.CAKEBANK {
  width: 16px;
  height: 16px;
  background-position: -480px -720px;
}

.CAKECRYPT {
  width: 16px;
  height: 16px;
  background-position: -496px -720px;
}

.CAKEGAME {
  width: 16px;
  height: 16px;
  background-position: -512px -720px;
}

.CAKEGIRL {
  width: 16px;
  height: 16px;
  background-position: -528px -720px;
}

.CAKEPUNKS {
  width: 16px;
  height: 16px;
  background-position: -544px -720px;
}

.CAKESWAP {
  width: 16px;
  height: 16px;
  background-position: -560px -720px;
}

.CAKEUP {
  width: 16px;
  height: 16px;
  background-position: -576px -720px;
}

.CAKEZILLA {
  width: 16px;
  height: 16px;
  background-position: -592px -720px;
}

.CAKF {
  width: 16px;
  height: 16px;
  background-position: -608px -720px;
}

.CAKITA {
  width: 16px;
  height: 16px;
  background-position: -624px -720px;
}

.CAL {
  width: 16px;
  height: 16px;
  background-position: -640px -720px;
}

.CALCIFIRE {
  width: 16px;
  height: 16px;
  background-position: -656px -720px;
}

.CALI {
  width: 16px;
  height: 16px;
  background-position: -672px -720px;
}

.CALIT {
  width: 16px;
  height: 16px;
  background-position: -688px -720px;
}

.CALL {
  width: 16px;
  height: 16px;
  background-position: -704px -720px;
}

.CALO {
  width: 16px;
  height: 16px;
  background-position: -720px -720px;
}

.CAM {
  width: 16px;
  height: 16px;
  background-position: -736px -0;
}

.CAMBU {
  width: 16px;
  height: 16px;
  background-position: -736px -16px;
}

.CAMO {
  width: 16px;
  height: 16px;
  background-position: -736px -32px;
}

.CAMP {
  width: 16px;
  height: 16px;
  background-position: -736px -48px;
}

.CAN {
  width: 16px;
  height: 16px;
  background-position: -736px -64px;
}

.CANDLE {
  width: 16px;
  height: 16px;
  background-position: -736px -80px;
}

.Candy {
  width: 16px;
  height: 16px;
  background-position: -736px -96px;
}

.CANDY {
  width: 16px;
  height: 16px;
  background-position: -736px -112px;
}

.CANDYDEX {
  width: 16px;
  height: 16px;
  background-position: -736px -128px;
}

.CANDYLAD {
  width: 16px;
  height: 16px;
  background-position: -736px -144px;
}

.CANN {
  width: 16px;
  height: 16px;
  background-position: -736px -160px;
}

.CANNA {
  width: 16px;
  height: 16px;
  background-position: -736px -176px;
}

.CANU {
  width: 16px;
  height: 16px;
  background-position: -736px -192px;
}

.CAP {
  width: 16px;
  height: 16px;
  background-position: -736px -208px;
}

.CAP9 {
  width: 16px;
  height: 16px;
  background-position: -736px -224px;
}

.CAPP {
  width: 16px;
  height: 16px;
  background-position: -736px -240px;
}

.CAPS {
  width: 16px;
  height: 16px;
  background-position: -736px -256px;
}

.CAPT {
  width: 16px;
  height: 16px;
  background-position: -736px -272px;
}

.CAPY {
  width: 16px;
  height: 16px;
  background-position: -736px -288px;
}

.CAR {
  width: 16px;
  height: 16px;
  background-position: -736px -304px;
}

.CARAT {
  width: 16px;
  height: 16px;
  background-position: -736px -320px;
}

.CARB {
  width: 16px;
  height: 16px;
  background-position: -736px -336px;
}

.CARBO {
  width: 16px;
  height: 16px;
  background-position: -736px -352px;
}

.CARBON {
  width: 16px;
  height: 16px;
  background-position: -736px -368px;
}

.C_ARCADE {
  width: 16px;
  height: 16px;
  background-position: -736px -384px;
}

.CARD {
  width: 16px;
  height: 16px;
  background-position: -736px -400px;
}

.CARDS {
  width: 16px;
  height: 16px;
  background-position: -736px -416px;
}

.CARE {
  width: 16px;
  height: 16px;
  background-position: -736px -432px;
}

.CARES {
  width: 16px;
  height: 16px;
  background-position: -736px -448px;
}

.CARGO {
  width: 16px;
  height: 16px;
  background-position: -736px -464px;
}

.CARM {
  width: 16px;
  height: 16px;
  background-position: -736px -480px;
}

.CARMA {
  width: 16px;
  height: 16px;
  background-position: -736px -496px;
}

.CAROM {
  width: 16px;
  height: 16px;
  background-position: -736px -512px;
}

.CARR {
  width: 16px;
  height: 16px;
  background-position: -736px -528px;
}

.CARROT {
  width: 16px;
  height: 16px;
  background-position: -736px -544px;
}

.CARS {
  width: 16px;
  height: 16px;
  background-position: -736px -560px;
}

.CART {
  width: 16px;
  height: 16px;
  background-position: -736px -576px;
}

.CAS {
  width: 16px;
  height: 16px;
  background-position: -736px -592px;
}

.CASECLOSED {
  width: 16px;
  height: 16px;
  background-position: -736px -608px;
}

.CASH {
  width: 16px;
  height: 16px;
  background-position: -736px -624px;
}

.CASH2 {
  width: 16px;
  height: 16px;
  background-position: -736px -640px;
}

.CASHDOG {
  width: 16px;
  height: 16px;
  background-position: -736px -656px;
}

.CASHIO {
  width: 16px;
  height: 16px;
  background-position: -736px -672px;
}

.CASIO {
  width: 16px;
  height: 16px;
  background-position: -736px -688px;
}

.CASS {
  width: 16px;
  height: 16px;
  background-position: -736px -704px;
}

.CAST {
  width: 16px;
  height: 16px;
  background-position: -736px -720px;
}

.CAT {
  width: 16px;
  height: 16px;
  background-position: -0 -736px;
}

.CAT\+ {
  width: 16px;
  height: 16px;
  background-position: -16px -736px;
}

.CATBOY {
  width: 16px;
  height: 16px;
  background-position: -32px -736px;
}

.CATBREAD {
  width: 16px;
  height: 16px;
  background-position: -48px -736px;
}

.CATCHY {
  width: 16px;
  height: 16px;
  background-position: -64px -736px;
}

.CATCOIN {
  width: 16px;
  height: 16px;
  background-position: -80px -736px;
}

.CATE {
  width: 16px;
  height: 16px;
  background-position: -96px -736px;
}

.CATFISH {
  width: 16px;
  height: 16px;
  background-position: -112px -736px;
}

.CATGE {
  width: 16px;
  height: 16px;
  background-position: -128px -736px;
}

.CATGIRL {
  width: 16px;
  height: 16px;
  background-position: -144px -736px;
}

.CATO {
  width: 16px;
  height: 16px;
  background-position: -160px -736px;
}

.CATPAY {
  width: 16px;
  height: 16px;
  background-position: -176px -736px;
}

.CATS {
  width: 16px;
  height: 16px;
  background-position: -192px -736px;
}

.CATT {
  width: 16px;
  height: 16px;
  background-position: -208px -736px;
}

.CATX {
  width: 16px;
  height: 16px;
  background-position: -224px -736px;
}

.CATZ {
  width: 16px;
  height: 16px;
  background-position: -240px -736px;
}

.CAVA {
  width: 16px;
  height: 16px;
  background-position: -256px -736px;
}

.CAVE {
  width: 16px;
  height: 16px;
  background-position: -272px -736px;
}

.CAVO {
  width: 16px;
  height: 16px;
  background-position: -288px -736px;
}

.CAW {
  width: 16px;
  height: 16px;
  background-position: -304px -736px;
}

.CAX {
  width: 16px;
  height: 16px;
  background-position: -320px -736px;
}

.CAZ {
  width: 16px;
  height: 16px;
  background-position: -336px -736px;
}

.CB {
  width: 16px;
  height: 16px;
  background-position: -352px -736px;
}

.CBA {
  width: 16px;
  height: 16px;
  background-position: -368px -736px;
}

.CBANK {
  width: 16px;
  height: 16px;
  background-position: -384px -736px;
}

.CBAT {
  width: 16px;
  height: 16px;
  background-position: -400px -736px;
}

.CBC {
  width: 16px;
  height: 16px;
  background-position: -416px -736px;
}

.CBD {
  width: 16px;
  height: 16px;
  background-position: -432px -736px;
}

.CBET {
  width: 16px;
  height: 16px;
  background-position: -448px -736px;
}

.CBEX {
  width: 16px;
  height: 16px;
  background-position: -464px -736px;
}

.CBG {
  width: 16px;
  height: 16px;
  background-position: -480px -736px;
}

.CBIX {
  width: 16px;
  height: 16px;
  background-position: -496px -736px;
}

.CBIX_P {
  width: 16px;
  height: 16px;
  background-position: -512px -736px;
}

.CBK {
  width: 16px;
  height: 16px;
  background-position: -528px -736px;
}

.CBLT {
  width: 16px;
  height: 16px;
  background-position: -544px -736px;
}

.CBM {
  width: 16px;
  height: 16px;
  background-position: -560px -736px;
}

.CBN {
  width: 16px;
  height: 16px;
  background-position: -576px -736px;
}

.CBOMBER {
  width: 16px;
  height: 16px;
  background-position: -592px -736px;
}

.CBP {
  width: 16px;
  height: 16px;
  background-position: -608px -736px;
}

.CBR {
  width: 16px;
  height: 16px;
  background-position: -624px -736px;
}

.CBRL {
  width: 16px;
  height: 16px;
  background-position: -640px -736px;
}

.CBS {
  width: 16px;
  height: 16px;
  background-position: -656px -736px;
}

.CBSN {
  width: 16px;
  height: 16px;
  background-position: -672px -736px;
}

.CBT {
  width: 16px;
  height: 16px;
  background-position: -688px -736px;
}

.CBU {
  width: 16px;
  height: 16px;
  background-position: -704px -736px;
}

.CBUCKS {
  width: 16px;
  height: 16px;
  background-position: -720px -736px;
}

.CBUNNY {
  width: 16px;
  height: 16px;
  background-position: -736px -736px;
}

.CBX {
  width: 16px;
  height: 16px;
  background-position: -752px -0;
}

.CC {
  width: 16px;
  height: 16px;
  background-position: -752px -16px;
}

.CC10 {
  width: 16px;
  height: 16px;
  background-position: -752px -32px;
}

.CCA {
  width: 16px;
  height: 16px;
  background-position: -752px -48px;
}

.CCAKE {
  width: 16px;
  height: 16px;
  background-position: -752px -64px;
}

.CCAR {
  width: 16px;
  height: 16px;
  background-position: -752px -80px;
}

.CCASH {
  width: 16px;
  height: 16px;
  background-position: -752px -96px;
}

.CCAT {
  width: 16px;
  height: 16px;
  background-position: -752px -112px;
}

.CCBCH {
  width: 16px;
  height: 16px;
  background-position: -752px -128px;
}

.CCC {
  width: 16px;
  height: 16px;
  background-position: -752px -144px;
}

.CCD {
  width: 16px;
  height: 16px;
  background-position: -752px -160px;
}

.CCDOGE {
  width: 16px;
  height: 16px;
  background-position: -752px -176px;
}

.CCE {
  width: 16px;
  height: 16px;
  background-position: -752px -192px;
}

.CCF {
  width: 16px;
  height: 16px;
  background-position: -752px -208px;
}

.CCG {
  width: 16px;
  height: 16px;
  background-position: -752px -224px;
}

.CCH {
  width: 16px;
  height: 16px;
  background-position: -752px -240px;
}

.CCL {
  width: 16px;
  height: 16px;
  background-position: -752px -256px;
}

.CCM {
  width: 16px;
  height: 16px;
  background-position: -752px -272px;
}

.CCN {
  width: 16px;
  height: 16px;
  background-position: -752px -288px;
}

.CCO {
  width: 16px;
  height: 16px;
  background-position: -752px -304px;
}

.CCOIN {
  width: 16px;
  height: 16px;
  background-position: -752px -320px;
}

.CCOM {
  width: 16px;
  height: 16px;
  background-position: -752px -336px;
}

.CCOMP {
  width: 16px;
  height: 16px;
  background-position: -752px -352px;
}

.CCP {
  width: 16px;
  height: 16px;
  background-position: -752px -368px;
}

.CCRB {
  width: 16px;
  height: 16px;
  background-position: -752px -384px;
}

.CCT {
  width: 16px;
  height: 16px;
  background-position: -752px -400px;
}

.CCURVE {
  width: 16px;
  height: 16px;
  background-position: -752px -416px;
}

.CCV2 {
  width: 16px;
  height: 16px;
  background-position: -752px -432px;
}

.CCX {
  width: 16px;
  height: 16px;
  background-position: -752px -448px;
}

.CCXX {
  width: 16px;
  height: 16px;
  background-position: -752px -464px;
}

.CCY {
  width: 16px;
  height: 16px;
  background-position: -752px -480px;
}

.CD {
  width: 16px;
  height: 16px;
  background-position: -752px -496px;
}

.CDAG {
  width: 16px;
  height: 16px;
  background-position: -752px -512px;
}

.CDAI {
  width: 16px;
  height: 16px;
  background-position: -752px -528px;
}

.CDASH {
  width: 16px;
  height: 16px;
  background-position: -752px -544px;
}

.CDB {
  width: 16px;
  height: 16px;
  background-position: -752px -560px;
}

.CDC {
  width: 16px;
  height: 16px;
  background-position: -752px -576px;
}

.CDDSP {
  width: 16px;
  height: 16px;
  background-position: -752px -592px;
}

.CDEX {
  width: 16px;
  height: 16px;
  background-position: -752px -608px;
}

.CDK {
  width: 16px;
  height: 16px;
  background-position: -752px -624px;
}

.CDL {
  width: 16px;
  height: 16px;
  background-position: -752px -640px;
}

.CDM {
  width: 16px;
  height: 16px;
  background-position: -752px -656px;
}

.CDONK {
  width: 16px;
  height: 16px;
  background-position: -752px -672px;
}

.CDS {
  width: 16px;
  height: 16px;
  background-position: -752px -688px;
}

.CDT {
  width: 16px;
  height: 16px;
  background-position: -752px -704px;
}

.CDTC {
  width: 16px;
  height: 16px;
  background-position: -752px -720px;
}

.CDX {
  width: 16px;
  height: 16px;
  background-position: -752px -736px;
}

.CDZ {
  width: 16px;
  height: 16px;
  background-position: -0 -752px;
}

.CDZC {
  width: 16px;
  height: 16px;
  background-position: -16px -752px;
}

.CE {
  width: 16px;
  height: 16px;
  background-position: -32px -752px;
}

.CEC_CEG {
  width: 16px;
  height: 16px;
  background-position: -48px -752px;
}

.CEDEX {
  width: 16px;
  height: 16px;
  background-position: -64px -752px;
}

.CEDS {
  width: 16px;
  height: 16px;
  background-position: -80px -752px;
}

.CEEK {
  width: 16px;
  height: 16px;
  background-position: -96px -752px;
}

.CEL {
  width: 16px;
  height: 16px;
  background-position: -112px -752px;
}

.CELC {
  width: 16px;
  height: 16px;
  background-position: -128px -752px;
}

.CELEB {
  width: 16px;
  height: 16px;
  background-position: -144px -752px;
}

.CELES {
  width: 16px;
  height: 16px;
  background-position: -160px -752px;
}

.CELF {
  width: 16px;
  height: 16px;
  background-position: -176px -752px;
}

.CELL {
  width: 16px;
  height: 16px;
  background-position: -192px -752px;
}

.CELO {
  width: 16px;
  height: 16px;
  background-position: -208px -752px;
}

.CELR {
  width: 16px;
  height: 16px;
  background-position: -224px -752px;
}

.CELT {
  width: 16px;
  height: 16px;
  background-position: -240px -752px;
}

.CEN {
  width: 16px;
  height: 16px;
  background-position: -256px -752px;
}

.CENNZ {
  width: 16px;
  height: 16px;
  background-position: -272px -752px;
}

.CENS {
  width: 16px;
  height: 16px;
  background-position: -288px -752px;
}

.CENT {
  width: 16px;
  height: 16px;
  background-position: -304px -752px;
}

.CENX {
  width: 16px;
  height: 16px;
  background-position: -320px -752px;
}

.CER {
  width: 16px;
  height: 16px;
  background-position: -336px -752px;
}

.CERBERUS {
  width: 16px;
  height: 16px;
  background-position: -352px -752px;
}

.CERBY {
  width: 16px;
  height: 16px;
  background-position: -368px -752px;
}

.CERE {
  width: 16px;
  height: 16px;
  background-position: -384px -752px;
}

.CERES {
  width: 16px;
  height: 16px;
  background-position: -400px -752px;
}

.CERT {
  width: 16px;
  height: 16px;
  background-position: -416px -752px;
}

.CET {
  width: 16px;
  height: 16px;
  background-position: -432px -752px;
}

.CETH {
  width: 16px;
  height: 16px;
  background-position: -448px -752px;
}

.CEUR {
  width: 16px;
  height: 16px;
  background-position: -464px -752px;
}

.CEV {
  width: 16px;
  height: 16px;
  background-position: -480px -752px;
}

.CEVO {
  width: 16px;
  height: 16px;
  background-position: -496px -752px;
}

.CEX {
  width: 16px;
  height: 16px;
  background-position: -512px -752px;
}

.CEXY {
  width: 16px;
  height: 16px;
  background-position: -528px -752px;
}

.CF {
  width: 16px;
  height: 16px;
  background-position: -544px -752px;
}

.CFA {
  width: 16px;
  height: 16px;
  background-position: -560px -752px;
}

.CFAR {
  width: 16px;
  height: 16px;
  background-position: -576px -752px;
}

.CFB {
  width: 16px;
  height: 16px;
  background-position: -592px -752px;
}

.CFC {
  width: 16px;
  height: 16px;
  background-position: -608px -752px;
}

.CFF {
  width: 16px;
  height: 16px;
  background-position: -624px -752px;
}

.CFG {
  width: 16px;
  height: 16px;
  background-position: -640px -752px;
}

.CFi {
  width: 16px;
  height: 16px;
  background-position: -656px -752px;
}

.CFL {
  width: 16px;
  height: 16px;
  background-position: -672px -752px;
}

.CFL365 {
  width: 16px;
  height: 16px;
  background-position: -688px -752px;
}

.CFLIX {
  width: 16px;
  height: 16px;
  background-position: -704px -752px;
}

.CFloki_X {
  width: 16px;
  height: 16px;
  background-position: -720px -752px;
}

.CFLT {
  width: 16px;
  height: 16px;
  background-position: -736px -752px;
}

.CFRESH {
  width: 16px;
  height: 16px;
  background-position: -752px -752px;
}

.CFS {
  width: 16px;
  height: 16px;
  background-position: -768px -0;
}

.CFT {
  width: 16px;
  height: 16px;
  background-position: -768px -16px;
}

.CFTI {
  width: 16px;
  height: 16px;
  background-position: -768px -32px;
}

.CFUN {
  width: 16px;
  height: 16px;
  background-position: -768px -48px;
}

.CFX {
  width: 16px;
  height: 16px;
  background-position: -768px -64px;
}

.CFXQ {
  width: 16px;
  height: 16px;
  background-position: -768px -80px;
}

.CFXT {
  width: 16px;
  height: 16px;
  background-position: -768px -96px;
}

.CFY {
  width: 16px;
  height: 16px;
  background-position: -768px -112px;
}

.CG {
  width: 16px;
  height: 16px;
  background-position: -768px -128px;
}

.CGANG {
  width: 16px;
  height: 16px;
  background-position: -768px -144px;
}

.CGAR {
  width: 16px;
  height: 16px;
  background-position: -768px -160px;
}

.CGAZ {
  width: 16px;
  height: 16px;
  background-position: -768px -176px;
}

.CGC {
  width: 16px;
  height: 16px;
  background-position: -768px -192px;
}

.CGEN {
  width: 16px;
  height: 16px;
  background-position: -768px -208px;
}

.CGG {
  width: 16px;
  height: 16px;
  background-position: -768px -224px;
}

.CGO {
  width: 16px;
  height: 16px;
  background-position: -768px -240px;
}

.CGOLD {
  width: 16px;
  height: 16px;
  background-position: -768px -256px;
}

.CRIME {
  width: 16px;
  height: 16px;
  background-position: -768px -272px;
}

.CGRESS {
  width: 16px;
  height: 16px;
  background-position: -768px -288px;
}

.CGS {
  width: 16px;
  height: 16px;
  background-position: -768px -304px;
}

.CGT {
  width: 16px;
  height: 16px;
  background-position: -768px -320px;
}

.CGU {
  width: 16px;
  height: 16px;
  background-position: -768px -336px;
}

.CGX {
  width: 16px;
  height: 16px;
  background-position: -768px -352px;
}

.CHA {
  width: 16px;
  height: 16px;
  background-position: -768px -368px;
}

.CHAD {
  width: 16px;
  height: 16px;
  background-position: -768px -384px;
}

.CHADLINK {
  width: 16px;
  height: 16px;
  background-position: -768px -400px;
}

.CHADS {
  width: 16px;
  height: 16px;
  background-position: -768px -416px;
}

.CHAIN {
  width: 16px;
  height: 16px;
  background-position: -768px -432px;
}

.CHAINCADE {
  width: 16px;
  height: 16px;
  background-position: -768px -448px;
}

.CHAKRA {
  width: 16px;
  height: 16px;
  background-position: -768px -464px;
}

.CHAL {
  width: 16px;
  height: 16px;
  background-position: -768px -480px;
}

.CHAMP {
  width: 16px;
  height: 16px;
  background-position: -768px -496px;
}

.CHAMPINU {
  width: 16px;
  height: 16px;
  background-position: -768px -512px;
}

.CHANE {
  width: 16px;
  height: 16px;
  background-position: -768px -528px;
}

.CHANGE {
  width: 16px;
  height: 16px;
  background-position: -768px -544px;
}

.CHAOS {
  width: 16px;
  height: 16px;
  background-position: -768px -560px;
}

.CHAR {
  width: 16px;
  height: 16px;
  background-position: -768px -576px;
}

.CHARGE {
  width: 16px;
  height: 16px;
  background-position: -768px -592px;
}

.CHARGED {
  width: 16px;
  height: 16px;
  background-position: -768px -608px;
}

.CHARIX_TOKEN {
  width: 16px;
  height: 16px;
  background-position: -768px -624px;
}

.CHARIZARD {
  width: 16px;
  height: 16px;
  background-position: -768px -640px;
}

.CHARM {
  width: 16px;
  height: 16px;
  background-position: -768px -656px;
}

.CHART {
  width: 16px;
  height: 16px;
  background-position: -768px -672px;
}

.CHAT {
  width: 16px;
  height: 16px;
  background-position: -768px -688px;
}

.CHB {
  width: 16px;
  height: 16px;
  background-position: -768px -704px;
}

.CHBT {
  width: 16px;
  height: 16px;
  background-position: -768px -720px;
}

.CHC {
  width: 16px;
  height: 16px;
  background-position: -768px -736px;
}

.CHD {
  width: 16px;
  height: 16px;
  background-position: -768px -752px;
}

.CHE {
  width: 16px;
  height: 16px;
  background-position: -0 -768px;
}

.CHECK {
  width: 16px;
  height: 16px;
  background-position: -16px -768px;
}

.CHECKR {
  width: 16px;
  height: 16px;
  background-position: -32px -768px;
}

.CHECOIN {
  width: 16px;
  height: 16px;
  background-position: -48px -768px;
}

.CHEDDA {
  width: 16px;
  height: 16px;
  background-position: -64px -768px;
}

.CHEE {
  width: 16px;
  height: 16px;
  background-position: -80px -768px;
}

.CHEEMS {
  width: 16px;
  height: 16px;
  background-position: -96px -768px;
}

.CHEEMSBILLIONAIRE {
  width: 16px;
  height: 16px;
  background-position: -112px -768px;
}

.CHEERS {
  width: 16px;
  height: 16px;
  background-position: -128px -768px;
}

.CHEESE {
  width: 16px;
  height: 16px;
  background-position: -144px -768px;
}

.CHEESUS {
  width: 16px;
  height: 16px;
  background-position: -160px -768px;
}

.CHEEZ {
  width: 16px;
  height: 16px;
  background-position: -176px -768px;
}

.CHEFCAKE {
  width: 16px;
  height: 16px;
  background-position: -192px -768px;
}

.CHEQ {
  width: 16px;
  height: 16px;
  background-position: -208px -768px;
}

.CHER {
  width: 16px;
  height: 16px;
  background-position: -224px -768px;
}

.CHERRY {
  width: 16px;
  height: 16px;
  background-position: -240px -768px;
}

.CHES {
  width: 16px;
  height: 16px;
  background-position: -256px -768px;
}

.CHESS {
  width: 16px;
  height: 16px;
  background-position: -272px -768px;
}

.CHEX {
  width: 16px;
  height: 16px;
  background-position: -288px -768px;
}

.CHFT {
  width: 16px;
  height: 16px;
  background-position: -304px -768px;
}

.CHFU {
  width: 16px;
  height: 16px;
  background-position: -320px -768px;
}

.CHFX {
  width: 16px;
  height: 16px;
  background-position: -336px -768px;
}

.CHG {
  width: 16px;
  height: 16px;
  background-position: -352px -768px;
}

.CHH {
  width: 16px;
  height: 16px;
  background-position: -368px -768px;
}

.CHI {
  width: 16px;
  height: 16px;
  background-position: -384px -768px;
}

.CHIBA {
  width: 16px;
  height: 16px;
  background-position: -400px -768px;
}

.CHIBI {
  width: 16px;
  height: 16px;
  background-position: -416px -768px;
}

.CHIBIKI {
  width: 16px;
  height: 16px;
  background-position: -432px -768px;
}

.CHICK {
  width: 16px;
  height: 16px;
  background-position: -448px -768px;
}

.CHICKS {
  width: 16px;
  height: 16px;
  background-position: -464px -768px;
}

.CHIH {
  width: 16px;
  height: 16px;
  background-position: -480px -768px;
}

.CHIHUA {
  width: 16px;
  height: 16px;
  background-position: -496px -768px;
}

.CHIK {
  width: 16px;
  height: 16px;
  background-position: -512px -768px;
}

.CHIKA {
  width: 16px;
  height: 16px;
  background-position: -528px -768px;
}

.CHIKUINU {
  width: 16px;
  height: 16px;
  background-position: -544px -768px;
}

.CHIM {
  width: 16px;
  height: 16px;
  background-position: -560px -768px;
}

.CHIMOM {
  width: 16px;
  height: 16px;
  background-position: -576px -768px;
}

.CHIMPY {
  width: 16px;
  height: 16px;
  background-position: -592px -768px;
}

.CHINAZILLA {
  width: 16px;
  height: 16px;
  background-position: -608px -768px;
}

.CHINU {
  width: 16px;
  height: 16px;
  background-position: -624px -768px;
}

.CHIP {
  width: 16px;
  height: 16px;
  background-position: -640px -768px;
}

.CHIPS {
  width: 16px;
  height: 16px;
  background-position: -656px -768px;
}

.CHIRO {
  width: 16px;
  height: 16px;
  background-position: -672px -768px;
}

.CHIRP {
  width: 16px;
  height: 16px;
  background-position: -688px -768px;
}

.CHIV {
  width: 16px;
  height: 16px;
  background-position: -704px -768px;
}

.CHIWA {
  width: 16px;
  height: 16px;
  background-position: -720px -768px;
}

.CHIZ {
  width: 16px;
  height: 16px;
  background-position: -736px -768px;
}

.CHKN {
  width: 16px;
  height: 16px;
  background-position: -752px -768px;
}

.CHL {
  width: 16px;
  height: 16px;
  background-position: -768px -768px;
}

.CHLI {
  width: 16px;
  height: 16px;
  background-position: -784px -0;
}

.CHLT {
  width: 16px;
  height: 16px;
  background-position: -784px -16px;
}

.CHM {
  width: 16px;
  height: 16px;
  background-position: -784px -32px;
}

.CHMB {
  width: 16px;
  height: 16px;
  background-position: -784px -48px;
}

.CHMP {
  width: 16px;
  height: 16px;
  background-position: -784px -64px;
}

.CHN {
  width: 16px;
  height: 16px;
  background-position: -784px -80px;
}

.CHND {
  width: 16px;
  height: 16px;
  background-position: -784px -96px;
}

.CHNG {
  width: 16px;
  height: 16px;
  background-position: -784px -112px;
}

.CHO {
  width: 16px;
  height: 16px;
  background-position: -784px -128px;
}

.CHOICE {
  width: 16px;
  height: 16px;
  background-position: -784px -144px;
}

.CHONK {
  width: 16px;
  height: 16px;
  background-position: -784px -160px;
}

.CHOP {
  width: 16px;
  height: 16px;
  background-position: -784px -176px;
}

.CHOPPER {
  width: 16px;
  height: 16px;
  background-position: -784px -192px;
}

.CHORD {
  width: 16px;
  height: 16px;
  background-position: -784px -208px;
}

.CHORSE {
  width: 16px;
  height: 16px;
  background-position: -784px -224px;
}

.CHOW {
  width: 16px;
  height: 16px;
  background-position: -784px -240px;
}

.CHP {
  width: 16px;
  height: 16px;
  background-position: -784px -256px;
}

.CHPZ {
  width: 16px;
  height: 16px;
  background-position: -784px -272px;
}

.CHR {
  width: 16px;
  height: 16px;
  background-position: -784px -288px;
}

.CHRONO {
  width: 16px;
  height: 16px;
  background-position: -784px -304px;
}

.CHS {
  width: 16px;
  height: 16px;
  background-position: -784px -320px;
}

.CHSB {
  width: 16px;
  height: 16px;
  background-position: -784px -336px;
}

.CHT {
  width: 16px;
  height: 16px;
  background-position: -784px -352px;
}

.CHTRv2 {
  width: 16px;
  height: 16px;
  background-position: -784px -368px;
}

.CHTS {
  width: 16px;
  height: 16px;
  background-position: -784px -384px;
}

.CHTT {
  width: 16px;
  height: 16px;
  background-position: -784px -400px;
}

.CHTX {
  width: 16px;
  height: 16px;
  background-position: -784px -416px;
}

.CHUBS {
  width: 16px;
  height: 16px;
  background-position: -784px -432px;
}

.CHUM {
  width: 16px;
  height: 16px;
  background-position: -784px -448px;
}

.CHURCH {
  width: 16px;
  height: 16px;
  background-position: -784px -464px;
}

.CHX {
  width: 16px;
  height: 16px;
  background-position: -784px -480px;
}

.CHY {
  width: 16px;
  height: 16px;
  background-position: -784px -496px;
}

.CHZ {
  width: 16px;
  height: 16px;
  background-position: -784px -512px;
}

.CHZ006 {
  width: 16px;
  height: 16px;
  background-position: -784px -528px;
}

.CI {
  width: 16px;
  height: 16px;
  background-position: -784px -544px;
}

.CIA {
  width: 16px;
  height: 16px;
  background-position: -784px -560px;
}

.CIC {
  width: 16px;
  height: 16px;
  background-position: -784px -576px;
}

.CICX {
  width: 16px;
  height: 16px;
  background-position: -784px -592px;
}

.CID {
  width: 16px;
  height: 16px;
  background-position: -784px -608px;
}

.CIF {
  width: 16px;
  height: 16px;
  background-position: -784px -624px;
}

.CIFI {
  width: 16px;
  height: 16px;
  background-position: -784px -640px;
}

.CIND {
  width: 16px;
  height: 16px;
  background-position: -784px -656px;
}

.CINO {
  width: 16px;
  height: 16px;
  background-position: -784px -672px;
}

.CINU {
  width: 16px;
  height: 16px;
  background-position: -784px -688px;
}

.CIOTX {
  width: 16px;
  height: 16px;
  background-position: -784px -704px;
}

.CIPHC {
  width: 16px;
  height: 16px;
  background-position: -784px -720px;
}

.CIPX {
  width: 16px;
  height: 16px;
  background-position: -784px -736px;
}

.CIR {
  width: 16px;
  height: 16px;
  background-position: -784px -752px;
}

.CIRQ {
  width: 16px;
  height: 16px;
  background-position: -784px -768px;
}

.CIRRUS {
  width: 16px;
  height: 16px;
  background-position: -0 -784px;
}

.CIRUS {
  width: 16px;
  height: 16px;
  background-position: -16px -784px;
}

.CISLA {
  width: 16px;
  height: 16px;
  background-position: -32px -784px;
}

.CIT {
  width: 16px;
  height: 16px;
  background-position: -48px -784px;
}

.CITIZEN {
  width: 16px;
  height: 16px;
  background-position: -64px -784px;
}

.CITY {
  width: 16px;
  height: 16px;
  background-position: -80px -784px;
}

.CIV {
  width: 16px;
  height: 16px;
  background-position: -96px -784px;
}

.CIX100 {
  width: 16px;
  height: 16px;
  background-position: -112px -784px;
}

.CJ {
  width: 16px;
  height: 16px;
  background-position: -128px -784px;
}

.CJET {
  width: 16px;
  height: 16px;
  background-position: -144px -784px;
}

.CJP {
  width: 16px;
  height: 16px;
  background-position: -160px -784px;
}

.CJT {
  width: 16px;
  height: 16px;
  background-position: -176px -784px;
}

.CKB {
  width: 16px;
  height: 16px;
  background-position: -192px -784px;
}

.CKC {
  width: 16px;
  height: 16px;
  background-position: -208px -784px;
}

.CKEY {
  width: 16px;
  height: 16px;
  background-position: -224px -784px;
}

.CKG {
  width: 16px;
  height: 16px;
  background-position: -240px -784px;
}

.CKP {
  width: 16px;
  height: 16px;
  background-position: -256px -784px;
}

.CKT {
  width: 16px;
  height: 16px;
  background-position: -272px -784px;
}

.CKUSD {
  width: 16px;
  height: 16px;
  background-position: -288px -784px;
}

.CL {
  width: 16px;
  height: 16px;
  background-position: -304px -784px;
}

.cLA {
  width: 16px;
  height: 16px;
  background-position: -320px -784px;
}

.CLA {
  width: 16px;
  height: 16px;
  background-position: -336px -784px;
}

.CLAIM {
  width: 16px;
  height: 16px;
  background-position: -352px -784px;
}

.CLAM {
  width: 16px;
  height: 16px;
  background-position: -368px -784px;
}

.CLAP {
  width: 16px;
  height: 16px;
  background-position: -384px -784px;
}

.CLASH {
  width: 16px;
  height: 16px;
  background-position: -400px -784px;
}

.CLASS {
  width: 16px;
  height: 16px;
  background-position: -416px -784px;
}

.CLAVIS {
  width: 16px;
  height: 16px;
  background-position: -432px -784px;
}

.CLAW {
  width: 16px;
  height: 16px;
  background-position: -448px -784px;
}

.CLB {
  width: 16px;
  height: 16px;
  background-position: -464px -784px;
}

.CLBK {
  width: 16px;
  height: 16px;
  background-position: -480px -784px;
}

.CLBR {
  width: 16px;
  height: 16px;
  background-position: -496px -784px;
}

.CLC {
  width: 16px;
  height: 16px;
  background-position: -512px -784px;
}

.CLCT {
  width: 16px;
  height: 16px;
  background-position: -528px -784px;
}

.CLD {
  width: 16px;
  height: 16px;
  background-position: -544px -784px;
}

.CLEAN {
  width: 16px;
  height: 16px;
  background-position: -560px -784px;
}

.CLEAR {
  width: 16px;
  height: 16px;
  background-position: -576px -784px;
}

.CLEG {
  width: 16px;
  height: 16px;
  background-position: -592px -784px;
}

.CLEX {
  width: 16px;
  height: 16px;
  background-position: -608px -784px;
}

.CLG {
  width: 16px;
  height: 16px;
  background-position: -624px -784px;
}

.CLH {
  width: 16px;
  height: 16px;
  background-position: -640px -784px;
}

.CLIFF {
  width: 16px;
  height: 16px;
  background-position: -656px -784px;
}

.CLIMB {
  width: 16px;
  height: 16px;
  background-position: -672px -784px;
}

.CLION {
  width: 16px;
  height: 16px;
  background-position: -688px -784px;
}

.CLIQ {
  width: 16px;
  height: 16px;
  background-position: -704px -784px;
}

.CLIST {
  width: 16px;
  height: 16px;
  background-position: -720px -784px;
}

.CLIT\$ {
  width: 16px;
  height: 16px;
  background-position: -736px -784px;
}

.CLL {
  width: 16px;
  height: 16px;
  background-position: -752px -784px;
}

.CLM {
  width: 16px;
  height: 16px;
  background-position: -768px -784px;
}

.CLN {
  width: 16px;
  height: 16px;
  background-position: -784px -784px;
}

.CLNY {
  width: 16px;
  height: 16px;
  background-position: -800px -0;
}

.CLO {
  width: 16px;
  height: 16px;
  background-position: -800px -16px;
}

.CLOAK {
  width: 16px;
  height: 16px;
  background-position: -800px -32px;
}

.CLONEX {
  width: 16px;
  height: 16px;
  background-position: -800px -48px;
}

.CLOUD {
  width: 16px;
  height: 16px;
  background-position: -800px -64px;
}

.CLOUD9 {
  width: 16px;
  height: 16px;
  background-position: -800px -80px;
}

.CLOUT {
  width: 16px;
  height: 16px;
  background-position: -800px -96px;
}

.CLR {
  width: 16px;
  height: 16px;
  background-position: -800px -112px;
}

.CLS {
  width: 16px;
  height: 16px;
  background-position: -800px -128px;
}

.CLSL {
  width: 16px;
  height: 16px;
  background-position: -800px -144px;
}

.CLT {
  width: 16px;
  height: 16px;
  background-position: -800px -160px;
}

.CLU {
  width: 16px;
  height: 16px;
  background-position: -800px -176px;
}

.CLUB {
  width: 16px;
  height: 16px;
  background-position: -800px -192px;
}

.CLUNA {
  width: 16px;
  height: 16px;
  background-position: -800px -208px;
}

.CLV {
  width: 16px;
  height: 16px;
  background-position: -800px -224px;
}

.CLVX {
  width: 16px;
  height: 16px;
  background-position: -800px -240px;
}

.CLX {
  width: 16px;
  height: 16px;
  background-position: -800px -256px;
}

.CLXY {
  width: 16px;
  height: 16px;
  background-position: -800px -272px;
}

.CLY {
  width: 16px;
  height: 16px;
  background-position: -800px -288px;
}

.CM {
  width: 16px;
  height: 16px;
  background-position: -800px -304px;
}

.CMA {
  width: 16px;
  height: 16px;
  background-position: -800px -320px;
}

.CMALL {
  width: 16px;
  height: 16px;
  background-position: -800px -336px;
}

.CMB {
  width: 16px;
  height: 16px;
  background-position: -800px -352px;
}

.CMC {
  width: 16px;
  height: 16px;
  background-position: -800px -368px;
}

.CMCT {
  width: 16px;
  height: 16px;
  background-position: -800px -384px;
}

.CMCX {
  width: 16px;
  height: 16px;
  background-position: -800px -400px;
}

.CMD {
  width: 16px;
  height: 16px;
  background-position: -800px -416px;
}

.CMDX {
  width: 16px;
  height: 16px;
  background-position: -800px -432px;
}

.CMERGE {
  width: 16px;
  height: 16px;
  background-position: -800px -448px;
}

.CMETA {
  width: 16px;
  height: 16px;
  background-position: -800px -464px;
}

.CMF {
  width: 16px;
  height: 16px;
  background-position: -800px -480px;
}

.CMFI {
  width: 16px;
  height: 16px;
  background-position: -800px -496px;
}

.CMI {
  width: 16px;
  height: 16px;
  background-position: -800px -512px;
}

.CMID {
  width: 16px;
  height: 16px;
  background-position: -800px -528px;
}

.CMIT {
  width: 16px;
  height: 16px;
  background-position: -800px -544px;
}

.CMK {
  width: 16px;
  height: 16px;
  background-position: -800px -560px;
}

.CMLCOIN {
  width: 16px;
  height: 16px;
  background-position: -800px -576px;
}

.CMLT {
  width: 16px;
  height: 16px;
  background-position: -800px -592px;
}

.CMM {
  width: 16px;
  height: 16px;
  background-position: -800px -608px;
}

.CMN {
  width: 16px;
  height: 16px;
  background-position: -800px -624px;
}

.CMP {
  width: 16px;
  height: 16px;
  background-position: -800px -640px;
}

.CMS {
  width: 16px;
  height: 16px;
  background-position: -800px -656px;
}

.CMSN {
  width: 16px;
  height: 16px;
  background-position: -800px -672px;
}

.CMT {
  width: 16px;
  height: 16px;
  background-position: -800px -688px;
}

.CMTR {
  width: 16px;
  height: 16px;
  background-position: -800px -704px;
}

.CMX {
  width: 16px;
  height: 16px;
  background-position: -800px -720px;
}

.CNAME {
  width: 16px;
  height: 16px;
  background-position: -800px -736px;
}

.CNB {
  width: 16px;
  height: 16px;
  background-position: -800px -752px;
}

.CNC {
  width: 16px;
  height: 16px;
  background-position: -800px -768px;
}

.CNCT {
  width: 16px;
  height: 16px;
  background-position: -800px -784px;
}

.CND {
  width: 16px;
  height: 16px;
  background-position: -0 -800px;
}

.CNDL {
  width: 16px;
  height: 16px;
  background-position: -16px -800px;
}

.CNET {
  width: 16px;
  height: 16px;
  background-position: -32px -800px;
}

.cNETA {
  width: 16px;
  height: 16px;
  background-position: -48px -800px;
}

.CNF {
  width: 16px;
  height: 16px;
  background-position: -64px -800px;
}

.CNFI {
  width: 16px;
  height: 16px;
  background-position: -80px -800px;
}

.CNFT {
  width: 16px;
  height: 16px;
  background-position: -96px -800px;
}

.CNG {
  width: 16px;
  height: 16px;
  background-position: -112px -800px;
}

.CNHT {
  width: 16px;
  height: 16px;
  background-position: -128px -800px;
}

.CNJ {
  width: 16px;
  height: 16px;
  background-position: -144px -800px;
}

.CNMC {
  width: 16px;
  height: 16px;
  background-position: -160px -800px;
}

.CNN {
  width: 16px;
  height: 16px;
  background-position: -176px -800px;
}

.CNNC {
  width: 16px;
  height: 16px;
  background-position: -192px -800px;
}

.CNNS {
  width: 16px;
  height: 16px;
  background-position: -208px -800px;
}

.CNOW {
  width: 16px;
  height: 16px;
  background-position: -224px -800px;
}

.CNR {
  width: 16px;
  height: 16px;
  background-position: -240px -800px;
}

.CNRG {
  width: 16px;
  height: 16px;
  background-position: -256px -800px;
}

.CNS {
  width: 16px;
  height: 16px;
  background-position: -272px -800px;
}

.CNT {
  width: 16px;
  height: 16px;
  background-position: -288px -800px;
}

.CNTM {
  width: 16px;
  height: 16px;
  background-position: -304px -800px;
}

.CNTR {
  width: 16px;
  height: 16px;
  background-position: -320px -800px;
}

.CNTX {
  width: 16px;
  height: 16px;
  background-position: -336px -800px;
}

.CNUS {
  width: 16px;
  height: 16px;
  background-position: -352px -800px;
}

.CNV {
  width: 16px;
  height: 16px;
  background-position: -368px -800px;
}

.CNX {
  width: 16px;
  height: 16px;
  background-position: -384px -800px;
}

.CNYT {
  width: 16px;
  height: 16px;
  background-position: -400px -800px;
}

.CNYX {
  width: 16px;
  height: 16px;
  background-position: -416px -800px;
}

.CNZ {
  width: 16px;
  height: 16px;
  background-position: -432px -800px;
}

.CO {
  width: 16px;
  height: 16px;
  background-position: -448px -800px;
}

.CO2 {
  width: 16px;
  height: 16px;
  background-position: -464px -800px;
}

.CO2B {
  width: 16px;
  height: 16px;
  background-position: -480px -800px;
}

.COA {
  width: 16px;
  height: 16px;
  background-position: -496px -800px;
}

.COAL {
  width: 16px;
  height: 16px;
  background-position: -512px -800px;
}

.COAPE {
  width: 16px;
  height: 16px;
  background-position: -528px -800px;
}

.COB {
  width: 16px;
  height: 16px;
  background-position: -544px -800px;
}

.COBAN {
  width: 16px;
  height: 16px;
  background-position: -560px -800px;
}

.COBRA {
  width: 16px;
  height: 16px;
  background-position: -576px -800px;
}

.COC {
  width: 16px;
  height: 16px;
  background-position: -592px -800px;
}

.COCK {
  width: 16px;
  height: 16px;
  background-position: -608px -800px;
}

.COCKTAIL {
  width: 16px;
  height: 16px;
  background-position: -624px -800px;
}

.COCO {
  width: 16px;
  height: 16px;
  background-position: -640px -800px;
}

.COCOS {
  width: 16px;
  height: 16px;
  background-position: -656px -800px;
}

.COD {
  width: 16px;
  height: 16px;
  background-position: -672px -800px;
}

.CODE7 {
  width: 16px;
  height: 16px;
  background-position: -688px -800px;
}

.CODEO {
  width: 16px;
  height: 16px;
  background-position: -704px -800px;
}

.CODEX {
  width: 16px;
  height: 16px;
  background-position: -720px -800px;
}

.CODI {
  width: 16px;
  height: 16px;
  background-position: -736px -800px;
}

.CODY {
  width: 16px;
  height: 16px;
  background-position: -752px -800px;
}

.COFFEE {
  width: 16px;
  height: 16px;
  background-position: -768px -800px;
}

.COFFIN {
  width: 16px;
  height: 16px;
  background-position: -784px -800px;
}

.COFI {
  width: 16px;
  height: 16px;
  background-position: -800px -800px;
}

.COFLOKI {
  width: 16px;
  height: 16px;
  background-position: -816px -0;
}

.COGE {
  width: 16px;
  height: 16px;
  background-position: -816px -16px;
}

.COGI {
  width: 16px;
  height: 16px;
  background-position: -816px -32px;
}

.COIIN {
  width: 16px;
  height: 16px;
  background-position: -816px -48px;
}

.COIL {
  width: 16px;
  height: 16px;
  background-position: -816px -64px;
}

.COIN {
  width: 16px;
  height: 16px;
  background-position: -816px -80px;
}

.COINS {
  width: 16px;
  height: 16px;
  background-position: -816px -96px;
}

.COINSCOPE {
  width: 16px;
  height: 16px;
  background-position: -816px -112px;
}

.COKE {
  width: 16px;
  height: 16px;
  background-position: -816px -128px;
}

.COL {
  width: 16px;
  height: 16px;
  background-position: -816px -144px;
}

.COLA {
  width: 16px;
  height: 16px;
  background-position: -816px -160px;
}

.COLD {
  width: 16px;
  height: 16px;
  background-position: -816px -176px;
}

.COLDKOALA {
  width: 16px;
  height: 16px;
  background-position: -816px -192px;
}

.COLI {
  width: 16px;
  height: 16px;
  background-position: -816px -208px;
}

.COLL {
  width: 16px;
  height: 16px;
  background-position: -816px -224px;
}

.COLLAR {
  width: 16px;
  height: 16px;
  background-position: -816px -240px;
}

.COLLG {
  width: 16px;
  height: 16px;
  background-position: -816px -256px;
}

.COLON {
  width: 16px;
  height: 16px;
  background-position: -816px -272px;
}

.COLOS {
  width: 16px;
  height: 16px;
  background-position: -816px -288px;
}

.COLX {
  width: 16px;
  height: 16px;
  background-position: -816px -304px;
}

.COM {
  width: 16px;
  height: 16px;
  background-position: -816px -320px;
}

.COMB {
  width: 16px;
  height: 16px;
  background-position: -816px -336px;
}

.COMBO {
  width: 16px;
  height: 16px;
  background-position: -816px -352px;
}

.COMET {
  width: 16px;
  height: 16px;
  background-position: -816px -368px;
}

.COMFI {
  width: 16px;
  height: 16px;
  background-position: -816px -384px;
}

.COMFY {
  width: 16px;
  height: 16px;
  background-position: -816px -400px;
}

.COMIC {
  width: 16px;
  height: 16px;
  background-position: -816px -416px;
}

.COMOS {
  width: 16px;
  height: 16px;
  background-position: -816px -432px;
}

.COMP {
  width: 16px;
  height: 16px;
  background-position: -816px -448px;
}

.COMT {
  width: 16px;
  height: 16px;
  background-position: -816px -464px;
}

.CON {
  width: 16px;
  height: 16px;
  background-position: -816px -480px;
}

.CONC {
  width: 16px;
  height: 16px;
  background-position: -816px -496px;
}

.CONDOMS {
  width: 16px;
  height: 16px;
  background-position: -816px -512px;
}

.CONE {
  width: 16px;
  height: 16px;
  background-position: -816px -528px;
}

.CONEGAME {
  width: 16px;
  height: 16px;
  background-position: -816px -544px;
}

.CONI {
  width: 16px;
  height: 16px;
  background-position: -816px -560px;
}

.CONJ {
  width: 16px;
  height: 16px;
  background-position: -816px -576px;
}

.CONQ {
  width: 16px;
  height: 16px;
  background-position: -816px -592px;
}

.CONST {
  width: 16px;
  height: 16px;
  background-position: -816px -608px;
}

.CONT {
  width: 16px;
  height: 16px;
  background-position: -816px -624px;
}

.CONV {
  width: 16px;
  height: 16px;
  background-position: -816px -640px;
}

.CONX {
  width: 16px;
  height: 16px;
  background-position: -816px -656px;
}

.COO {
  width: 16px;
  height: 16px;
  background-position: -816px -672px;
}

.COOGA {
  width: 16px;
  height: 16px;
  background-position: -816px -688px;
}

.COOHA {
  width: 16px;
  height: 16px;
  background-position: -816px -704px;
}

.COOK {
  width: 16px;
  height: 16px;
  background-position: -816px -720px;
}

.COOM {
  width: 16px;
  height: 16px;
  background-position: -816px -736px;
}

.COP {
  width: 16px;
  height: 16px;
  background-position: -816px -752px;
}

.COPE {
  width: 16px;
  height: 16px;
  background-position: -816px -768px;
}

.COPI {
  width: 16px;
  height: 16px;
  background-position: -816px -784px;
}

.COPPER {
  width: 16px;
  height: 16px;
  background-position: -816px -800px;
}

.COPS {
  width: 16px;
  height: 16px;
  background-position: -0 -816px;
}

.COPYCAT {
  width: 16px;
  height: 16px;
  background-position: -16px -816px;
}

.COR {
  width: 16px;
  height: 16px;
  background-position: -32px -816px;
}

.CORA {
  width: 16px;
  height: 16px;
  background-position: -48px -816px;
}

.CORAL {
  width: 16px;
  height: 16px;
  background-position: -64px -816px;
}

.CORD {
  width: 16px;
  height: 16px;
  background-position: -80px -816px;
}

.CORE {
  width: 16px;
  height: 16px;
  background-position: -96px -816px;
}

.COREDAO {
  width: 16px;
  height: 16px;
  background-position: -112px -816px;
}

.CORGI {
  width: 16px;
  height: 16px;
  background-position: -128px -816px;
}

.CORGIB {
  width: 16px;
  height: 16px;
  background-position: -144px -816px;
}

.CORIS {
  width: 16px;
  height: 16px;
  background-position: -160px -816px;
}

.CORK {
  width: 16px;
  height: 16px;
  background-position: -176px -816px;
}

.CORN {
  width: 16px;
  height: 16px;
  background-position: -192px -816px;
}

.CORNX {
  width: 16px;
  height: 16px;
  background-position: -208px -816px;
}

.CORSACV2 {
  width: 16px;
  height: 16px;
  background-position: -224px -816px;
}

.CORX {
  width: 16px;
  height: 16px;
  background-position: -240px -816px;
}

.COS {
  width: 16px;
  height: 16px;
  background-position: -256px -816px;
}

.COSBY {
  width: 16px;
  height: 16px;
  background-position: -272px -816px;
}

.COSG {
  width: 16px;
  height: 16px;
  background-position: -288px -816px;
}

.COSHI {
  width: 16px;
  height: 16px;
  background-position: -304px -816px;
}

.COSM {
  width: 16px;
  height: 16px;
  background-position: -320px -816px;
}

.COSMIC {
  width: 16px;
  height: 16px;
  background-position: -336px -816px;
}

.COSMIK {
  width: 16px;
  height: 16px;
  background-position: -352px -816px;
}

.COT {
  width: 16px;
  height: 16px;
  background-position: -368px -816px;
}

.COTI {
  width: 16px;
  height: 16px;
  background-position: -384px -816px;
}

.COTK {
  width: 16px;
  height: 16px;
  background-position: -400px -816px;
}

.COTN {
  width: 16px;
  height: 16px;
  background-position: -416px -816px;
}

.COU {
  width: 16px;
  height: 16px;
  background-position: -432px -816px;
}

.COUNTY {
  width: 16px;
  height: 16px;
  background-position: -448px -816px;
}

.COURSE {
  width: 16px;
  height: 16px;
  background-position: -464px -816px;
}

.COUSD {
  width: 16px;
  height: 16px;
  background-position: -480px -816px;
}

.COUSINDOGE {
  width: 16px;
  height: 16px;
  background-position: -496px -816px;
}

.COV {
  width: 16px;
  height: 16px;
  background-position: -512px -816px;
}

.COVA {
  width: 16px;
  height: 16px;
  background-position: -528px -816px;
}

.COVAL {
  width: 16px;
  height: 16px;
  background-position: -544px -816px;
}

.COVDR {
  width: 16px;
  height: 16px;
  background-position: -560px -816px;
}

.COVER {
  width: 16px;
  height: 16px;
  background-position: -576px -816px;
}

.COVID19 {
  width: 16px;
  height: 16px;
  background-position: -592px -816px;
}

.COVID_DOGE {
  width: 16px;
  height: 16px;
  background-position: -608px -816px;
}

.COVIDTOKEN {
  width: 16px;
  height: 16px;
  background-position: -624px -816px;
}

.COVN {
  width: 16px;
  height: 16px;
  background-position: -640px -816px;
}

.COW {
  width: 16px;
  height: 16px;
  background-position: -656px -816px;
}

.COWS {
  width: 16px;
  height: 16px;
  background-position: -672px -816px;
}

.COX {
  width: 16px;
  height: 16px;
  background-position: -688px -816px;
}

.COZOM {
  width: 16px;
  height: 16px;
  background-position: -704px -816px;
}

.COZP {
  width: 16px;
  height: 16px;
  background-position: -720px -816px;
}

.CP {
  width: 16px;
  height: 16px;
  background-position: -736px -816px;
}

.CP3R {
  width: 16px;
  height: 16px;
  background-position: -752px -816px;
}

.CPAC {
  width: 16px;
  height: 16px;
  background-position: -768px -816px;
}

.CPAD {
  width: 16px;
  height: 16px;
  background-position: -784px -816px;
}

.CPAN {
  width: 16px;
  height: 16px;
  background-position: -800px -816px;
}

.CPAY {
  width: 16px;
  height: 16px;
  background-position: -816px -816px;
}

.CPC {
  width: 16px;
  height: 16px;
  background-position: -832px -0;
}

.CPD {
  width: 16px;
  height: 16px;
  background-position: -832px -16px;
}

.CPE {
  width: 16px;
  height: 16px;
  background-position: -832px -32px;
}

.CPET {
  width: 16px;
  height: 16px;
  background-position: -832px -48px;
}

.CPH {
  width: 16px;
  height: 16px;
  background-position: -832px -64px;
}

.CPHR {
  width: 16px;
  height: 16px;
  background-position: -832px -80px;
}

.CPHX {
  width: 16px;
  height: 16px;
  background-position: -832px -96px;
}

.CPI {
  width: 16px;
  height: 16px;
  background-position: -832px -112px;
}

.CPIE {
  width: 16px;
  height: 16px;
  background-position: -832px -128px;
}

.CPL {
  width: 16px;
  height: 16px;
  background-position: -832px -144px;
}

.CPLAY {
  width: 16px;
  height: 16px;
  background-position: -832px -160px;
}

.CPMT {
  width: 16px;
  height: 16px;
  background-position: -832px -176px;
}

.CPN {
  width: 16px;
  height: 16px;
  background-position: -832px -192px;
}

.CPO {
  width: 16px;
  height: 16px;
  background-position: -832px -208px;
}

.CPOO {
  width: 16px;
  height: 16px;
  background-position: -832px -224px;
}

.CPOOL {
  width: 16px;
  height: 16px;
  background-position: -832px -240px;
}

.CPOS {
  width: 16px;
  height: 16px;
  background-position: -832px -256px;
}

.CPR {
  width: 16px;
  height: 16px;
  background-position: -832px -272px;
}

.CPRO {
  width: 16px;
  height: 16px;
  background-position: -832px -288px;
}

.CPRX {
  width: 16px;
  height: 16px;
  background-position: -832px -304px;
}

.CPS {
  width: 16px;
  height: 16px;
  background-position: -832px -320px;
}

.CPT {
  width: 16px;
  height: 16px;
  background-position: -832px -336px;
}

.CPTB {
  width: 16px;
  height: 16px;
  background-position: -832px -352px;
}

.CPTE {
  width: 16px;
  height: 16px;
  background-position: -832px -368px;
}

.CPTINU {
  width: 16px;
  height: 16px;
  background-position: -832px -384px;
}

.CPTL {
  width: 16px;
  height: 16px;
  background-position: -832px -400px;
}

.CPU {
  width: 16px;
  height: 16px;
  background-position: -832px -416px;
}

.CPX {
  width: 16px;
  height: 16px;
  background-position: -832px -432px;
}

.CPZ {
  width: 16px;
  height: 16px;
  background-position: -832px -448px;
}

.CQT {
  width: 16px;
  height: 16px;
  background-position: -832px -464px;
}

.CRA {
  width: 16px;
  height: 16px;
  background-position: -832px -480px;
}

.CRAB {
  width: 16px;
  height: 16px;
  background-position: -832px -496px;
}

.CRACE {
  width: 16px;
  height: 16px;
  background-position: -832px -512px;
}

.CRAD {
  width: 16px;
  height: 16px;
  background-position: -832px -528px;
}

.CRAFT {
  width: 16px;
  height: 16px;
  background-position: -832px -544px;
}

.CRAM {
  width: 16px;
  height: 16px;
  background-position: -832px -560px;
}

.CRAVE {
  width: 16px;
  height: 16px;
  background-position: -832px -576px;
}

.CRB {
  width: 16px;
  height: 16px;
  background-position: -832px -592px;
}

.CRBN {
  width: 16px;
  height: 16px;
  background-position: -832px -608px;
}

.CRBRUS {
  width: 16px;
  height: 16px;
  background-position: -832px -624px;
}

.CRC {
  width: 16px;
  height: 16px;
  background-position: -832px -640px;
}

.CRCT {
  width: 16px;
  height: 16px;
  background-position: -832px -656px;
}

.CRD {
  width: 16px;
  height: 16px;
  background-position: -832px -672px;
}

.CRDAO {
  width: 16px;
  height: 16px;
  background-position: -832px -688px;
}

.CRDC {
  width: 16px;
  height: 16px;
  background-position: -832px -704px;
}

.CRDN {
  width: 16px;
  height: 16px;
  background-position: -832px -720px;
}

.CRDT {
  width: 16px;
  height: 16px;
  background-position: -832px -736px;
}

.CRE {
  width: 16px;
  height: 16px;
  background-position: -832px -752px;
}

.CRE8 {
  width: 16px;
  height: 16px;
  background-position: -832px -768px;
}

.CRE8R {
  width: 16px;
  height: 16px;
  background-position: -832px -784px;
}

.CREA {
  width: 16px;
  height: 16px;
  background-position: -832px -800px;
}

.CREAL {
  width: 16px;
  height: 16px;
  background-position: -832px -816px;
}

.CREAM {
  width: 16px;
  height: 16px;
  background-position: -0 -832px;
}

.CRED {
  width: 16px;
  height: 16px;
  background-position: -16px -832px;
}

.CREDA {
  width: 16px;
  height: 16px;
  background-position: -32px -832px;
}

.CREDI {
  width: 16px;
  height: 16px;
  background-position: -48px -832px;
}

.CREDIT {
  width: 16px;
  height: 16px;
  background-position: -64px -832px;
}

.CREDITS {
  width: 16px;
  height: 16px;
  background-position: -80px -832px;
}

.CREDO {
  width: 16px;
  height: 16px;
  background-position: -96px -832px;
}

.CREED {
  width: 16px;
  height: 16px;
  background-position: -112px -832px;
}

.CREO {
  width: 16px;
  height: 16px;
  background-position: -128px -832px;
}

.CREP {
  width: 16px;
  height: 16px;
  background-position: -144px -832px;
}

.CRETH2 {
  width: 16px;
  height: 16px;
  background-position: -160px -832px;
}

.CREVA {
  width: 16px;
  height: 16px;
  background-position: -176px -832px;
}

.CREX {
  width: 16px;
  height: 16px;
  background-position: -192px -832px;
}

.CRF {
  width: 16px;
  height: 16px;
  background-position: -208px -832px;
}

.CRFI {
  width: 16px;
  height: 16px;
  background-position: -224px -832px;
}

.CRIB {
  width: 16px;
  height: 16px;
  background-position: -240px -832px;
}

.CRIC {
  width: 16px;
  height: 16px;
  background-position: -256px -832px;
}

.CRICKET {
  width: 16px;
  height: 16px;
  background-position: -272px -832px;
}

.CRK {
  width: 16px;
  height: 16px;
  background-position: -288px -832px;
}

.CRL {
  width: 16px;
  height: 16px;
  background-position: -304px -832px;
}

.CRM {
  width: 16px;
  height: 16px;
  background-position: -320px -832px;
}

.CRN {
  width: 16px;
  height: 16px;
  background-position: -336px -832px;
}

.CRO {
  width: 16px;
  height: 16px;
  background-position: -352px -832px;
}

.CROAT {
  width: 16px;
  height: 16px;
  background-position: -368px -832px;
}

.CROBLANC {
  width: 16px;
  height: 16px;
  background-position: -384px -832px;
}

.CROC {
  width: 16px;
  height: 16px;
  background-position: -400px -832px;
}

.CROCASH {
  width: 16px;
  height: 16px;
  background-position: -416px -832px;
}

.CROCKET {
  width: 16px;
  height: 16px;
  background-position: -432px -832px;
}

.CROCRO {
  width: 16px;
  height: 16px;
  background-position: -448px -832px;
}

.CROD {
  width: 16px;
  height: 16px;
  background-position: -464px -832px;
}

.CROGE {
  width: 16px;
  height: 16px;
  background-position: -480px -832px;
}

.CROISSANT {
  width: 16px;
  height: 16px;
  background-position: -496px -832px;
}

.CROKI {
  width: 16px;
  height: 16px;
  background-position: -512px -832px;
}

.CROLAMBOS {
  width: 16px;
  height: 16px;
  background-position: -528px -832px;
}

.CRON {
  width: 16px;
  height: 16px;
  background-position: -544px -832px;
}

.CRONA {
  width: 16px;
  height: 16px;
  background-position: -560px -832px;
}

.CRONO {
  width: 16px;
  height: 16px;
  background-position: -576px -832px;
}

.CRONOAPE {
  width: 16px;
  height: 16px;
  background-position: -592px -832px;
}

.CROP {
  width: 16px;
  height: 16px;
  background-position: -608px -832px;
}

.CROSS {
  width: 16px;
  height: 16px;
  background-position: -624px -832px;
}

.CROTAMA {
  width: 16px;
  height: 16px;
  background-position: -640px -832px;
}

.CROW {
  width: 16px;
  height: 16px;
  background-position: -656px -832px;
}

.CROWN {
  width: 16px;
  height: 16px;
  background-position: -672px -832px;
}

.CROX {
  width: 16px;
  height: 16px;
  background-position: -688px -832px;
}

.CRP {
  width: 16px;
  height: 16px;
  background-position: -704px -832px;
}

.CRPRO {
  width: 16px;
  height: 16px;
  background-position: -720px -832px;
}

.CRPT {
  width: 16px;
  height: 16px;
  background-position: -736px -832px;
}

.CRS {
  width: 16px;
  height: 16px;
  background-position: -752px -832px;
}

.CRT {
  width: 16px;
  height: 16px;
  background-position: -768px -832px;
}

.CRTS {
  width: 16px;
  height: 16px;
  background-position: -784px -832px;
}

.CRU {
  width: 16px;
  height: 16px;
  background-position: -800px -832px;
}

.CRUDE {
  width: 16px;
  height: 16px;
  background-position: -816px -832px;
}

.CRUNCH {
  width: 16px;
  height: 16px;
  background-position: -832px -832px;
}

.CRUSADER {
  width: 16px;
  height: 16px;
  background-position: -848px -0;
}

.CRUSH {
  width: 16px;
  height: 16px;
  background-position: -848px -16px;
}

.CRUX {
  width: 16px;
  height: 16px;
  background-position: -848px -32px;
}

.CRV {
  width: 16px;
  height: 16px;
  background-position: -848px -48px;
}

.CRVT {
  width: 16px;
  height: 16px;
  background-position: -848px -64px;
}

.CRW {
  width: 16px;
  height: 16px;
  background-position: -848px -80px;
}

.CRWNY {
  width: 16px;
  height: 16px;
  background-position: -848px -96px;
}

.CRX {
  width: 16px;
  height: 16px;
  background-position: -848px -112px;
}

.CRYB {
  width: 16px;
  height: 16px;
  background-position: -848px -128px;
}

.CRYN {
  width: 16px;
  height: 16px;
  background-position: -848px -144px;
}

.CRYP {
  width: 16px;
  height: 16px;
  background-position: -848px -160px;
}

.CRYPL {
  width: 16px;
  height: 16px;
  background-position: -848px -176px;
}

.CRYPT {
  width: 16px;
  height: 16px;
  background-position: -848px -192px;
}

.CRYPTOBALL {
  width: 16px;
  height: 16px;
  background-position: -848px -208px;
}

.CRYPTOGRAM {
  width: 16px;
  height: 16px;
  background-position: -848px -224px;
}

.CRYSTAL {
  width: 16px;
  height: 16px;
  background-position: -848px -240px;
}

.CRYSTL {
  width: 16px;
  height: 16px;
  background-position: -848px -256px;
}

.CRYT {
  width: 16px;
  height: 16px;
  background-position: -848px -272px;
}

.CRYY {
  width: 16px;
  height: 16px;
  background-position: -848px -288px;
}

.CS {
  width: 16px;
  height: 16px;
  background-position: -848px -304px;
}

.CSAI {
  width: 16px;
  height: 16px;
  background-position: -848px -320px;
}

.CSB {
  width: 16px;
  height: 16px;
  background-position: -848px -336px;
}

.CSC {
  width: 16px;
  height: 16px;
  background-position: -848px -352px;
}

.CSCJ {
  width: 16px;
  height: 16px;
  background-position: -848px -368px;
}

.CSE {
  width: 16px;
  height: 16px;
  background-position: -848px -384px;
}

.CSF {
  width: 16px;
  height: 16px;
  background-position: -848px -400px;
}

.CSHARE {
  width: 16px;
  height: 16px;
  background-position: -848px -416px;
}

.CSHIP {
  width: 16px;
  height: 16px;
  background-position: -848px -432px;
}

.CSM {
  width: 16px;
  height: 16px;
  background-position: -848px -448px;
}

.CSMC {
  width: 16px;
  height: 16px;
  background-position: -848px -464px;
}

.CSMS {
  width: 16px;
  height: 16px;
  background-position: -848px -480px;
}

.CSNO {
  width: 16px;
  height: 16px;
  background-position: -848px -496px;
}

.CSNP {
  width: 16px;
  height: 16px;
  background-position: -848px -512px;
}

.CSO {
  width: 16px;
  height: 16px;
  background-position: -848px -528px;
}

.CSOV {
  width: 16px;
  height: 16px;
  background-position: -848px -544px;
}

.CSP {
  width: 16px;
  height: 16px;
  background-position: -848px -560px;
}

.CSPC {
  width: 16px;
  height: 16px;
  background-position: -848px -576px;
}

.CSPD {
  width: 16px;
  height: 16px;
  background-position: -848px -592px;
}

.CSPN {
  width: 16px;
  height: 16px;
  background-position: -848px -608px;
}

.CSPR {
  width: 16px;
  height: 16px;
  background-position: -848px -624px;
}

.CSPRO {
  width: 16px;
  height: 16px;
  background-position: -848px -640px;
}

.CSR {
  width: 16px;
  height: 16px;
  background-position: -848px -656px;
}

.CSS {
  width: 16px;
  height: 16px;
  background-position: -848px -672px;
}

.CST {
  width: 16px;
  height: 16px;
  background-position: -848px -688px;
}

.CSTL {
  width: 16px;
  height: 16px;
  background-position: -848px -704px;
}

.CSTO {
  width: 16px;
  height: 16px;
  background-position: -848px -720px;
}

.CSTR {
  width: 16px;
  height: 16px;
  background-position: -848px -736px;
}

.CSWAP {
  width: 16px;
  height: 16px;
  background-position: -848px -752px;
}

.CSX {
  width: 16px;
  height: 16px;
  background-position: -848px -768px;
}

.CT {
  width: 16px;
  height: 16px;
  background-position: -848px -784px;
}

.CTASK {
  width: 16px;
  height: 16px;
  background-position: -848px -800px;
}

.CTAT {
  width: 16px;
  height: 16px;
  background-position: -848px -816px;
}

.CTAX {
  width: 16px;
  height: 16px;
  background-position: -848px -832px;
}

.CTB {
  width: 16px;
  height: 16px;
  background-position: -0 -848px;
}

.CTC {
  width: 16px;
  height: 16px;
  background-position: -16px -848px;
}

.CTCN {
  width: 16px;
  height: 16px;
  background-position: -32px -848px;
}

.CTCO {
  width: 16px;
  height: 16px;
  background-position: -48px -848px;
}

.CTD {
  width: 16px;
  height: 16px;
  background-position: -64px -848px;
}

.CTF {
  width: 16px;
  height: 16px;
  background-position: -80px -848px;
}

.CTFT {
  width: 16px;
  height: 16px;
  background-position: -96px -848px;
}

.CTH {
  width: 16px;
  height: 16px;
  background-position: -112px -848px;
}

.CTHAI {
  width: 16px;
  height: 16px;
  background-position: -128px -848px;
}

.CTI {
  width: 16px;
  height: 16px;
  background-position: -144px -848px;
}

.CTIC3 {
  width: 16px;
  height: 16px;
  background-position: -160px -848px;
}

.CTK {
  width: 16px;
  height: 16px;
  background-position: -176px -848px;
}

.CTL {
  width: 16px;
  height: 16px;
  background-position: -192px -848px;
}

.CTLX {
  width: 16px;
  height: 16px;
  background-position: -208px -848px;
}

.CTN {
  width: 16px;
  height: 16px;
  background-position: -224px -848px;
}

.CTNT {
  width: 16px;
  height: 16px;
  background-position: -240px -848px;
}

.CTO {
  width: 16px;
  height: 16px;
  background-position: -256px -848px;
}

.CTOGER {
  width: 16px;
  height: 16px;
  background-position: -272px -848px;
}

.CTOK {
  width: 16px;
  height: 16px;
  background-position: -288px -848px;
}

.CTP {
  width: 16px;
  height: 16px;
  background-position: -304px -848px;
}

.CTPL {
  width: 16px;
  height: 16px;
  background-position: -320px -848px;
}

.CTR {
  width: 16px;
  height: 16px;
  background-position: -336px -848px;
}

.CTRAIN {
  width: 16px;
  height: 16px;
  background-position: -352px -848px;
}

.CTRFI {
  width: 16px;
  height: 16px;
  background-position: -368px -848px;
}

.CTRIBE {
  width: 16px;
  height: 16px;
  background-position: -384px -848px;
}

.CTRO {
  width: 16px;
  height: 16px;
  background-position: -400px -848px;
}

.CTRS {
  width: 16px;
  height: 16px;
  background-position: -416px -848px;
}

.CTRT {
  width: 16px;
  height: 16px;
  background-position: -432px -848px;
}

.CTS {
  width: 16px;
  height: 16px;
  background-position: -448px -848px;
}

.CTSC {
  width: 16px;
  height: 16px;
  background-position: -464px -848px;
}

.CTSI {
  width: 16px;
  height: 16px;
  background-position: -480px -848px;
}

.CTT {
  width: 16px;
  height: 16px;
  background-position: -496px -848px;
}

.CTX {
  width: 16px;
  height: 16px;
  background-position: -512px -848px;
}

.CTXC {
  width: 16px;
  height: 16px;
  background-position: -528px -848px;
}

.CTZN {
  width: 16px;
  height: 16px;
  background-position: -544px -848px;
}

.CU {
  width: 16px;
  height: 16px;
  background-position: -560px -848px;
}

.CUAG {
  width: 16px;
  height: 16px;
  background-position: -576px -848px;
}

.CUAN {
  width: 16px;
  height: 16px;
  background-position: -592px -848px;
}

.CUB {
  width: 16px;
  height: 16px;
  background-position: -608px -848px;
}

.CUBE {
  width: 16px;
  height: 16px;
  background-position: -624px -848px;
}

.CUBO {
  width: 16px;
  height: 16px;
  background-position: -640px -848px;
}

.CUDOS {
  width: 16px;
  height: 16px;
  background-position: -656px -848px;
}

.CUE {
  width: 16px;
  height: 16px;
  background-position: -672px -848px;
}

.CUEX {
  width: 16px;
  height: 16px;
  background-position: -688px -848px;
}

.CUFFIES {
  width: 16px;
  height: 16px;
  background-position: -704px -848px;
}

.CUJO {
  width: 16px;
  height: 16px;
  background-position: -720px -848px;
}

.CULT {
  width: 16px;
  height: 16px;
  background-position: -736px -848px;
}

.CULTOS {
  width: 16px;
  height: 16px;
  background-position: -752px -848px;
}

.CUM {
  width: 16px;
  height: 16px;
  background-position: -768px -848px;
}

.CUMINU {
  width: 16px;
  height: 16px;
  background-position: -784px -848px;
}

.CUMMIES {
  width: 16px;
  height: 16px;
  background-position: -800px -848px;
}

.CUMSTAR {
  width: 16px;
  height: 16px;
  background-position: -816px -848px;
}

.CUNI {
  width: 16px;
  height: 16px;
  background-position: -832px -848px;
}

.CUP {
  width: 16px;
  height: 16px;
  background-position: -848px -848px;
}

.CUR {
  width: 16px;
  height: 16px;
  background-position: -864px -0;
}

.CURE {
  width: 16px;
  height: 16px;
  background-position: -864px -16px;
}

.CURRY {
  width: 16px;
  height: 16px;
  background-position: -864px -32px;
}

.CUSD {
  width: 16px;
  height: 16px;
  background-position: -864px -48px;
}

.CUSDC {
  width: 16px;
  height: 16px;
  background-position: -864px -64px;
}

.CUSDT {
  width: 16px;
  height: 16px;
  background-position: -864px -80px;
}

.CUST {
  width: 16px;
  height: 16px;
  background-position: -864px -96px;
}

.CUT {
  width: 16px;
  height: 16px;
  background-position: -864px -112px;
}

.CV {
  width: 16px;
  height: 16px;
  background-position: -864px -128px;
}

.CVA {
  width: 16px;
  height: 16px;
  background-position: -864px -144px;
}

.CVAG {
  width: 16px;
  height: 16px;
  background-position: -864px -160px;
}

.CVC {
  width: 16px;
  height: 16px;
  background-position: -864px -176px;
}

.CVCC {
  width: 16px;
  height: 16px;
  background-position: -864px -192px;
}

.CVD19 {
  width: 16px;
  height: 16px;
  background-position: -864px -208px;
}

.CVIP {
  width: 16px;
  height: 16px;
  background-position: -864px -224px;
}

.CVN {
  width: 16px;
  height: 16px;
  background-position: -864px -240px;
}

.CVNT {
  width: 16px;
  height: 16px;
  background-position: -864px -256px;
}

.CVNX {
  width: 16px;
  height: 16px;
  background-position: -864px -272px;
}

.CVOL {
  width: 16px;
  height: 16px;
  background-position: -864px -288px;
}

.CVP {
  width: 16px;
  height: 16px;
  background-position: -864px -304px;
}

.CVR {
  width: 16px;
  height: 16px;
  background-position: -864px -320px;
}

.CVSHOT {
  width: 16px;
  height: 16px;
  background-position: -864px -336px;
}

.CVT {
  width: 16px;
  height: 16px;
  background-position: -864px -352px;
}

.CVX {
  width: 16px;
  height: 16px;
  background-position: -864px -368px;
}

.CVXCRV {
  width: 16px;
  height: 16px;
  background-position: -864px -384px;
}

.CVZ {
  width: 16px;
  height: 16px;
  background-position: -864px -400px;
}

.CW {
  width: 16px;
  height: 16px;
  background-position: -864px -416px;
}

.CWAP {
  width: 16px;
  height: 16px;
  background-position: -864px -432px;
}

.CWAR {
  width: 16px;
  height: 16px;
  background-position: -864px -448px;
}

.CWBTC {
  width: 16px;
  height: 16px;
  background-position: -864px -464px;
}

.CWD {
  width: 16px;
  height: 16px;
  background-position: -864px -480px;
}

.CWE {
  width: 16px;
  height: 16px;
  background-position: -864px -496px;
}

.CWEB {
  width: 16px;
  height: 16px;
  background-position: -864px -512px;
}

.CWN {
  width: 16px;
  height: 16px;
  background-position: -864px -528px;
}

.CWOLF {
  width: 16px;
  height: 16px;
  background-position: -864px -544px;
}

.CWS {
  width: 16px;
  height: 16px;
  background-position: -864px -560px;
}

.CWT {
  width: 16px;
  height: 16px;
  background-position: -864px -576px;
}

.CWV {
  width: 16px;
  height: 16px;
  background-position: -864px -592px;
}

.CX1 {
  width: 16px;
  height: 16px;
  background-position: -864px -608px;
}

.CXC {
  width: 16px;
  height: 16px;
  background-position: -864px -624px;
}

.CXD {
  width: 16px;
  height: 16px;
  background-position: -864px -640px;
}

.CXGEM {
  width: 16px;
  height: 16px;
  background-position: -864px -656px;
}

.CXN {
  width: 16px;
  height: 16px;
  background-position: -864px -672px;
}

.CXO {
  width: 16px;
  height: 16px;
  background-position: -864px -688px;
}

.CXP {
  width: 16px;
  height: 16px;
  background-position: -864px -704px;
}

.CXPAD {
  width: 16px;
  height: 16px;
  background-position: -864px -720px;
}

.CXRBN {
  width: 16px;
  height: 16px;
  background-position: -864px -736px;
}

.CXT {
  width: 16px;
  height: 16px;
  background-position: -864px -752px;
}

.CYB {
  width: 16px;
  height: 16px;
  background-position: -864px -768px;
}

.CYBER {
  width: 16px;
  height: 16px;
  background-position: -864px -784px;
}

.CYBER_NEKO {
  width: 16px;
  height: 16px;
  background-position: -864px -800px;
}

.CYBG {
  width: 16px;
  height: 16px;
  background-position: -864px -816px;
}

.CYBR {
  width: 16px;
  height: 16px;
  background-position: -864px -832px;
}

.CybrrrDOGE {
  width: 16px;
  height: 16px;
  background-position: -864px -848px;
}

.CYC {
  width: 16px;
  height: 16px;
  background-position: -0 -864px;
}

.CYCE {
  width: 16px;
  height: 16px;
  background-position: -16px -864px;
}

.CYCLE {
  width: 16px;
  height: 16px;
  background-position: -32px -864px;
}

.CYCLUB {
  width: 16px;
  height: 16px;
  background-position: -48px -864px;
}

.CYF {
  width: 16px;
  height: 16px;
  background-position: -64px -864px;
}

.CYFM {
  width: 16px;
  height: 16px;
  background-position: -80px -864px;
}

.CYL {
  width: 16px;
  height: 16px;
  background-position: -96px -864px;
}

.CYM {
  width: 16px;
  height: 16px;
  background-position: -112px -864px;
}

.CYMT {
  width: 16px;
  height: 16px;
  background-position: -128px -864px;
}

.CYN {
  width: 16px;
  height: 16px;
  background-position: -144px -864px;
}

.CYNC {
  width: 16px;
  height: 16px;
  background-position: -160px -864px;
}

.CYO {
  width: 16px;
  height: 16px;
  background-position: -176px -864px;
}

.CYOP {
  width: 16px;
  height: 16px;
  background-position: -192px -864px;
}

.CYR {
  width: 16px;
  height: 16px;
  background-position: -208px -864px;
}

.CYS {
  width: 16px;
  height: 16px;
  background-position: -224px -864px;
}

.CYSHI {
  width: 16px;
  height: 16px;
  background-position: -240px -864px;
}

.CYT {
  width: 16px;
  height: 16px;
  background-position: -256px -864px;
}

.CYTR {
  width: 16px;
  height: 16px;
  background-position: -272px -864px;
}

.CZ {
  width: 16px;
  height: 16px;
  background-position: -288px -864px;
}

.CZATS {
  width: 16px;
  height: 16px;
  background-position: -304px -864px;
}

.CZF {
  width: 16px;
  height: 16px;
  background-position: -320px -864px;
}

.CZR {
  width: 16px;
  height: 16px;
  background-position: -336px -864px;
}

.CZRX {
  width: 16px;
  height: 16px;
  background-position: -352px -864px;
}

.CZUSD {
  width: 16px;
  height: 16px;
  background-position: -368px -864px;
}

.CZZ {
  width: 16px;
  height: 16px;
  background-position: -384px -864px;
}
