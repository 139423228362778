.J,
.J8T,
.J9BC,
.JACK,
.JACY,
.JADE,
.JAGUAR,
.JAIHO,
.JAM,
.JAMM,
.JAMON,
.JAN,
.JAR,
.JASMY,
.JAWS,
.JAY,
.JAZ,
.JB,
.JBC,
.JBP,
.JBX,
.JC,
.JCC,
.JCG,
.JCR,
.JCT,
.JDB,
.JDC,
.JDI,
.JED,
.JEDALS,
.JEET,
.JEFE,
.JEJUDOGE,
.JELLY,
.JEM,
.JET,
.JETS,
.JEW,
.JEWEL,
.JEX,
.JF,
.JFC,
.JFI,
.JFM,
.JGN,
.JGRD,
.JIAOZI,
.JIG,
.JIMN,
.JIN,
.JIND,
.JINDOGE,
.JINU,
.JINX,
.JIYO,
.JK,
.JKT,
.JM,
.JMC,
.JMPT,
.JMT,
.JNB,
.JNT,
.JNTR,
.JNTR_Etherum,
.JOB,
.JOC,
.JOE,
.JOIN,
.JOINT,
.JOJO,
.JOK,
.JOKE,
.JOKER,
.JOL,
.JOLLY,
.JOLT,
.JONES,
.JOON,
.JOOPS,
.JOOS,
.JORDAN,
.JOT,
.JOY,
.JOYS,
.JPAW,
.JPEG,
.JPEGS,
.JPG,
.JPT,
.JPX,
.JPYC,
.JPYN,
.JPYX,
.JRC,
.JRIT,
.JRSC,
.JRSHIB,
.JRT,
.JS,
.JSB,
.JSE,
.JSHIBA,
.JSOL,
.JST,
.JT,
.JTS,
.JUB,
.JUDE,
.JUI,
.JUICE,
.JUKE,
.JUL,
.JULB,
.JULD,
.JUMBO,
.JUMP,
.JUNGLE,
.JUNKOINU,
.JUNO,
.JUP,
.JUR,
.JURM,
.JUS,
.JUST,
.JUSTICE,
.JUV,
.JVC,
.JVZ,
.JWL,
.K4R,
.K9,
.K21,
.KAASO,
.KABA,
.KABOSU,
.KABY,
.KAC,
.KACY,
.KADA,
.KAE,
.KAFE,
.KAI,
.KAIBA,
.KAIDHT,
.KAIECO,
.KAIINU,
.KAIJU,
.KAINET,
.KAIZILLA,
.KAKA,
.KAKASHI,
.KAKI,
.KAL,
.KALA,
.KALAM,
.KALI,
.KALLY,
.KALM,
.KAM,
.KAMI,
.KAMPAY,
.KAN,
.KANA,
.KANDA,
.KANDY,
.KANGAL,
.KANGAROO,
.KAP,
.KAPEX,
.KAPP,
.KAR,
.KARA,
.KAREN,
.KARMA,
.KART,
.KAS,
.KASH,
.KASSIAHOME,
.KASSIAHOTEL,
.KASTA,
.KAT,
.KATA,
.Katal,
.KATANA,
.KATS,
.KATSUMI,
.KAU,
.KAVA,
.KAWA,
.KAWAII,
.KAZAMA,
.KAZU,
.KB3,
.KBC,
.KBD,
.KBOT,
.KBOX,
.KBP,
.KBTC,
.KBX,
.KCAKE,
.KCAL,
.KCASH,
.KCCGO,
.KCCL,
.KRED,
.KCCM,
.KCCPAD,
.KCCSWAP,
.KCH,
.KCLP,
.kCoin,
.KCS,
.KCT,
.KDA,
.KDAG,
.KDAO,
.KDC,
.KDG,
.KDOGE,
.KEANU,
.KEBAB,
.KEEP,
.KEI,
.KEIKO,
.KEK,
.KEKW,
.KEL,
.KELPIE,
.KEMA,
.KEN,
.KENNEL,
.KENSHI,
.KENU,
.KET,
.KETCHUP,
.KEVIN,
.KEX,
.KEY,
.KEYFI,
.KEYS,
.KEYSWAP,
.KEYT,
.KFC,
.KFI,
.KFR,
.KFS_G,
.KFT,
.KFX,
.KGC,
.KGO,
.KGT,
.KHADD,
.KHALIFA,
.KHAOS,
.KHC,
.KIAN,
.KIBA,
.KIC,
.KICH,
.KICK,
.KICKS,
.KIDS,
.KIF,
.KIK,
.KIKA,
.KIKI,
.KIKO,
.KILI,
.KILL,
.KILLDOGE,
.KILLER,
.KILLSQUID,
.KILLUA,
.KILT,
.KIM,
.KIMCHI,
.KIMETSU,
.KIMJ,
.KIMOCHI,
.KIN,
.KIND,
.KINE,
.KING,
.KINGDOG,
.KINGSHIB,
.KINGSHIBA,
.KINGTAMA,
.KINJA,
.KINO,
.KINT,
.KINTA,
.KINU,
.KIP,
.KIRADOGE,
.KIRBY,
.KIRO,
.Kishimoto,
.KISHU,
.KISHUTAMA,
.KISSMYMOON,
.KIT,
.KITE,
.KITTENS,
.kitty,
.KITTY,
.KITTY_DINGER,
.KIWI,
.KIZ,
.KIZO,
.KK,
.KKC,
.KKI,
.KKMA,
.KKO,
.KKT,
.KLAY,
.KLAYE,
.KLAYG,
.KLC,
.KLEAR,
.KLEE,
.KLH,
.KLIMA,
.KLING,
.KLKS,
.KLN,
.KLNW,
.KLO,
.KLON,
.KLP,
.KLT,
.KLTR,
.KLV,
.KMA,
.KMC,
.KMD,
.KMDICE,
.KMO,
.KMON,
.KMONS,
.KMT,
.KMW,
.KNB,
.KNC,
.KNCHALF,
.LINKHEDGE,
.LEOHALF,
.KNCBULL,
.LEOHEDGE,
.KNCHEDGE,
.LINKHALF,
.KNCBEAR,
.KNCL,
.KNDC,
.KNG,
.KNIGHT,
.KNIGHTS,
.KNLC,
.KNOT,
.KNOW,
.KNOX,
.KNT,
.KNUCKLES,
.KNV,
.KO,
.KOACOMBAT,
.KOBE,
.KOBO,
.KOD,
.KODA,
.KODI,
.KODURO,
.KODX,
.KOEL,
.KOGE,
.KOGECOIN,
.KOI,
.KOIN,
.Koi_Token,
.KOJI,
.KOK,
.KOKO,
.KOKOA,
.KOKOMO,
.KOL,
.KOLIN,
.KOLO,
.KOM,
.KOMBAI,
.KOMBAT,
.KOMET,
.KOMP,
.KOMPETE,
.KON,
.KONG,
.KONGTAMA,
.KONJ,
.KONO,
.KOOL,
.KOP,
.KORA,
.KORE,
.KORI,
.KOROMARU,
.KOSHU,
.KOSON,
.KOT,
.KOTO,
.KP2R,
.KP3R,
.KP3RB,
.KP4R,
.KPAD,
.KPC,
.KPER,
.KPETS,
.KPHI,
.KPLAY,
.KPOP,
.KRAKENSQUID,
.KRAUSE,
.KRB,
.KRD,
.KREDS,
.KRG,
.KRIDA,
.KRILL,
.KRL,
.KRM,
.KRN,
.KRNO,
.KRO,
.KROM,
.KRONOS,
.KROOT,
.KRRX,
.KRS,
.LTCHALF,
.KRT,
.KRW,
.KRWT,
.KRX,
.KRYPTO,
.KRZ,
.KS,
.KS2,
.KSAMO,
.KSC,
.KSE,
.KSEED,
.KSF,
.KSG,
.KSH,
.KSHIB,
.KSHIBA,
.KSHIBINU,
.KSLV,
.KSM,
.KSP,
.KSR,
.KSS,
.KST,
.KSTT,
.KSW,
.KT,
.KTC,
.KTE,
.KTETH,
.KTLYO,
.KTN,
.KTO,
.KTON,
.KTS,
.KTT,
.KTV,
.KTX,
.KTY,
.KTZ,
.KUB,
.KUBE,
.KUBO,
.KUDO,
.KUE,
.KUJI,
.KUKA,
.KUKU,
.KUMA,
.KUN,
.KUNCI,
.KUNO,
.KUNU,
.KURAI,
.KURO,
.KURT,
.KUS,
.KUST,
.KUSUNOKI,
.KUT,
.KUV,
.KUY,
.KVA,
.KVERSE,
.KVI,
.KVNT,
.KVT,
.KWATT,
.KWH,
.KWIK,
.KWOOF,
.KWS,
.KWT,
.KXA,
.KXC,
.KYANITE,
.KYCC,
.KYDC,
.KYF,
.KYI,
.KYL,
.KYOKO,
.KYSC,
.KYSR,
.KYTE,
.KYUB,
.KZ,
.KZC,
.KZE,
.KZEN,
.KZN,
.L1T,
.L2,
.L2P,
.L2PAD,
.L3P,
.L9,
.L99,
.LA,
.LABH,
.LABO,
.LABRA,
.LABS,
.LAB_v2,
.LABX,
.LAC,
.LACE,
.LAD,
.LADA,
.LAEEB,
.LAI,
.LAIKA,
.LALA,
.LAMA,
.LAMB,
.LAMBO,
.LAMBOINU,
.LAMBS,
.Laminar,
.LANA,
.LANC,
.LAND,
.LANDOFDOGES,
.LAR,
.LARIX,
.LARO,
.LAS,
.LASEREYES,
.LASM,
.LASSECASH,
.LASSO,
.LAT,
.LATTE,
.LATX,
.LAUNCH,
.LAVA,
.LAVAFALL,
.LAVAX,
.LAW,
.LAYER,
.LAYERX,
.LAYR,
.LAZIO,
.LAZYSHIBA,
.LBA,
.LBC,
.LBCC,
.LBD,
.LBIT,
.LBK,
.LBL,
.LBLOCK,
.LBN,
.LBO,
.LBOT,
.LBP,
.LBR,
.LBS,
.LBTC,
.Lburst,
.LBXC,
.LBY,
.LC,
.LCC,
.LCD,
.LCDOT,
.LCDP,
.LCG,
.LCH,
.LCINU,
.LCMS,
.LCP,
.LCS,
.LCT,
.LCX,
.LDC,
.LDDP,
.LDF,
.LDFI,
.LDG,
.LDM,
.LDN,
.LDO,
.LDOGE,
.LDOT,
.LDT,
.LDX,
.LDXG,
.LEA,
.LEAD,
.LEAF,
.LEAFTY,
.LEAG,
.LEAN,
.LEASH,
.LEC,
.LECLIENTE,
.LED,
.LEDU,
.LEG,
.LEGEND,
.LEGO,
.LELE,
.LEM,
.LEMC,
.LEMD,
.LEMN,
.LEMO,
.LEND,
.LENDA,
.LENS,
.LEO,
.LEOMOON,
.LTCDOOM,
.LINKBULL,
.LTCMOON,
.LINKBEAR,
.LEON,
.LEONIDAS,
.LEOPARD,
.LEOS,
.LEP,
.LEPA,
.LESLAR,
.LESS,
.LET,
.LETSGO,
.LEV,
.LEVELG,
.LEVL,
.LEVO,
.LEWK,
.LEX,
.LEXI,
.LEZ,
.LF,
.LFC,
.LFEC,
.LFG,
.LFI,
.LFNTY,
.LFT,
.LFW,
.LGB,
.LGBT,
.LGBTQ,
.LGC,
.LGCY,
.LGG,
.LGND,
.LGO,
.LGOLD,
.LGS,
.LGX,
.LHB,
.LHC,
.LHRC,
.LHT,
.LIB,
.LIBERO,
.LIBERTAS,
.LIBERTE,
.LIBF,
.LIBFX,
.LIBRE,
.LIBREF,
.LICK,
.LICO,
.LICP,
.LID,
.LIEN,
.LIF,
.LIF3,
.LIFE,
.LIFEBIRD,
.LIFT,
.LIGHT,
.LIKE,
.LIL,
.LILDOGEFLOKI,
.LILFLOKI,
.LILTK,
.LILY,
.LIME,
.LIMEX,
.LIMIT,
.LIMON,
.LINA,
.LINEAR,
.LINK,
.LINKA,
.LINKUP,
.LINKDOWN,
.LTCUP,
.LTCDOWN,
.LINKETHPA,
.LINKETHRSI,
.LINKPT,
.LINKRSICO,
.LINKS,
.LINKUSD,
.LINSPIRIT,
.LINU,
.LINX,
.LIO,
.Lion,
.LION,
.LIQ,
.LIQLO,
.LIQR,
.LIQUID,
.LIST,
.LIT,
.LITH,
.LITHO,
.LITO,
.LITTLEDOGE,
.LIU,
.LIVE,
.LIVENFT,
.LIZARD,
.LK3R,
.LKK,
.LKM,
.LKN,
.LKR,
.LKSC,
.LKSM,
.LKT,
.LKU,
.LKY,
.LL,
.LLAND,
.LLC,
.LLELL,
.LLG,
.LLN,
.LLT,
.LLTH,
.LLVERSE,
.LM,
.LMAO,
.LMC,
.LMCH,
.LMCSWAP,
.LMG,
.LML,
.LMN,
.LMR,
.LMS,
.LMT,
.LMY,
.LN,
.LNC,
.LNCX,
.LND,
.LNFS,
.LNO,
.LNOT,
.LNR,
.LNT,
.LNX,
.LO,
.LOA,
.LOAD,
.LOBI,
.LOBS,
.LOC,
.LOCAL,
.LOCC,
.LOCG,
.LOCI,
.LOCK,
.LOCKPAY,
.LOCO,
.LOCUS,
.LOF,
.LOFI,
.LOG,
.LOGE,
.LOGOS,
.LOKA,
.LOL,
.LOLLI,
.LOLZ,
.LOM,
.LON,
.LONDON,
.LONG,
.LOOF,
.LOOKS,
.LOOM,
.LOOMI,
.LOON,
.LOOP,
.LOOPS,
.LOOT,
.LOOTDOLLAR,
.LOOX,
.LOR,
.LORC,
.LORD,
.LORDA,
.LORDS,
.LORDZ,
.LORI,
.LORY,
.LOS,
.LOST,
.LOT,
.LOTA,
.LOTEU,
.LOTT,
.LOTTO,
.Lotus,
.LOUD,
.LOUIEDUCKT,
.LOUVRE,
.LOV,
.LOVE,
.LOVELY,
.LOVES,
.LOVIL,
.LOWB,
.LOX,
.LOZ,
.LPC,
.LPDI,
.LPI,
.LPK,
.LPL,
.LPNT,
.LPO,
.LPOOL,
.LPS,
.LPT,
.LPY,
.LQ,
.LQD,
.LQDR,
.LQF,
.LQR,
.LQT,
.LQTY,
.LR,
.LRA,
.LRC,
.LRG,
.LRM,
.LSC,
.LSH,
.LSHARE,
.LSILVER,
.LSK,
.LSP,
.LSR,
.LSS,
.LST,
.LSTAR,
.LSTN,
.LSV,
.LSWAP,
.LT,
.LTB,
.LTC,
.LTCBEAR,
.LTCBULL,
.LTCP,
.LTCR,
.LTCU,
.LTD,
.LTE,
.LTEX,
.LTFM,
.LTFN,
.LTHN,
.LTK,
.LTMS,
.LTN,
.LTNM,
.LTNV2,
.LTO,
.LTP,
.LTR,
.LTRBT,
.LTS,
.LTSG,
.LTT,
.LTVN,
.LTX,
.LTY,
.LTZ,
.LUA,
.LUAN,
.LUART,
.LUB,
.LUC,
.LUCA,
.LUCHOW,
.LUCK,
.LUCKY,
.LUCKYCLOVER,
.LUCKYDOGE,
.LuckyPig,
.LUCY,
.LUD,
.LUDO,
.LUFC,
.LUFFY,
.LUFX,
.LUM,
.LUMEN,
.LUMI,
.LUMOSX,
.LUN,
.LUNA,
.LUNAINU,
.LUNAM,
.LUNAPAD,
.LUNAR,
.LUNAX,
.LUNC,
.LUNCH,
.LUNES,
.LUNI,
.LUNR,
.LUNU,
.LUS,
.LUSD,
.LUSHI,
.LUST,
.LUTO,
.LUV,
.LUX,
.LUXE,
.LUXO,
.LUXY,
.LVBD,
.LVH,
.LVL,
.LVLUP,
.LVN,
.LVT,
.LVX,
.LWAZI,
.LXF,
.LXT,
.LXTO,
.Ly,
.LYCA,
.LYD,
.LYFE,
.LYKA,
.LYL,
.LYM,
.LYNC,
.LYNK,
.LYNX,
.LYO,
.LYPTUS,
.LYR,
.LYRA,
.LYS,
.LYTX,
.LYXe,
.LZ,
.LZE,
.LZN,
.LZP {
  display: inline-block;
  background: url('../img/tokens_logo_JtoEndL.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.J {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.J8T {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.J9BC {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.JACK {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.JACY {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.JADE {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.JAGUAR {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.JAIHO {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.JAM {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.JAMM {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.JAMON {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.JAN {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.JAR {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.JASMY {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.JAWS {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.JAY {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.JAZ {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.JB {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.JBC {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.JBP {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.JBX {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.JC {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.JCC {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.JCG {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.JCR {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.JCT {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.JDB {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.JDC {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.JDI {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.JED {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.JEDALS {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.JEET {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.JEFE {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.JEJUDOGE {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.JELLY {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.JEM {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.JET {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.JETS {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.JEW {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.JEWEL {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.JEX {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.JF {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.JFC {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.JFI {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.JFM {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.JGN {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.JGRD {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.JIAOZI {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.JIG {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.JIMN {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.JIN {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.JIND {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.JINDOGE {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.JINU {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.JINX {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.JIYO {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.JK {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.JKT {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.JM {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.JMC {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.JMPT {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.JMT {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.JNB {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.JNT {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.JNTR {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.JNTR_Etherum {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.JOB {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.JOC {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.JOE {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.JOIN {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.JOINT {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.JOJO {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.JOK {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.JOKE {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.JOKER {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.JOL {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.JOLLY {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.JOLT {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.JONES {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.JOON {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.JOOPS {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.JOOS {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.JORDAN {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.JOT {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.JOY {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.JOYS {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.JPAW {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.JPEG {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.JPEGS {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.JPG {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.JPT {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.JPX {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.JPYC {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.JPYN {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.JPYX {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.JRC {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.JRIT {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.JRSC {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.JRSHIB {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.JRT {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.JS {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.JSB {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.JSE {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.JSHIBA {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.JSOL {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.JST {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.JT {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.JTS {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.JUB {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.JUDE {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.JUI {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.JUICE {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.JUKE {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.JUL {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.JULB {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.JULD {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.JUMBO {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.JUMP {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.JUNGLE {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.JUNKOINU {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.JUNO {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.JUP {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.JUR {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.JURM {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.JUS {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.JUST {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.JUSTICE {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.JUV {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.JVC {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.JVZ {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.JWL {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.K4R {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.K9 {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.K21 {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.KAASO {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.KABA {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.KABOSU {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.KABY {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.KAC {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.KACY {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.KADA {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.KAE {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.KAFE {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.KAI {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.KAIBA {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.KAIDHT {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.KAIECO {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.KAIINU {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.KAIJU {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.KAINET {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.KAIZILLA {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.KAKA {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.KAKASHI {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.KAKI {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.KAL {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.KALA {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.KALAM {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.KALI {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.KALLY {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.KALM {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.KAM {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.KAMI {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.KAMPAY {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.KAN {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.KANA {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.KANDA {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.KANDY {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.KANGAL {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.KANGAROO {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.KAP {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.KAPEX {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.KAPP {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.KAR {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.KARA {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.KAREN {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.KARMA {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.KART {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.KAS {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.KASH {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.KASSIAHOME {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.KASSIAHOTEL {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.KASTA {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.KAT {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.KATA {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.Katal {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.KATANA {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.KATS {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.KATSUMI {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.KAU {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.KAVA {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.KAWA {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.KAWAII {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.KAZAMA {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.KAZU {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.KB3 {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.KBC {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.KBD {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.KBOT {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.KBOX {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.KBP {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.KBTC {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.KBX {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.KCAKE {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.KCAL {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.KCASH {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.KCCGO {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.KCCL {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.KRED {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.KCCM {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.KCCPAD {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.KCCSWAP {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.KCH {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.KCLP {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.kCoin {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.KCS {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.KCT {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.KDA {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.KDAG {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.KDAO {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.KDC {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.KDG {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.KDOGE {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.KEANU {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.KEBAB {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.KEEP {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.KEI {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.KEIKO {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.KEK {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.KEKW {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.KEL {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.KELPIE {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.KEMA {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.KEN {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.KENNEL {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.KENSHI {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.KENU {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.KET {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.KETCHUP {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.KEVIN {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.KEX {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.KEY {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.KEYFI {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.KEYS {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.KEYSWAP {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.KEYT {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.KFC {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.KFI {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.KFR {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.KFS_G {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.KFT {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.KFX {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.KGC {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.KGO {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.KGT {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.KHADD {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.KHALIFA {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.KHAOS {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.KHC {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.KIAN {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.KIBA {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.KIC {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.KICH {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.KICK {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.KICKS {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.KIDS {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.KIF {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.KIK {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.KIKA {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.KIKI {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.KIKO {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.KILI {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.KILL {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.KILLDOGE {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.KILLER {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.KILLSQUID {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.KILLUA {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.KILT {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.KIM {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.KIMCHI {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.KIMETSU {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.KIMJ {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.KIMOCHI {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.KIN {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.KIND {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.KINE {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.KING {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.KINGDOG {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.KINGSHIB {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.KINGSHIBA {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.KINGTAMA {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.KINJA {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.KINO {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.KINT {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.KINTA {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.KINU {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.KIP {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.KIRADOGE {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.KIRBY {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.KIRO {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.Kishimoto {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.KISHU {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.KISHUTAMA {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.KISSMYMOON {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.KIT {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.KITE {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.KITTENS {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.kitty {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.KITTY {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.KITTY_DINGER {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.KIWI {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.KIZ {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.KIZO {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.KK {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.KKC {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.KKI {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.KKMA {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.KKO {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.KKT {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.KLAY {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.KLAYE {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.KLAYG {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.KLC {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.KLEAR {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.KLEE {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.KLH {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.KLIMA {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.KLING {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.KLKS {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.KLN {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.KLNW {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.KLO {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.KLON {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.KLP {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.KLT {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.KLTR {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.KLV {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.KMA {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.KMC {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.KMD {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.KMDICE {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.KMO {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.KMON {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.KMONS {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.KMT {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.KMW {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.KNB {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.KNC {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.KNCHALF {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.LINKHEDGE {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.LEOHALF {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.KNCBULL {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.LEOHEDGE {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.KNCHEDGE {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.LINKHALF {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.KNCBEAR {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.KNCL {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.KNDC {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.KNG {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.KNIGHT {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.KNIGHTS {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.KNLC {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.KNOT {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.KNOW {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.KNOX {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.KNT {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.KNUCKLES {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.KNV {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.KO {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.KOACOMBAT {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.KOBE {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.KOBO {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.KOD {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.KODA {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.KODI {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.KODURO {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.KODX {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.KOEL {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.KOGE {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.KOGECOIN {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.KOI {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.KOIN {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.Koi_Token {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.KOJI {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.KOK {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.KOKO {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.KOKOA {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.KOKOMO {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.KOL {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.KOLIN {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.KOLO {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.KOM {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.KOMBAI {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.KOMBAT {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.KOMET {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.KOMP {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.KOMPETE {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.KON {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.KONG {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.KONGTAMA {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.KONJ {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.KONO {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.KOOL {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.KOP {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.KORA {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.KORE {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.KORI {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.KOROMARU {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.KOSHU {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.KOSON {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.KOT {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.KOTO {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.KP2R {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.KP3R {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.KP3RB {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.KP4R {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.KPAD {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.KPC {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.KPER {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.KPETS {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.KPHI {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.KPLAY {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.KPOP {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.KRAKENSQUID {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.KRAUSE {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.KRB {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.KRD {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.KREDS {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.KRG {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.KRIDA {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.KRILL {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.KRL {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.KRM {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.KRN {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.KRNO {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.KRO {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.KROM {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.KRONOS {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.KROOT {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.KRRX {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.KRS {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.LTCHALF {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.KRT {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.KRW {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.KRWT {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.KRX {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.KRYPTO {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.KRZ {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.KS {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.KS2 {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.KSAMO {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.KSC {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.KSE {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.KSEED {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.KSF {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.KSG {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.KSH {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.KSHIB {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.KSHIBA {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.KSHIBINU {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.KSLV {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.KSM {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.KSP {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.KSR {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.KSS {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.KST {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.KSTT {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.KSW {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.KT {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.KTC {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.KTE {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.KTETH {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.KTLYO {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.KTN {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.KTO {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.KTON {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.KTS {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.KTT {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.KTV {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.KTX {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.KTY {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.KTZ {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.KUB {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.KUBE {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.KUBO {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.KUDO {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.KUE {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.KUJI {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.KUKA {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.KUKU {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.KUMA {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.KUN {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.KUNCI {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.KUNO {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.KUNU {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.KURAI {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.KURO {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.KURT {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.KUS {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.KUST {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.KUSUNOKI {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.KUT {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.KUV {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.KUY {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.KVA {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.KVERSE {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.KVI {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.KVNT {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.KVT {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.KWATT {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.KWH {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.KWIK {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.KWOOF {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.KWS {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.KWT {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.KXA {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.KXC {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.KYANITE {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.KYCC {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.KYDC {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.KYF {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.KYI {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.KYL {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.KYOKO {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.KYSC {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.KYSR {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.KYTE {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.KYUB {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.KZ {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.KZC {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.KZE {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.KZEN {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.KZN {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.L1T {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.L2 {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.L2P {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.L2PAD {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.L3P {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.L9 {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.L99 {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.LA {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.LABH {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.LABO {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.LABRA {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.LABS {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.LAB_v2 {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.LABX {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.LAC {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.LACE {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.LAD {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.LADA {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.LAEEB {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.LAI {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.LAIKA {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.LALA {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.LAMA {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.LAMB {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.LAMBO {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.LAMBOINU {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.LAMBS {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.Laminar {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.LANA {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.LANC {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.LAND {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.LANDOFDOGES {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.LAR {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.LARIX {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.LARO {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.LAS {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.LASEREYES {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.LASM {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.LASSECASH {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.LASSO {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.LAT {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.LATTE {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.LATX {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.LAUNCH {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.LAVA {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.LAVAFALL {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.LAVAX {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.LAW {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.LAYER {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.LAYERX {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.LAYR {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.LAZIO {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.LAZYSHIBA {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.LBA {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.LBC {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.LBCC {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.LBD {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.LBIT {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.LBK {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.LBL {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.LBLOCK {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.LBN {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.LBO {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.LBOT {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.LBP {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.LBR {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.LBS {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.LBTC {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.Lburst {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.LBXC {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.LBY {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.LC {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.LCC {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.LCD {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.LCDOT {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.LCDP {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.LCG {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.LCH {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.LCINU {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.LCMS {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.LCP {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.LCS {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.LCT {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.LCX {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.LDC {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.LDDP {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.LDF {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.LDFI {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.LDG {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.LDM {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.LDN {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.LDO {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.LDOGE {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.LDOT {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.LDT {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.LDX {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.LDXG {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.LEA {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.LEAD {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.LEAF {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.LEAFTY {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.LEAG {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.LEAN {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.LEASH {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.LEC {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.LECLIENTE {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.LED {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.LEDU {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.LEG {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.LEGEND {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.LEGO {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.LELE {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.LEM {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.LEMC {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.LEMD {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.LEMN {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.LEMO {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.LEND {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.LENDA {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.LENS {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.LEO {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.LEOMOON {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.LTCDOOM {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.LINKBULL {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.LTCMOON {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.LINKBEAR {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.LEON {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.LEONIDAS {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.LEOPARD {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.LEOS {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.LEP {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.LEPA {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.LESLAR {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.LESS {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.LET {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.LETSGO {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.LEV {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.LEVELG {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.LEVL {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.LEVO {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.LEWK {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.LEX {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.LEXI {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.LEZ {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.LF {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.LFC {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.LFEC {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.LFG {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.LFI {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.LFNTY {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.LFT {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.LFW {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.LGB {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.LGBT {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.LGBTQ {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.LGC {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.LGCY {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.LGG {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.LGND {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.LGO {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.LGOLD {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.LGS {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.LGX {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.LHB {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.LHC {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.LHRC {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.LHT {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.LIB {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.LIBERO {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.LIBERTAS {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.LIBERTE {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.LIBF {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.LIBFX {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.LIBRE {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.LIBREF {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.LICK {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.LICO {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.LICP {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.LID {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.LIEN {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.LIF {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.LIF3 {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.LIFE {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}

.LIFEBIRD {
  width: 16px;
  height: 16px;
  background-position: -208px -416px;
}

.LIFT {
  width: 16px;
  height: 16px;
  background-position: -224px -416px;
}

.LIGHT {
  width: 16px;
  height: 16px;
  background-position: -240px -416px;
}

.LIKE {
  width: 16px;
  height: 16px;
  background-position: -256px -416px;
}

.LIL {
  width: 16px;
  height: 16px;
  background-position: -272px -416px;
}

.LILDOGEFLOKI {
  width: 16px;
  height: 16px;
  background-position: -288px -416px;
}

.LILFLOKI {
  width: 16px;
  height: 16px;
  background-position: -304px -416px;
}

.LILTK {
  width: 16px;
  height: 16px;
  background-position: -320px -416px;
}

.LILY {
  width: 16px;
  height: 16px;
  background-position: -336px -416px;
}

.LIME {
  width: 16px;
  height: 16px;
  background-position: -352px -416px;
}

.LIMEX {
  width: 16px;
  height: 16px;
  background-position: -368px -416px;
}

.LIMIT {
  width: 16px;
  height: 16px;
  background-position: -384px -416px;
}

.LIMON {
  width: 16px;
  height: 16px;
  background-position: -400px -416px;
}

.LINA {
  width: 16px;
  height: 16px;
  background-position: -416px -416px;
}

.LINEAR {
  width: 16px;
  height: 16px;
  background-position: -432px -0;
}

.LINK {
  width: 16px;
  height: 16px;
  background-position: -432px -16px;
}

.LINKA {
  width: 16px;
  height: 16px;
  background-position: -432px -32px;
}

.LINKUP {
  width: 16px;
  height: 16px;
  background-position: -432px -48px;
}

.LINKDOWN {
  width: 16px;
  height: 16px;
  background-position: -432px -64px;
}

.LTCUP {
  width: 16px;
  height: 16px;
  background-position: -432px -80px;
}

.LTCDOWN {
  width: 16px;
  height: 16px;
  background-position: -432px -96px;
}

.LINKETHPA {
  width: 16px;
  height: 16px;
  background-position: -432px -112px;
}

.LINKETHRSI {
  width: 16px;
  height: 16px;
  background-position: -432px -128px;
}

.LINKPT {
  width: 16px;
  height: 16px;
  background-position: -432px -144px;
}

.LINKRSICO {
  width: 16px;
  height: 16px;
  background-position: -432px -160px;
}

.LINKS {
  width: 16px;
  height: 16px;
  background-position: -432px -176px;
}

.LINKUSD {
  width: 16px;
  height: 16px;
  background-position: -432px -192px;
}

.LINSPIRIT {
  width: 16px;
  height: 16px;
  background-position: -432px -208px;
}

.LINU {
  width: 16px;
  height: 16px;
  background-position: -432px -224px;
}

.LINX {
  width: 16px;
  height: 16px;
  background-position: -432px -240px;
}

.LIO {
  width: 16px;
  height: 16px;
  background-position: -432px -256px;
}

.Lion {
  width: 16px;
  height: 16px;
  background-position: -432px -272px;
}

.LION {
  width: 16px;
  height: 16px;
  background-position: -432px -288px;
}

.LIQ {
  width: 16px;
  height: 16px;
  background-position: -432px -304px;
}

.LIQLO {
  width: 16px;
  height: 16px;
  background-position: -432px -320px;
}

.LIQR {
  width: 16px;
  height: 16px;
  background-position: -432px -336px;
}

.LIQUID {
  width: 16px;
  height: 16px;
  background-position: -432px -352px;
}

.LIST {
  width: 16px;
  height: 16px;
  background-position: -432px -368px;
}

.LIT {
  width: 16px;
  height: 16px;
  background-position: -432px -384px;
}

.LITH {
  width: 16px;
  height: 16px;
  background-position: -432px -400px;
}

.LITHO {
  width: 16px;
  height: 16px;
  background-position: -432px -416px;
}

.LITO {
  width: 16px;
  height: 16px;
  background-position: -0 -432px;
}

.LITTLEDOGE {
  width: 16px;
  height: 16px;
  background-position: -16px -432px;
}

.LIU {
  width: 16px;
  height: 16px;
  background-position: -32px -432px;
}

.LIVE {
  width: 16px;
  height: 16px;
  background-position: -48px -432px;
}

.LIVENFT {
  width: 16px;
  height: 16px;
  background-position: -64px -432px;
}

.LIZARD {
  width: 16px;
  height: 16px;
  background-position: -80px -432px;
}

.LK3R {
  width: 16px;
  height: 16px;
  background-position: -96px -432px;
}

.LKK {
  width: 16px;
  height: 16px;
  background-position: -112px -432px;
}

.LKM {
  width: 16px;
  height: 16px;
  background-position: -128px -432px;
}

.LKN {
  width: 16px;
  height: 16px;
  background-position: -144px -432px;
}

.LKR {
  width: 16px;
  height: 16px;
  background-position: -160px -432px;
}

.LKSC {
  width: 16px;
  height: 16px;
  background-position: -176px -432px;
}

.LKSM {
  width: 16px;
  height: 16px;
  background-position: -192px -432px;
}

.LKT {
  width: 16px;
  height: 16px;
  background-position: -208px -432px;
}

.LKU {
  width: 16px;
  height: 16px;
  background-position: -224px -432px;
}

.LKY {
  width: 16px;
  height: 16px;
  background-position: -240px -432px;
}

.LL {
  width: 16px;
  height: 16px;
  background-position: -256px -432px;
}

.LLAND {
  width: 16px;
  height: 16px;
  background-position: -272px -432px;
}

.LLC {
  width: 16px;
  height: 16px;
  background-position: -288px -432px;
}

.LLELL {
  width: 16px;
  height: 16px;
  background-position: -304px -432px;
}

.LLG {
  width: 16px;
  height: 16px;
  background-position: -320px -432px;
}

.LLN {
  width: 16px;
  height: 16px;
  background-position: -336px -432px;
}

.LLT {
  width: 16px;
  height: 16px;
  background-position: -352px -432px;
}

.LLTH {
  width: 16px;
  height: 16px;
  background-position: -368px -432px;
}

.LLVERSE {
  width: 16px;
  height: 16px;
  background-position: -384px -432px;
}

.LM {
  width: 16px;
  height: 16px;
  background-position: -400px -432px;
}

.LMAO {
  width: 16px;
  height: 16px;
  background-position: -416px -432px;
}

.LMC {
  width: 16px;
  height: 16px;
  background-position: -432px -432px;
}

.LMCH {
  width: 16px;
  height: 16px;
  background-position: -448px -0;
}

.LMCSWAP {
  width: 16px;
  height: 16px;
  background-position: -448px -16px;
}

.LMG {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}

.LML {
  width: 16px;
  height: 16px;
  background-position: -448px -48px;
}

.LMN {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}

.LMR {
  width: 16px;
  height: 16px;
  background-position: -448px -80px;
}

.LMS {
  width: 16px;
  height: 16px;
  background-position: -448px -96px;
}

.LMT {
  width: 16px;
  height: 16px;
  background-position: -448px -112px;
}

.LMY {
  width: 16px;
  height: 16px;
  background-position: -448px -128px;
}

.LN {
  width: 16px;
  height: 16px;
  background-position: -448px -144px;
}

.LNC {
  width: 16px;
  height: 16px;
  background-position: -448px -160px;
}

.LNCX {
  width: 16px;
  height: 16px;
  background-position: -448px -176px;
}

.LND {
  width: 16px;
  height: 16px;
  background-position: -448px -192px;
}

.LNFS {
  width: 16px;
  height: 16px;
  background-position: -448px -208px;
}

.LNO {
  width: 16px;
  height: 16px;
  background-position: -448px -224px;
}

.LNOT {
  width: 16px;
  height: 16px;
  background-position: -448px -240px;
}

.LNR {
  width: 16px;
  height: 16px;
  background-position: -448px -256px;
}

.LNT {
  width: 16px;
  height: 16px;
  background-position: -448px -272px;
}

.LNX {
  width: 16px;
  height: 16px;
  background-position: -448px -288px;
}

.LO {
  width: 16px;
  height: 16px;
  background-position: -448px -304px;
}

.LOA {
  width: 16px;
  height: 16px;
  background-position: -448px -320px;
}

.LOAD {
  width: 16px;
  height: 16px;
  background-position: -448px -336px;
}

.LOBI {
  width: 16px;
  height: 16px;
  background-position: -448px -352px;
}

.LOBS {
  width: 16px;
  height: 16px;
  background-position: -448px -368px;
}

.LOC {
  width: 16px;
  height: 16px;
  background-position: -448px -384px;
}

.LOCAL {
  width: 16px;
  height: 16px;
  background-position: -448px -400px;
}

.LOCC {
  width: 16px;
  height: 16px;
  background-position: -448px -416px;
}

.LOCG {
  width: 16px;
  height: 16px;
  background-position: -448px -432px;
}

.LOCI {
  width: 16px;
  height: 16px;
  background-position: -0 -448px;
}

.LOCK {
  width: 16px;
  height: 16px;
  background-position: -16px -448px;
}

.LOCKPAY {
  width: 16px;
  height: 16px;
  background-position: -32px -448px;
}

.LOCO {
  width: 16px;
  height: 16px;
  background-position: -48px -448px;
}

.LOCUS {
  width: 16px;
  height: 16px;
  background-position: -64px -448px;
}

.LOF {
  width: 16px;
  height: 16px;
  background-position: -80px -448px;
}

.LOFI {
  width: 16px;
  height: 16px;
  background-position: -96px -448px;
}

.LOG {
  width: 16px;
  height: 16px;
  background-position: -112px -448px;
}

.LOGE {
  width: 16px;
  height: 16px;
  background-position: -128px -448px;
}

.LOGOS {
  width: 16px;
  height: 16px;
  background-position: -144px -448px;
}

.LOKA {
  width: 16px;
  height: 16px;
  background-position: -160px -448px;
}

.LOL {
  width: 16px;
  height: 16px;
  background-position: -176px -448px;
}

.LOLLI {
  width: 16px;
  height: 16px;
  background-position: -192px -448px;
}

.LOLZ {
  width: 16px;
  height: 16px;
  background-position: -208px -448px;
}

.LOM {
  width: 16px;
  height: 16px;
  background-position: -224px -448px;
}

.LON {
  width: 16px;
  height: 16px;
  background-position: -240px -448px;
}

.LONDON {
  width: 16px;
  height: 16px;
  background-position: -256px -448px;
}

.LONG {
  width: 16px;
  height: 16px;
  background-position: -272px -448px;
}

.LOOF {
  width: 16px;
  height: 16px;
  background-position: -288px -448px;
}

.LOOKS {
  width: 16px;
  height: 16px;
  background-position: -304px -448px;
}

.LOOM {
  width: 16px;
  height: 16px;
  background-position: -320px -448px;
}

.LOOMI {
  width: 16px;
  height: 16px;
  background-position: -336px -448px;
}

.LOON {
  width: 16px;
  height: 16px;
  background-position: -352px -448px;
}

.LOOP {
  width: 16px;
  height: 16px;
  background-position: -368px -448px;
}

.LOOPS {
  width: 16px;
  height: 16px;
  background-position: -384px -448px;
}

.LOOT {
  width: 16px;
  height: 16px;
  background-position: -400px -448px;
}

.LOOTDOLLAR {
  width: 16px;
  height: 16px;
  background-position: -416px -448px;
}

.LOOX {
  width: 16px;
  height: 16px;
  background-position: -432px -448px;
}

.LOR {
  width: 16px;
  height: 16px;
  background-position: -448px -448px;
}

.LORC {
  width: 16px;
  height: 16px;
  background-position: -464px -0;
}

.LORD {
  width: 16px;
  height: 16px;
  background-position: -464px -16px;
}

.LORDA {
  width: 16px;
  height: 16px;
  background-position: -464px -32px;
}

.LORDS {
  width: 16px;
  height: 16px;
  background-position: -464px -48px;
}

.LORDZ {
  width: 16px;
  height: 16px;
  background-position: -464px -64px;
}

.LORI {
  width: 16px;
  height: 16px;
  background-position: -464px -80px;
}

.LORY {
  width: 16px;
  height: 16px;
  background-position: -464px -96px;
}

.LOS {
  width: 16px;
  height: 16px;
  background-position: -464px -112px;
}

.LOST {
  width: 16px;
  height: 16px;
  background-position: -464px -128px;
}

.LOT {
  width: 16px;
  height: 16px;
  background-position: -464px -144px;
}

.LOTA {
  width: 16px;
  height: 16px;
  background-position: -464px -160px;
}

.LOTEU {
  width: 16px;
  height: 16px;
  background-position: -464px -176px;
}

.LOTT {
  width: 16px;
  height: 16px;
  background-position: -464px -192px;
}

.LOTTO {
  width: 16px;
  height: 16px;
  background-position: -464px -208px;
}

.Lotus {
  width: 16px;
  height: 16px;
  background-position: -464px -224px;
}

.LOUD {
  width: 16px;
  height: 16px;
  background-position: -464px -240px;
}

.LOUIEDUCKT {
  width: 16px;
  height: 16px;
  background-position: -464px -256px;
}

.LOUVRE {
  width: 16px;
  height: 16px;
  background-position: -464px -272px;
}

.LOV {
  width: 16px;
  height: 16px;
  background-position: -464px -288px;
}

.LOVE {
  width: 16px;
  height: 16px;
  background-position: -464px -304px;
}

.LOVELY {
  width: 16px;
  height: 16px;
  background-position: -464px -320px;
}

.LOVES {
  width: 16px;
  height: 16px;
  background-position: -464px -336px;
}

.LOVIL {
  width: 16px;
  height: 16px;
  background-position: -464px -352px;
}

.LOWB {
  width: 16px;
  height: 16px;
  background-position: -464px -368px;
}

.LOX {
  width: 16px;
  height: 16px;
  background-position: -464px -384px;
}

.LOZ {
  width: 16px;
  height: 16px;
  background-position: -464px -400px;
}

.LPC {
  width: 16px;
  height: 16px;
  background-position: -464px -416px;
}

.LPDI {
  width: 16px;
  height: 16px;
  background-position: -464px -432px;
}

.LPI {
  width: 16px;
  height: 16px;
  background-position: -464px -448px;
}

.LPK {
  width: 16px;
  height: 16px;
  background-position: -0 -464px;
}

.LPL {
  width: 16px;
  height: 16px;
  background-position: -16px -464px;
}

.LPNT {
  width: 16px;
  height: 16px;
  background-position: -32px -464px;
}

.LPO {
  width: 16px;
  height: 16px;
  background-position: -48px -464px;
}

.LPOOL {
  width: 16px;
  height: 16px;
  background-position: -64px -464px;
}

.LPS {
  width: 16px;
  height: 16px;
  background-position: -80px -464px;
}

.LPT {
  width: 16px;
  height: 16px;
  background-position: -96px -464px;
}

.LPY {
  width: 16px;
  height: 16px;
  background-position: -112px -464px;
}

.LQ {
  width: 16px;
  height: 16px;
  background-position: -128px -464px;
}

.LQD {
  width: 16px;
  height: 16px;
  background-position: -144px -464px;
}

.LQDR {
  width: 16px;
  height: 16px;
  background-position: -160px -464px;
}

.LQF {
  width: 16px;
  height: 16px;
  background-position: -176px -464px;
}

.LQR {
  width: 16px;
  height: 16px;
  background-position: -192px -464px;
}

.LQT {
  width: 16px;
  height: 16px;
  background-position: -208px -464px;
}

.LQTY {
  width: 16px;
  height: 16px;
  background-position: -224px -464px;
}

.LR {
  width: 16px;
  height: 16px;
  background-position: -240px -464px;
}

.LRA {
  width: 16px;
  height: 16px;
  background-position: -256px -464px;
}

.LRC {
  width: 16px;
  height: 16px;
  background-position: -272px -464px;
}

.LRG {
  width: 16px;
  height: 16px;
  background-position: -288px -464px;
}

.LRM {
  width: 16px;
  height: 16px;
  background-position: -304px -464px;
}

.LSC {
  width: 16px;
  height: 16px;
  background-position: -320px -464px;
}

.LSH {
  width: 16px;
  height: 16px;
  background-position: -336px -464px;
}

.LSHARE {
  width: 16px;
  height: 16px;
  background-position: -352px -464px;
}

.LSILVER {
  width: 16px;
  height: 16px;
  background-position: -368px -464px;
}

.LSK {
  width: 16px;
  height: 16px;
  background-position: -384px -464px;
}

.LSP {
  width: 16px;
  height: 16px;
  background-position: -400px -464px;
}

.LSR {
  width: 16px;
  height: 16px;
  background-position: -416px -464px;
}

.LSS {
  width: 16px;
  height: 16px;
  background-position: -432px -464px;
}

.LST {
  width: 16px;
  height: 16px;
  background-position: -448px -464px;
}

.LSTAR {
  width: 16px;
  height: 16px;
  background-position: -464px -464px;
}

.LSTN {
  width: 16px;
  height: 16px;
  background-position: -480px -0;
}

.LSV {
  width: 16px;
  height: 16px;
  background-position: -480px -16px;
}

.LSWAP {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}

.LT {
  width: 16px;
  height: 16px;
  background-position: -480px -48px;
}

.LTB {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}

.LTC {
  width: 16px;
  height: 16px;
  background-position: -480px -80px;
}

.LTCBEAR {
  width: 16px;
  height: 16px;
  background-position: -480px -96px;
}

.LTCBULL {
  width: 16px;
  height: 16px;
  background-position: -480px -112px;
}

.LTCP {
  width: 16px;
  height: 16px;
  background-position: -480px -128px;
}

.LTCR {
  width: 16px;
  height: 16px;
  background-position: -480px -144px;
}

.LTCU {
  width: 16px;
  height: 16px;
  background-position: -480px -160px;
}

.LTD {
  width: 16px;
  height: 16px;
  background-position: -480px -176px;
}

.LTE {
  width: 16px;
  height: 16px;
  background-position: -480px -192px;
}

.LTEX {
  width: 16px;
  height: 16px;
  background-position: -480px -208px;
}

.LTFM {
  width: 16px;
  height: 16px;
  background-position: -480px -224px;
}

.LTFN {
  width: 16px;
  height: 16px;
  background-position: -480px -240px;
}

.LTHN {
  width: 16px;
  height: 16px;
  background-position: -480px -256px;
}

.LTK {
  width: 16px;
  height: 16px;
  background-position: -480px -272px;
}

.LTMS {
  width: 16px;
  height: 16px;
  background-position: -480px -288px;
}

.LTN {
  width: 16px;
  height: 16px;
  background-position: -480px -304px;
}

.LTNM {
  width: 16px;
  height: 16px;
  background-position: -480px -320px;
}

.LTNV2 {
  width: 16px;
  height: 16px;
  background-position: -480px -336px;
}

.LTO {
  width: 16px;
  height: 16px;
  background-position: -480px -352px;
}

.LTP {
  width: 16px;
  height: 16px;
  background-position: -480px -368px;
}

.LTR {
  width: 16px;
  height: 16px;
  background-position: -480px -384px;
}

.LTRBT {
  width: 16px;
  height: 16px;
  background-position: -480px -400px;
}

.LTS {
  width: 16px;
  height: 16px;
  background-position: -480px -416px;
}

.LTSG {
  width: 16px;
  height: 16px;
  background-position: -480px -432px;
}

.LTT {
  width: 16px;
  height: 16px;
  background-position: -480px -448px;
}

.LTVN {
  width: 16px;
  height: 16px;
  background-position: -480px -464px;
}

.LTX {
  width: 16px;
  height: 16px;
  background-position: -0 -480px;
}

.LTY {
  width: 16px;
  height: 16px;
  background-position: -16px -480px;
}

.LTZ {
  width: 16px;
  height: 16px;
  background-position: -32px -480px;
}

.LUA {
  width: 16px;
  height: 16px;
  background-position: -48px -480px;
}

.LUAN {
  width: 16px;
  height: 16px;
  background-position: -64px -480px;
}

.LUART {
  width: 16px;
  height: 16px;
  background-position: -80px -480px;
}

.LUB {
  width: 16px;
  height: 16px;
  background-position: -96px -480px;
}

.LUC {
  width: 16px;
  height: 16px;
  background-position: -112px -480px;
}

.LUCA {
  width: 16px;
  height: 16px;
  background-position: -128px -480px;
}

.LUCHOW {
  width: 16px;
  height: 16px;
  background-position: -144px -480px;
}

.LUCK {
  width: 16px;
  height: 16px;
  background-position: -160px -480px;
}

.LUCKY {
  width: 16px;
  height: 16px;
  background-position: -176px -480px;
}

.LUCKYCLOVER {
  width: 16px;
  height: 16px;
  background-position: -192px -480px;
}

.LUCKYDOGE {
  width: 16px;
  height: 16px;
  background-position: -208px -480px;
}

.LuckyPig {
  width: 16px;
  height: 16px;
  background-position: -224px -480px;
}

.LUCY {
  width: 16px;
  height: 16px;
  background-position: -240px -480px;
}

.LUD {
  width: 16px;
  height: 16px;
  background-position: -256px -480px;
}

.LUDO {
  width: 16px;
  height: 16px;
  background-position: -272px -480px;
}

.LUFC {
  width: 16px;
  height: 16px;
  background-position: -288px -480px;
}

.LUFFY {
  width: 16px;
  height: 16px;
  background-position: -304px -480px;
}

.LUFX {
  width: 16px;
  height: 16px;
  background-position: -320px -480px;
}

.LUM {
  width: 16px;
  height: 16px;
  background-position: -336px -480px;
}

.LUMEN {
  width: 16px;
  height: 16px;
  background-position: -352px -480px;
}

.LUMI {
  width: 16px;
  height: 16px;
  background-position: -368px -480px;
}

.LUMOSX {
  width: 16px;
  height: 16px;
  background-position: -384px -480px;
}

.LUN {
  width: 16px;
  height: 16px;
  background-position: -400px -480px;
}

.LUNA {
  width: 16px;
  height: 16px;
  background-position: -416px -480px;
}

.LUNAINU {
  width: 16px;
  height: 16px;
  background-position: -432px -480px;
}

.LUNAM {
  width: 16px;
  height: 16px;
  background-position: -448px -480px;
}

.LUNAPAD {
  width: 16px;
  height: 16px;
  background-position: -464px -480px;
}

.LUNAR {
  width: 16px;
  height: 16px;
  background-position: -480px -480px;
}

.LUNAX {
  width: 16px;
  height: 16px;
  background-position: -496px -0;
}

.LUNC {
  width: 16px;
  height: 16px;
  background-position: -496px -16px;
}

.LUNCH {
  width: 16px;
  height: 16px;
  background-position: -496px -32px;
}

.LUNES {
  width: 16px;
  height: 16px;
  background-position: -496px -48px;
}

.LUNI {
  width: 16px;
  height: 16px;
  background-position: -496px -64px;
}

.LUNR {
  width: 16px;
  height: 16px;
  background-position: -496px -80px;
}

.LUNU {
  width: 16px;
  height: 16px;
  background-position: -496px -96px;
}

.LUS {
  width: 16px;
  height: 16px;
  background-position: -496px -112px;
}

.LUSD {
  width: 16px;
  height: 16px;
  background-position: -496px -128px;
}

.LUSHI {
  width: 16px;
  height: 16px;
  background-position: -496px -144px;
}

.LUST {
  width: 16px;
  height: 16px;
  background-position: -496px -160px;
}

.LUTO {
  width: 16px;
  height: 16px;
  background-position: -496px -176px;
}

.LUV {
  width: 16px;
  height: 16px;
  background-position: -496px -192px;
}

.LUX {
  width: 16px;
  height: 16px;
  background-position: -496px -208px;
}

.LUXE {
  width: 16px;
  height: 16px;
  background-position: -496px -224px;
}

.LUXO {
  width: 16px;
  height: 16px;
  background-position: -496px -240px;
}

.LUXY {
  width: 16px;
  height: 16px;
  background-position: -496px -256px;
}

.LVBD {
  width: 16px;
  height: 16px;
  background-position: -496px -272px;
}

.LVH {
  width: 16px;
  height: 16px;
  background-position: -496px -288px;
}

.LVL {
  width: 16px;
  height: 16px;
  background-position: -496px -304px;
}

.LVLUP {
  width: 16px;
  height: 16px;
  background-position: -496px -320px;
}

.LVN {
  width: 16px;
  height: 16px;
  background-position: -496px -336px;
}

.LVT {
  width: 16px;
  height: 16px;
  background-position: -496px -352px;
}

.LVX {
  width: 16px;
  height: 16px;
  background-position: -496px -368px;
}

.LWAZI {
  width: 16px;
  height: 16px;
  background-position: -496px -384px;
}

.LXF {
  width: 16px;
  height: 16px;
  background-position: -496px -400px;
}

.LXT {
  width: 16px;
  height: 16px;
  background-position: -496px -416px;
}

.LXTO {
  width: 16px;
  height: 16px;
  background-position: -496px -432px;
}

.Ly {
  width: 16px;
  height: 16px;
  background-position: -496px -448px;
}

.LYCA {
  width: 16px;
  height: 16px;
  background-position: -496px -464px;
}

.LYD {
  width: 16px;
  height: 16px;
  background-position: -496px -480px;
}

.LYFE {
  width: 16px;
  height: 16px;
  background-position: -0 -496px;
}

.LYKA {
  width: 16px;
  height: 16px;
  background-position: -16px -496px;
}

.LYL {
  width: 16px;
  height: 16px;
  background-position: -32px -496px;
}

.LYM {
  width: 16px;
  height: 16px;
  background-position: -48px -496px;
}

.LYNC {
  width: 16px;
  height: 16px;
  background-position: -64px -496px;
}

.LYNK {
  width: 16px;
  height: 16px;
  background-position: -80px -496px;
}

.LYNX {
  width: 16px;
  height: 16px;
  background-position: -96px -496px;
}

.LYO {
  width: 16px;
  height: 16px;
  background-position: -112px -496px;
}

.LYPTUS {
  width: 16px;
  height: 16px;
  background-position: -128px -496px;
}

.LYR {
  width: 16px;
  height: 16px;
  background-position: -144px -496px;
}

.LYRA {
  width: 16px;
  height: 16px;
  background-position: -160px -496px;
}

.LYS {
  width: 16px;
  height: 16px;
  background-position: -176px -496px;
}

.LYTX {
  width: 16px;
  height: 16px;
  background-position: -192px -496px;
}

.LYXe {
  width: 16px;
  height: 16px;
  background-position: -208px -496px;
}

.LZ {
  width: 16px;
  height: 16px;
  background-position: -224px -496px;
}

.LZE {
  width: 16px;
  height: 16px;
  background-position: -240px -496px;
}

.LZN {
  width: 16px;
  height: 16px;
  background-position: -256px -496px;
}

.LZP {
  width: 16px;
  height: 16px;
  background-position: -272px -496px;
}
