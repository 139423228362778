.wobble {
  animation: wobbler 1s linear infinite;
}

@keyframes wobbler {
  25% {
    transform: rotate(15deg);
  }

  50% {
    transform: rotate(-30deg);
  }

  75% {
    transform: rotate(5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
