.V,
.V\$,
.VAB,
.VACAY,
.vADA,
.VADER,
.VADS,
.VAGA,
.VAI,
.VAIN,
.VAIP,
.VAL,
.VALAS,
.VALK,
.VALOR,
.VALUE,
.VAMP,
.VAN,
.VANA,
.VANCAT,
.VANCII,
.VANTA,
.VANY,
.VAP,
.VARC,
.VARIANTS,
.VASE,
.VAT,
.VAULT,
.VAULT_S,
.VAULTZ,
.vBCH,
.VBETH,
.VBit,
.VBIT,
.VBK,
.VBN,
.vBNB,
.VBNT,
.VBSWAP,
.VBT,
.vBTC,
.vBUSD,
.VBZRX,
.VC,
.VCAKE,
.VCCO,
.VCE,
.VCF,
.VCG,
.VCK,
.VCO,
.VCT,
.VD,
.vDAI,
.VDG,
.VDGT,
.VDL,
.vDOGE,
.VDOGE,
.VDORA,
.vDOT,
.VDR,
.VDV,
.VDX,
.VEC2,
.VECO,
.VECT,
.VEDX,
.VEE,
.VEED,
.VEEN,
.VEF,
.VEGA,
.VEGAS,
.VEGI,
.VEGU,
.VEIL,
.VEKTOR,
.VELO,
.VEMP,
.VEN,
.VENA,
.VENT,
.VENTION,
.VENUS,
.VEO,
.VER,
.VERA,
.VERI,
.VERIFY,
.VERO,
.VERS,
.VERSA,
.VERSE,
.VERSUS,
.VERVE,
.VESPASHIBA,
.VEST,
.VESTA,
.VESTX,
.VET,
.VETBULL,
.VETBEAR,
.VETHEDGE,
.vETH,
.VETH,
.VETH2,
.VETTER,
.VEUSD,
.VEX,
.VEY,
.VFC,
.VFI,
.vFIL,
.VFOX,
.VFT,
.VGA,
.VGD,
.VGM,
.VGO,
.VGTN,
.VGW,
.VGX,
.VHC,
.VI,
.VIA,
.VIAGRA,
.VIB,
.VIBE,
.VIBLO,
.VIBRA,
.VIBS,
.VIC,
.VICA,
.VICE,
.VICEX,
.VICS,
.VID,
.VIDA,
.VIDT,
.VIDY,
.VIDYA,
.VIDYX,
.VIDZ,
.VIEW,
.VIG,
.VIK,
.VIKC,
.VIKING,
.VIKINGS,
.VIKKY,
.VIM,
.VIN,
.VINCI,
.VINU,
.VIP,
.VIPER,
.VIPS,
.VIR,
.VIRAL,
.VIRES,
.VIS,
.VISA,
.VISION,
.VISR,
.VIT,
.VITA,
.VITAE,
.VITAL,
.VITE,
.VITES,
.VITY,
.VIU,
.VIV,
.VIVA,
.VIVAION,
.VIVE,
.VIVID,
.VIVO,
.VIX,
.VIXG,
.VIZ,
.VIZSLASWAP,
.VJC,
.VKF,
.VKNF,
.VKR,
.VLAD,
.VLC,
.VLD,
.vLINK,
.VLK,
.VLM,
.VLO,
.VLS,
.VLT,
.vLTC,
.VLTY,
.VLU,
.VLX,
.VLXPAD,
.VMC,
.VME,
.VML,
.VMR,
.VMS,
.VN,
.VNDC,
.VNDT,
.VNL,
.VNLA,
.VNM,
.VNT,
.VNTW,
.VNX,
.VNXLU,
.VNY,
.VOCO,
.VODKA,
.VOICE,
.VOID,
.VOIDX,
.VOISE,
.VOL,
.VOLC,
.VOLLAR,
.VOLT,
.VOLTA,
.VOLTS,
.VOLTZ,
.VOO,
.VOT,
.VOTE,
.VOW,
.VOX,
.VOXEL,
.VOY,
.VOYCE,
.VOYRME,
.VP,
.VPAD,
.VPC,
.VPK,
.VPND,
.VPP,
.VPS,
.VPX,
.VR,
.VRA,
.VRAP,
.VRBLOCKS,
.VRC,
.VRFY,
.VRGX,
.VRM,
.VRN,
.VRO,
.VRS,
.VRSC,
.VRT,
.VRTN,
.VRX,
.VRYNT,
.VS,
.VSC,
.VSD,
.VSF,
.VSHARE,
.VSL,
.VSN,
.VSO,
.VSOL,
.VSP,
.VSPACEX,
.VSQ,
.VST,
.VSTA,
.VSU,
.VSX,
.vSXP,
.VSYS,
.VT,
.VTA,
.VTC,
.VTEX,
.VTG,
.VTHO,
.VTN,
.VTOPIA,
.VTT,
.VTUBE,
.VTX,
.VULC,
.vUSDC,
.vUSDT,
.VVL,
.VVS,
.VVT,
.VVV,
.VWAVE,
.VX,
.VXC,
.VXL,
.vXRP,
.VXT,
.VXV,
.vXVS,
.VYBE,
.VYFI,
.VYNC,
.VZT,
.W2E,
.W12,
.WAB,
.WABI,
.WAC,
.WACO,
.WAD,
.WADA,
.WADS,
.WAF,
.WAFERSE,
.WAG,
.WAGE,
.WAGMI,
.WAGMIGAMES,
.WAI,
.WAIF,
.WAIFER,
.WAIV,
.WAKA,
.WAL,
.WALE,
.WALKN,
.WALL,
.WALLET,
.WALLSTREETINU,
.WALMETA,
.WAM,
.WAMO,
.WAMPL,
.WAN,
.WANA,
.wANATHA,
.WANBTC,
.WAND,
.WANDER,
.WANEOS,
.WANETH,
.WANLINK,
.WANNA,
.WANSUSHI,
.WANUNI,
.WANUSDC,
.WANUSDT,
.WAP,
.WAR,
.WARE,
.WAROO,
.WARP,
.WARS,
.WAS,
.WASABI,
.WASP,
.WATCH,
.WATER,
.WATERFALL,
.WAULT,
.WAULTX,
.WAV3,
.WAVAX,
.WAVE,
.WAVES,
.WAXE,
.WAXP,
.WAY,
.WAYF,
.WB,
.WBB,
.WBBC,
.WBC,
.WBCH,
.WBET,
.WBI,
.WBIND,
.WBL,
.WBNB,
.WBOND,
.WBT,
.WBTC,
.WBTCBAM,
.WBX,
.WC,
.WCC,
.WCCX,
.WCELO,
.WCF,
.WCFG,
.WCH,
.WCK,
.WCM,
.WCO,
.wCRES,
.WCRO,
.WCS,
.WCSOV,
.WCT,
.WCT1,
.WCUSD,
.WDC,
.WDO,
.WDOGE,
.WDP,
.WDR,
.WDUCX,
.WE,
.WEAPON,
.WEAR,
.WEATHER,
.WEAVE,
.WEB,
.WEB2,
.WEB3,
.WEB3ALLBI,
.WEBD,
.WEBFOUR,
.WEBN,
.WEBOO,
.WEC,
.WEENIE,
.WEFI,
.WEFIN,
.WEGLD,
.WEGRO,
.WEIUP,
.WEL,
.WELA,
.WELD,
.WELL,
.WELT,
.WELUPS,
.WEMIX,
.WEMP,
.WEN,
.WENB,
.WENLAMBO,
.WENMOON,
.WENR,
.WES,
.WEST,
.WET,
.WETA,
.WETH,
.WEVE,
.WEVER,
.WEWON,
.WEX,
.WEXPOLY,
.WEYU,
.WFAIR,
.WFC,
.WFCT,
.WFIL,
.WFT,
.WFTM,
.WFUSE,
.WFX,
.WG0,
.WGames,
.WGC,
.WGIRL,
.WGL,
.WGLMR,
.WGMI,
.WGO,
.WGOLD,
.WGP,
.WGR,
.WGRT,
.WHACKD,
.Whale,
.WHALE,
.WHB,
.WHE,
.WHEAT,
.WHEEL,
.WHEN,
.WHENLAMBO,
.WHIRL,
.WHITE,
.WHIVE,
.WHL,
.WHOLE,
.wHOO,
.WHT,
.WHX,
.WIB,
.WICC,
.WICKED,
.WIDI,
.WIFEDOGE,
.WIFI,
.WIGO,
.WIKEN,
.WIKI,
.WILC,
.WILD,
.WILDF,
.WILLIE,
.WIMI,
.WIN,
.WINALAMBO,
.WINATESLA,
.WINB,
.WIND,
.WINDY,
.WINE,
.WING,
.WINGS,
.WINIPHONE,
.WINK,
.WINLAMBO,
.WINNINGS,
.WINR,
.WINRY,
.WINTER,
.WINTERFLOKI,
.WINU,
.WIOTX,
.WIPE,
.WIRE,
.WIRTUAL,
.WIS,
.WISB,
.WISE,
.WISH,
.WISHDOGE,
.WIT,
.WITCH,
.WIV,
.WIVA,
.WIX,
.WIZ,
.WIZARD,
.WIZZY,
.WJXN,
.WKAI,
.WKC,
.WKCS,
.WKD,
.WLB,
.WLD,
.WLEO,
.WLF,
.WLFGRL,
.WLITI,
.WLKN,
.WLLO,
.WLO,
.WLRS,
.WLS,
.WLT,
.WLUNC,
.WLVR,
.WMATIC,
.WMEMO,
.WMF,
.WMK,
.WMOVR,
.WMP,
.WMT,
.WMW,
.WNCE,
.WNCG,
.WND,
.WNDG95v2,
.WNDR,
.WNEAR,
.WNK,
.WNL,
.WNNW,
.WNOW,
.WNRZ,
.WVSOL,
.WNT,
.WNTR,
.WNXM,
.WNYC,
.WNZ,
.WOA,
.WOD,
.WOE,
.WOFM,
.WOJ,
.WOK,
.WOKT,
.WOL,
.WOLF,
.WOLFGIRL,
.WOLFI,
.WOLFIES,
.WOLFILAND,
.WOLFY,
.WOLVERINU,
.WOM,
.WOMEN,
.WOMI,
.WON,
.WONE,
.WOO,
.WOOD,
.WOOF,
.WOOFY,
.WOOL,
.WOOLONG,
.WOONK,
.WOOP,
.WORDL,
.WORK,
.WORLD,
.Worldcoin,
.WORM,
.WORMS,
.WORTH,
.WORX,
.WOT,
.WOTG,
.WOW,
.WOWS,
.WOZX,
.WP,
.WPC,
.WPKT,
.WPL,
.WPP,
.WPR,
.WPX,
.WQT,
.WRAITH,
.WRBTC,
.WRC,
.WRK,
.WRKZ,
.WRLD,
.WRLK,
.WRN,
.wROSE,
.WRX,
.WSB,
.WSC,
.WSCRT,
.WSD,
.WSDQ,
.WSF,
.WSG,
.WSHARE,
.WSHEC,
.WSHIB,
.WSHIFT,
.WSI,
.wSIENNA,
.WSN,
.WSO,
.WSOHM,
.WSOTE,
.WSOW,
.WSP,
.WSPORTS,
.WSPP,
.WST,
.WSTA,
.WSTETH,
.WSTR,
.WSWAP,
.WSYS,
.WT,
.WTA,
.WTC,
.WTF,
.WTK,
.WTL,
.WTLOS,
.WTM,
.WTN,
.WTP,
.WTR,
.WTRTL,
.WTRX,
.WTW,
.WTWOOL,
.WULA,
.WURK,
.WUSD,
.WVET,
.WVG0,
.WVLX,
.WWAN,
.WWB,
.WWCN,
.WWIN,
.WWY,
.WX,
.WXC,
.WXCH,
.wxDai,
.WXMR,
.WXT,
.WXTC,
.WYS,
.WZEC,
.WZM,
.WZRD,
.WZYON,
.WZYX {
  display: inline-block;
  background: url('../img/tokens_logo_VtoEndW.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.V {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.V\$ {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.VAB {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.VACAY {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.vADA {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.VADER {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.VADS {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.VAGA {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.VAI {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.VAIN {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.VAIP {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.VAL {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.VALAS {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.VALK {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.VALOR {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.VALUE {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.VAMP {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.VAN {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.VANA {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.VANCAT {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.VANCII {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.VANTA {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.VANY {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.VAP {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.VARC {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.VARIANTS {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.VASE {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.VAT {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.VAULT {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.VAULT_S {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.VAULTZ {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.vBCH {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.VBETH {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.VBit {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.VBIT {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.VBK {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.VBN {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.vBNB {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.VBNT {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.VBSWAP {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.VBT {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.vBTC {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.vBUSD {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.VBZRX {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.VC {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.VCAKE {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.VCCO {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.VCE {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.VCF {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.VCG {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.VCK {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.VCO {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.VCT {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.VD {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.vDAI {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.VDG {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.VDGT {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.VDL {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.vDOGE {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.VDOGE {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.VDORA {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.vDOT {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.VDR {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.VDV {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.VDX {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.VEC2 {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.VECO {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.VECT {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.VEDX {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.VEE {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.VEED {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.VEEN {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.VEF {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.VEGA {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.VEGAS {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.VEGI {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.VEGU {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.VEIL {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.VEKTOR {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.VELO {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.VEMP {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.VEN {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.VENA {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.VENT {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.VENTION {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.VENUS {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.VEO {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.VER {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.VERA {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.VERI {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.VERIFY {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.VERO {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.VERS {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.VERSA {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.VERSE {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.VERSUS {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.VERVE {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.VESPASHIBA {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.VEST {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.VESTA {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.VESTX {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.VET {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.VETBULL {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.VETBEAR {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.VETHEDGE {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.vETH {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.VETH {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.VETH2 {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.VETTER {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.VEUSD {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.VEX {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.VEY {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.VFC {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.VFI {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.vFIL {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.VFOX {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.VFT {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.VGA {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.VGD {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.VGM {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.VGO {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.VGTN {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.VGW {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.VGX {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.VHC {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.VI {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.VIA {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.VIAGRA {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.VIB {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.VIBE {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.VIBLO {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.VIBRA {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.VIBS {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.VIC {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.VICA {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.VICE {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.VICEX {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.VICS {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.VID {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.VIDA {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.VIDT {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.VIDY {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.VIDYA {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.VIDYX {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.VIDZ {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.VIEW {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.VIG {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.VIK {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.VIKC {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.VIKING {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.VIKINGS {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.VIKKY {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.VIM {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.VIN {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.VINCI {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.VINU {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.VIP {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.VIPER {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.VIPS {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.VIR {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.VIRAL {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.VIRES {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.VIS {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.VISA {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.VISION {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.VISR {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.VIT {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.VITA {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.VITAE {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.VITAL {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.VITE {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.VITES {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.VITY {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.VIU {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.VIV {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.VIVA {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.VIVAION {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.VIVE {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.VIVID {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.VIVO {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.VIX {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.VIXG {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.VIZ {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.VIZSLASWAP {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.VJC {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.VKF {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.VKNF {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.VKR {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.VLAD {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.VLC {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.VLD {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.vLINK {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.VLK {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.VLM {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.VLO {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.VLS {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.VLT {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.vLTC {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.VLTY {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.VLU {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.VLX {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.VLXPAD {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.VMC {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.VME {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.VML {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.VMR {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.VMS {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.VN {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.VNDC {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.VNDT {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.VNL {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.VNLA {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.VNM {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.VNT {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.VNTW {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.VNX {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.VNXLU {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.VNY {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.VOCO {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.VODKA {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.VOICE {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.VOID {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.VOIDX {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.VOISE {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.VOL {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.VOLC {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.VOLLAR {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.VOLT {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.VOLTA {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.VOLTS {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.VOLTZ {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.VOO {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.VOT {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.VOTE {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.VOW {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.VOX {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.VOXEL {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.VOY {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.VOYCE {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.VOYRME {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.VP {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.VPAD {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.VPC {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.VPK {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.VPND {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.VPP {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.VPS {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.VPX {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.VR {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.VRA {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.VRAP {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.VRBLOCKS {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.VRC {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.VRFY {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.VRGX {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.VRM {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.VRN {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.VRO {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.VRS {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.VRSC {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.VRT {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.VRTN {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.VRX {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.VRYNT {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.VS {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.VSC {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.VSD {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.VSF {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.VSHARE {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.VSL {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.VSN {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.VSO {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.VSOL {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.VSP {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.VSPACEX {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.VSQ {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.VST {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.VSTA {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.VSU {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.VSX {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.vSXP {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.VSYS {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.VT {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.VTA {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.VTC {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.VTEX {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.VTG {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.VTHO {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.VTN {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.VTOPIA {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.VTT {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.VTUBE {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.VTX {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.VULC {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.vUSDC {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.vUSDT {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.VVL {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.VVS {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.VVT {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.VVV {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.VWAVE {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.VX {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.VXC {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.VXL {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.vXRP {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.VXT {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.VXV {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.vXVS {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.VYBE {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.VYFI {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.VYNC {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.VZT {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.W2E {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.W12 {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.WAB {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.WABI {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.WAC {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.WACO {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.WAD {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.WADA {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.WADS {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.WAF {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.WAFERSE {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.WAG {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.WAGE {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.WAGMI {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.WAGMIGAMES {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.WAI {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.WAIF {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.WAIFER {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.WAIV {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.WAKA {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.WAL {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.WALE {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.WALKN {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.WALL {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.WALLET {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.WALLSTREETINU {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.WALMETA {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.WAM {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.WAMO {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.WAMPL {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.WAN {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.WANA {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.wANATHA {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.WANBTC {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.WAND {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.WANDER {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.WANEOS {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.WANETH {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.WANLINK {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.WANNA {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.WANSUSHI {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.WANUNI {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.WANUSDC {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.WANUSDT {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.WAP {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.WAR {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.WARE {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.WAROO {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.WARP {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.WARS {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.WAS {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.WASABI {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.WASP {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.WATCH {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.WATER {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.WATERFALL {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.WAULT {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.WAULTX {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.WAV3 {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.WAVAX {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.WAVE {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.WAVES {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.WAXE {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.WAXP {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.WAY {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.WAYF {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.WB {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.WBB {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.WBBC {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.WBC {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.WBCH {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.WBET {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.WBI {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.WBIND {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.WBL {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.WBNB {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.WBOND {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.WBT {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.WBTC {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.WBTCBAM {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.WBX {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.WC {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.WCC {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.WCCX {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.WCELO {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.WCF {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.WCFG {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.WCH {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.WCK {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.WCM {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.WCO {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.wCRES {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.WCRO {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.WCS {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.WCSOV {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.WCT {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.WCT1 {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.WCUSD {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.WDC {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.WDO {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.WDOGE {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.WDP {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.WDR {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.WDUCX {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.WE {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.WEAPON {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.WEAR {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.WEATHER {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.WEAVE {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.WEB {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.WEB2 {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.WEB3 {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.WEB3ALLBI {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.WEBD {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.WEBFOUR {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.WEBN {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.WEBOO {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.WEC {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.WEENIE {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.WEFI {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.WEFIN {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.WEGLD {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.WEGRO {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.WEIUP {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.WEL {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.WELA {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.WELD {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.WELL {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.WELT {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.WELUPS {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.WEMIX {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.WEMP {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.WEN {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.WENB {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.WENLAMBO {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.WENMOON {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.WENR {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.WES {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.WEST {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.WET {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.WETA {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.WETH {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.WEVE {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.WEVER {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.WEWON {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.WEX {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.WEXPOLY {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.WEYU {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.WFAIR {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.WFC {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.WFCT {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.WFIL {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.WFT {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.WFTM {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.WFUSE {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.WFX {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.WG0 {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.WGames {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.WGC {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.WGIRL {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.WGL {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.WGLMR {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.WGMI {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.WGO {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.WGOLD {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.WGP {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.WGR {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.WGRT {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.WHACKD {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.Whale {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.WHALE {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.WHB {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.WHE {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.WHEAT {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.WHEEL {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.WHEN {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.WHENLAMBO {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.WHIRL {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.WHITE {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.WHIVE {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.WHL {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.WHOLE {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.wHOO {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.WHT {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.WHX {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.WIB {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.WICC {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.WICKED {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.WIDI {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.WIFEDOGE {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.WIFI {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.WIGO {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.WIKEN {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.WIKI {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.WILC {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.WILD {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.WILDF {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.WILLIE {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.WIMI {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.WIN {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.WINALAMBO {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.WINATESLA {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.WINB {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.WIND {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.WINDY {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.WINE {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.WING {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.WINGS {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.WINIPHONE {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.WINK {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.WINLAMBO {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.WINNINGS {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.WINR {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.WINRY {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.WINTER {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.WINTERFLOKI {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.WINU {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.WIOTX {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.WIPE {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.WIRE {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.WIRTUAL {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.WIS {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.WISB {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.WISE {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.WISH {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.WISHDOGE {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.WIT {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.WITCH {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.WIV {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.WIVA {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.WIX {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.WIZ {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.WIZARD {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.WIZZY {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.WJXN {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.WKAI {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.WKC {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.WKCS {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.WKD {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.WLB {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.WLD {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.WLEO {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.WLF {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.WLFGRL {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.WLITI {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.WLKN {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.WLLO {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.WLO {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.WLRS {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.WLS {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.WLT {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.WLUNC {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.WLVR {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.WMATIC {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.WMEMO {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.WMF {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.WMK {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.WMOVR {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.WMP {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.WMT {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.WMW {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.WNCE {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.WNCG {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.WND {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.WNDG95v2 {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.WNDR {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.WNEAR {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.WNK {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.WNL {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.WNNW {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.WNOW {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.WNRZ {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.WVSOL {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.WNT {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.WNTR {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.WNXM {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.WNYC {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.WNZ {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.WOA {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.WOD {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.WOE {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.WOFM {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.WOJ {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.WOK {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.WOKT {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.WOL {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.WOLF {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.WOLFGIRL {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.WOLFI {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.WOLFIES {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.WOLFILAND {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.WOLFY {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.WOLVERINU {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.WOM {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.WOMEN {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.WOMI {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.WON {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.WONE {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.WOO {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.WOOD {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.WOOF {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.WOOFY {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.WOOL {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.WOOLONG {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.WOONK {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.WOOP {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.WORDL {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.WORK {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.WORLD {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.Worldcoin {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.WORM {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.WORMS {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.WORTH {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.WORX {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.WOT {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.WOTG {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.WOW {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.WOWS {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.WOZX {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.WP {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.WPC {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.WPKT {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.WPL {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.WPP {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.WPR {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.WPX {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.WQT {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.WRAITH {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.WRBTC {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.WRC {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.WRK {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.WRKZ {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.WRLD {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.WRLK {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.WRN {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.wROSE {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.WRX {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.WSB {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.WSC {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.WSCRT {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.WSD {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.WSDQ {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.WSF {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.WSG {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.WSHARE {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.WSHEC {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.WSHIB {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.WSHIFT {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.WSI {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.wSIENNA {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.WSN {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.WSO {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.WSOHM {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.WSOTE {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.WSOW {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.WSP {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.WSPORTS {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.WSPP {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.WST {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.WSTA {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.WSTETH {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.WSTR {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.WSWAP {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.WSYS {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.WT {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.WTA {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.WTC {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.WTF {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.WTK {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.WTL {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.WTLOS {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.WTM {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.WTN {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.WTP {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.WTR {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.WTRTL {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.WTRX {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.WTW {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.WTWOOL {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.WULA {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.WURK {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.WUSD {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.WVET {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.WVG0 {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.WVLX {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.WWAN {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.WWB {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.WWCN {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.WWIN {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.WWY {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.WX {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.WXC {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.WXCH {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.wxDai {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.WXMR {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.WXT {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.WXTC {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.WYS {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.WZEC {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.WZM {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.WZRD {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.WZYON {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.WZYX {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}
