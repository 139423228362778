div.commerce-loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(6, 103, 208, 0.05);
  border-radius: 100%;
  border-top-color: white;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  33% {
    transform: rotate(90deg);
  }
  66% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

div.coinbase-commerce-iframe-container {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99998;

  background-color: rgba(0, 0, 0, 0.5);
}

iframe.coinbase-commerce-iframe {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  border: none;
}
