.x6,
.X,
.X2,
.X2P,
.X2Y2,
.X3S,
.X3SWAP_TOKEN,
.X8X,
.X12,
.X13,
.X22,
.X42,
.X99,
.XAC,
.XAEA12,
.XAEA_Xii,
.XAG,
.XAI,
.XAMP,
.XANK,
.XAP,
.XAR,
.XAS,
.XAT,
.XAUR,
.XAUT,
.XAUTBULL,
.XAUTBEAR,
.XAUTHALF,
.XAUTHEDGE,
.XAVA,
.XAVE,
.XBA,
.XBASE,
.XBBT,
.XBC,
.XBE,
.XBG,
.XBI,
.XBL,
.xBLZD,
.XBN,
.XBP,
.XBT,
.xBTC,
.XBTC21,
.XBX,
.XBY,
.XC,
.XCAD,
.XCASH,
.XCB,
.XCC,
.XCD,
.XCDOT,
.XCF,
.XCG,
.XCH,
.XCHF,
.XCLR,
.XCM,
.XCMG,
.XCN,
.XCO,
.XCON,
.XCP,
.XCRE,
.XCT,
.XCUR,
.XCV,
.XCX,
.XCXT,
.XCZ,
.XD,
.XDAG,
.xDAI,
.XDB,
.XDC,
.XDEF2,
.XDEFI,
.XDEN,
.XDEX,
.xDG,
.XDN,
.XDNA,
.XDO,
.XDOGE,
.xDOT,
.XDR,
.xDRACE,
.XDSHARE,
.XDUDE,
.XEC,
.XED,
.XEEB,
.XEL,
.XELS,
.XEM,
.XEN,
.XEND,
.XENO,
.XENOP,
.XENOX,
.XEP,
.XEQ,
.XERM,
.XES,
.XET,
.XETA,
.XETH,
.XETH_G,
.XEUR,
.XEUS,
.XEV,
.XFC,
.XFE,
.XFG,
.XFI,
.XFII,
.XFIT,
.XFL,
.XFLOKI,
.XFR,
.XFRC,
.XFT,
.XFTM,
.XFUEL,
.XFUND,
.XFYI,
.XG,
.XGB,
.XGC,
.XGCS,
.XGDAO,
.XGEM,
.XGG,
.XGLI,
.XGM,
.XGOLD,
.XGOX,
.XGS,
.XGT,
.XHDX,
.XHI,
.XHT,
.XHUNTER,
.XHV,
.XRPHALF,
.XRPHEDGE,
.XIASI,
.XIDO,
.XIDR,
.XIF,
.XIL,
.XIM,
.XIN,
.XIND,
.XIO,
.XIOT,
.XIRCUS,
.XIV,
.XJEWEL,
.XJO,
.XJP,
.XKAWA,
.XKI,
.XKR,
.XL,
.XLA,
.XLAB,
.XTZHALF,
.XTZHEDGE,
.XLB,
.XLC,
.XLD,
.XLG,
.XLM,
.YFIUP,
.YFIDOWN,
.XLMDOWN,
.XLMUP,
.XLMG,
.XLMN,
.XLMX,
.XLPG,
.XLR,
.XLS,
.XLT,
.XLV,
.XM,
.XMALL,
.XMAN,
.XMARK,
.XMAS,
.XMASBNB,
.XMC,
.XMCC,
.XMCT,
.XMETA,
.XMG,
.XMM,
.XMN,
.XMON,
.xMOON,
.XMOON,
.XMR,
.XMS,
.XMT,
.XMV,
.XMX,
.XMY,
.XN35,
.XNB,
.XNC,
.XNFT,
.XNK,
.XNL,
.XNO,
.XNODE,
.XNP,
.XNS,
.XNV,
.XODEX,
.XOLO,
.XOR,
.XOS,
.XOT,
.XOV,
.XOX,
.XOXO,
.XP,
.XPA,
.XPAD,
.XPANZ,
.XPAST,
.XPAT,
.XPAY,
.XPB,
.XPC,
.XPCOIN,
.XPD,
.XPH,
.XPIE,
.XPLAY,
.XPM,
.XPN,
.XPND,
.XPNET,
.XPO,
.XPR,
.XPRESS,
.XPRT,
.XPS,
.XPT,
.XPTP,
.XPTX,
.XPUNK,
.XPX,
.XPY,
.XQC,
.XQK,
.XQN,
.XQR,
.XRA,
.XRAY,
.XRC,
.XRD,
.XRDOGE,
.XRE,
.XRGARY,
.XRGE,
.XRH,
.XRHP,
.XRI,
.XRM,
.XRN,
.XRNBW,
.XRP,
.XRPAPE,
.XRPAYNET,
.XTZBULL,
.XTZBEAR,
.XRPBEAR,
.XRPBULL,
.XRP_BF2,
.XRPUP,
.XRPDOWN,
.XRPMOON,
.XRPR,
.XRSHIB,
.XRSW,
.XRT,
.XRUN,
.XRUNE,
.XRX,
.xSAT,
.XSAT,
.XSB,
.XSC,
.XSCC,
.XSD,
.XSGD,
.XSH,
.XSLR,
.XSM,
.XSN,
.XSNXA,
.XSP,
.XSPC,
.XSPECTAR,
.XSR,
.XST,
.XSTAR,
.XSTIK,
.XSTUSD,
.XSTX,
.XSUSHI,
.XSUTER,
.XT,
.XT3,
.XTA,
.XTAG,
.XTAROT,
.XTK,
.XTLR,
.XTM,
.X_TOKEN,
.XTP,
.XTR,
.XTRA,
.XTRD,
.XTRI,
.XTRM,
.XTS,
.XTT_B20,
.XTX,
.XTZ,
.XTZDOWN,
.XTZUP,
.XUC,
.XUEZ,
.XUNI,
.XUSB,
.XUSD,
.XVC,
.XVG,
.XVIX,
.XVMC,
.XVR,
.XVS,
.XWALL,
.XWAP,
.XWC,
.XWG,
.XWIN,
.XWIP,
.XWO,
.XWP,
.XX,
.XXA,
.XXP,
.XXT,
.XXX,
.XXXANIME,
.XY,
.XYA,
.XYM,
.XYO,
.XYS,
.XYSL,
.XYZ,
.xZAR,
.XZT,
.Y_5,
.YAAN,
.YACHT,
.YACHTX,
.YAE,
.YAG,
.YAH,
.YAK,
.YAM,
.YAMV2,
.YANSH,
.YAP,
.YAPE,
.YARD,
.YARL,
.YAS,
.YASHA,
.YAT,
.YATX,
.YAW,
.YAXIS,
.YAY,
.yBAN,
.YBEAR,
.YBO,
.YBREE,
.YBX,
.YCC,
.YCE,
.YCT,
.YDA,
.YDAI\+YUSDC\+YUSDT\+YTUSD,
.YDR,
.YDZ,
.YEA,
.YEAGER,
.YEAR,
.YEC,
.YEE,
.YEED,
.YEET,
.YEFAM,
.YEFIM,
.YEL,
.YELD,
.YEP,
.YESTRUMP,
.YETH,
.YUSD,
.YETI,
.YETIC,
.YETI_COIN,
.YETU,
.YF4,
.YFA,
.YFARM,
.YFARMER,
.YFB2,
.YFBETA,
.YFBT,
.YFBTC,
.YFD,
.YF-DAI,
.YFDOT,
.YFDT,
.YFE,
.YFED,
.YFET,
.YFF,
.YFFC,
.YFFI,
.YFFII,
.YFFS,
.YFI,
.YFI2,
.YFI3,
.YFIA,
.YFIAG,
.YFIb,
.YFIB,
.YFIC,
.YFICG,
.YFID,
.YFIE,
.YFIEC,
.YFIH2,
.YFII,
.YFIIG,
.YFIII,
.YFIKING,
.YFILD,
.YFIM,
.YFIPT,
.YFIS,
.YFIV,
.YFIVE,
.YFIX,
.YFKA,
.YFL,
.YFLUSD,
.YFMB,
.YFMS,
.YFN,
.YFO,
.YFOS,
.YFOX,
.YFP,
.YFPI,
.YFPRO,
.YFRB,
.YFRM,
.YFS,
.YFSI,
.YFST,
.YFT,
.YFTE,
.YFUEL,
.YFW,
.YFX,
.YGD,
.YGG,
.YGN,
.YHC,
.YI12,
.YIELD,
.YIELDX,
.YIN,
.YINBI,
.YIP,
.YKZ,
.YLAB,
.YLAND,
.YLB,
.YLC,
.YLD,
.YLDY,
.YLF,
.YLFI,
.YLUNA,
.YLZ,
.YMAX,
.YMEN,
.YMF20,
.YMPA,
.YMPL,
.YMS,
.YMY,
.YNI,
.YNK,
.YNY,
.YO,
.YOC,
.YOCO,
.YODA,
.YODL,
.YOK,
.YOKAI,
.YOLO,
.YON,
.YOOSHI,
.YOP,
.YORK,
.YOSHI,
.YOSI,
.YOT,
.YOU,
.YOUC,
.YOUR,
.YOYOW,
.yPANDA,
.YPIE,
.YPLT,
.YPRO,
.YRISE,
.YRT,
.YSEC,
.YSHIBAINU,
.YSKF,
.YSL,
.YSOY,
.YSR,
.YT,
.YTA,
.YTN,
.YTS,
.yTSLA,
.YTTRIUM,
.YTZ,
.YUAN,
.YUANG,
.YUCT,
.YUGI,
.YUI,
.YUKI,
.YUKON,
.YUM,
.YUMMI,
.YUMMY,
.YUNO,
.YUP,
.YUS,
.YUSD_OCT20,
.YUSD_SEP20,
.YUSDT,
.YUSE,
.YUSRA,
.YUZU,
.yVault_LP_yCurve_YYCRV_,
.YVS,
.YYE,
.YYFI,
.YZC,
.Z,
.Z7,
.Z502,
.ZABAKU,
.ZABU,
.ZAC,
.ZADA,
.ZADDY,
.ZAI,
.ZAIF,
.ZAM,
.ZAMZAM,
.ZANO,
.ZAP,
.ZARD,
.ZARX,
.ZASH,
.ZAT,
.ZAY,
.ZAZINGA,
.ZB,
.ZBA,
.ZBC,
.ZBK,
.ZBT,
.ZBTC,
.ZBUX,
.ZC,
.ZCH,
.ZCK,
.ZCL,
.ZCN,
.ZCON,
.ZCR,
.ZCRT,
.ZCX,
.ZD,
.ZDC,
.ZDCV2,
.ZDEX,
.ZDR,
.ZDX,
.ZEBI,
.ZEC,
.ZEDXION,
.ZEE,
.ZEFI,
.ZEFU,
.ZEG,
.ZEIT,
.ZEN,
.ZENC,
.ZEND,
.ZENFI,
.ZENI,
.ZENITH,
.ZENO,
.ZENX,
.ZEON,
.ZEP,
.ZEPH,
.ZER,
.ZERC,
.ZERI,
.ZERO,
.ZeroChain,
.ZEROPAY,
.ZET,
.ZETH,
.ZEU\$,
.ZEUM,
.ZEUS,
.ZEUS10000,
.ZFAI,
.ZFL,
.ZG,
.ZGD,
.ZGOAT,
.ZHEGIC,
.ZHT,
.ZIG,
.ZIJA,
.ZIK,
.ZIL,
.ZILD,
.ZILLA,
.ZSHARE,
.ZILLADOGE,
.ZIN,
.ZINA,
.ZINAX,
.ZINC,
.ZINU,
.ZION,
.ZIOT,
.ZIOX,
.ZIP,
.ZIPT,
.ZIRVE,
.ZJLT,
.ZJS,
.ZK,
.ZKP,
.ZKS,
.ZKT,
.ZLA,
.ZLC,
.ZLDA,
.ZLK,
.ZLOT,
.ZLP,
.ZLS,
.ZLW,
.ZM,
.ZMATIC,
.ZMAX,
.ZMBE,
.ZMN,
.ZMT,
.ZMY,
.ZND,
.ZNN,
.ZNS,
.ZNT,
.ZNY,
.ZNZ,
.ZOA,
.ZOC,
.ZOD,
.ZODI,
.ZOE,
.ZOM,
.ZOMB,
.ZOMBIE,
.ZOMFI,
.ZON,
.ZONE,
.ZONO,
.ZONX,
.ZOO,
.ZOOM,
.ZOON,
.ZOOSHI,
.ZOOT,
.ZORA,
.ZORG,
.ZORO,
.ZORT,
.ZP,
.ZPAE,
.ZPAINT,
.ZPAY,
.ZPR,
.ZPRO,
.ZPT,
.ZPTC,
.ZRC,
.ZRO,
.ZRX,
.ZSC,
.ZSKULL,
.ZT,
.ZTB,
.ZTC,
.ZTG,
.ZTNZ,
.ZTU,
.ZUC,
.ZUF,
.ZUG,
.ZUKA,
.ZUKI,
.ZUM,
.ZUMD,
.ZUNA,
.ZUR,
.ZURR,
.zUSD,
.ZUSD,
.ZUT,
.ZUZ,
.ZVC,
.ZVRS,
.ZWALL,
.ZWAP,
.ZWZ,
.ZXC,
.ZXTH,
.ZYD,
.ZYN,
.ZYON,
.ZYRO,
.ZYTH,
.ZYX,
.ZZC,
.ZZZ,
.ZZZV2,
._Δ_,
.ΤBTC,
.ΤDOGE {
  display: inline-block;
  background: url('../img/tokens_logo_XtoEndZ.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.x6 {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.X {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.X2 {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.X2P {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.X2Y2 {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.X3S {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.X3SWAP_TOKEN {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.X8X {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.X12 {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.X13 {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.X22 {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.X42 {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.X99 {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.XAC {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.XAEA12 {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.XAEA_Xii {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.XAG {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.XAI {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.XAMP {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.XANK {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.XAP {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.XAR {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.XAS {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.XAT {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.XAUR {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.XAUT {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.XAUTBULL {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.XAUTBEAR {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.XAUTHALF {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.XAUTHEDGE {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.XAVA {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.XAVE {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.XBA {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.XBASE {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.XBBT {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.XBC {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.XBE {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.XBG {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.XBI {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.XBL {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.xBLZD {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.XBN {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.XBP {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.XBT {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.xBTC {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.XBTC21 {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.XBX {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.XBY {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.XC {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.XCAD {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.XCASH {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.XCB {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.XCC {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.XCD {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.XCDOT {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.XCF {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.XCG {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.XCH {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.XCHF {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.XCLR {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.XCM {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.XCMG {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.XCN {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.XCO {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.XCON {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.XCP {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.XCRE {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.XCT {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.XCUR {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.XCV {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.XCX {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.XCXT {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.XCZ {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.XD {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.XDAG {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.xDAI {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.XDB {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.XDC {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.XDEF2 {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.XDEFI {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.XDEN {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.XDEX {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.xDG {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.XDN {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.XDNA {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.XDO {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.XDOGE {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.xDOT {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.XDR {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.xDRACE {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.XDSHARE {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.XDUDE {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.XEC {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.XED {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.XEEB {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.XEL {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.XELS {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.XEM {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.XEN {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.XEND {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.XENO {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.XENOP {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.XENOX {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.XEP {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.XEQ {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.XERM {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.XES {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.XET {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.XETA {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.XETH {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.XETH_G {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.XEUR {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.XEUS {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.XEV {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.XFC {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.XFE {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.XFG {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.XFI {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.XFII {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.XFIT {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.XFL {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.XFLOKI {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.XFR {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.XFRC {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.XFT {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.XFTM {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.XFUEL {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.XFUND {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.XFYI {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.XG {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.XGB {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.XGC {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.XGCS {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.XGDAO {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.XGEM {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.XGG {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.XGLI {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.XGM {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.XGOLD {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.XGOX {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.XGS {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.XGT {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.XHDX {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.XHI {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.XHT {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.XHUNTER {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.XHV {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.XRPHALF {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.XRPHEDGE {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.XIASI {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.XIDO {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.XIDR {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.XIF {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.XIL {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.XIM {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.XIN {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.XIND {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.XIO {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.XIOT {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.XIRCUS {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.XIV {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.XJEWEL {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.XJO {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.XJP {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.XKAWA {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.XKI {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.XKR {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.XL {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.XLA {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.XLAB {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.XTZHALF {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.XTZHEDGE {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.XLB {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.XLC {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.XLD {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.XLG {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.XLM {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.YFIUP {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.YFIDOWN {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.XLMDOWN {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.XLMUP {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.XLMG {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.XLMN {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.XLMX {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.XLPG {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.XLR {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.XLS {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.XLT {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.XLV {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.XM {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.XMALL {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.XMAN {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.XMARK {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.XMAS {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.XMASBNB {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.XMC {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.XMCC {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.XMCT {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.XMETA {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.XMG {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.XMM {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.XMN {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.XMON {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.xMOON {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.XMOON {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.XMR {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.XMS {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.XMT {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.XMV {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.XMX {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.XMY {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.XN35 {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.XNB {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.XNC {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.XNFT {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.XNK {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.XNL {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.XNO {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.XNODE {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.XNP {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.XNS {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.XNV {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.XODEX {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.XOLO {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.XOR {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.XOS {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.XOT {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.XOV {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.XOX {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.XOXO {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.XP {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.XPA {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.XPAD {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.XPANZ {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.XPAST {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.XPAT {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.XPAY {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.XPB {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.XPC {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.XPCOIN {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.XPD {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.XPH {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.XPIE {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.XPLAY {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.XPM {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.XPN {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.XPND {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.XPNET {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.XPO {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.XPR {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.XPRESS {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.XPRT {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.XPS {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.XPT {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.XPTP {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.XPTX {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.XPUNK {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.XPX {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.XPY {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.XQC {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.XQK {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.XQN {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.XQR {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.XRA {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.XRAY {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.XRC {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.XRD {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.XRDOGE {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.XRE {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.XRGARY {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.XRGE {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.XRH {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.XRHP {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.XRI {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.XRM {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.XRN {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.XRNBW {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.XRP {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.XRPAPE {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.XRPAYNET {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.XTZBULL {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.XTZBEAR {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.XRPBEAR {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.XRPBULL {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.XRP_BF2 {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.XRPUP {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.XRPDOWN {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.XRPMOON {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.XRPR {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.XRSHIB {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.XRSW {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.XRT {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.XRUN {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.XRUNE {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.XRX {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.xSAT {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.XSAT {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.XSB {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.XSC {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.XSCC {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.XSD {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.XSGD {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.XSH {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.XSLR {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.XSM {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.XSN {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.XSNXA {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.XSP {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.XSPC {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.XSPECTAR {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.XSR {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.XST {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.XSTAR {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.XSTIK {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.XSTUSD {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.XSTX {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.XSUSHI {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.XSUTER {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.XT {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.XT3 {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.XTA {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.XTAG {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.XTAROT {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.XTK {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.XTLR {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.XTM {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.X_TOKEN {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.XTP {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.XTR {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.XTRA {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.XTRD {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.XTRI {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.XTRM {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.XTS {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.XTT_B20 {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.XTX {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.XTZ {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.XTZDOWN {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.XTZUP {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.XUC {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.XUEZ {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.XUNI {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.XUSB {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.XUSD {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.XVC {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.XVG {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.XVIX {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.XVMC {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.XVR {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.XVS {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.XWALL {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.XWAP {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.XWC {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.XWG {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.XWIN {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.XWIP {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.XWO {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.XWP {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.XX {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.XXA {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.XXP {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.XXT {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.XXX {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.XXXANIME {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.XY {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.XYA {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.XYM {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.XYO {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.XYS {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.XYSL {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.XYZ {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.xZAR {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.XZT {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.Y_5 {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.YAAN {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.YACHT {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.YACHTX {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.YAE {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.YAG {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.YAH {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.YAK {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.YAM {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.YAMV2 {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.YANSH {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.YAP {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.YAPE {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.YARD {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.YARL {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.YAS {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.YASHA {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.YAT {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.YATX {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.YAW {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.YAXIS {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.YAY {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.yBAN {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.YBEAR {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.YBO {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.YBREE {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.YBX {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.YCC {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.YCE {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.YCT {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.YDA {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.YDAI\+YUSDC\+YUSDT\+YTUSD {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.YDR {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.YDZ {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.YEA {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.YEAGER {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.YEAR {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.YEC {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.YEE {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.YEED {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.YEET {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.YEFAM {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.YEFIM {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.YEL {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.YELD {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.YEP {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.YESTRUMP {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.YETH {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.YUSD {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.YETI {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.YETIC {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.YETI_COIN {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.YETU {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.YF4 {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.YFA {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.YFARM {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.YFARMER {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.YFB2 {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.YFBETA {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.YFBT {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.YFBTC {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.YFD {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.YF-DAI {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.YFDOT {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.YFDT {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.YFE {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.YFED {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.YFET {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.YFF {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.YFFC {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.YFFI {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.YFFII {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.YFFS {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.YFI {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.YFI2 {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.YFI3 {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.YFIA {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.YFIAG {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.YFIb {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.YFIB {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.YFIC {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.YFICG {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.YFID {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.YFIE {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.YFIEC {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.YFIH2 {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.YFII {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.YFIIG {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.YFIII {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.YFIKING {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.YFILD {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.YFIM {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.YFIPT {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.YFIS {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.YFIV {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.YFIVE {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.YFIX {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.YFKA {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.YFL {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.YFLUSD {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.YFMB {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.YFMS {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.YFN {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.YFO {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.YFOS {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.YFOX {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.YFP {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.YFPI {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.YFPRO {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.YFRB {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.YFRM {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.YFS {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.YFSI {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.YFST {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.YFT {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.YFTE {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.YFUEL {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.YFW {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.YFX {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.YGD {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.YGG {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.YGN {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.YHC {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.YI12 {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.YIELD {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.YIELDX {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.YIN {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.YINBI {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.YIP {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.YKZ {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.YLAB {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.YLAND {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.YLB {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.YLC {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.YLD {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.YLDY {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.YLF {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.YLFI {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.YLUNA {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.YLZ {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.YMAX {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.YMEN {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.YMF20 {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.YMPA {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.YMPL {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.YMS {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.YMY {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.YNI {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.YNK {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.YNY {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.YO {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.YOC {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.YOCO {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.YODA {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.YODL {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.YOK {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.YOKAI {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.YOLO {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.YON {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.YOOSHI {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.YOP {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.YORK {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.YOSHI {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.YOSI {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.YOT {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.YOU {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.YOUC {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.YOUR {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.YOYOW {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.yPANDA {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.YPIE {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.YPLT {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.YPRO {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.YRISE {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.YRT {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.YSEC {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.YSHIBAINU {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.YSKF {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.YSL {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.YSOY {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.YSR {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.YT {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.YTA {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.YTN {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.YTS {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.yTSLA {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.YTTRIUM {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.YTZ {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.YUAN {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.YUANG {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.YUCT {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.YUGI {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.YUI {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.YUKI {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.YUKON {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.YUM {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.YUMMI {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.YUMMY {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.YUNO {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.YUP {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.YUS {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.YUSD_OCT20 {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.YUSD_SEP20 {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.YUSDT {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.YUSE {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.YUSRA {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.YUZU {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.yVault_LP_yCurve_YYCRV_ {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.YVS {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.YYE {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.YYFI {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.YZC {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.Z {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.Z7 {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.Z502 {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.ZABAKU {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.ZABU {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.ZAC {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.ZADA {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.ZADDY {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.ZAI {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.ZAIF {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.ZAM {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.ZAMZAM {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.ZANO {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.ZAP {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.ZARD {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.ZARX {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.ZASH {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.ZAT {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.ZAY {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.ZAZINGA {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.ZB {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.ZBA {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.ZBC {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.ZBK {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.ZBT {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.ZBTC {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.ZBUX {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.ZC {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.ZCH {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.ZCK {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.ZCL {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.ZCN {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.ZCON {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.ZCR {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.ZCRT {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.ZCX {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.ZD {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.ZDC {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.ZDCV2 {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.ZDEX {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.ZDR {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.ZDX {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.ZEBI {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.ZEC {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.ZEDXION {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.ZEE {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.ZEFI {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.ZEFU {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.ZEG {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.ZEIT {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.ZEN {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.ZENC {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.ZEND {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.ZENFI {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.ZENI {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.ZENITH {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.ZENO {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.ZENX {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.ZEON {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.ZEP {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.ZEPH {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.ZER {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.ZERC {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.ZERI {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.ZERO {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.ZeroChain {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.ZEROPAY {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.ZET {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.ZETH {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.ZEU\$ {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.ZEUM {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.ZEUS {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.ZEUS10000 {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.ZFAI {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.ZFL {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.ZG {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.ZGD {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.ZGOAT {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.ZHEGIC {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.ZHT {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.ZIG {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.ZIJA {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.ZIK {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.ZIL {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.ZILD {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.ZILLA {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.ZSHARE {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.ZILLADOGE {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.ZIN {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.ZINA {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.ZINAX {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.ZINC {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.ZINU {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.ZION {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.ZIOT {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.ZIOX {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.ZIP {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.ZIPT {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.ZIRVE {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.ZJLT {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.ZJS {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.ZK {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.ZKP {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.ZKS {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.ZKT {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.ZLA {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.ZLC {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.ZLDA {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.ZLK {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.ZLOT {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.ZLP {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.ZLS {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.ZLW {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.ZM {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.ZMATIC {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.ZMAX {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.ZMBE {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.ZMN {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.ZMT {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.ZMY {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.ZND {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.ZNN {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.ZNS {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.ZNT {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.ZNY {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.ZNZ {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.ZOA {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.ZOC {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.ZOD {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.ZODI {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}

.ZOE {
  width: 16px;
  height: 16px;
  background-position: -208px -416px;
}

.ZOM {
  width: 16px;
  height: 16px;
  background-position: -224px -416px;
}

.ZOMB {
  width: 16px;
  height: 16px;
  background-position: -240px -416px;
}

.ZOMBIE {
  width: 16px;
  height: 16px;
  background-position: -256px -416px;
}

.ZOMFI {
  width: 16px;
  height: 16px;
  background-position: -272px -416px;
}

.ZON {
  width: 16px;
  height: 16px;
  background-position: -288px -416px;
}

.ZONE {
  width: 16px;
  height: 16px;
  background-position: -304px -416px;
}

.ZONO {
  width: 16px;
  height: 16px;
  background-position: -320px -416px;
}

.ZONX {
  width: 16px;
  height: 16px;
  background-position: -336px -416px;
}

.ZOO {
  width: 16px;
  height: 16px;
  background-position: -352px -416px;
}

.ZOOM {
  width: 16px;
  height: 16px;
  background-position: -368px -416px;
}

.ZOON {
  width: 16px;
  height: 16px;
  background-position: -384px -416px;
}

.ZOOSHI {
  width: 16px;
  height: 16px;
  background-position: -400px -416px;
}

.ZOOT {
  width: 16px;
  height: 16px;
  background-position: -416px -416px;
}

.ZORA {
  width: 16px;
  height: 16px;
  background-position: -432px -0;
}

.ZORG {
  width: 16px;
  height: 16px;
  background-position: -432px -16px;
}

.ZORO {
  width: 16px;
  height: 16px;
  background-position: -432px -32px;
}

.ZORT {
  width: 16px;
  height: 16px;
  background-position: -432px -48px;
}

.ZP {
  width: 16px;
  height: 16px;
  background-position: -432px -64px;
}

.ZPAE {
  width: 16px;
  height: 16px;
  background-position: -432px -80px;
}

.ZPAINT {
  width: 16px;
  height: 16px;
  background-position: -432px -96px;
}

.ZPAY {
  width: 16px;
  height: 16px;
  background-position: -432px -112px;
}

.ZPR {
  width: 16px;
  height: 16px;
  background-position: -432px -128px;
}

.ZPRO {
  width: 16px;
  height: 16px;
  background-position: -432px -144px;
}

.ZPT {
  width: 16px;
  height: 16px;
  background-position: -432px -160px;
}

.ZPTC {
  width: 16px;
  height: 16px;
  background-position: -432px -176px;
}

.ZRC {
  width: 16px;
  height: 16px;
  background-position: -432px -192px;
}

.ZRO {
  width: 16px;
  height: 16px;
  background-position: -432px -208px;
}

.ZRX {
  width: 16px;
  height: 16px;
  background-position: -432px -224px;
}

.ZSC {
  width: 16px;
  height: 16px;
  background-position: -432px -240px;
}

.ZSKULL {
  width: 16px;
  height: 16px;
  background-position: -432px -256px;
}

.ZT {
  width: 16px;
  height: 16px;
  background-position: -432px -272px;
}

.ZTB {
  width: 16px;
  height: 16px;
  background-position: -432px -288px;
}

.ZTC {
  width: 16px;
  height: 16px;
  background-position: -432px -304px;
}

.ZTG {
  width: 16px;
  height: 16px;
  background-position: -432px -320px;
}

.ZTNZ {
  width: 16px;
  height: 16px;
  background-position: -432px -336px;
}

.ZTU {
  width: 16px;
  height: 16px;
  background-position: -432px -352px;
}

.ZUC {
  width: 16px;
  height: 16px;
  background-position: -432px -368px;
}

.ZUF {
  width: 16px;
  height: 16px;
  background-position: -432px -384px;
}

.ZUG {
  width: 16px;
  height: 16px;
  background-position: -432px -400px;
}

.ZUKA {
  width: 16px;
  height: 16px;
  background-position: -432px -416px;
}

.ZUKI {
  width: 16px;
  height: 16px;
  background-position: -0 -432px;
}

.ZUM {
  width: 16px;
  height: 16px;
  background-position: -16px -432px;
}

.ZUMD {
  width: 16px;
  height: 16px;
  background-position: -32px -432px;
}

.ZUNA {
  width: 16px;
  height: 16px;
  background-position: -48px -432px;
}

.ZUR {
  width: 16px;
  height: 16px;
  background-position: -64px -432px;
}

.ZURR {
  width: 16px;
  height: 16px;
  background-position: -80px -432px;
}

.zUSD {
  width: 16px;
  height: 16px;
  background-position: -96px -432px;
}

.ZUSD {
  width: 16px;
  height: 16px;
  background-position: -112px -432px;
}

.ZUT {
  width: 16px;
  height: 16px;
  background-position: -128px -432px;
}

.ZUZ {
  width: 16px;
  height: 16px;
  background-position: -144px -432px;
}

.ZVC {
  width: 16px;
  height: 16px;
  background-position: -160px -432px;
}

.ZVRS {
  width: 16px;
  height: 16px;
  background-position: -176px -432px;
}

.ZWALL {
  width: 16px;
  height: 16px;
  background-position: -192px -432px;
}

.ZWAP {
  width: 16px;
  height: 16px;
  background-position: -208px -432px;
}

.ZWZ {
  width: 16px;
  height: 16px;
  background-position: -224px -432px;
}

.ZXC {
  width: 16px;
  height: 16px;
  background-position: -240px -432px;
}

.ZXTH {
  width: 16px;
  height: 16px;
  background-position: -256px -432px;
}

.ZYD {
  width: 16px;
  height: 16px;
  background-position: -272px -432px;
}

.ZYN {
  width: 16px;
  height: 16px;
  background-position: -288px -432px;
}

.ZYON {
  width: 16px;
  height: 16px;
  background-position: -304px -432px;
}

.ZYRO {
  width: 16px;
  height: 16px;
  background-position: -320px -432px;
}

.ZYTH {
  width: 16px;
  height: 16px;
  background-position: -336px -432px;
}

.ZYX {
  width: 16px;
  height: 16px;
  background-position: -352px -432px;
}

.ZZC {
  width: 16px;
  height: 16px;
  background-position: -368px -432px;
}

.ZZZ {
  width: 16px;
  height: 16px;
  background-position: -384px -432px;
}

.ZZZV2 {
  width: 16px;
  height: 16px;
  background-position: -400px -432px;
}

._Δ_ {
  width: 16px;
  height: 16px;
  background-position: -416px -432px;
}

.ΤBTC {
  width: 16px;
  height: 16px;
  background-position: -432px -432px;
}

.ΤDOGE {
  width: 16px;
  height: 16px;
  background-position: -448px -0;
}
