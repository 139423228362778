.USD {
  display: inline-block;
  background: url('../img/dollar.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.USD {
  background-position: -0px -0px;
  width: 16px;
  height: 16px;
}
