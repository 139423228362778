.ascendex,
.binance,
.bitfinex,
.bitflyer,
.bitget,
.bitmart,
.bitmex,
.bitso,
.bitstamp,
.bittrex,
.bitvavo,
.blockchain,
.btcmarkets,
.bybit,
.cdax,
.cex,
.coinbase,
.coincheck,
.coinex,
.coinone,
.cryptocom,
.currency,
.delta,
.deribit,
.exmo,
.ftx,
.gate,
.gemini,
.hitbtc,
.huobi,
.idex,
.kraken,
.kucoin,
.lbank,
.liquid,
.mercadobitcoin,
.mexc,
.okcoin,
.okx,
.paymium,
.phemex,
.poloniex,
.procoinbase,
.upbit,
.whitebit,
.woo,
.zbex {
  display: inline-block;
  background: url('../img/exchange-logo.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.ascendex {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.binance {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.bitfinex {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.bitflyer {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.bitget {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.bitmart {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.bitmex {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.bitso {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.bitstamp {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.bittrex {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.bitvavo {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.blockchain {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.btcmarkets {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.bybit {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.cdax {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.cex {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.coinbase {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.coincheck {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.coinex {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.coinone {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.cryptocom {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.currency {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.delta {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.deribit {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.exmo {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.ftx {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.gate {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.gemini {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.hitbtc {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.huobi {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.idex {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.kraken {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.kucoin {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.lbank {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.liquid {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.mercadobitcoin {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.mexc {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.okcoin {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.okx {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.paymium {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.phemex {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.poloniex {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.procoinbase {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.upbit {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.whitebit {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.woo {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.zbex {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}
