/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */
.flag {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: url('flags.png') no-repeat;
}
.flag.flag-br {
  background-position: -32px 0;
}
.flag.flag-cn {
  background-position: -64px 0;
}
.flag.flag-fr {
  background-position: 0 -32px;
}
.flag.flag-gb {
  background-position: -32px -32px;
}
.flag.flag-jp {
  background-position: -64px -32px;
}
.flag.flag-kr {
  background-position: 0 -64px;
}
.flag.flag-us {
  background-position: -32px -64px;
}
.flag.flag-ru {
  background-position: -64px -64px;
}
