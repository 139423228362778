.D,
.D2D,
.D4RK,
.D6T,
.D11,
.D100,
.DAAPL,
.DAB,
.DAC,
.DACC,
.DACC2,
.DACHX,
.DACS,
.DACXI,
.DAD,
.DADA,
.DADDYCAKE,
.DADDYDB,
.DADDYDOGE,
.DADDYETH,
.DADDYSHIBA,
.DADDYUSDT,
.DAF,
.DAFI,
.DAFT,
.DAG,
.DAGO,
.DAGT,
.DAH,
.DAI,
.DAILY,
.DAILYS,
.DAIN,
.DAIQ,
.DAISY,
.DAKE,
.DAL,
.DALC,
.DALI,
.DAM,
.DAMEX,
.DAMN,
.DAMZN,
.DAN,
.DANA,
.DANDY,
.DANGEL,
.DANGERMOON,
.DANK,
.DANTE,
.DAO,
.DAO1,
.DAOF,
.DAOJONES,
.DAOS,
.DAOSOL,
.DAOVC,
.DAP,
.DAPES,
.DAPP,
.DAPPT,
.DAPPX,
.DAPS,
.DAR,
.DARA,
.DARC,
.DARK,
.DARKK,
.DARKO,
.dART,
.DART,
.DASH,
.DASHD,
.DASHG,
.DASS,
.DAT,
.DATA,
.DATE,
.DATP,
.DATX,
.DAU,
.DAV,
.DAVIS,
.DAVP,
.DAW,
.DAWG,
.DAWGS,
.DAWN,
.DAX,
.DAXT,
.DAY,
.DBA,
.DBABA,
.DBAY,
.DBC,
.DBD,
.DBDOGE,
.DBEA,
.DBET,
.DBK,
.DBL,
.DBQ,
.DBR,
.DBS,
.DBT,
.DBUBBLE,
.DBUY,
.DBX,
.DBY,
.DBZ,
.DC,
.DCA,
.DCASH,
.DCAU,
.DCB,
.DCC,
.DCCT,
.DCD,
.DCH,
.DCHS,
.DCIP,
.DCL,
.DCLUB,
.DCN,
.DCNTR,
.DCOIN,
.DCORE,
.DCR,
.DCS,
.DCT,
.DCTD,
.DCTO,
.DCVC,
.DCX,
.DCY,
.DDAI,
.DDAM,
.DDAO,
.DDC,
.DDD,
.DDDD,
.DDDX,
.DDETH,
.DDIM,
.DDK,
.DDL,
.DDN,
.DDOGE,
.DDOS,
.DDR,
.DDRST,
.DDRT,
.DDS,
.DDT,
.DDTG,
.DDX,
.DDY,
.DEA,
.DEAL,
.DEALDOUGH,
.DEATH,
.DEB,
.DEBA,
.DEBASE,
.DEC,
.DECENT,
.DECO,
.DED,
.DEEM,
.DEEP,
.DEER,
.DEEX,
.DEEZ,
.DEEZNUTS,
.DEF,
.DEFC,
.DEFI,
.DEFI5,
.DEFI\+\+,
.DEFIHEDGE,
.DEFIBEAR,
.DEFIHALF,
.DEFIBULL,
.DEFIDO,
.DEFI\+L,
.DEFILANCER,
.DEFI\+S,
.DEFIT,
.DEFIY,
.DEFLA,
.DEFLCT,
.DEFLY,
.DEFO,
.DEFT,
.DEFX,
.DEFY,
.DEG,
.DEGEN,
.DEGENS,
.DEGG,
.DEGN,
.DEGO,
.DEGOV,
.DEHUB,
.DEI,
.DEIP,
.DEK,
.DEKU,
.DMGBEAR,
.DMGBULL,
.DEL,
.DELFI,
.DELISH,
.DELO,
.DELOS,
.DELOT,
.DELTA,
.DELTAF,
.DELTA_RLP,
.DEM,
.DEMA,
.DEMIR,
.DEMY,
.DEN,
.DENA,
.DENT,
.DENTRO,
.DEO,
.DEOR,
.DEP,
.DEPAY,
.DEPO,
.DEQ,
.DERC,
.DERI,
.DERO,
.DES,
.DESIRE,
.DESO,
.DESU,
.DET,
.DETO,
.DETS,
.DEUS,
.DEUX,
.DEV,
.DEVA,
.DEVE,
.DEVIA8,
.DEVIL,
.DEVO,
.DEVT,
.DEW,
.DEWO,
.DOGEHALF,
.DOGEHEDGE,
.DEX,
.DEXA,
.DEXE,
.DEXF,
.DEXG,
.DEXI,
.DEXM,
.DEXSHARE,
.DEXT,
.DEXTF,
.DF,
.DFA,
.DFAI,
.DFB,
.DFC,
.DFD,
.DFE,
.DFF,
.DFG,
.DFGL,
.DFH,
.DFI,
.DFIAT,
.DFIN,
.DFIO,
.DFIP,
.DFK,
.DFL,
.DFM,
.DFND,
.DFNI,
.DFP2,
.DFR,
.DFS,
.DFSG,
.DFSM,
.DFSOCIAL,
.DFST,
.DFT,
.DFW,
.DFX,
.DFY,
.DFYN,
.DG,
.DGA,
.DGAT,
.DGB,
.DGC,
.DGCL,
.DGD,
.DGE,
.DGEMS,
.DGG,
.DGI,
.DGLD,
.DGLN,
.DGMAN,
.DGME,
.DGMOON,
.DGMT,
.DGMUSK,
.DGMV,
.DGMV2,
.DGN,
.DGNN,
.DGOLD,
.DGOOGL,
.DGP,
.DGS,
.DGT,
.DGTL,
.DGTX,
.DGVC,
.DGW,
.DGX,
.DGZV,
.DHC,
.DHD,
.DHG,
.DHLT,
.DHN,
.DHOLD,
.DHP,
.DHR,
.DHT,
.DHV,
.DHX,
.DI,
.DIA,
.DIAH,
.DIAMND,
.DIAMOND,
.DIAMONDHANDS,
.DIAMONDS,
.DIANA,
.DIBA,
.DIBS,
.DIC,
.DICE,
.DICK,
.DID,
.DIEM,
.DIESEL,
.DIFF,
.DIFX,
.DIG,
.DIGAU,
.DIGEX,
.DIGG,
.DIGI,
.DIGICHAIN,
.DIGINU,
.DIGS,
.DIIC,
.DIK,
.DIKE,
.DIKO,
.DILI,
.DIM,
.DIME,
.DIN,
.DINA,
.DINGER,
.DINGO,
.DINJA,
.DINK,
.DINNER,
.DINO,
.DINOEGG,
.DINU,
.DIO,
.DION,
.DIOS,
.DIP,
.Dipole,
.DIQ,
.DIREWOLF,
.DIRKDIGGLER,
.DIRTY,
.DIS,
.DISC,
.DISTX,
.DIT,
.DITTO,
.DITTOINU,
.DIV,
.DIVER,
.DIVI,
.DIVINE,
.DIVS,
.DIXT,
.DIYAR,
.DJ15,
.DJBZ,
.DJN,
.DJV,
.DK,
.DKA,
.DKC,
.DKEY,
.DKKS,
.DKM,
.DKMT,
.DKNIGHT,
.DKS,
.DKT,
.DKWON,
.DKYC,
.DLANA,
.DLAUNCH,
.DLB,
.DLC,
.DLD,
.DLEGENDS,
.DLN,
.DLQ,
.DLT,
.DLTA,
.DLX,
.DLYCOP,
.DMASK,
.DMB,
.DMC,
.DMCH,
.DMCI,
.DMD,
.DMG,
.DMGK,
.DML,
.DMLG,
.DMME,
.DMN,
.DMND,
.DMOD,
.DMOON,
.DMR,
.DMS,
.DMSFT,
.DMST,
.DMT,
.DMTC,
.DMTR,
.DMUSK,
.DMX,
.DMZ,
.DNA,
.DND,
.DNDB,
.DNF,
.DNFLX,
.DNFT,
.DNKL,
.DNKY,
.DNL,
.DNLD,
.DNP,
.DNS,
.DNT,
.DNVDA,
.DNXC,
.DO,
.DOA,
.DOBE,
.DOBO,
.DOC,
.DOCA,
.DOCK,
.DOCO,
.DOD,
.DODI,
.DODO,
.DOE,
.DOEX,
.DOFI,
.DOG,
.DOG\$,
.DOGA,
.DOGBOSS,
.DOGDEFI,
.DOGE,
.DOGE0,
.DOGE2,
.DOGE_1,
.DOGEALLY,
.DOGEBACK,
.DOGEBULL,
.DOOM,
.DOGEBEAR,
.DOGEBNB,
.DOGEBROS,
.DOGEBTC,
.DOGEC,
.DOGECO,
.DOGECOIN,
.DOGECOLA,
.DOGECZ,
.DOGEDAO,
.DOGEDASH,
.DOGEDEAD,
.Doge_Dealer,
.DOGEDEALER,
.DOGEDI,
.DOGEDRINKS,
.DOGEFANS,
.DOGEFATHER,
.DOGEFI,
.DOGEFOOD,
.DOGEGF,
.DOGEGIVING,
.DOGEK,
.DogeKing,
.DOGEKING,
.DOGEKONGZILLA,
.DOGEMANIA,
.DOGEN,
.DOGEP,
.DOGEPEPSI,
.DOGEQUEEN,
.DOGERISEUP,
.DOGERKT,
.DOGES,
.DOGESX,
.DOGET,
.DOGETF,
.DOGEV,
.DOGEWHALE,
.DOGEWHISKY,
.DOGEX,
.DOGEY,
.DOGEZILLA,
.DOGGER,
.DOGGGO,
.DRGNBEAR,
.DRGNBULL,
.DRGNHALF,
.DRGNHEDGE,
.DOGGY,
.DOGIRA,
.DOGMOON,
.DOGO,
.DOGOWN,
.DOGS,
.DOGUS,
.DOGY,
.DOGZ,
.DOIT,
.DOJO,
.DOK,
.DOKEN,
.DOKI,
.DOL,
.DOLA,
.DOLLAR,
.DOLLARDOGE,
.DOLLO,
.DOLLY,
.DOLLYV2,
.DOLP,
.DOM,
.DOME,
.DOMI,
.DON,
.DONA,
.DONI,
.DONK,
.DONU,
.DONUT,
.DOO,
.DOOGEE,
.DOOH,
.DOOR,
.DOOS,
.DOP,
.DOPE,
.DOPX,
.DOR,
.DORA,
.DORAEMONINU,
.DOS,
.DOSA,
.DOSE,
.DOSHI,
.DOSHIBA,
.DOT,
.DOTA,
.DOTC,
.DOTUP,
.DOTDOWN,
.DOTMATRIX,
.DOTS,
.DUSDC,
.DUSDT,
.DOTX,
.DOUGE,
.DOUGH,
.DOV,
.DOVE,
.DOW,
.DOWS,
.DOX,
.DOZEN,
.DP,
.DPACE,
.DPAD,
.DPC,
.DPDBC,
.DPET,
.DPH,
.DPI,
.DPK_TOKEN,
.DPLTR,
.DPN,
.DPR,
.DPS,
.DPT,
.DPWK,
.DPX,
.DPY,
.DPZ,
.DQQQ,
.DRA,
.DRAC,
.DRACE,
.DRACO,
.DRAG,
.EOSDOWN,
.EOSHALF,
.EOSHEDGE,
.EOSMOON,
.EOSUP,
.ETCBEAR,
.ETCBULL,
.ETCHALF,
.ETCHEDGE,
.ETCMOON,
.DRAGON,
.DRAGONS,
.ETHDOOM,
.ETHHALF,
.ETHUP,
.DRATE,
.DRAW,
.DRAY,
.DRC,
.DRCT,
.DRE,
.DREAM,
.DREAMN,
.DREAMS,
.DREGG,
.DREP,
.DRF,
.EXCHHALF,
.EXCHHEDGE,
.EXCHMOON,
.DRG,
.DRGB,
.DRGN,
.DRGNZ,
.DRIFT,
.DRINK,
.DRIP,
.DRIV,
.DRIVE,
.DRK,
.DRM,
.DROP,
.DROPS,
.DROVERS,
.DRPU,
.DRS,
.DRSL,
.DRT,
.DRUG,
.DRUGS,
.DRUN,
.DRUNK,
.DRV,
.DRYDOGE,
.DRYP,
.DS\$,
.DSATA,
.DSBOWL,
.DSC,
.DSCPL,
.DSD,
.DSE,
.DSFR,
.DSG,
.DSH,
.DSHARE,
.DSI,
.DSK,
.DSL,
.DSLA,
.DSLV,
.DSM,
.DSOL,
.DSPY,
.DSR,
.DSS,
.DST,
.DSTR,
.DSWAP,
.DSYS,
.DT,
.DTA,
.DTC,
.DTD,
.DTE,
.FILDOWN,
.FILUP,
.DTEM,
.DTEP,
.DTF,
.DTH,
.DTK,
.DTLT,
.DTN,
.DTO,
.DTOP,
.DTR,
.DTRC,
.DTSLA,
.DTUBE,
.DTX,
.DUBI,
.DUC,
.DUCATO,
.DUCK,
.DUDE,
.DUEL,
.DUET,
.DUKE,
.DUMPDOGE,
.DUN,
.DUNE,
.DUNES,
.DUO,
.DURTH,
.DUSD,
.DUSK,
.DUST,
.DUTCH,
.DUW,
.DUZCE,
.DV,
.DVC,
.DVD,
.DVDX,
.DVERSENFT,
.DVF,
.DVFB,
.DVI,
.DVILLE,
.DVK,
.DVLD,
.DVNQ,
.DVOO,
.DVP,
.DVPN,
.DVS,
.DVT,
.DVX,
.DW,
.DWC,
.DWEB,
.DWR,
.DWS,
.DWT,
.DWZ,
.DX,
.DXB,
.DXC,
.DXCHECK,
.DXCT,
.DXD,
.DXF,
.DXG,
.DXGM,
.DXH,
.DXIOT,
.DXL,
.DXM,
.DXN,
.DXO,
.DXP,
.DXR,
.DXS,
.DXSANTA,
.DXT,
.DXTA,
.DXTS,
.DXY,
.DYDX,
.DYN,
.DYNA,
.DYNB,
.DYNMT,
.DYOR,
.DYP,
.DYST,
.DYT,
.DYZ,
.DZAR,
.DZG,
.DZI,
.DZOO,
.E8,
.E\$P,
.EA,
.EAC,
.EAD,
.EAGLE,
.EAGON,
.EAI,
.EAN,
.EARENA,
.EARN,
.EARNX,
.EARTH,
.EARTHS,
.EARTHSHIB,
.EAURIC,
.EB,
.EBA,
.EBASE,
.EBC,
.EBCC,
.EBEN,
.EBIRD,
.EBK,
.EBLOAP,
.EBOX,
.EBRH,
.EBSO,
.EBST,
.EBT,
.EBTC,
.EBUSD,
.EBYT,
.EC,
.EC2,
.ECA,
.ECASH,
.ECC,
.ECCHI,
.ECCU,
.ECD,
.ECDF,
.ECELL,
.ECH,
.ECHO,
.ECHT,
.ECIO,
.ECN,
.ECO,
.ECOB,
.ECOC,
.ECOEC,
.ECOIN,
.ECOM,
.ECOP,
.ECOREAL,
.ECOS,
.ECOT,
.ECP,
.ECPN,
.ECR,
.ECT,
.ECTE,
.ECTO,
.ECU,
.ECXX,
.EDAC,
.EDAO,
.EDAT,
.EDC,
.EDDA,
.EDDIE,
.EDEN,
.EDG,
.EDGE,
.EDGELON,
.EDGT,
.EDI,
.EDN,
.EDO,
.EDOGE,
.EDR,
.EDRC,
.EDS,
.EDU,
.EDUX,
.EDX,
.EEAT,
.EER,
.EEUR,
.EF,
.EFAR,
.EFB,
.EFC,
.EFFORT,
.EFG,
.EFI,
.EFIL,
.EFIN,
.EFK,
.EFL,
.EFloki,
.EFLOKI,
.EFR,
.EFREEMOON,
.EFT,
.EFUN,
.EFX,
.EGAME,
.EGAS,
.EGC,
.EGCC,
.EGEM,
.EGG,
.EGGP,
.EGI,
.EGL,
.EGLD,
.EGOD,
.EGOH,
.EGP,
.EGPT,
.EGR,
.EGS,
.EGT,
.EGWCAP,
.EGX,
.EGY,
.EHASH,
.EHB,
.EHRT,
.EHX,
.EIDOS,
.EIFI,
.EIGHT,
.EJECT,
.EJOB,
.EJS,
.EKA,
.EKG,
.EKO,
.EKT,
.EKTA,
.EL,
.ELA,
.ELAMA,
.ELAND,
.ELC,
.ELCASH,
.ELD,
.ELE,
.ELEC,
.ELEF,
.ELEN,
.ELENA,
.ELEPHANT,
.ELET,
.ELF,
.ELFI,
.ELFIN,
.ELFT,
.ELG,
.ELI,
.ELITE,
.ELIX,
.ELIXIR,
.ELK,
.ELL,
.ELLA,
.ELLI,
.ELLO,
.ELM,
.ELMON,
.ELNC,
.ELOC,
.ELOIN,
.ELO_INU,
.ELON,
.ELONBALLS,
.ELONBANK,
.ELONCAT,
.ELONGATE,
.ELONGD,
.ELONGRAB,
.ELONHYPE,
.ELONJET,
.ELONMOON,
.ELONOM,
.ELONONE,
.ELONPEG,
.ELP,
.ELPH,
.ELS,
.ELT,
.ELTCOIN,
.ELTG,
.ELTHM,
.ELU,
.ELV,
.ELVES,
.ELVN,
.ELX,
.ELXR,
.ELY,
.ELYX,
.EM,
.EMAX,
.EMB,
.EMBR,
.EMC,
.EMC2,
.EMD,
.EMERGENTS,
.EMHMC,
.EMJ,
.EMN,
.EMO,
.EMON,
.EMP,
.EMPIRE,
.EMPR,
.EMPYR,
.EMRALS,
.EMRX,
.EMS,
.EMT,
.eMTR,
.eMTRG,
.ENB,
.ENBE,
.ENC,
.Encointer,
.ENCORE,
.ENCX,
.END,
.Endcex,
.ENE,
.ENERGY,
.EnergyX,
.ENFT,
.ENG,
.ENGM,
.ENGN,
.ENGT,
.ENH,
.ENHANCE,
.ENIX,
.ENJ,
.ENK,
.ENKI,
.ENNO,
.ENOL,
.ENQ,
.ENRG,
.ENRX,
.ENS,
.ENSP_V2,
.ENT,
.ENTC,
.ENTERMETA,
.ENTONE,
.ENTR,
.ENTRC,
.ENTRP,
.ENTS,
.ENU,
.ENV,
.ENVIRO,
.ENX,
.ENY,
.EOC,
.EON,
.EONS,
.EOS,
.EOSBEAR,
.EOSBULL,
.EOSC,
.EOSDAC,
.EOSDT,
.EOST,
.EOTO,
.EOX,
.EPAN,
.EPAY,
.EPC,
.EPG,
.EPH,
.EPIC,
.EPICHERO,
.EPIK,
.EPIX,
.EPK,
.EPM,
.EPRINT,
.EPRO,
.EPS,
.EPSTEIN,
.EPTK,
.EPTT,
.EPW,
.EPX,
.EPY,
.EQ,
.EQL,
.EQMT,
.EQO,
.EQT,
.EQUAD,
.EQUITY,
.EQX,
.EQZ,
.ERA,
.ERABBIT,
.ERC,
.ERC20,
.ERD,
.ERECT,
.ERG,
.ERGOPAD,
.ERK,
.ERN,
.ERO,
.ERON,
.EROS,
.EROTICA,
.erowan,
.ERP,
.ERPT,
.ERR,
.eRSDL,
.ERT,
.ERTHA,
.ERW,
.ERY,
.ERZ,
.ES,
.ES3,
.ESAX,
.ESBC,
.ESC,
.ESCB,
.ESCE,
.ESCO,
.ESCROW,
.ESCX,
.ESD,
.ESG,
.ESH,
.ESHARE,
.EShib,
.ESHIB,
.ESHK,
.ESI,
.ESK,
.ESN,
.ESOL,
.ESOS,
.ESP,
.ESPL,
.ESPN,
.ESPRO,
.ESRC,
.ESS,
.EST,
.ESTATE,
.ESTI,
.ESW,
.ESWA,
.ESWAP,
.ESWAPV2,
.ESZ,
.ET,
.ETA,
.ETAS,
.ETB,
.ETBS,
.ETC,
.ETCH,
.ETE,
.ETER,
.ETERNAL,
.ETF,
.ETG,
.ETGF,
.ETGL,
.ETGP,
.ETH,
.ETH2X_FLI,
.ETH2X_FLI_P,
.ETH20SMACO,
.ETHA,
.ETHAX,
.ETHBACK,
.ETHBEAR,
.ETHBULL,
.ETHBN,
.ETHBTCRSI,
.ETHCOIN,
.ETHDOWN,
.ETHE,
.ETHEMAAPY,
.ETHERNAL,
.ETHFIN,
.ETHHEDGE,
.ETHIX,
.ETHK,
.ETHM,
.ETHMACOAPY,
.ETHMATRIX,
.ETHMNY,
.ETHO,
.ETHOS,
.ETHP,
.ETHPA,
.ETHPAD,
.ETHPLO,
.ETHPY,
.ETHRSI6040,
.ETHRSIAPY,
.ETHS,
.ETHSC,
.ETHSHIB,
.ETHUSDADL4,
.ETHUSDCTA,
.ETHV,
.ETHVAULT,
.ETHW,
.ETHY,
.ETHYS,
.ETHZILLA,
.ETI,
.ETJ,
.ETK,
.ETL,
.ETLG,
.ETLT,
.EtLyteT,
.ETM,
.ETN,
.ETNA,
.ETNX,
.ETNXP,
.ETNY,
.ETO,
.ETOS,
.ETP,
.ETR,
.ETS,
.ETT,
.ETX,
.ETZ,
.EUC,
.EUL,
.EULER,
.EUM,
.EUNO,
.EUP,
.EUPH,
.EURL,
.EURN,
.EUROC,
.EURONIN,
.EURS,
.EURST,
.EURT,
.EURU,
.EURX,
.EURXB,
.EUSD,
.EUT,
.EV,
.EVA,
.EVAN,
.EVAPE,
.EVAULT,
.EVC,
.EVCoin,
.EVDC,
.EVE,
.EVED,
.EVER,
.EVERBNB,
.EVERDOT,
.EVEREST,
.EVERETH,
.EVERF,
.EVERSAFU,
.EVI,
.EVIL,
.EVILSQUID,
.EVIRAL,
.EVM,
.EVMOS,
.EVN,
.EVO,
.EVOC,
.EVOL,
.EVOS,
.EVR,
.EVRF,
.EVRT,
.EVRY,
.EVS,
.EVT,
.EVU,
.EVX,
.EVY,
.EVZ,
.EWAR,
.EWC,
.EWT,
.EX,
.EXALT,
.EXC,
.EXCC,
.EXCL,
.EXE,
.EXF,
.EXFI,
.EXIP,
.EXIST,
.EXM,
.EXMR,
.EXN,
.EXNT,
.EXO,
.EXOD,
.EXOR,
.EXOS,
.EXOTIX,
.EXP,
.EXPO,
.EXPR,
.ExPRO,
.EXRD,
.EXRN,
.EXRT,
.EXS,
.EXT,
.EXY,
.EXZO,
.eYe,
.EYE,
.EYES,
.EZ,
.EZC,
.EZI,
.EZW,
.EZX,
.EZY,
.F1C,
.F2C,
.F5S,
.F7,
.F9,
.F11,
.FA,
.FAB,
.FABRIC,
.FACE,
.FACEDAO,
.FACT,
.FACTORY,
.FACTR,
.FADA,
.FADO,
.FAF,
.FAFI,
.FILDA,
.FAI,
.FAIL,
.FAIR,
.FAIRC,
.FAIRLIFE,
.FAITH,
.FAKT,
.FAL,
.FALAFEL,
.FALCON9,
.FALCONS,
.FALQOM,
.FAM,
.FAME,
.FAMEDOGE,
.FAMY,
.FAN,
.FAN8,
.FANC,
.FANG,
.FANI,
.FANNED,
.FANS,
.FANT,
.FANTA,
.FANTOMAPES,
.FANTV,
.FANV,
.FAR,
.FARA,
.FARI,
.FARM,
.FAS,
.FAST,
.FAT,
.FATCAKE,
.FATE,
.FATHER,
.FATOSHI,
.FAVAX,
.FAVOR,
.FAW,
.FAWA,
.FAYRE,
.FB,
.FBB,
.FBC,
.FBE,
.FBN,
.fBNB,
.FBNB,
.FBRO,
.FBS,
.FBT,
.FBULL,
.FBX,
.FC,
.FCA,
.FCB,
.FCBTC,
.FCD,
.FCF,
.FCH,
.FCK,
.FCL,
.FCN,
.FCON,
.FCP,
.FCQ,
.FCR,
.FCT,
.FCT,
.FCT2,
.FCX,
.FDD,
.FDLS,
.FDM,
.FDNZA,
.FDO,
.FDoge,
.FDOGE,
.FDOTA,
.FDR,
.FDT,
.FDX,
.FDZ,
.FEAR,
.FEAST,
.FEB,
.FECLIPSE,
.FED,
.FEED,
.FEEDTK,
.FEEL,
.FEENIXV2,
.FEES,
.FEEX,
.FEG,
.FEGN,
.FEGZ,
.FEI,
.FEN,
.FENIX,
.FENOMY,
.FER,
.FERA,
.FERRARI,
.FERT,
.FES,
.FESBNB,
.FESS,
.FET,
.FETA,
.FETCH,
.FETE,
.FETHP,
.FETT,
.FEVR,
.FEX,
.FEY,
.FF,
.FF1,
.FFF,
.FFLY,
.FFN,
.FFS,
.FFT,
.FFWOOL,
.FFYI,
.FG,
.FGC,
.FGD,
.FGI,
.FGP,
.FGROW,
.FGSPORT,
.FHM,
.FHSE,
.FHTN,
.FIA,
.FIAT,
.FIBO,
.FIC,
.FICO,
.FID,
.FIDA,
.FIDL,
.FIDYO,
.FIEF,
.FIELDS,
.FIFTY,
.FIG,
.FIGHT,
.FIGHTER,
.FIII,
.FIL,
.FILK,
.FILST,
.FIMI,
.FIN,
.FINA,
.FIND,
.FINE,
.FINIX,
.FINN,
.FINO,
.FINS,
.FINT,
.FINU,
.FIO,
.FIPI,
.FIRA,
.FIRE,
.FIREROCKET,
.FIRO,
.FIRST,
.FIRSTDOGE,
.FirstHare,
.FIRSTSHIB,
.FIRU,
.FIS,
.FISH,
.FIST,
.FIT,
.FITFI,
.FITI,
.FITN,
.FITT,
.FIU,
.FIWA,
.FJB,
.FJC,
.FJT,
.FKSK,
.FKX,
.FL,
.FLA,
.FLAFLO,
.FLAG,
.FLAKE,
.FLAME,
.FLARE,
.FLASH,
.FLAU,
.FLC,
.FLD,
.FLDC,
.FLESH,
.FLETA,
.FLEX,
.FLEXETHBTC,
.FLG,
.FLIBERO,
.FLICK,
.FLIP,
.FLISHU,
.FLIXX,
.FLK,
.FLL,
.FLM,
.FLMT,
.FLN,
.FLO,
.FLOAT,
.FLOBO,
.FloFru,
.FLOGE,
.FLOKACHU,
.floki,
.FLOKI,
.FLOKICOKE,
.FLOKIDAO,
.FLOKIELON,
.FLOKIFAM,
.FLOKIFARM,
.FLOKIFM,
.FLOKIFRUNKPUPPY,
.FLOKIG,
.FLOKIGOLD,
.FLOKILOKI,
.FLOKIM,
.FLOKIMONK,
.FLOKIMOON,
.FLOKIN,
.FLOKINY,
.FLOKIPAD,
.FLOKIPUP,
.FLOKIRAI,
.FLOKIRUN,
.FLOKIS,
.FLOKI_SUPER,
.FLOKITTEN,
.FLOKIV,
.FLOKIZ,
.FLOM,
.FLOOF,
.FLOOR,
.FLOSHIN,
.FLOT,
.FLOV,
.FLOVM,
.FLOW,
.FLOYX,
.FLP,
.FLPD,
.FLQ,
.FLR,
.FLRS,
.FLS,
.FLT,
.FLTY,
.FLUF,
.FLUFFY,
.FLUID,
.FLUNAR,
.FLURRY,
.FLUSD,
.FLUX,
.FLVR,
.FLX,
.FLY,
.FMA,
.FMAN,
.FMB,
.FMC,
.FME,
.FMEV2,
.FMEX,
.FMF,
.FMG,
.FMON,
.FMT,
.FMTA,
.FMV,
.FN,
.FNB,
.FNC,
.FNCY,
.FND,
.FNDZ,
.FNF,
.FNG,
.FNK,
.FNKOS,
.FNRD,
.FNSP,
.FNT,
.FNTB,
.FNX,
.FO,
.FOA,
.FOAM,
.FOC,
.FOCV,
.FOD,
.FODL,
.FOGE,
.FOHO,
.FOIL,
.FOIN,
.FOL,
.FOLD,
.FOLK,
.FOLO,
.FOMO,
.FOMOBABY,
.FomoETH,
.FOMP,
.FON,
.FONE,
.FONT,
.FOOD,
.FOODPAY,
.FOOFIGHT,
.FOOLSDOGE,
.FOOT,
.FootballStars,
.FOOTIE,
.FOPAY,
.FOR,
.FORCE,
.FORESTPLUS,
.FOREVERFOMO,
.FOREX,
.FORGE,
.FORINT,
.FORK,
.FORM,
.FORS,
.FORT,
.FORTH,
.FORTUNE,
.FORWARD,
.FOSSIL,
.FOTA,
.FOTC,
.FOTO,
.FOUND,
.FOUR,
.FOUR__4_,
.FOX,
.FOXD,
.FOXGIRL,
.FOXT,
.FOXX,
.Foxy,
.FP,
.FPAD,
.FPC,
.FPET,
.FPFT,
.FPI,
.FPIS,
.FPL,
.FPS,
.FPT,
.FPUMP,
.FPUP,
.FQS,
.FR,
.FRA,
.FRANC,
.FRANK,
.FRAS,
.FRAT,
.FRAX,
.FRC,
.FREC,
.FRECNX,
.FRED,
.FREDX,
.FREE,
.FREED,
.FREEDOM,
.FREEMOON,
.FREL,
.FREN,
.FRENS,
.FRET,
.FREY,
.FRF,
.FRG,
.FRIDGE,
.FRIES,
.FRIN,
.FRINU,
.FRKT,
.FRM,
.FRMS,
.FRMX,
.FRN,
.FRNK,
.FRNT,
.FROG,
.FROGEX,
.FROGGIES,
.FRONT,
.FROST,
.FROSTEDCAKE,
.FROSTYFLOKI,
.FROYO,
.FRR,
.FRST,
.FRT,
.FRTM,
.FRTS,
.FRUIT,
.FRY,
.FRZSS,
.FS,
.FSAFE,
.FSBT,
.FSC,
.FSCC,
.FSCP,
.FSD,
.FSDCOIN,
.FSH,
.FSHIB,
.FSHIBBY,
.FSHN,
.FSINU,
.FSK,
.FTMX,
.FSM,
.FSN,
.FSP,
.FST,
.FSTAR,
.FSW,
.FSWAP,
.FSXA,
.FSXU,
.FT,
.FT1,
.FTB,
.FTC,
.FTD,
.FTE,
.FTERRA,
.FTF,
.FTG,
.FTH,
.FTI,
.FTL,
.FTM,
.FTML,
.FTMO,
.FTN,
.FTO,
.FTON,
.FTP,
.FTR,
.FTRB,
.FTS,
.FTSY,
.FTT,
.FTV,
.FTX,
.FTXT,
.FUBUKI,
.FUCKMUSK,
.FUD,
.FUEL,
.FUFI,
.FUFU,
.FUKU,
.FULA,
.FULLSEND,
.FUM,
.FUMA,
.FUN,
.FUND,
.FUNDX,
.FUNDZ,
.FUNK,
.FUPS,
.FUR,
.FURY,
.FUSD,
.fUSDT,
.FUSE,
.FUSII,
.FUTURE,
.FUU,
.FUZE,
.FUZZ,
.FVP,
.FVT,
.FWATCH,
.FWB,
.FWC,
.FWG,
.FWT,
.FWW,
.FX,
.FX1,
.FXBC,
.FXC,
.FXDX,
.FXF,
.FXP,
.FXS,
.FXT,
.FXTE,
.FYD,
.FYN,
.FYP,
.FYZ,
.FYZNFT,
.FZ,
.FZL,
.FZS,
.FZY {
  display: inline-block;
  background: url('../img/tokens_logo_DtoEndF.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.D {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.D2D {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.D4RK {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.D6T {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.D11 {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.D100 {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.DAAPL {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.DAB {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.DAC {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.DACC {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.DACC2 {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.DACHX {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.DACS {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.DACXI {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.DAD {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.DADA {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.DADDYCAKE {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.DADDYDB {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.DADDYDOGE {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.DADDYETH {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.DADDYSHIBA {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.DADDYUSDT {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.DAF {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.DAFI {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.DAFT {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.DAG {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.DAGO {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.DAGT {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.DAH {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.DAI {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.DAILY {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.DAILYS {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.DAIN {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.DAIQ {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.DAISY {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.DAKE {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.DAL {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.DALC {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.DALI {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.DAM {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.DAMEX {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.DAMN {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.DAMZN {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.DAN {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.DANA {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.DANDY {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.DANGEL {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.DANGERMOON {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.DANK {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.DANTE {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.DAO {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.DAO1 {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.DAOF {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.DAOJONES {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.DAOS {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.DAOSOL {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.DAOVC {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.DAP {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.DAPES {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.DAPP {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.DAPPT {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.DAPPX {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.DAPS {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.DAR {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.DARA {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.DARC {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.DARK {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.DARKK {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.DARKO {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.dART {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.DART {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.DASH {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.DASHD {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.DASHG {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.DASS {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.DAT {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.DATA {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.DATE {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.DATP {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.DATX {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.DAU {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.DAV {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.DAVIS {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.DAVP {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.DAW {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.DAWG {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.DAWGS {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.DAWN {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.DAX {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.DAXT {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.DAY {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.DBA {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.DBABA {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.DBAY {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.DBC {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.DBD {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.DBDOGE {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.DBEA {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.DBET {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.DBK {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.DBL {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.DBQ {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.DBR {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.DBS {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.DBT {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.DBUBBLE {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.DBUY {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.DBX {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.DBY {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.DBZ {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.DC {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.DCA {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.DCASH {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.DCAU {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.DCB {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.DCC {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.DCCT {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.DCD {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.DCH {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.DCHS {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.DCIP {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.DCL {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.DCLUB {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.DCN {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.DCNTR {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.DCOIN {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.DCORE {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.DCR {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.DCS {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.DCT {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.DCTD {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.DCTO {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.DCVC {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.DCX {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.DCY {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.DDAI {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.DDAM {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.DDAO {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.DDC {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.DDD {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.DDDD {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.DDDX {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.DDETH {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.DDIM {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.DDK {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.DDL {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.DDN {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.DDOGE {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.DDOS {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.DDR {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.DDRST {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.DDRT {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.DDS {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.DDT {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.DDTG {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.DDX {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.DDY {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.DEA {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.DEAL {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.DEALDOUGH {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.DEATH {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.DEB {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.DEBA {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.DEBASE {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.DEC {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.DECENT {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.DECO {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.DED {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.DEEM {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.DEEP {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.DEER {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.DEEX {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.DEEZ {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.DEEZNUTS {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.DEF {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.DEFC {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.DEFI {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.DEFI5 {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.DEFI\+\+ {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.DEFIHEDGE {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.DEFIBEAR {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.DEFIHALF {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.DEFIBULL {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.DEFIDO {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.DEFI\+L {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.DEFILANCER {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.DEFI\+S {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.DEFIT {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.DEFIY {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.DEFLA {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.DEFLCT {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.DEFLY {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.DEFO {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.DEFT {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.DEFX {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.DEFY {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.DEG {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.DEGEN {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.DEGENS {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.DEGG {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.DEGN {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.DEGO {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.DEGOV {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.DEHUB {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.DEI {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.DEIP {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.DEK {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.DEKU {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.DMGBEAR {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.DMGBULL {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.DEL {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.DELFI {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.DELISH {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.DELO {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.DELOS {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.DELOT {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.DELTA {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.DELTAF {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.DELTA_RLP {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.DEM {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.DEMA {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.DEMIR {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.DEMY {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.DEN {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.DENA {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.DENT {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.DENTRO {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.DEO {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.DEOR {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.DEP {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.DEPAY {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.DEPO {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.DEQ {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.DERC {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.DERI {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.DERO {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.DES {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.DESIRE {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.DESO {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.DESU {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.DET {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.DETO {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.DETS {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.DEUS {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.DEUX {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.DEV {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.DEVA {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.DEVE {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.DEVIA8 {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.DEVIL {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.DEVO {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.DEVT {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.DEW {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.DEWO {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.DOGEHALF {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.DOGEHEDGE {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.DEX {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.DEXA {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.DEXE {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.DEXF {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.DEXG {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.DEXI {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.DEXM {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.DEXSHARE {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.DEXT {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.DEXTF {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.DF {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.DFA {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.DFAI {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.DFB {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.DFC {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.DFD {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.DFE {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.DFF {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.DFG {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.DFGL {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.DFH {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.DFI {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.DFIAT {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.DFIN {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.DFIO {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.DFIP {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.DFK {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.DFL {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.DFM {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.DFND {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.DFNI {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.DFP2 {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.DFR {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.DFS {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.DFSG {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.DFSM {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.DFSOCIAL {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.DFST {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.DFT {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.DFW {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.DFX {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.DFY {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.DFYN {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.DG {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.DGA {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.DGAT {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.DGB {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.DGC {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.DGCL {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.DGD {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.DGE {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.DGEMS {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.DGG {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.DGI {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.DGLD {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.DGLN {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.DGMAN {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.DGME {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.DGMOON {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.DGMT {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.DGMUSK {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.DGMV {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.DGMV2 {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.DGN {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.DGNN {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.DGOLD {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.DGOOGL {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.DGP {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.DGS {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.DGT {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.DGTL {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.DGTX {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.DGVC {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.DGW {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.DGX {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.DGZV {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.DHC {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.DHD {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.DHG {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.DHLT {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.DHN {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.DHOLD {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.DHP {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.DHR {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.DHT {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.DHV {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.DHX {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.DI {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.DIA {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.DIAH {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.DIAMND {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.DIAMOND {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.DIAMONDHANDS {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.DIAMONDS {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.DIANA {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.DIBA {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.DIBS {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.DIC {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.DICE {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.DICK {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.DID {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.DIEM {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.DIESEL {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.DIFF {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.DIFX {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.DIG {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.DIGAU {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.DIGEX {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.DIGG {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.DIGI {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.DIGICHAIN {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.DIGINU {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.DIGS {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.DIIC {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.DIK {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.DIKE {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.DIKO {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.DILI {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.DIM {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.DIME {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.DIN {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.DINA {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.DINGER {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.DINGO {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.DINJA {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.DINK {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.DINNER {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.DINO {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.DINOEGG {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.DINU {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.DIO {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.DION {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.DIOS {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.DIP {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.Dipole {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.DIQ {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.DIREWOLF {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.DIRKDIGGLER {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.DIRTY {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.DIS {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.DISC {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.DISTX {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.DIT {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.DITTO {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.DITTOINU {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.DIV {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.DIVER {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.DIVI {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.DIVINE {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.DIVS {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.DIXT {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.DIYAR {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.DJ15 {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.DJBZ {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.DJN {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.DJV {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.DK {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.DKA {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.DKC {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.DKEY {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.DKKS {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.DKM {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.DKMT {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.DKNIGHT {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.DKS {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.DKT {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.DKWON {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.DKYC {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.DLANA {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.DLAUNCH {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.DLB {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.DLC {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.DLD {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.DLEGENDS {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.DLN {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.DLQ {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.DLT {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.DLTA {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.DLX {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.DLYCOP {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.DMASK {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.DMB {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.DMC {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.DMCH {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.DMCI {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.DMD {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.DMG {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.DMGK {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.DML {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.DMLG {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.DMME {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.DMN {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.DMND {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.DMOD {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.DMOON {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.DMR {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.DMS {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.DMSFT {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.DMST {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.DMT {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.DMTC {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.DMTR {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.DMUSK {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.DMX {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.DMZ {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.DNA {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.DND {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.DNDB {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.DNF {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.DNFLX {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.DNFT {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.DNKL {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.DNKY {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.DNL {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.DNLD {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.DNP {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.DNS {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.DNT {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.DNVDA {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.DNXC {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.DO {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.DOA {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.DOBE {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.DOBO {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.DOC {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.DOCA {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.DOCK {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.DOCO {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.DOD {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.DODI {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.DODO {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.DOE {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.DOEX {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.DOFI {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.DOG {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.DOG\$ {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.DOGA {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.DOGBOSS {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.DOGDEFI {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.DOGE {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.DOGE0 {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.DOGE2 {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.DOGE_1 {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.DOGEALLY {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.DOGEBACK {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.DOGEBULL {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.DOOM {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.DOGEBEAR {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.DOGEBNB {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.DOGEBROS {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.DOGEBTC {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.DOGEC {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.DOGECO {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.DOGECOIN {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.DOGECOLA {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.DOGECZ {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.DOGEDAO {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.DOGEDASH {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.DOGEDEAD {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.Doge_Dealer {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.DOGEDEALER {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.DOGEDI {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.DOGEDRINKS {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.DOGEFANS {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.DOGEFATHER {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.DOGEFI {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.DOGEFOOD {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.DOGEGF {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.DOGEGIVING {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.DOGEK {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.DogeKing {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.DOGEKING {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.DOGEKONGZILLA {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.DOGEMANIA {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.DOGEN {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.DOGEP {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.DOGEPEPSI {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.DOGEQUEEN {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.DOGERISEUP {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.DOGERKT {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.DOGES {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.DOGESX {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.DOGET {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.DOGETF {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.DOGEV {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.DOGEWHALE {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.DOGEWHISKY {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.DOGEX {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.DOGEY {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.DOGEZILLA {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.DOGGER {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.DOGGGO {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.DRGNBEAR {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.DRGNBULL {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.DRGNHALF {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.DRGNHEDGE {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.DOGGY {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.DOGIRA {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.DOGMOON {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.DOGO {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.DOGOWN {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.DOGS {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.DOGUS {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.DOGY {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.DOGZ {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.DOIT {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.DOJO {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.DOK {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.DOKEN {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.DOKI {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.DOL {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.DOLA {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.DOLLAR {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.DOLLARDOGE {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.DOLLO {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.DOLLY {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.DOLLYV2 {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.DOLP {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.DOM {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.DOME {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.DOMI {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.DON {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.DONA {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.DONI {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.DONK {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.DONU {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.DONUT {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.DOO {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.DOOGEE {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.DOOH {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.DOOR {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.DOOS {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.DOP {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.DOPE {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.DOPX {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.DOR {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.DORA {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.DORAEMONINU {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.DOS {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.DOSA {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.DOSE {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.DOSHI {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.DOSHIBA {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.DOT {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.DOTA {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.DOTC {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.DOTUP {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.DOTDOWN {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.DOTMATRIX {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.DOTS {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.DUSDC {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.DUSDT {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.DOTX {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.DOUGE {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.DOUGH {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.DOV {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.DOVE {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.DOW {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.DOWS {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.DOX {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.DOZEN {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.DP {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.DPACE {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.DPAD {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.DPC {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.DPDBC {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.DPET {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.DPH {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.DPI {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.DPK_TOKEN {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.DPLTR {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.DPN {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.DPR {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.DPS {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.DPT {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.DPWK {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.DPX {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.DPY {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.DPZ {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.DQQQ {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.DRA {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.DRAC {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.DRACE {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.DRACO {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.DRAG {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.EOSDOWN {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.EOSHALF {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.EOSHEDGE {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.EOSMOON {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.EOSUP {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.ETCBEAR {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.ETCBULL {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.ETCHALF {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.ETCHEDGE {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.ETCMOON {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.DRAGON {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.DRAGONS {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.ETHDOOM {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.ETHHALF {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.ETHUP {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.DRATE {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.DRAW {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.DRAY {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.DRC {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.DRCT {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.DRE {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.DREAM {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.DREAMN {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.DREAMS {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.DREGG {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.DREP {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.DRF {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.EXCHHALF {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.EXCHHEDGE {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.EXCHMOON {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.DRG {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.DRGB {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.DRGN {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.DRGNZ {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.DRIFT {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.DRINK {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.DRIP {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.DRIV {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.DRIVE {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.DRK {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.DRM {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.DROP {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.DROPS {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.DROVERS {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.DRPU {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.DRS {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.DRSL {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.DRT {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.DRUG {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.DRUGS {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.DRUN {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.DRUNK {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.DRV {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.DRYDOGE {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.DRYP {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.DS\$ {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.DSATA {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.DSBOWL {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.DSC {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.DSCPL {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.DSD {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.DSE {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.DSFR {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.DSG {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.DSH {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.DSHARE {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.DSI {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.DSK {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.DSL {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.DSLA {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.DSLV {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.DSM {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.DSOL {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.DSPY {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}

.DSR {
  width: 16px;
  height: 16px;
  background-position: -208px -416px;
}

.DSS {
  width: 16px;
  height: 16px;
  background-position: -224px -416px;
}

.DST {
  width: 16px;
  height: 16px;
  background-position: -240px -416px;
}

.DSTR {
  width: 16px;
  height: 16px;
  background-position: -256px -416px;
}

.DSWAP {
  width: 16px;
  height: 16px;
  background-position: -272px -416px;
}

.DSYS {
  width: 16px;
  height: 16px;
  background-position: -288px -416px;
}

.DT {
  width: 16px;
  height: 16px;
  background-position: -304px -416px;
}

.DTA {
  width: 16px;
  height: 16px;
  background-position: -320px -416px;
}

.DTC {
  width: 16px;
  height: 16px;
  background-position: -336px -416px;
}

.DTD {
  width: 16px;
  height: 16px;
  background-position: -352px -416px;
}

.DTE {
  width: 16px;
  height: 16px;
  background-position: -368px -416px;
}

.FILDOWN {
  width: 16px;
  height: 16px;
  background-position: -384px -416px;
}

.FILUP {
  width: 16px;
  height: 16px;
  background-position: -400px -416px;
}

.DTEM {
  width: 16px;
  height: 16px;
  background-position: -416px -416px;
}

.DTEP {
  width: 16px;
  height: 16px;
  background-position: -432px -0;
}

.DTF {
  width: 16px;
  height: 16px;
  background-position: -432px -16px;
}

.DTH {
  width: 16px;
  height: 16px;
  background-position: -432px -32px;
}

.DTK {
  width: 16px;
  height: 16px;
  background-position: -432px -48px;
}

.DTLT {
  width: 16px;
  height: 16px;
  background-position: -432px -64px;
}

.DTN {
  width: 16px;
  height: 16px;
  background-position: -432px -80px;
}

.DTO {
  width: 16px;
  height: 16px;
  background-position: -432px -96px;
}

.DTOP {
  width: 16px;
  height: 16px;
  background-position: -432px -112px;
}

.DTR {
  width: 16px;
  height: 16px;
  background-position: -432px -128px;
}

.DTRC {
  width: 16px;
  height: 16px;
  background-position: -432px -144px;
}

.DTSLA {
  width: 16px;
  height: 16px;
  background-position: -432px -160px;
}

.DTUBE {
  width: 16px;
  height: 16px;
  background-position: -432px -176px;
}

.DTX {
  width: 16px;
  height: 16px;
  background-position: -432px -192px;
}

.DUBI {
  width: 16px;
  height: 16px;
  background-position: -432px -208px;
}

.DUC {
  width: 16px;
  height: 16px;
  background-position: -432px -224px;
}

.DUCATO {
  width: 16px;
  height: 16px;
  background-position: -432px -240px;
}

.DUCK {
  width: 16px;
  height: 16px;
  background-position: -432px -256px;
}

.DUDE {
  width: 16px;
  height: 16px;
  background-position: -432px -272px;
}

.DUEL {
  width: 16px;
  height: 16px;
  background-position: -432px -288px;
}

.DUET {
  width: 16px;
  height: 16px;
  background-position: -432px -304px;
}

.DUKE {
  width: 16px;
  height: 16px;
  background-position: -432px -320px;
}

.DUMPDOGE {
  width: 16px;
  height: 16px;
  background-position: -432px -336px;
}

.DUN {
  width: 16px;
  height: 16px;
  background-position: -432px -352px;
}

.DUNE {
  width: 16px;
  height: 16px;
  background-position: -432px -368px;
}

.DUNES {
  width: 16px;
  height: 16px;
  background-position: -432px -384px;
}

.DUO {
  width: 16px;
  height: 16px;
  background-position: -432px -400px;
}

.DURTH {
  width: 16px;
  height: 16px;
  background-position: -432px -416px;
}

.DUSD {
  width: 16px;
  height: 16px;
  background-position: -0 -432px;
}

.DUSK {
  width: 16px;
  height: 16px;
  background-position: -16px -432px;
}

.DUST {
  width: 16px;
  height: 16px;
  background-position: -32px -432px;
}

.DUTCH {
  width: 16px;
  height: 16px;
  background-position: -48px -432px;
}

.DUW {
  width: 16px;
  height: 16px;
  background-position: -64px -432px;
}

.DUZCE {
  width: 16px;
  height: 16px;
  background-position: -80px -432px;
}

.DV {
  width: 16px;
  height: 16px;
  background-position: -96px -432px;
}

.DVC {
  width: 16px;
  height: 16px;
  background-position: -112px -432px;
}

.DVD {
  width: 16px;
  height: 16px;
  background-position: -128px -432px;
}

.DVDX {
  width: 16px;
  height: 16px;
  background-position: -144px -432px;
}

.DVERSENFT {
  width: 16px;
  height: 16px;
  background-position: -160px -432px;
}

.DVF {
  width: 16px;
  height: 16px;
  background-position: -176px -432px;
}

.DVFB {
  width: 16px;
  height: 16px;
  background-position: -192px -432px;
}

.DVI {
  width: 16px;
  height: 16px;
  background-position: -208px -432px;
}

.DVILLE {
  width: 16px;
  height: 16px;
  background-position: -224px -432px;
}

.DVK {
  width: 16px;
  height: 16px;
  background-position: -240px -432px;
}

.DVLD {
  width: 16px;
  height: 16px;
  background-position: -256px -432px;
}

.DVNQ {
  width: 16px;
  height: 16px;
  background-position: -272px -432px;
}

.DVOO {
  width: 16px;
  height: 16px;
  background-position: -288px -432px;
}

.DVP {
  width: 16px;
  height: 16px;
  background-position: -304px -432px;
}

.DVPN {
  width: 16px;
  height: 16px;
  background-position: -320px -432px;
}

.DVS {
  width: 16px;
  height: 16px;
  background-position: -336px -432px;
}

.DVT {
  width: 16px;
  height: 16px;
  background-position: -352px -432px;
}

.DVX {
  width: 16px;
  height: 16px;
  background-position: -368px -432px;
}

.DW {
  width: 16px;
  height: 16px;
  background-position: -384px -432px;
}

.DWC {
  width: 16px;
  height: 16px;
  background-position: -400px -432px;
}

.DWEB {
  width: 16px;
  height: 16px;
  background-position: -416px -432px;
}

.DWR {
  width: 16px;
  height: 16px;
  background-position: -432px -432px;
}

.DWS {
  width: 16px;
  height: 16px;
  background-position: -448px -0;
}

.DWT {
  width: 16px;
  height: 16px;
  background-position: -448px -16px;
}

.DWZ {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}

.DX {
  width: 16px;
  height: 16px;
  background-position: -448px -48px;
}

.DXB {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}

.DXC {
  width: 16px;
  height: 16px;
  background-position: -448px -80px;
}

.DXCHECK {
  width: 16px;
  height: 16px;
  background-position: -448px -96px;
}

.DXCT {
  width: 16px;
  height: 16px;
  background-position: -448px -112px;
}

.DXD {
  width: 16px;
  height: 16px;
  background-position: -448px -128px;
}

.DXF {
  width: 16px;
  height: 16px;
  background-position: -448px -144px;
}

.DXG {
  width: 16px;
  height: 16px;
  background-position: -448px -160px;
}

.DXGM {
  width: 16px;
  height: 16px;
  background-position: -448px -176px;
}

.DXH {
  width: 16px;
  height: 16px;
  background-position: -448px -192px;
}

.DXIOT {
  width: 16px;
  height: 16px;
  background-position: -448px -208px;
}

.DXL {
  width: 16px;
  height: 16px;
  background-position: -448px -224px;
}

.DXM {
  width: 16px;
  height: 16px;
  background-position: -448px -240px;
}

.DXN {
  width: 16px;
  height: 16px;
  background-position: -448px -256px;
}

.DXO {
  width: 16px;
  height: 16px;
  background-position: -448px -272px;
}

.DXP {
  width: 16px;
  height: 16px;
  background-position: -448px -288px;
}

.DXR {
  width: 16px;
  height: 16px;
  background-position: -448px -304px;
}

.DXS {
  width: 16px;
  height: 16px;
  background-position: -448px -320px;
}

.DXSANTA {
  width: 16px;
  height: 16px;
  background-position: -448px -336px;
}

.DXT {
  width: 16px;
  height: 16px;
  background-position: -448px -352px;
}

.DXTA {
  width: 16px;
  height: 16px;
  background-position: -448px -368px;
}

.DXTS {
  width: 16px;
  height: 16px;
  background-position: -448px -384px;
}

.DXY {
  width: 16px;
  height: 16px;
  background-position: -448px -400px;
}

.DYDX {
  width: 16px;
  height: 16px;
  background-position: -448px -416px;
}

.DYN {
  width: 16px;
  height: 16px;
  background-position: -448px -432px;
}

.DYNA {
  width: 16px;
  height: 16px;
  background-position: -0 -448px;
}

.DYNB {
  width: 16px;
  height: 16px;
  background-position: -16px -448px;
}

.DYNMT {
  width: 16px;
  height: 16px;
  background-position: -32px -448px;
}

.DYOR {
  width: 16px;
  height: 16px;
  background-position: -48px -448px;
}

.DYP {
  width: 16px;
  height: 16px;
  background-position: -64px -448px;
}

.DYST {
  width: 16px;
  height: 16px;
  background-position: -80px -448px;
}

.DYT {
  width: 16px;
  height: 16px;
  background-position: -96px -448px;
}

.DYZ {
  width: 16px;
  height: 16px;
  background-position: -112px -448px;
}

.DZAR {
  width: 16px;
  height: 16px;
  background-position: -128px -448px;
}

.DZG {
  width: 16px;
  height: 16px;
  background-position: -144px -448px;
}

.DZI {
  width: 16px;
  height: 16px;
  background-position: -160px -448px;
}

.DZOO {
  width: 16px;
  height: 16px;
  background-position: -176px -448px;
}

.E8 {
  width: 16px;
  height: 16px;
  background-position: -192px -448px;
}

.E\$P {
  width: 16px;
  height: 16px;
  background-position: -208px -448px;
}

.EA {
  width: 16px;
  height: 16px;
  background-position: -224px -448px;
}

.EAC {
  width: 16px;
  height: 16px;
  background-position: -240px -448px;
}

.EAD {
  width: 16px;
  height: 16px;
  background-position: -256px -448px;
}

.EAGLE {
  width: 16px;
  height: 16px;
  background-position: -272px -448px;
}

.EAGON {
  width: 16px;
  height: 16px;
  background-position: -288px -448px;
}

.EAI {
  width: 16px;
  height: 16px;
  background-position: -304px -448px;
}

.EAN {
  width: 16px;
  height: 16px;
  background-position: -320px -448px;
}

.EARENA {
  width: 16px;
  height: 16px;
  background-position: -336px -448px;
}

.EARN {
  width: 16px;
  height: 16px;
  background-position: -352px -448px;
}

.EARNX {
  width: 16px;
  height: 16px;
  background-position: -368px -448px;
}

.EARTH {
  width: 16px;
  height: 16px;
  background-position: -384px -448px;
}

.EARTHS {
  width: 16px;
  height: 16px;
  background-position: -400px -448px;
}

.EARTHSHIB {
  width: 16px;
  height: 16px;
  background-position: -416px -448px;
}

.EAURIC {
  width: 16px;
  height: 16px;
  background-position: -432px -448px;
}

.EB {
  width: 16px;
  height: 16px;
  background-position: -448px -448px;
}

.EBA {
  width: 16px;
  height: 16px;
  background-position: -464px -0;
}

.EBASE {
  width: 16px;
  height: 16px;
  background-position: -464px -16px;
}

.EBC {
  width: 16px;
  height: 16px;
  background-position: -464px -32px;
}

.EBCC {
  width: 16px;
  height: 16px;
  background-position: -464px -48px;
}

.EBEN {
  width: 16px;
  height: 16px;
  background-position: -464px -64px;
}

.EBIRD {
  width: 16px;
  height: 16px;
  background-position: -464px -80px;
}

.EBK {
  width: 16px;
  height: 16px;
  background-position: -464px -96px;
}

.EBLOAP {
  width: 16px;
  height: 16px;
  background-position: -464px -112px;
}

.EBOX {
  width: 16px;
  height: 16px;
  background-position: -464px -128px;
}

.EBRH {
  width: 16px;
  height: 16px;
  background-position: -464px -144px;
}

.EBSO {
  width: 16px;
  height: 16px;
  background-position: -464px -160px;
}

.EBST {
  width: 16px;
  height: 16px;
  background-position: -464px -176px;
}

.EBT {
  width: 16px;
  height: 16px;
  background-position: -464px -192px;
}

.EBTC {
  width: 16px;
  height: 16px;
  background-position: -464px -208px;
}

.EBUSD {
  width: 16px;
  height: 16px;
  background-position: -464px -224px;
}

.EBYT {
  width: 16px;
  height: 16px;
  background-position: -464px -240px;
}

.EC {
  width: 16px;
  height: 16px;
  background-position: -464px -256px;
}

.EC2 {
  width: 16px;
  height: 16px;
  background-position: -464px -272px;
}

.ECA {
  width: 16px;
  height: 16px;
  background-position: -464px -288px;
}

.ECASH {
  width: 16px;
  height: 16px;
  background-position: -464px -304px;
}

.ECC {
  width: 16px;
  height: 16px;
  background-position: -464px -320px;
}

.ECCHI {
  width: 16px;
  height: 16px;
  background-position: -464px -336px;
}

.ECCU {
  width: 16px;
  height: 16px;
  background-position: -464px -352px;
}

.ECD {
  width: 16px;
  height: 16px;
  background-position: -464px -368px;
}

.ECDF {
  width: 16px;
  height: 16px;
  background-position: -464px -384px;
}

.ECELL {
  width: 16px;
  height: 16px;
  background-position: -464px -400px;
}

.ECH {
  width: 16px;
  height: 16px;
  background-position: -464px -416px;
}

.ECHO {
  width: 16px;
  height: 16px;
  background-position: -464px -432px;
}

.ECHT {
  width: 16px;
  height: 16px;
  background-position: -464px -448px;
}

.ECIO {
  width: 16px;
  height: 16px;
  background-position: -0 -464px;
}

.ECN {
  width: 16px;
  height: 16px;
  background-position: -16px -464px;
}

.ECO {
  width: 16px;
  height: 16px;
  background-position: -32px -464px;
}

.ECOB {
  width: 16px;
  height: 16px;
  background-position: -48px -464px;
}

.ECOC {
  width: 16px;
  height: 16px;
  background-position: -64px -464px;
}

.ECOEC {
  width: 16px;
  height: 16px;
  background-position: -80px -464px;
}

.ECOIN {
  width: 16px;
  height: 16px;
  background-position: -96px -464px;
}

.ECOM {
  width: 16px;
  height: 16px;
  background-position: -112px -464px;
}

.ECOP {
  width: 16px;
  height: 16px;
  background-position: -128px -464px;
}

.ECOREAL {
  width: 16px;
  height: 16px;
  background-position: -144px -464px;
}

.ECOS {
  width: 16px;
  height: 16px;
  background-position: -160px -464px;
}

.ECOT {
  width: 16px;
  height: 16px;
  background-position: -176px -464px;
}

.ECP {
  width: 16px;
  height: 16px;
  background-position: -192px -464px;
}

.ECPN {
  width: 16px;
  height: 16px;
  background-position: -208px -464px;
}

.ECR {
  width: 16px;
  height: 16px;
  background-position: -224px -464px;
}

.ECT {
  width: 16px;
  height: 16px;
  background-position: -240px -464px;
}

.ECTE {
  width: 16px;
  height: 16px;
  background-position: -256px -464px;
}

.ECTO {
  width: 16px;
  height: 16px;
  background-position: -272px -464px;
}

.ECU {
  width: 16px;
  height: 16px;
  background-position: -288px -464px;
}

.ECXX {
  width: 16px;
  height: 16px;
  background-position: -304px -464px;
}

.EDAC {
  width: 16px;
  height: 16px;
  background-position: -320px -464px;
}

.EDAO {
  width: 16px;
  height: 16px;
  background-position: -336px -464px;
}

.EDAT {
  width: 16px;
  height: 16px;
  background-position: -352px -464px;
}

.EDC {
  width: 16px;
  height: 16px;
  background-position: -368px -464px;
}

.EDDA {
  width: 16px;
  height: 16px;
  background-position: -384px -464px;
}

.EDDIE {
  width: 16px;
  height: 16px;
  background-position: -400px -464px;
}

.EDEN {
  width: 16px;
  height: 16px;
  background-position: -416px -464px;
}

.EDG {
  width: 16px;
  height: 16px;
  background-position: -432px -464px;
}

.EDGE {
  width: 16px;
  height: 16px;
  background-position: -448px -464px;
}

.EDGELON {
  width: 16px;
  height: 16px;
  background-position: -464px -464px;
}

.EDGT {
  width: 16px;
  height: 16px;
  background-position: -480px -0;
}

.EDI {
  width: 16px;
  height: 16px;
  background-position: -480px -16px;
}

.EDN {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}

.EDO {
  width: 16px;
  height: 16px;
  background-position: -480px -48px;
}

.EDOGE {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}

.EDR {
  width: 16px;
  height: 16px;
  background-position: -480px -80px;
}

.EDRC {
  width: 16px;
  height: 16px;
  background-position: -480px -96px;
}

.EDS {
  width: 16px;
  height: 16px;
  background-position: -480px -112px;
}

.EDU {
  width: 16px;
  height: 16px;
  background-position: -480px -128px;
}

.EDUX {
  width: 16px;
  height: 16px;
  background-position: -480px -144px;
}

.EDX {
  width: 16px;
  height: 16px;
  background-position: -480px -160px;
}

.EEAT {
  width: 16px;
  height: 16px;
  background-position: -480px -176px;
}

.EER {
  width: 16px;
  height: 16px;
  background-position: -480px -192px;
}

.EEUR {
  width: 16px;
  height: 16px;
  background-position: -480px -208px;
}

.EF {
  width: 16px;
  height: 16px;
  background-position: -480px -224px;
}

.EFAR {
  width: 16px;
  height: 16px;
  background-position: -480px -240px;
}

.EFB {
  width: 16px;
  height: 16px;
  background-position: -480px -256px;
}

.EFC {
  width: 16px;
  height: 16px;
  background-position: -480px -272px;
}

.EFFORT {
  width: 16px;
  height: 16px;
  background-position: -480px -288px;
}

.EFG {
  width: 16px;
  height: 16px;
  background-position: -480px -304px;
}

.EFI {
  width: 16px;
  height: 16px;
  background-position: -480px -320px;
}

.EFIL {
  width: 16px;
  height: 16px;
  background-position: -480px -336px;
}

.EFIN {
  width: 16px;
  height: 16px;
  background-position: -480px -352px;
}

.EFK {
  width: 16px;
  height: 16px;
  background-position: -480px -368px;
}

.EFL {
  width: 16px;
  height: 16px;
  background-position: -480px -384px;
}

.EFloki {
  width: 16px;
  height: 16px;
  background-position: -480px -400px;
}

.EFLOKI {
  width: 16px;
  height: 16px;
  background-position: -480px -416px;
}

.EFR {
  width: 16px;
  height: 16px;
  background-position: -480px -432px;
}

.EFREEMOON {
  width: 16px;
  height: 16px;
  background-position: -480px -448px;
}

.EFT {
  width: 16px;
  height: 16px;
  background-position: -480px -464px;
}

.EFUN {
  width: 16px;
  height: 16px;
  background-position: -0 -480px;
}

.EFX {
  width: 16px;
  height: 16px;
  background-position: -16px -480px;
}

.EGAME {
  width: 16px;
  height: 16px;
  background-position: -32px -480px;
}

.EGAS {
  width: 16px;
  height: 16px;
  background-position: -48px -480px;
}

.EGC {
  width: 16px;
  height: 16px;
  background-position: -64px -480px;
}

.EGCC {
  width: 16px;
  height: 16px;
  background-position: -80px -480px;
}

.EGEM {
  width: 16px;
  height: 16px;
  background-position: -96px -480px;
}

.EGG {
  width: 16px;
  height: 16px;
  background-position: -112px -480px;
}

.EGGP {
  width: 16px;
  height: 16px;
  background-position: -128px -480px;
}

.EGI {
  width: 16px;
  height: 16px;
  background-position: -144px -480px;
}

.EGL {
  width: 16px;
  height: 16px;
  background-position: -160px -480px;
}

.EGLD {
  width: 16px;
  height: 16px;
  background-position: -176px -480px;
}

.EGOD {
  width: 16px;
  height: 16px;
  background-position: -192px -480px;
}

.EGOH {
  width: 16px;
  height: 16px;
  background-position: -208px -480px;
}

.EGP {
  width: 16px;
  height: 16px;
  background-position: -224px -480px;
}

.EGPT {
  width: 16px;
  height: 16px;
  background-position: -240px -480px;
}

.EGR {
  width: 16px;
  height: 16px;
  background-position: -256px -480px;
}

.EGS {
  width: 16px;
  height: 16px;
  background-position: -272px -480px;
}

.EGT {
  width: 16px;
  height: 16px;
  background-position: -288px -480px;
}

.EGWCAP {
  width: 16px;
  height: 16px;
  background-position: -304px -480px;
}

.EGX {
  width: 16px;
  height: 16px;
  background-position: -320px -480px;
}

.EGY {
  width: 16px;
  height: 16px;
  background-position: -336px -480px;
}

.EHASH {
  width: 16px;
  height: 16px;
  background-position: -352px -480px;
}

.EHB {
  width: 16px;
  height: 16px;
  background-position: -368px -480px;
}

.EHRT {
  width: 16px;
  height: 16px;
  background-position: -384px -480px;
}

.EHX {
  width: 16px;
  height: 16px;
  background-position: -400px -480px;
}

.EIDOS {
  width: 16px;
  height: 16px;
  background-position: -416px -480px;
}

.EIFI {
  width: 16px;
  height: 16px;
  background-position: -432px -480px;
}

.EIGHT {
  width: 16px;
  height: 16px;
  background-position: -448px -480px;
}

.EJECT {
  width: 16px;
  height: 16px;
  background-position: -464px -480px;
}

.EJOB {
  width: 16px;
  height: 16px;
  background-position: -480px -480px;
}

.EJS {
  width: 16px;
  height: 16px;
  background-position: -496px -0;
}

.EKA {
  width: 16px;
  height: 16px;
  background-position: -496px -16px;
}

.EKG {
  width: 16px;
  height: 16px;
  background-position: -496px -32px;
}

.EKO {
  width: 16px;
  height: 16px;
  background-position: -496px -48px;
}

.EKT {
  width: 16px;
  height: 16px;
  background-position: -496px -64px;
}

.EKTA {
  width: 16px;
  height: 16px;
  background-position: -496px -80px;
}

.EL {
  width: 16px;
  height: 16px;
  background-position: -496px -96px;
}

.ELA {
  width: 16px;
  height: 16px;
  background-position: -496px -112px;
}

.ELAMA {
  width: 16px;
  height: 16px;
  background-position: -496px -128px;
}

.ELAND {
  width: 16px;
  height: 16px;
  background-position: -496px -144px;
}

.ELC {
  width: 16px;
  height: 16px;
  background-position: -496px -160px;
}

.ELCASH {
  width: 16px;
  height: 16px;
  background-position: -496px -176px;
}

.ELD {
  width: 16px;
  height: 16px;
  background-position: -496px -192px;
}

.ELE {
  width: 16px;
  height: 16px;
  background-position: -496px -208px;
}

.ELEC {
  width: 16px;
  height: 16px;
  background-position: -496px -224px;
}

.ELEF {
  width: 16px;
  height: 16px;
  background-position: -496px -240px;
}

.ELEN {
  width: 16px;
  height: 16px;
  background-position: -496px -256px;
}

.ELENA {
  width: 16px;
  height: 16px;
  background-position: -496px -272px;
}

.ELEPHANT {
  width: 16px;
  height: 16px;
  background-position: -496px -288px;
}

.ELET {
  width: 16px;
  height: 16px;
  background-position: -496px -304px;
}

.ELF {
  width: 16px;
  height: 16px;
  background-position: -496px -320px;
}

.ELFI {
  width: 16px;
  height: 16px;
  background-position: -496px -336px;
}

.ELFIN {
  width: 16px;
  height: 16px;
  background-position: -496px -352px;
}

.ELFT {
  width: 16px;
  height: 16px;
  background-position: -496px -368px;
}

.ELG {
  width: 16px;
  height: 16px;
  background-position: -496px -384px;
}

.ELI {
  width: 16px;
  height: 16px;
  background-position: -496px -400px;
}

.ELITE {
  width: 16px;
  height: 16px;
  background-position: -496px -416px;
}

.ELIX {
  width: 16px;
  height: 16px;
  background-position: -496px -432px;
}

.ELIXIR {
  width: 16px;
  height: 16px;
  background-position: -496px -448px;
}

.ELK {
  width: 16px;
  height: 16px;
  background-position: -496px -464px;
}

.ELL {
  width: 16px;
  height: 16px;
  background-position: -496px -480px;
}

.ELLA {
  width: 16px;
  height: 16px;
  background-position: -0 -496px;
}

.ELLI {
  width: 16px;
  height: 16px;
  background-position: -16px -496px;
}

.ELLO {
  width: 16px;
  height: 16px;
  background-position: -32px -496px;
}

.ELM {
  width: 16px;
  height: 16px;
  background-position: -48px -496px;
}

.ELMON {
  width: 16px;
  height: 16px;
  background-position: -64px -496px;
}

.ELNC {
  width: 16px;
  height: 16px;
  background-position: -80px -496px;
}

.ELOC {
  width: 16px;
  height: 16px;
  background-position: -96px -496px;
}

.ELOIN {
  width: 16px;
  height: 16px;
  background-position: -112px -496px;
}

.ELO_INU {
  width: 16px;
  height: 16px;
  background-position: -128px -496px;
}

.ELON {
  width: 16px;
  height: 16px;
  background-position: -144px -496px;
}

.ELONBALLS {
  width: 16px;
  height: 16px;
  background-position: -160px -496px;
}

.ELONBANK {
  width: 16px;
  height: 16px;
  background-position: -176px -496px;
}

.ELONCAT {
  width: 16px;
  height: 16px;
  background-position: -192px -496px;
}

.ELONGATE {
  width: 16px;
  height: 16px;
  background-position: -208px -496px;
}

.ELONGD {
  width: 16px;
  height: 16px;
  background-position: -224px -496px;
}

.ELONGRAB {
  width: 16px;
  height: 16px;
  background-position: -240px -496px;
}

.ELONHYPE {
  width: 16px;
  height: 16px;
  background-position: -256px -496px;
}

.ELONJET {
  width: 16px;
  height: 16px;
  background-position: -272px -496px;
}

.ELONMOON {
  width: 16px;
  height: 16px;
  background-position: -288px -496px;
}

.ELONOM {
  width: 16px;
  height: 16px;
  background-position: -304px -496px;
}

.ELONONE {
  width: 16px;
  height: 16px;
  background-position: -320px -496px;
}

.ELONPEG {
  width: 16px;
  height: 16px;
  background-position: -336px -496px;
}

.ELP {
  width: 16px;
  height: 16px;
  background-position: -352px -496px;
}

.ELPH {
  width: 16px;
  height: 16px;
  background-position: -368px -496px;
}

.ELS {
  width: 16px;
  height: 16px;
  background-position: -384px -496px;
}

.ELT {
  width: 16px;
  height: 16px;
  background-position: -400px -496px;
}

.ELTCOIN {
  width: 16px;
  height: 16px;
  background-position: -416px -496px;
}

.ELTG {
  width: 16px;
  height: 16px;
  background-position: -432px -496px;
}

.ELTHM {
  width: 16px;
  height: 16px;
  background-position: -448px -496px;
}

.ELU {
  width: 16px;
  height: 16px;
  background-position: -464px -496px;
}

.ELV {
  width: 16px;
  height: 16px;
  background-position: -480px -496px;
}

.ELVES {
  width: 16px;
  height: 16px;
  background-position: -496px -496px;
}

.ELVN {
  width: 16px;
  height: 16px;
  background-position: -512px -0;
}

.ELX {
  width: 16px;
  height: 16px;
  background-position: -512px -16px;
}

.ELXR {
  width: 16px;
  height: 16px;
  background-position: -512px -32px;
}

.ELY {
  width: 16px;
  height: 16px;
  background-position: -512px -48px;
}

.ELYX {
  width: 16px;
  height: 16px;
  background-position: -512px -64px;
}

.EM {
  width: 16px;
  height: 16px;
  background-position: -512px -80px;
}

.EMAX {
  width: 16px;
  height: 16px;
  background-position: -512px -96px;
}

.EMB {
  width: 16px;
  height: 16px;
  background-position: -512px -112px;
}

.EMBR {
  width: 16px;
  height: 16px;
  background-position: -512px -128px;
}

.EMC {
  width: 16px;
  height: 16px;
  background-position: -512px -144px;
}

.EMC2 {
  width: 16px;
  height: 16px;
  background-position: -512px -160px;
}

.EMD {
  width: 16px;
  height: 16px;
  background-position: -512px -176px;
}

.EMERGENTS {
  width: 16px;
  height: 16px;
  background-position: -512px -192px;
}

.EMHMC {
  width: 16px;
  height: 16px;
  background-position: -512px -208px;
}

.EMJ {
  width: 16px;
  height: 16px;
  background-position: -512px -224px;
}

.EMN {
  width: 16px;
  height: 16px;
  background-position: -512px -240px;
}

.EMO {
  width: 16px;
  height: 16px;
  background-position: -512px -256px;
}

.EMON {
  width: 16px;
  height: 16px;
  background-position: -512px -272px;
}

.EMP {
  width: 16px;
  height: 16px;
  background-position: -512px -288px;
}

.EMPIRE {
  width: 16px;
  height: 16px;
  background-position: -512px -304px;
}

.EMPR {
  width: 16px;
  height: 16px;
  background-position: -512px -320px;
}

.EMPYR {
  width: 16px;
  height: 16px;
  background-position: -512px -336px;
}

.EMRALS {
  width: 16px;
  height: 16px;
  background-position: -512px -352px;
}

.EMRX {
  width: 16px;
  height: 16px;
  background-position: -512px -368px;
}

.EMS {
  width: 16px;
  height: 16px;
  background-position: -512px -384px;
}

.EMT {
  width: 16px;
  height: 16px;
  background-position: -512px -400px;
}

.eMTR {
  width: 16px;
  height: 16px;
  background-position: -512px -416px;
}

.eMTRG {
  width: 16px;
  height: 16px;
  background-position: -512px -432px;
}

.ENB {
  width: 16px;
  height: 16px;
  background-position: -512px -448px;
}

.ENBE {
  width: 16px;
  height: 16px;
  background-position: -512px -464px;
}

.ENC {
  width: 16px;
  height: 16px;
  background-position: -512px -480px;
}

.Encointer {
  width: 16px;
  height: 16px;
  background-position: -512px -496px;
}

.ENCORE {
  width: 16px;
  height: 16px;
  background-position: -0 -512px;
}

.ENCX {
  width: 16px;
  height: 16px;
  background-position: -16px -512px;
}

.END {
  width: 16px;
  height: 16px;
  background-position: -32px -512px;
}

.Endcex {
  width: 16px;
  height: 16px;
  background-position: -48px -512px;
}

.ENE {
  width: 16px;
  height: 16px;
  background-position: -64px -512px;
}

.ENERGY {
  width: 16px;
  height: 16px;
  background-position: -80px -512px;
}

.EnergyX {
  width: 16px;
  height: 16px;
  background-position: -96px -512px;
}

.ENFT {
  width: 16px;
  height: 16px;
  background-position: -112px -512px;
}

.ENG {
  width: 16px;
  height: 16px;
  background-position: -128px -512px;
}

.ENGM {
  width: 16px;
  height: 16px;
  background-position: -144px -512px;
}

.ENGN {
  width: 16px;
  height: 16px;
  background-position: -160px -512px;
}

.ENGT {
  width: 16px;
  height: 16px;
  background-position: -176px -512px;
}

.ENH {
  width: 16px;
  height: 16px;
  background-position: -192px -512px;
}

.ENHANCE {
  width: 16px;
  height: 16px;
  background-position: -208px -512px;
}

.ENIX {
  width: 16px;
  height: 16px;
  background-position: -224px -512px;
}

.ENJ {
  width: 16px;
  height: 16px;
  background-position: -240px -512px;
}

.ENK {
  width: 16px;
  height: 16px;
  background-position: -256px -512px;
}

.ENKI {
  width: 16px;
  height: 16px;
  background-position: -272px -512px;
}

.ENNO {
  width: 16px;
  height: 16px;
  background-position: -288px -512px;
}

.ENOL {
  width: 16px;
  height: 16px;
  background-position: -304px -512px;
}

.ENQ {
  width: 16px;
  height: 16px;
  background-position: -320px -512px;
}

.ENRG {
  width: 16px;
  height: 16px;
  background-position: -336px -512px;
}

.ENRX {
  width: 16px;
  height: 16px;
  background-position: -352px -512px;
}

.ENS {
  width: 16px;
  height: 16px;
  background-position: -368px -512px;
}

.ENSP_V2 {
  width: 16px;
  height: 16px;
  background-position: -384px -512px;
}

.ENT {
  width: 16px;
  height: 16px;
  background-position: -400px -512px;
}

.ENTC {
  width: 16px;
  height: 16px;
  background-position: -416px -512px;
}

.ENTERMETA {
  width: 16px;
  height: 16px;
  background-position: -432px -512px;
}

.ENTONE {
  width: 16px;
  height: 16px;
  background-position: -448px -512px;
}

.ENTR {
  width: 16px;
  height: 16px;
  background-position: -464px -512px;
}

.ENTRC {
  width: 16px;
  height: 16px;
  background-position: -480px -512px;
}

.ENTRP {
  width: 16px;
  height: 16px;
  background-position: -496px -512px;
}

.ENTS {
  width: 16px;
  height: 16px;
  background-position: -512px -512px;
}

.ENU {
  width: 16px;
  height: 16px;
  background-position: -528px -0;
}

.ENV {
  width: 16px;
  height: 16px;
  background-position: -528px -16px;
}

.ENVIRO {
  width: 16px;
  height: 16px;
  background-position: -528px -32px;
}

.ENX {
  width: 16px;
  height: 16px;
  background-position: -528px -48px;
}

.ENY {
  width: 16px;
  height: 16px;
  background-position: -528px -64px;
}

.EOC {
  width: 16px;
  height: 16px;
  background-position: -528px -80px;
}

.EON {
  width: 16px;
  height: 16px;
  background-position: -528px -96px;
}

.EONS {
  width: 16px;
  height: 16px;
  background-position: -528px -112px;
}

.EOS {
  width: 16px;
  height: 16px;
  background-position: -528px -128px;
}

.EOSBEAR {
  width: 16px;
  height: 16px;
  background-position: -528px -144px;
}

.EOSBULL {
  width: 16px;
  height: 16px;
  background-position: -528px -160px;
}

.EOSC {
  width: 16px;
  height: 16px;
  background-position: -528px -176px;
}

.EOSDAC {
  width: 16px;
  height: 16px;
  background-position: -528px -192px;
}

.EOSDT {
  width: 16px;
  height: 16px;
  background-position: -528px -208px;
}

.EOST {
  width: 16px;
  height: 16px;
  background-position: -528px -224px;
}

.EOTO {
  width: 16px;
  height: 16px;
  background-position: -528px -240px;
}

.EOX {
  width: 16px;
  height: 16px;
  background-position: -528px -256px;
}

.EPAN {
  width: 16px;
  height: 16px;
  background-position: -528px -272px;
}

.EPAY {
  width: 16px;
  height: 16px;
  background-position: -528px -288px;
}

.EPC {
  width: 16px;
  height: 16px;
  background-position: -528px -304px;
}

.EPG {
  width: 16px;
  height: 16px;
  background-position: -528px -320px;
}

.EPH {
  width: 16px;
  height: 16px;
  background-position: -528px -336px;
}

.EPIC {
  width: 16px;
  height: 16px;
  background-position: -528px -352px;
}

.EPICHERO {
  width: 16px;
  height: 16px;
  background-position: -528px -368px;
}

.EPIK {
  width: 16px;
  height: 16px;
  background-position: -528px -384px;
}

.EPIX {
  width: 16px;
  height: 16px;
  background-position: -528px -400px;
}

.EPK {
  width: 16px;
  height: 16px;
  background-position: -528px -416px;
}

.EPM {
  width: 16px;
  height: 16px;
  background-position: -528px -432px;
}

.EPRINT {
  width: 16px;
  height: 16px;
  background-position: -528px -448px;
}

.EPRO {
  width: 16px;
  height: 16px;
  background-position: -528px -464px;
}

.EPS {
  width: 16px;
  height: 16px;
  background-position: -528px -480px;
}

.EPSTEIN {
  width: 16px;
  height: 16px;
  background-position: -528px -496px;
}

.EPTK {
  width: 16px;
  height: 16px;
  background-position: -528px -512px;
}

.EPTT {
  width: 16px;
  height: 16px;
  background-position: -0 -528px;
}

.EPW {
  width: 16px;
  height: 16px;
  background-position: -16px -528px;
}

.EPX {
  width: 16px;
  height: 16px;
  background-position: -32px -528px;
}

.EPY {
  width: 16px;
  height: 16px;
  background-position: -48px -528px;
}

.EQ {
  width: 16px;
  height: 16px;
  background-position: -64px -528px;
}

.EQL {
  width: 16px;
  height: 16px;
  background-position: -80px -528px;
}

.EQMT {
  width: 16px;
  height: 16px;
  background-position: -96px -528px;
}

.EQO {
  width: 16px;
  height: 16px;
  background-position: -112px -528px;
}

.EQT {
  width: 16px;
  height: 16px;
  background-position: -128px -528px;
}

.EQUAD {
  width: 16px;
  height: 16px;
  background-position: -144px -528px;
}

.EQUITY {
  width: 16px;
  height: 16px;
  background-position: -160px -528px;
}

.EQX {
  width: 16px;
  height: 16px;
  background-position: -176px -528px;
}

.EQZ {
  width: 16px;
  height: 16px;
  background-position: -192px -528px;
}

.ERA {
  width: 16px;
  height: 16px;
  background-position: -208px -528px;
}

.ERABBIT {
  width: 16px;
  height: 16px;
  background-position: -224px -528px;
}

.ERC {
  width: 16px;
  height: 16px;
  background-position: -240px -528px;
}

.ERC20 {
  width: 16px;
  height: 16px;
  background-position: -256px -528px;
}

.ERD {
  width: 16px;
  height: 16px;
  background-position: -272px -528px;
}

.ERECT {
  width: 16px;
  height: 16px;
  background-position: -288px -528px;
}

.ERG {
  width: 16px;
  height: 16px;
  background-position: -304px -528px;
}

.ERGOPAD {
  width: 16px;
  height: 16px;
  background-position: -320px -528px;
}

.ERK {
  width: 16px;
  height: 16px;
  background-position: -336px -528px;
}

.ERN {
  width: 16px;
  height: 16px;
  background-position: -352px -528px;
}

.ERO {
  width: 16px;
  height: 16px;
  background-position: -368px -528px;
}

.ERON {
  width: 16px;
  height: 16px;
  background-position: -384px -528px;
}

.EROS {
  width: 16px;
  height: 16px;
  background-position: -400px -528px;
}

.EROTICA {
  width: 16px;
  height: 16px;
  background-position: -416px -528px;
}

.erowan {
  width: 16px;
  height: 16px;
  background-position: -432px -528px;
}

.ERP {
  width: 16px;
  height: 16px;
  background-position: -448px -528px;
}

.ERPT {
  width: 16px;
  height: 16px;
  background-position: -464px -528px;
}

.ERR {
  width: 16px;
  height: 16px;
  background-position: -480px -528px;
}

.eRSDL {
  width: 16px;
  height: 16px;
  background-position: -496px -528px;
}

.ERT {
  width: 16px;
  height: 16px;
  background-position: -512px -528px;
}

.ERTHA {
  width: 16px;
  height: 16px;
  background-position: -528px -528px;
}

.ERW {
  width: 16px;
  height: 16px;
  background-position: -544px -0;
}

.ERY {
  width: 16px;
  height: 16px;
  background-position: -544px -16px;
}

.ERZ {
  width: 16px;
  height: 16px;
  background-position: -544px -32px;
}

.ES {
  width: 16px;
  height: 16px;
  background-position: -544px -48px;
}

.ES3 {
  width: 16px;
  height: 16px;
  background-position: -544px -64px;
}

.ESAX {
  width: 16px;
  height: 16px;
  background-position: -544px -80px;
}

.ESBC {
  width: 16px;
  height: 16px;
  background-position: -544px -96px;
}

.ESC {
  width: 16px;
  height: 16px;
  background-position: -544px -112px;
}

.ESCB {
  width: 16px;
  height: 16px;
  background-position: -544px -128px;
}

.ESCE {
  width: 16px;
  height: 16px;
  background-position: -544px -144px;
}

.ESCO {
  width: 16px;
  height: 16px;
  background-position: -544px -160px;
}

.ESCROW {
  width: 16px;
  height: 16px;
  background-position: -544px -176px;
}

.ESCX {
  width: 16px;
  height: 16px;
  background-position: -544px -192px;
}

.ESD {
  width: 16px;
  height: 16px;
  background-position: -544px -208px;
}

.ESG {
  width: 16px;
  height: 16px;
  background-position: -544px -224px;
}

.ESH {
  width: 16px;
  height: 16px;
  background-position: -544px -240px;
}

.ESHARE {
  width: 16px;
  height: 16px;
  background-position: -544px -256px;
}

.EShib {
  width: 16px;
  height: 16px;
  background-position: -544px -272px;
}

.ESHIB {
  width: 16px;
  height: 16px;
  background-position: -544px -288px;
}

.ESHK {
  width: 16px;
  height: 16px;
  background-position: -544px -304px;
}

.ESI {
  width: 16px;
  height: 16px;
  background-position: -544px -320px;
}

.ESK {
  width: 16px;
  height: 16px;
  background-position: -544px -336px;
}

.ESN {
  width: 16px;
  height: 16px;
  background-position: -544px -352px;
}

.ESOL {
  width: 16px;
  height: 16px;
  background-position: -544px -368px;
}

.ESOS {
  width: 16px;
  height: 16px;
  background-position: -544px -384px;
}

.ESP {
  width: 16px;
  height: 16px;
  background-position: -544px -400px;
}

.ESPL {
  width: 16px;
  height: 16px;
  background-position: -544px -416px;
}

.ESPN {
  width: 16px;
  height: 16px;
  background-position: -544px -432px;
}

.ESPRO {
  width: 16px;
  height: 16px;
  background-position: -544px -448px;
}

.ESRC {
  width: 16px;
  height: 16px;
  background-position: -544px -464px;
}

.ESS {
  width: 16px;
  height: 16px;
  background-position: -544px -480px;
}

.EST {
  width: 16px;
  height: 16px;
  background-position: -544px -496px;
}

.ESTATE {
  width: 16px;
  height: 16px;
  background-position: -544px -512px;
}

.ESTI {
  width: 16px;
  height: 16px;
  background-position: -544px -528px;
}

.ESW {
  width: 16px;
  height: 16px;
  background-position: -0 -544px;
}

.ESWA {
  width: 16px;
  height: 16px;
  background-position: -16px -544px;
}

.ESWAP {
  width: 16px;
  height: 16px;
  background-position: -32px -544px;
}

.ESWAPV2 {
  width: 16px;
  height: 16px;
  background-position: -48px -544px;
}

.ESZ {
  width: 16px;
  height: 16px;
  background-position: -64px -544px;
}

.ET {
  width: 16px;
  height: 16px;
  background-position: -80px -544px;
}

.ETA {
  width: 16px;
  height: 16px;
  background-position: -96px -544px;
}

.ETAS {
  width: 16px;
  height: 16px;
  background-position: -112px -544px;
}

.ETB {
  width: 16px;
  height: 16px;
  background-position: -128px -544px;
}

.ETBS {
  width: 16px;
  height: 16px;
  background-position: -144px -544px;
}

.ETC {
  width: 16px;
  height: 16px;
  background-position: -160px -544px;
}

.ETCH {
  width: 16px;
  height: 16px;
  background-position: -176px -544px;
}

.ETE {
  width: 16px;
  height: 16px;
  background-position: -192px -544px;
}

.ETER {
  width: 16px;
  height: 16px;
  background-position: -208px -544px;
}

.ETERNAL {
  width: 16px;
  height: 16px;
  background-position: -224px -544px;
}

.ETF {
  width: 16px;
  height: 16px;
  background-position: -240px -544px;
}

.ETG {
  width: 16px;
  height: 16px;
  background-position: -256px -544px;
}

.ETGF {
  width: 16px;
  height: 16px;
  background-position: -272px -544px;
}

.ETGL {
  width: 16px;
  height: 16px;
  background-position: -288px -544px;
}

.ETGP {
  width: 16px;
  height: 16px;
  background-position: -304px -544px;
}

.ETH {
  width: 16px;
  height: 16px;
  background-position: -320px -544px;
}

.ETH2X_FLI {
  width: 16px;
  height: 16px;
  background-position: -336px -544px;
}

.ETH2X_FLI_P {
  width: 16px;
  height: 16px;
  background-position: -352px -544px;
}

.ETH20SMACO {
  width: 16px;
  height: 16px;
  background-position: -368px -544px;
}

.ETHA {
  width: 16px;
  height: 16px;
  background-position: -384px -544px;
}

.ETHAX {
  width: 16px;
  height: 16px;
  background-position: -400px -544px;
}

.ETHBACK {
  width: 16px;
  height: 16px;
  background-position: -416px -544px;
}

.ETHBEAR {
  width: 16px;
  height: 16px;
  background-position: -432px -544px;
}

.ETHBULL {
  width: 16px;
  height: 16px;
  background-position: -448px -544px;
}

.ETHBN {
  width: 16px;
  height: 16px;
  background-position: -464px -544px;
}

.ETHBTCRSI {
  width: 16px;
  height: 16px;
  background-position: -480px -544px;
}

.ETHCOIN {
  width: 16px;
  height: 16px;
  background-position: -496px -544px;
}

.ETHDOWN {
  width: 16px;
  height: 16px;
  background-position: -512px -544px;
}

.ETHE {
  width: 16px;
  height: 16px;
  background-position: -528px -544px;
}

.ETHEMAAPY {
  width: 16px;
  height: 16px;
  background-position: -544px -544px;
}

.ETHERNAL {
  width: 16px;
  height: 16px;
  background-position: -560px -0;
}

.ETHFIN {
  width: 16px;
  height: 16px;
  background-position: -560px -16px;
}

.ETHHEDGE {
  width: 16px;
  height: 16px;
  background-position: -560px -32px;
}

.ETHIX {
  width: 16px;
  height: 16px;
  background-position: -560px -48px;
}

.ETHK {
  width: 16px;
  height: 16px;
  background-position: -560px -64px;
}

.ETHM {
  width: 16px;
  height: 16px;
  background-position: -560px -80px;
}

.ETHMACOAPY {
  width: 16px;
  height: 16px;
  background-position: -560px -96px;
}

.ETHMATRIX {
  width: 16px;
  height: 16px;
  background-position: -560px -112px;
}

.ETHMNY {
  width: 16px;
  height: 16px;
  background-position: -560px -128px;
}

.ETHO {
  width: 16px;
  height: 16px;
  background-position: -560px -144px;
}

.ETHOS {
  width: 16px;
  height: 16px;
  background-position: -560px -160px;
}

.ETHP {
  width: 16px;
  height: 16px;
  background-position: -560px -176px;
}

.ETHPA {
  width: 16px;
  height: 16px;
  background-position: -560px -192px;
}

.ETHPAD {
  width: 16px;
  height: 16px;
  background-position: -560px -208px;
}

.ETHPLO {
  width: 16px;
  height: 16px;
  background-position: -560px -224px;
}

.ETHPY {
  width: 16px;
  height: 16px;
  background-position: -560px -240px;
}

.ETHRSI6040 {
  width: 16px;
  height: 16px;
  background-position: -560px -256px;
}

.ETHRSIAPY {
  width: 16px;
  height: 16px;
  background-position: -560px -272px;
}

.ETHS {
  width: 16px;
  height: 16px;
  background-position: -560px -288px;
}

.ETHSC {
  width: 16px;
  height: 16px;
  background-position: -560px -304px;
}

.ETHSHIB {
  width: 16px;
  height: 16px;
  background-position: -560px -320px;
}

.ETHUSDADL4 {
  width: 16px;
  height: 16px;
  background-position: -560px -336px;
}

.ETHUSDCTA {
  width: 16px;
  height: 16px;
  background-position: -560px -352px;
}

.ETHV {
  width: 16px;
  height: 16px;
  background-position: -560px -368px;
}

.ETHVAULT {
  width: 16px;
  height: 16px;
  background-position: -560px -384px;
}

.ETHW {
  width: 16px;
  height: 16px;
  background-position: -560px -400px;
}

.ETHY {
  width: 16px;
  height: 16px;
  background-position: -560px -416px;
}

.ETHYS {
  width: 16px;
  height: 16px;
  background-position: -560px -432px;
}

.ETHZILLA {
  width: 16px;
  height: 16px;
  background-position: -560px -448px;
}

.ETI {
  width: 16px;
  height: 16px;
  background-position: -560px -464px;
}

.ETJ {
  width: 16px;
  height: 16px;
  background-position: -560px -480px;
}

.ETK {
  width: 16px;
  height: 16px;
  background-position: -560px -496px;
}

.ETL {
  width: 16px;
  height: 16px;
  background-position: -560px -512px;
}

.ETLG {
  width: 16px;
  height: 16px;
  background-position: -560px -528px;
}

.ETLT {
  width: 16px;
  height: 16px;
  background-position: -560px -544px;
}

.EtLyteT {
  width: 16px;
  height: 16px;
  background-position: -0 -560px;
}

.ETM {
  width: 16px;
  height: 16px;
  background-position: -16px -560px;
}

.ETN {
  width: 16px;
  height: 16px;
  background-position: -32px -560px;
}

.ETNA {
  width: 16px;
  height: 16px;
  background-position: -48px -560px;
}

.ETNX {
  width: 16px;
  height: 16px;
  background-position: -64px -560px;
}

.ETNXP {
  width: 16px;
  height: 16px;
  background-position: -80px -560px;
}

.ETNY {
  width: 16px;
  height: 16px;
  background-position: -96px -560px;
}

.ETO {
  width: 16px;
  height: 16px;
  background-position: -112px -560px;
}

.ETOS {
  width: 16px;
  height: 16px;
  background-position: -128px -560px;
}

.ETP {
  width: 16px;
  height: 16px;
  background-position: -144px -560px;
}

.ETR {
  width: 16px;
  height: 16px;
  background-position: -160px -560px;
}

.ETS {
  width: 16px;
  height: 16px;
  background-position: -176px -560px;
}

.ETT {
  width: 16px;
  height: 16px;
  background-position: -192px -560px;
}

.ETX {
  width: 16px;
  height: 16px;
  background-position: -208px -560px;
}

.ETZ {
  width: 16px;
  height: 16px;
  background-position: -224px -560px;
}

.EUC {
  width: 16px;
  height: 16px;
  background-position: -240px -560px;
}

.EUL {
  width: 16px;
  height: 16px;
  background-position: -256px -560px;
}

.EULER {
  width: 16px;
  height: 16px;
  background-position: -272px -560px;
}

.EUM {
  width: 16px;
  height: 16px;
  background-position: -288px -560px;
}

.EUNO {
  width: 16px;
  height: 16px;
  background-position: -304px -560px;
}

.EUP {
  width: 16px;
  height: 16px;
  background-position: -320px -560px;
}

.EUPH {
  width: 16px;
  height: 16px;
  background-position: -336px -560px;
}

.EURL {
  width: 16px;
  height: 16px;
  background-position: -352px -560px;
}

.EURN {
  width: 16px;
  height: 16px;
  background-position: -368px -560px;
}

.EUROC {
  width: 16px;
  height: 16px;
  background-position: -384px -560px;
}

.EURONIN {
  width: 16px;
  height: 16px;
  background-position: -400px -560px;
}

.EURS {
  width: 16px;
  height: 16px;
  background-position: -416px -560px;
}

.EURST {
  width: 16px;
  height: 16px;
  background-position: -432px -560px;
}

.EURT {
  width: 16px;
  height: 16px;
  background-position: -448px -560px;
}

.EURU {
  width: 16px;
  height: 16px;
  background-position: -464px -560px;
}

.EURX {
  width: 16px;
  height: 16px;
  background-position: -480px -560px;
}

.EURXB {
  width: 16px;
  height: 16px;
  background-position: -496px -560px;
}

.EUSD {
  width: 16px;
  height: 16px;
  background-position: -512px -560px;
}

.EUT {
  width: 16px;
  height: 16px;
  background-position: -528px -560px;
}

.EV {
  width: 16px;
  height: 16px;
  background-position: -544px -560px;
}

.EVA {
  width: 16px;
  height: 16px;
  background-position: -560px -560px;
}

.EVAN {
  width: 16px;
  height: 16px;
  background-position: -576px -0;
}

.EVAPE {
  width: 16px;
  height: 16px;
  background-position: -576px -16px;
}

.EVAULT {
  width: 16px;
  height: 16px;
  background-position: -576px -32px;
}

.EVC {
  width: 16px;
  height: 16px;
  background-position: -576px -48px;
}

.EVCoin {
  width: 16px;
  height: 16px;
  background-position: -576px -64px;
}

.EVDC {
  width: 16px;
  height: 16px;
  background-position: -576px -80px;
}

.EVE {
  width: 16px;
  height: 16px;
  background-position: -576px -96px;
}

.EVED {
  width: 16px;
  height: 16px;
  background-position: -576px -112px;
}

.EVER {
  width: 16px;
  height: 16px;
  background-position: -576px -128px;
}

.EVERBNB {
  width: 16px;
  height: 16px;
  background-position: -576px -144px;
}

.EVERDOT {
  width: 16px;
  height: 16px;
  background-position: -576px -160px;
}

.EVEREST {
  width: 16px;
  height: 16px;
  background-position: -576px -176px;
}

.EVERETH {
  width: 16px;
  height: 16px;
  background-position: -576px -192px;
}

.EVERF {
  width: 16px;
  height: 16px;
  background-position: -576px -208px;
}

.EVERSAFU {
  width: 16px;
  height: 16px;
  background-position: -576px -224px;
}

.EVI {
  width: 16px;
  height: 16px;
  background-position: -576px -240px;
}

.EVIL {
  width: 16px;
  height: 16px;
  background-position: -576px -256px;
}

.EVILSQUID {
  width: 16px;
  height: 16px;
  background-position: -576px -272px;
}

.EVIRAL {
  width: 16px;
  height: 16px;
  background-position: -576px -288px;
}

.EVM {
  width: 16px;
  height: 16px;
  background-position: -576px -304px;
}

.EVMOS {
  width: 16px;
  height: 16px;
  background-position: -576px -320px;
}

.EVN {
  width: 16px;
  height: 16px;
  background-position: -576px -336px;
}

.EVO {
  width: 16px;
  height: 16px;
  background-position: -576px -352px;
}

.EVOC {
  width: 16px;
  height: 16px;
  background-position: -576px -368px;
}

.EVOL {
  width: 16px;
  height: 16px;
  background-position: -576px -384px;
}

.EVOS {
  width: 16px;
  height: 16px;
  background-position: -576px -400px;
}

.EVR {
  width: 16px;
  height: 16px;
  background-position: -576px -416px;
}

.EVRF {
  width: 16px;
  height: 16px;
  background-position: -576px -432px;
}

.EVRT {
  width: 16px;
  height: 16px;
  background-position: -576px -448px;
}

.EVRY {
  width: 16px;
  height: 16px;
  background-position: -576px -464px;
}

.EVS {
  width: 16px;
  height: 16px;
  background-position: -576px -480px;
}

.EVT {
  width: 16px;
  height: 16px;
  background-position: -576px -496px;
}

.EVU {
  width: 16px;
  height: 16px;
  background-position: -576px -512px;
}

.EVX {
  width: 16px;
  height: 16px;
  background-position: -576px -528px;
}

.EVY {
  width: 16px;
  height: 16px;
  background-position: -576px -544px;
}

.EVZ {
  width: 16px;
  height: 16px;
  background-position: -576px -560px;
}

.EWAR {
  width: 16px;
  height: 16px;
  background-position: -0 -576px;
}

.EWC {
  width: 16px;
  height: 16px;
  background-position: -16px -576px;
}

.EWT {
  width: 16px;
  height: 16px;
  background-position: -32px -576px;
}

.EX {
  width: 16px;
  height: 16px;
  background-position: -48px -576px;
}

.EXALT {
  width: 16px;
  height: 16px;
  background-position: -64px -576px;
}

.EXC {
  width: 16px;
  height: 16px;
  background-position: -80px -576px;
}

.EXCC {
  width: 16px;
  height: 16px;
  background-position: -96px -576px;
}

.EXCL {
  width: 16px;
  height: 16px;
  background-position: -112px -576px;
}

.EXE {
  width: 16px;
  height: 16px;
  background-position: -128px -576px;
}

.EXF {
  width: 16px;
  height: 16px;
  background-position: -144px -576px;
}

.EXFI {
  width: 16px;
  height: 16px;
  background-position: -160px -576px;
}

.EXIP {
  width: 16px;
  height: 16px;
  background-position: -176px -576px;
}

.EXIST {
  width: 16px;
  height: 16px;
  background-position: -192px -576px;
}

.EXM {
  width: 16px;
  height: 16px;
  background-position: -208px -576px;
}

.EXMR {
  width: 16px;
  height: 16px;
  background-position: -224px -576px;
}

.EXN {
  width: 16px;
  height: 16px;
  background-position: -240px -576px;
}

.EXNT {
  width: 16px;
  height: 16px;
  background-position: -256px -576px;
}

.EXO {
  width: 16px;
  height: 16px;
  background-position: -272px -576px;
}

.EXOD {
  width: 16px;
  height: 16px;
  background-position: -288px -576px;
}

.EXOR {
  width: 16px;
  height: 16px;
  background-position: -304px -576px;
}

.EXOS {
  width: 16px;
  height: 16px;
  background-position: -320px -576px;
}

.EXOTIX {
  width: 16px;
  height: 16px;
  background-position: -336px -576px;
}

.EXP {
  width: 16px;
  height: 16px;
  background-position: -352px -576px;
}

.EXPO {
  width: 16px;
  height: 16px;
  background-position: -368px -576px;
}

.EXPR {
  width: 16px;
  height: 16px;
  background-position: -384px -576px;
}

.ExPRO {
  width: 16px;
  height: 16px;
  background-position: -400px -576px;
}

.EXRD {
  width: 16px;
  height: 16px;
  background-position: -416px -576px;
}

.EXRN {
  width: 16px;
  height: 16px;
  background-position: -432px -576px;
}

.EXRT {
  width: 16px;
  height: 16px;
  background-position: -448px -576px;
}

.EXS {
  width: 16px;
  height: 16px;
  background-position: -464px -576px;
}

.EXT {
  width: 16px;
  height: 16px;
  background-position: -480px -576px;
}

.EXY {
  width: 16px;
  height: 16px;
  background-position: -496px -576px;
}

.EXZO {
  width: 16px;
  height: 16px;
  background-position: -512px -576px;
}

.eYe {
  width: 16px;
  height: 16px;
  background-position: -528px -576px;
}

.EYE {
  width: 16px;
  height: 16px;
  background-position: -544px -576px;
}

.EYES {
  width: 16px;
  height: 16px;
  background-position: -560px -576px;
}

.EZ {
  width: 16px;
  height: 16px;
  background-position: -576px -576px;
}

.EZC {
  width: 16px;
  height: 16px;
  background-position: -592px -0;
}

.EZI {
  width: 16px;
  height: 16px;
  background-position: -592px -16px;
}

.EZW {
  width: 16px;
  height: 16px;
  background-position: -592px -32px;
}

.EZX {
  width: 16px;
  height: 16px;
  background-position: -592px -48px;
}

.EZY {
  width: 16px;
  height: 16px;
  background-position: -592px -64px;
}

.F1C {
  width: 16px;
  height: 16px;
  background-position: -592px -80px;
}

.F2C {
  width: 16px;
  height: 16px;
  background-position: -592px -96px;
}

.F5S {
  width: 16px;
  height: 16px;
  background-position: -592px -112px;
}

.F7 {
  width: 16px;
  height: 16px;
  background-position: -592px -128px;
}

.F9 {
  width: 16px;
  height: 16px;
  background-position: -592px -144px;
}

.F11 {
  width: 16px;
  height: 16px;
  background-position: -592px -160px;
}

.FA {
  width: 16px;
  height: 16px;
  background-position: -592px -176px;
}

.FAB {
  width: 16px;
  height: 16px;
  background-position: -592px -192px;
}

.FABRIC {
  width: 16px;
  height: 16px;
  background-position: -592px -208px;
}

.FACE {
  width: 16px;
  height: 16px;
  background-position: -592px -224px;
}

.FACEDAO {
  width: 16px;
  height: 16px;
  background-position: -592px -240px;
}

.FACT {
  width: 16px;
  height: 16px;
  background-position: -592px -256px;
}

.FACTORY {
  width: 16px;
  height: 16px;
  background-position: -592px -272px;
}

.FACTR {
  width: 16px;
  height: 16px;
  background-position: -592px -288px;
}

.FADA {
  width: 16px;
  height: 16px;
  background-position: -592px -304px;
}

.FADO {
  width: 16px;
  height: 16px;
  background-position: -592px -320px;
}

.FAF {
  width: 16px;
  height: 16px;
  background-position: -592px -336px;
}

.FAFI {
  width: 16px;
  height: 16px;
  background-position: -592px -352px;
}

.FILDA {
  width: 16px;
  height: 16px;
  background-position: -592px -368px;
}

.FAI {
  width: 16px;
  height: 16px;
  background-position: -592px -384px;
}

.FAIL {
  width: 16px;
  height: 16px;
  background-position: -592px -400px;
}

.FAIR {
  width: 16px;
  height: 16px;
  background-position: -592px -416px;
}

.FAIRC {
  width: 16px;
  height: 16px;
  background-position: -592px -432px;
}

.FAIRLIFE {
  width: 16px;
  height: 16px;
  background-position: -592px -448px;
}

.FAITH {
  width: 16px;
  height: 16px;
  background-position: -592px -464px;
}

.FAKT {
  width: 16px;
  height: 16px;
  background-position: -592px -480px;
}

.FAL {
  width: 16px;
  height: 16px;
  background-position: -592px -496px;
}

.FALAFEL {
  width: 16px;
  height: 16px;
  background-position: -592px -512px;
}

.FALCON9 {
  width: 16px;
  height: 16px;
  background-position: -592px -528px;
}

.FALCONS {
  width: 16px;
  height: 16px;
  background-position: -592px -544px;
}

.FALQOM {
  width: 16px;
  height: 16px;
  background-position: -592px -560px;
}

.FAM {
  width: 16px;
  height: 16px;
  background-position: -592px -576px;
}

.FAME {
  width: 16px;
  height: 16px;
  background-position: -0 -592px;
}

.FAMEDOGE {
  width: 16px;
  height: 16px;
  background-position: -16px -592px;
}

.FAMY {
  width: 16px;
  height: 16px;
  background-position: -32px -592px;
}

.FAN {
  width: 16px;
  height: 16px;
  background-position: -48px -592px;
}

.FAN8 {
  width: 16px;
  height: 16px;
  background-position: -64px -592px;
}

.FANC {
  width: 16px;
  height: 16px;
  background-position: -80px -592px;
}

.FANG {
  width: 16px;
  height: 16px;
  background-position: -96px -592px;
}

.FANI {
  width: 16px;
  height: 16px;
  background-position: -112px -592px;
}

.FANNED {
  width: 16px;
  height: 16px;
  background-position: -128px -592px;
}

.FANS {
  width: 16px;
  height: 16px;
  background-position: -144px -592px;
}

.FANT {
  width: 16px;
  height: 16px;
  background-position: -160px -592px;
}

.FANTA {
  width: 16px;
  height: 16px;
  background-position: -176px -592px;
}

.FANTOMAPES {
  width: 16px;
  height: 16px;
  background-position: -192px -592px;
}

.FANTV {
  width: 16px;
  height: 16px;
  background-position: -208px -592px;
}

.FANV {
  width: 16px;
  height: 16px;
  background-position: -224px -592px;
}

.FAR {
  width: 16px;
  height: 16px;
  background-position: -240px -592px;
}

.FARA {
  width: 16px;
  height: 16px;
  background-position: -256px -592px;
}

.FARI {
  width: 16px;
  height: 16px;
  background-position: -272px -592px;
}

.FARM {
  width: 16px;
  height: 16px;
  background-position: -288px -592px;
}

.FAS {
  width: 16px;
  height: 16px;
  background-position: -304px -592px;
}

.FAST {
  width: 16px;
  height: 16px;
  background-position: -320px -592px;
}

.FAT {
  width: 16px;
  height: 16px;
  background-position: -336px -592px;
}

.FATCAKE {
  width: 16px;
  height: 16px;
  background-position: -352px -592px;
}

.FATE {
  width: 16px;
  height: 16px;
  background-position: -368px -592px;
}

.FATHER {
  width: 16px;
  height: 16px;
  background-position: -384px -592px;
}

.FATOSHI {
  width: 16px;
  height: 16px;
  background-position: -400px -592px;
}

.FAVAX {
  width: 16px;
  height: 16px;
  background-position: -416px -592px;
}

.FAVOR {
  width: 16px;
  height: 16px;
  background-position: -432px -592px;
}

.FAW {
  width: 16px;
  height: 16px;
  background-position: -448px -592px;
}

.FAWA {
  width: 16px;
  height: 16px;
  background-position: -464px -592px;
}

.FAYRE {
  width: 16px;
  height: 16px;
  background-position: -480px -592px;
}

.FB {
  width: 16px;
  height: 16px;
  background-position: -496px -592px;
}

.FBB {
  width: 16px;
  height: 16px;
  background-position: -512px -592px;
}

.FBC {
  width: 16px;
  height: 16px;
  background-position: -528px -592px;
}

.FBE {
  width: 16px;
  height: 16px;
  background-position: -544px -592px;
}

.FBN {
  width: 16px;
  height: 16px;
  background-position: -560px -592px;
}

.fBNB {
  width: 16px;
  height: 16px;
  background-position: -576px -592px;
}

.FBNB {
  width: 16px;
  height: 16px;
  background-position: -592px -592px;
}

.FBRO {
  width: 16px;
  height: 16px;
  background-position: -608px -0;
}

.FBS {
  width: 16px;
  height: 16px;
  background-position: -608px -16px;
}

.FBT {
  width: 16px;
  height: 16px;
  background-position: -608px -32px;
}

.FBULL {
  width: 16px;
  height: 16px;
  background-position: -608px -48px;
}

.FBX {
  width: 16px;
  height: 16px;
  background-position: -608px -64px;
}

.FC {
  width: 16px;
  height: 16px;
  background-position: -608px -80px;
}

.FCA {
  width: 16px;
  height: 16px;
  background-position: -608px -96px;
}

.FCB {
  width: 16px;
  height: 16px;
  background-position: -608px -112px;
}

.FCBTC {
  width: 16px;
  height: 16px;
  background-position: -608px -128px;
}

.FCD {
  width: 16px;
  height: 16px;
  background-position: -608px -144px;
}

.FCF {
  width: 16px;
  height: 16px;
  background-position: -608px -160px;
}

.FCH {
  width: 16px;
  height: 16px;
  background-position: -608px -176px;
}

.FCK {
  width: 16px;
  height: 16px;
  background-position: -608px -192px;
}

.FCL {
  width: 16px;
  height: 16px;
  background-position: -608px -208px;
}

.FCN {
  width: 16px;
  height: 16px;
  background-position: -608px -224px;
}

.FCON {
  width: 16px;
  height: 16px;
  background-position: -608px -240px;
}

.FCP {
  width: 16px;
  height: 16px;
  background-position: -608px -256px;
}

.FCQ {
  width: 16px;
  height: 16px;
  background-position: -608px -272px;
}

.FCR {
  width: 16px;
  height: 16px;
  background-position: -608px -288px;
}

.FCT {
  width: 16px;
  height: 16px;
  background-position: -608px -304px;
}

.FCT2 {
  width: 16px;
  height: 16px;
  background-position: -608px -320px;
}

.FCX {
  width: 16px;
  height: 16px;
  background-position: -608px -336px;
}

.FDD {
  width: 16px;
  height: 16px;
  background-position: -608px -352px;
}

.FDLS {
  width: 16px;
  height: 16px;
  background-position: -608px -368px;
}

.FDM {
  width: 16px;
  height: 16px;
  background-position: -608px -384px;
}

.FDNZA {
  width: 16px;
  height: 16px;
  background-position: -608px -400px;
}

.FDO {
  width: 16px;
  height: 16px;
  background-position: -608px -416px;
}

.FDoge {
  width: 16px;
  height: 16px;
  background-position: -608px -432px;
}

.FDOGE {
  width: 16px;
  height: 16px;
  background-position: -608px -448px;
}

.FDOTA {
  width: 16px;
  height: 16px;
  background-position: -608px -464px;
}

.FDR {
  width: 16px;
  height: 16px;
  background-position: -608px -480px;
}

.FDT {
  width: 16px;
  height: 16px;
  background-position: -608px -496px;
}

.FDX {
  width: 16px;
  height: 16px;
  background-position: -608px -512px;
}

.FDZ {
  width: 16px;
  height: 16px;
  background-position: -608px -528px;
}

.FEAR {
  width: 16px;
  height: 16px;
  background-position: -608px -544px;
}

.FEAST {
  width: 16px;
  height: 16px;
  background-position: -608px -560px;
}

.FEB {
  width: 16px;
  height: 16px;
  background-position: -608px -576px;
}

.FECLIPSE {
  width: 16px;
  height: 16px;
  background-position: -608px -592px;
}

.FED {
  width: 16px;
  height: 16px;
  background-position: -0 -608px;
}

.FEED {
  width: 16px;
  height: 16px;
  background-position: -16px -608px;
}

.FEEDTK {
  width: 16px;
  height: 16px;
  background-position: -32px -608px;
}

.FEEL {
  width: 16px;
  height: 16px;
  background-position: -48px -608px;
}

.FEENIXV2 {
  width: 16px;
  height: 16px;
  background-position: -64px -608px;
}

.FEES {
  width: 16px;
  height: 16px;
  background-position: -80px -608px;
}

.FEEX {
  width: 16px;
  height: 16px;
  background-position: -96px -608px;
}

.FEG {
  width: 16px;
  height: 16px;
  background-position: -112px -608px;
}

.FEGN {
  width: 16px;
  height: 16px;
  background-position: -128px -608px;
}

.FEGZ {
  width: 16px;
  height: 16px;
  background-position: -144px -608px;
}

.FEI {
  width: 16px;
  height: 16px;
  background-position: -160px -608px;
}

.FEN {
  width: 16px;
  height: 16px;
  background-position: -176px -608px;
}

.FENIX {
  width: 16px;
  height: 16px;
  background-position: -192px -608px;
}

.FENOMY {
  width: 16px;
  height: 16px;
  background-position: -208px -608px;
}

.FER {
  width: 16px;
  height: 16px;
  background-position: -224px -608px;
}

.FERA {
  width: 16px;
  height: 16px;
  background-position: -240px -608px;
}

.FERRARI {
  width: 16px;
  height: 16px;
  background-position: -256px -608px;
}

.FERT {
  width: 16px;
  height: 16px;
  background-position: -272px -608px;
}

.FES {
  width: 16px;
  height: 16px;
  background-position: -288px -608px;
}

.FESBNB {
  width: 16px;
  height: 16px;
  background-position: -304px -608px;
}

.FESS {
  width: 16px;
  height: 16px;
  background-position: -320px -608px;
}

.FET {
  width: 16px;
  height: 16px;
  background-position: -336px -608px;
}

.FETA {
  width: 16px;
  height: 16px;
  background-position: -352px -608px;
}

.FETCH {
  width: 16px;
  height: 16px;
  background-position: -368px -608px;
}

.FETE {
  width: 16px;
  height: 16px;
  background-position: -384px -608px;
}

.FETHP {
  width: 16px;
  height: 16px;
  background-position: -400px -608px;
}

.FETT {
  width: 16px;
  height: 16px;
  background-position: -416px -608px;
}

.FEVR {
  width: 16px;
  height: 16px;
  background-position: -432px -608px;
}

.FEX {
  width: 16px;
  height: 16px;
  background-position: -448px -608px;
}

.FEY {
  width: 16px;
  height: 16px;
  background-position: -464px -608px;
}

.FF {
  width: 16px;
  height: 16px;
  background-position: -480px -608px;
}

.FF1 {
  width: 16px;
  height: 16px;
  background-position: -496px -608px;
}

.FFF {
  width: 16px;
  height: 16px;
  background-position: -512px -608px;
}

.FFLY {
  width: 16px;
  height: 16px;
  background-position: -528px -608px;
}

.FFN {
  width: 16px;
  height: 16px;
  background-position: -544px -608px;
}

.FFS {
  width: 16px;
  height: 16px;
  background-position: -560px -608px;
}

.FFT {
  width: 16px;
  height: 16px;
  background-position: -576px -608px;
}

.FFWOOL {
  width: 16px;
  height: 16px;
  background-position: -592px -608px;
}

.FFYI {
  width: 16px;
  height: 16px;
  background-position: -608px -608px;
}

.FG {
  width: 16px;
  height: 16px;
  background-position: -624px -0;
}

.FGC {
  width: 16px;
  height: 16px;
  background-position: -624px -16px;
}

.FGD {
  width: 16px;
  height: 16px;
  background-position: -624px -32px;
}

.FGI {
  width: 16px;
  height: 16px;
  background-position: -624px -48px;
}

.FGP {
  width: 16px;
  height: 16px;
  background-position: -624px -64px;
}

.FGROW {
  width: 16px;
  height: 16px;
  background-position: -624px -80px;
}

.FGSPORT {
  width: 16px;
  height: 16px;
  background-position: -624px -96px;
}

.FHM {
  width: 16px;
  height: 16px;
  background-position: -624px -112px;
}

.FHSE {
  width: 16px;
  height: 16px;
  background-position: -624px -128px;
}

.FHTN {
  width: 16px;
  height: 16px;
  background-position: -624px -144px;
}

.FIA {
  width: 16px;
  height: 16px;
  background-position: -624px -160px;
}

.FIAT {
  width: 16px;
  height: 16px;
  background-position: -624px -176px;
}

.FIBO {
  width: 16px;
  height: 16px;
  background-position: -624px -192px;
}

.FIC {
  width: 16px;
  height: 16px;
  background-position: -624px -208px;
}

.FICO {
  width: 16px;
  height: 16px;
  background-position: -624px -224px;
}

.FID {
  width: 16px;
  height: 16px;
  background-position: -624px -240px;
}

.FIDA {
  width: 16px;
  height: 16px;
  background-position: -624px -256px;
}

.FIDL {
  width: 16px;
  height: 16px;
  background-position: -624px -272px;
}

.FIDYO {
  width: 16px;
  height: 16px;
  background-position: -624px -288px;
}

.FIEF {
  width: 16px;
  height: 16px;
  background-position: -624px -304px;
}

.FIELDS {
  width: 16px;
  height: 16px;
  background-position: -624px -320px;
}

.FIFTY {
  width: 16px;
  height: 16px;
  background-position: -624px -336px;
}

.FIG {
  width: 16px;
  height: 16px;
  background-position: -624px -352px;
}

.FIGHT {
  width: 16px;
  height: 16px;
  background-position: -624px -368px;
}

.FIGHTER {
  width: 16px;
  height: 16px;
  background-position: -624px -384px;
}

.FIII {
  width: 16px;
  height: 16px;
  background-position: -624px -400px;
}

.FIL {
  width: 16px;
  height: 16px;
  background-position: -624px -416px;
}

.FILK {
  width: 16px;
  height: 16px;
  background-position: -624px -432px;
}

.FILST {
  width: 16px;
  height: 16px;
  background-position: -624px -448px;
}

.FIMI {
  width: 16px;
  height: 16px;
  background-position: -624px -464px;
}

.FIN {
  width: 16px;
  height: 16px;
  background-position: -624px -480px;
}

.FINA {
  width: 16px;
  height: 16px;
  background-position: -624px -496px;
}

.FIND {
  width: 16px;
  height: 16px;
  background-position: -624px -512px;
}

.FINE {
  width: 16px;
  height: 16px;
  background-position: -624px -528px;
}

.FINIX {
  width: 16px;
  height: 16px;
  background-position: -624px -544px;
}

.FINN {
  width: 16px;
  height: 16px;
  background-position: -624px -560px;
}

.FINO {
  width: 16px;
  height: 16px;
  background-position: -624px -576px;
}

.FINS {
  width: 16px;
  height: 16px;
  background-position: -624px -592px;
}

.FINT {
  width: 16px;
  height: 16px;
  background-position: -624px -608px;
}

.FINU {
  width: 16px;
  height: 16px;
  background-position: -0 -624px;
}

.FIO {
  width: 16px;
  height: 16px;
  background-position: -16px -624px;
}

.FIPI {
  width: 16px;
  height: 16px;
  background-position: -32px -624px;
}

.FIRA {
  width: 16px;
  height: 16px;
  background-position: -48px -624px;
}

.FIRE {
  width: 16px;
  height: 16px;
  background-position: -64px -624px;
}

.FIREROCKET {
  width: 16px;
  height: 16px;
  background-position: -80px -624px;
}

.FIRO {
  width: 16px;
  height: 16px;
  background-position: -96px -624px;
}

.FIRST {
  width: 16px;
  height: 16px;
  background-position: -112px -624px;
}

.FIRSTDOGE {
  width: 16px;
  height: 16px;
  background-position: -128px -624px;
}

.FirstHare {
  width: 16px;
  height: 16px;
  background-position: -144px -624px;
}

.FIRSTSHIB {
  width: 16px;
  height: 16px;
  background-position: -160px -624px;
}

.FIRU {
  width: 16px;
  height: 16px;
  background-position: -176px -624px;
}

.FIS {
  width: 16px;
  height: 16px;
  background-position: -192px -624px;
}

.FISH {
  width: 16px;
  height: 16px;
  background-position: -208px -624px;
}

.FIST {
  width: 16px;
  height: 16px;
  background-position: -224px -624px;
}

.FIT {
  width: 16px;
  height: 16px;
  background-position: -240px -624px;
}

.FITFI {
  width: 16px;
  height: 16px;
  background-position: -256px -624px;
}

.FITI {
  width: 16px;
  height: 16px;
  background-position: -272px -624px;
}

.FITN {
  width: 16px;
  height: 16px;
  background-position: -288px -624px;
}

.FITT {
  width: 16px;
  height: 16px;
  background-position: -304px -624px;
}

.FIU {
  width: 16px;
  height: 16px;
  background-position: -320px -624px;
}

.FIWA {
  width: 16px;
  height: 16px;
  background-position: -336px -624px;
}

.FJB {
  width: 16px;
  height: 16px;
  background-position: -352px -624px;
}

.FJC {
  width: 16px;
  height: 16px;
  background-position: -368px -624px;
}

.FJT {
  width: 16px;
  height: 16px;
  background-position: -384px -624px;
}

.FKSK {
  width: 16px;
  height: 16px;
  background-position: -400px -624px;
}

.FKX {
  width: 16px;
  height: 16px;
  background-position: -416px -624px;
}

.FL {
  width: 16px;
  height: 16px;
  background-position: -432px -624px;
}

.FLA {
  width: 16px;
  height: 16px;
  background-position: -448px -624px;
}

.FLAFLO {
  width: 16px;
  height: 16px;
  background-position: -464px -624px;
}

.FLAG {
  width: 16px;
  height: 16px;
  background-position: -480px -624px;
}

.FLAKE {
  width: 16px;
  height: 16px;
  background-position: -496px -624px;
}

.FLAME {
  width: 16px;
  height: 16px;
  background-position: -512px -624px;
}

.FLARE {
  width: 16px;
  height: 16px;
  background-position: -528px -624px;
}

.FLASH {
  width: 16px;
  height: 16px;
  background-position: -544px -624px;
}

.FLAU {
  width: 16px;
  height: 16px;
  background-position: -560px -624px;
}

.FLC {
  width: 16px;
  height: 16px;
  background-position: -576px -624px;
}

.FLD {
  width: 16px;
  height: 16px;
  background-position: -592px -624px;
}

.FLDC {
  width: 16px;
  height: 16px;
  background-position: -608px -624px;
}

.FLESH {
  width: 16px;
  height: 16px;
  background-position: -624px -624px;
}

.FLETA {
  width: 16px;
  height: 16px;
  background-position: -640px -0;
}

.FLEX {
  width: 16px;
  height: 16px;
  background-position: -640px -16px;
}

.FLEXETHBTC {
  width: 16px;
  height: 16px;
  background-position: -640px -32px;
}

.FLG {
  width: 16px;
  height: 16px;
  background-position: -640px -48px;
}

.FLIBERO {
  width: 16px;
  height: 16px;
  background-position: -640px -64px;
}

.FLICK {
  width: 16px;
  height: 16px;
  background-position: -640px -80px;
}

.FLIP {
  width: 16px;
  height: 16px;
  background-position: -640px -96px;
}

.FLISHU {
  width: 16px;
  height: 16px;
  background-position: -640px -112px;
}

.FLIXX {
  width: 16px;
  height: 16px;
  background-position: -640px -128px;
}

.FLK {
  width: 16px;
  height: 16px;
  background-position: -640px -144px;
}

.FLL {
  width: 16px;
  height: 16px;
  background-position: -640px -160px;
}

.FLM {
  width: 16px;
  height: 16px;
  background-position: -640px -176px;
}

.FLMT {
  width: 16px;
  height: 16px;
  background-position: -640px -192px;
}

.FLN {
  width: 16px;
  height: 16px;
  background-position: -640px -208px;
}

.FLO {
  width: 16px;
  height: 16px;
  background-position: -640px -224px;
}

.FLOAT {
  width: 16px;
  height: 16px;
  background-position: -640px -240px;
}

.FLOBO {
  width: 16px;
  height: 16px;
  background-position: -640px -256px;
}

.FloFru {
  width: 16px;
  height: 16px;
  background-position: -640px -272px;
}

.FLOGE {
  width: 16px;
  height: 16px;
  background-position: -640px -288px;
}

.FLOKACHU {
  width: 16px;
  height: 16px;
  background-position: -640px -304px;
}

.floki {
  width: 16px;
  height: 16px;
  background-position: -640px -320px;
}

.FLOKI {
  width: 16px;
  height: 16px;
  background-position: -640px -336px;
}

.FLOKICOKE {
  width: 16px;
  height: 16px;
  background-position: -640px -352px;
}

.FLOKIDAO {
  width: 16px;
  height: 16px;
  background-position: -640px -368px;
}

.FLOKIELON {
  width: 16px;
  height: 16px;
  background-position: -640px -384px;
}

.FLOKIFAM {
  width: 16px;
  height: 16px;
  background-position: -640px -400px;
}

.FLOKIFARM {
  width: 16px;
  height: 16px;
  background-position: -640px -416px;
}

.FLOKIFM {
  width: 16px;
  height: 16px;
  background-position: -640px -432px;
}

.FLOKIFRUNKPUPPY {
  width: 16px;
  height: 16px;
  background-position: -640px -448px;
}

.FLOKIG {
  width: 16px;
  height: 16px;
  background-position: -640px -464px;
}

.FLOKIGOLD {
  width: 16px;
  height: 16px;
  background-position: -640px -480px;
}

.FLOKILOKI {
  width: 16px;
  height: 16px;
  background-position: -640px -496px;
}

.FLOKIM {
  width: 16px;
  height: 16px;
  background-position: -640px -512px;
}

.FLOKIMONK {
  width: 16px;
  height: 16px;
  background-position: -640px -528px;
}

.FLOKIMOON {
  width: 16px;
  height: 16px;
  background-position: -640px -544px;
}

.FLOKIN {
  width: 16px;
  height: 16px;
  background-position: -640px -560px;
}

.FLOKINY {
  width: 16px;
  height: 16px;
  background-position: -640px -576px;
}

.FLOKIPAD {
  width: 16px;
  height: 16px;
  background-position: -640px -592px;
}

.FLOKIPUP {
  width: 16px;
  height: 16px;
  background-position: -640px -608px;
}

.FLOKIRAI {
  width: 16px;
  height: 16px;
  background-position: -640px -624px;
}

.FLOKIRUN {
  width: 16px;
  height: 16px;
  background-position: -0 -640px;
}

.FLOKIS {
  width: 16px;
  height: 16px;
  background-position: -16px -640px;
}

.FLOKI_SUPER {
  width: 16px;
  height: 16px;
  background-position: -32px -640px;
}

.FLOKITTEN {
  width: 16px;
  height: 16px;
  background-position: -48px -640px;
}

.FLOKIV {
  width: 16px;
  height: 16px;
  background-position: -64px -640px;
}

.FLOKIZ {
  width: 16px;
  height: 16px;
  background-position: -80px -640px;
}

.FLOM {
  width: 16px;
  height: 16px;
  background-position: -96px -640px;
}

.FLOOF {
  width: 16px;
  height: 16px;
  background-position: -112px -640px;
}

.FLOOR {
  width: 16px;
  height: 16px;
  background-position: -128px -640px;
}

.FLOSHIN {
  width: 16px;
  height: 16px;
  background-position: -144px -640px;
}

.FLOT {
  width: 16px;
  height: 16px;
  background-position: -160px -640px;
}

.FLOV {
  width: 16px;
  height: 16px;
  background-position: -176px -640px;
}

.FLOVM {
  width: 16px;
  height: 16px;
  background-position: -192px -640px;
}

.FLOW {
  width: 16px;
  height: 16px;
  background-position: -208px -640px;
}

.FLOYX {
  width: 16px;
  height: 16px;
  background-position: -224px -640px;
}

.FLP {
  width: 16px;
  height: 16px;
  background-position: -240px -640px;
}

.FLPD {
  width: 16px;
  height: 16px;
  background-position: -256px -640px;
}

.FLQ {
  width: 16px;
  height: 16px;
  background-position: -272px -640px;
}

.FLR {
  width: 16px;
  height: 16px;
  background-position: -288px -640px;
}

.FLRS {
  width: 16px;
  height: 16px;
  background-position: -304px -640px;
}

.FLS {
  width: 16px;
  height: 16px;
  background-position: -320px -640px;
}

.FLT {
  width: 16px;
  height: 16px;
  background-position: -336px -640px;
}

.FLTY {
  width: 16px;
  height: 16px;
  background-position: -352px -640px;
}

.FLUF {
  width: 16px;
  height: 16px;
  background-position: -368px -640px;
}

.FLUFFY {
  width: 16px;
  height: 16px;
  background-position: -384px -640px;
}

.FLUID {
  width: 16px;
  height: 16px;
  background-position: -400px -640px;
}

.FLUNAR {
  width: 16px;
  height: 16px;
  background-position: -416px -640px;
}

.FLURRY {
  width: 16px;
  height: 16px;
  background-position: -432px -640px;
}

.FLUSD {
  width: 16px;
  height: 16px;
  background-position: -448px -640px;
}

.FLUX {
  width: 16px;
  height: 16px;
  background-position: -464px -640px;
}

.FLVR {
  width: 16px;
  height: 16px;
  background-position: -480px -640px;
}

.FLX {
  width: 16px;
  height: 16px;
  background-position: -496px -640px;
}

.FLY {
  width: 16px;
  height: 16px;
  background-position: -512px -640px;
}

.FMA {
  width: 16px;
  height: 16px;
  background-position: -528px -640px;
}

.FMAN {
  width: 16px;
  height: 16px;
  background-position: -544px -640px;
}

.FMB {
  width: 16px;
  height: 16px;
  background-position: -560px -640px;
}

.FMC {
  width: 16px;
  height: 16px;
  background-position: -576px -640px;
}

.FME {
  width: 16px;
  height: 16px;
  background-position: -592px -640px;
}

.FMEV2 {
  width: 16px;
  height: 16px;
  background-position: -608px -640px;
}

.FMEX {
  width: 16px;
  height: 16px;
  background-position: -624px -640px;
}

.FMF {
  width: 16px;
  height: 16px;
  background-position: -640px -640px;
}

.FMG {
  width: 16px;
  height: 16px;
  background-position: -656px -0;
}

.FMON {
  width: 16px;
  height: 16px;
  background-position: -656px -16px;
}

.FMT {
  width: 16px;
  height: 16px;
  background-position: -656px -32px;
}

.FMTA {
  width: 16px;
  height: 16px;
  background-position: -656px -48px;
}

.FMV {
  width: 16px;
  height: 16px;
  background-position: -656px -64px;
}

.FN {
  width: 16px;
  height: 16px;
  background-position: -656px -80px;
}

.FNB {
  width: 16px;
  height: 16px;
  background-position: -656px -96px;
}

.FNC {
  width: 16px;
  height: 16px;
  background-position: -656px -112px;
}

.FNCY {
  width: 16px;
  height: 16px;
  background-position: -656px -128px;
}

.FND {
  width: 16px;
  height: 16px;
  background-position: -656px -144px;
}

.FNDZ {
  width: 16px;
  height: 16px;
  background-position: -656px -160px;
}

.FNF {
  width: 16px;
  height: 16px;
  background-position: -656px -176px;
}

.FNG {
  width: 16px;
  height: 16px;
  background-position: -656px -192px;
}

.FNK {
  width: 16px;
  height: 16px;
  background-position: -656px -208px;
}

.FNKOS {
  width: 16px;
  height: 16px;
  background-position: -656px -224px;
}

.FNRD {
  width: 16px;
  height: 16px;
  background-position: -656px -240px;
}

.FNSP {
  width: 16px;
  height: 16px;
  background-position: -656px -256px;
}

.FNT {
  width: 16px;
  height: 16px;
  background-position: -656px -272px;
}

.FNTB {
  width: 16px;
  height: 16px;
  background-position: -656px -288px;
}

.FNX {
  width: 16px;
  height: 16px;
  background-position: -656px -304px;
}

.FO {
  width: 16px;
  height: 16px;
  background-position: -656px -320px;
}

.FOA {
  width: 16px;
  height: 16px;
  background-position: -656px -336px;
}

.FOAM {
  width: 16px;
  height: 16px;
  background-position: -656px -352px;
}

.FOC {
  width: 16px;
  height: 16px;
  background-position: -656px -368px;
}

.FOCV {
  width: 16px;
  height: 16px;
  background-position: -656px -384px;
}

.FOD {
  width: 16px;
  height: 16px;
  background-position: -656px -400px;
}

.FODL {
  width: 16px;
  height: 16px;
  background-position: -656px -416px;
}

.FOGE {
  width: 16px;
  height: 16px;
  background-position: -656px -432px;
}

.FOHO {
  width: 16px;
  height: 16px;
  background-position: -656px -448px;
}

.FOIL {
  width: 16px;
  height: 16px;
  background-position: -656px -464px;
}

.FOIN {
  width: 16px;
  height: 16px;
  background-position: -656px -480px;
}

.FOL {
  width: 16px;
  height: 16px;
  background-position: -656px -496px;
}

.FOLD {
  width: 16px;
  height: 16px;
  background-position: -656px -512px;
}

.FOLK {
  width: 16px;
  height: 16px;
  background-position: -656px -528px;
}

.FOLO {
  width: 16px;
  height: 16px;
  background-position: -656px -544px;
}

.FOMO {
  width: 16px;
  height: 16px;
  background-position: -656px -560px;
}

.FOMOBABY {
  width: 16px;
  height: 16px;
  background-position: -656px -576px;
}

.FomoETH {
  width: 16px;
  height: 16px;
  background-position: -656px -592px;
}

.FOMP {
  width: 16px;
  height: 16px;
  background-position: -656px -608px;
}

.FON {
  width: 16px;
  height: 16px;
  background-position: -656px -624px;
}

.FONE {
  width: 16px;
  height: 16px;
  background-position: -656px -640px;
}

.FONT {
  width: 16px;
  height: 16px;
  background-position: -0 -656px;
}

.FOOD {
  width: 16px;
  height: 16px;
  background-position: -16px -656px;
}

.FOODPAY {
  width: 16px;
  height: 16px;
  background-position: -32px -656px;
}

.FOOFIGHT {
  width: 16px;
  height: 16px;
  background-position: -48px -656px;
}

.FOOLSDOGE {
  width: 16px;
  height: 16px;
  background-position: -64px -656px;
}

.FOOT {
  width: 16px;
  height: 16px;
  background-position: -80px -656px;
}

.FootballStars {
  width: 16px;
  height: 16px;
  background-position: -96px -656px;
}

.FOOTIE {
  width: 16px;
  height: 16px;
  background-position: -112px -656px;
}

.FOPAY {
  width: 16px;
  height: 16px;
  background-position: -128px -656px;
}

.FOR {
  width: 16px;
  height: 16px;
  background-position: -144px -656px;
}

.FORCE {
  width: 16px;
  height: 16px;
  background-position: -160px -656px;
}

.FORESTPLUS {
  width: 16px;
  height: 16px;
  background-position: -176px -656px;
}

.FOREVERFOMO {
  width: 16px;
  height: 16px;
  background-position: -192px -656px;
}

.FOREX {
  width: 16px;
  height: 16px;
  background-position: -208px -656px;
}

.FORGE {
  width: 16px;
  height: 16px;
  background-position: -224px -656px;
}

.FORINT {
  width: 16px;
  height: 16px;
  background-position: -240px -656px;
}

.FORK {
  width: 16px;
  height: 16px;
  background-position: -256px -656px;
}

.FORM {
  width: 16px;
  height: 16px;
  background-position: -272px -656px;
}

.FORS {
  width: 16px;
  height: 16px;
  background-position: -288px -656px;
}

.FORT {
  width: 16px;
  height: 16px;
  background-position: -304px -656px;
}

.FORTH {
  width: 16px;
  height: 16px;
  background-position: -320px -656px;
}

.FORTUNE {
  width: 16px;
  height: 16px;
  background-position: -336px -656px;
}

.FORWARD {
  width: 16px;
  height: 16px;
  background-position: -352px -656px;
}

.FOSSIL {
  width: 16px;
  height: 16px;
  background-position: -368px -656px;
}

.FOTA {
  width: 16px;
  height: 16px;
  background-position: -384px -656px;
}

.FOTC {
  width: 16px;
  height: 16px;
  background-position: -400px -656px;
}

.FOTO {
  width: 16px;
  height: 16px;
  background-position: -416px -656px;
}

.FOUND {
  width: 16px;
  height: 16px;
  background-position: -432px -656px;
}

.FOUR {
  width: 16px;
  height: 16px;
  background-position: -448px -656px;
}

.FOUR__4_ {
  width: 16px;
  height: 16px;
  background-position: -464px -656px;
}

.FOX {
  width: 16px;
  height: 16px;
  background-position: -480px -656px;
}

.FOXD {
  width: 16px;
  height: 16px;
  background-position: -496px -656px;
}

.FOXGIRL {
  width: 16px;
  height: 16px;
  background-position: -512px -656px;
}

.FOXT {
  width: 16px;
  height: 16px;
  background-position: -528px -656px;
}

.FOXX {
  width: 16px;
  height: 16px;
  background-position: -544px -656px;
}

.Foxy {
  width: 16px;
  height: 16px;
  background-position: -560px -656px;
}

.FP {
  width: 16px;
  height: 16px;
  background-position: -576px -656px;
}

.FPAD {
  width: 16px;
  height: 16px;
  background-position: -592px -656px;
}

.FPC {
  width: 16px;
  height: 16px;
  background-position: -608px -656px;
}

.FPET {
  width: 16px;
  height: 16px;
  background-position: -624px -656px;
}

.FPFT {
  width: 16px;
  height: 16px;
  background-position: -640px -656px;
}

.FPI {
  width: 16px;
  height: 16px;
  background-position: -656px -656px;
}

.FPIS {
  width: 16px;
  height: 16px;
  background-position: -672px -0;
}

.FPL {
  width: 16px;
  height: 16px;
  background-position: -672px -16px;
}

.FPS {
  width: 16px;
  height: 16px;
  background-position: -672px -32px;
}

.FPT {
  width: 16px;
  height: 16px;
  background-position: -672px -48px;
}

.FPUMP {
  width: 16px;
  height: 16px;
  background-position: -672px -64px;
}

.FPUP {
  width: 16px;
  height: 16px;
  background-position: -672px -80px;
}

.FQS {
  width: 16px;
  height: 16px;
  background-position: -672px -96px;
}

.FR {
  width: 16px;
  height: 16px;
  background-position: -672px -112px;
}

.FRA {
  width: 16px;
  height: 16px;
  background-position: -672px -128px;
}

.FRANC {
  width: 16px;
  height: 16px;
  background-position: -672px -144px;
}

.FRANK {
  width: 16px;
  height: 16px;
  background-position: -672px -160px;
}

.FRAS {
  width: 16px;
  height: 16px;
  background-position: -672px -176px;
}

.FRAT {
  width: 16px;
  height: 16px;
  background-position: -672px -192px;
}

.FRAX {
  width: 16px;
  height: 16px;
  background-position: -672px -208px;
}

.FRC {
  width: 16px;
  height: 16px;
  background-position: -672px -224px;
}

.FREC {
  width: 16px;
  height: 16px;
  background-position: -672px -240px;
}

.FRECNX {
  width: 16px;
  height: 16px;
  background-position: -672px -256px;
}

.FRED {
  width: 16px;
  height: 16px;
  background-position: -672px -272px;
}

.FREDX {
  width: 16px;
  height: 16px;
  background-position: -672px -288px;
}

.FREE {
  width: 16px;
  height: 16px;
  background-position: -672px -304px;
}

.FREED {
  width: 16px;
  height: 16px;
  background-position: -672px -320px;
}

.FREEDOM {
  width: 16px;
  height: 16px;
  background-position: -672px -336px;
}

.FREEMOON {
  width: 16px;
  height: 16px;
  background-position: -672px -352px;
}

.FREL {
  width: 16px;
  height: 16px;
  background-position: -672px -368px;
}

.FREN {
  width: 16px;
  height: 16px;
  background-position: -672px -384px;
}

.FRENS {
  width: 16px;
  height: 16px;
  background-position: -672px -400px;
}

.FRET {
  width: 16px;
  height: 16px;
  background-position: -672px -416px;
}

.FREY {
  width: 16px;
  height: 16px;
  background-position: -672px -432px;
}

.FRF {
  width: 16px;
  height: 16px;
  background-position: -672px -448px;
}

.FRG {
  width: 16px;
  height: 16px;
  background-position: -672px -464px;
}

.FRIDGE {
  width: 16px;
  height: 16px;
  background-position: -672px -480px;
}

.FRIES {
  width: 16px;
  height: 16px;
  background-position: -672px -496px;
}

.FRIN {
  width: 16px;
  height: 16px;
  background-position: -672px -512px;
}

.FRINU {
  width: 16px;
  height: 16px;
  background-position: -672px -528px;
}

.FRKT {
  width: 16px;
  height: 16px;
  background-position: -672px -544px;
}

.FRM {
  width: 16px;
  height: 16px;
  background-position: -672px -560px;
}

.FRMS {
  width: 16px;
  height: 16px;
  background-position: -672px -576px;
}

.FRMX {
  width: 16px;
  height: 16px;
  background-position: -672px -592px;
}

.FRN {
  width: 16px;
  height: 16px;
  background-position: -672px -608px;
}

.FRNK {
  width: 16px;
  height: 16px;
  background-position: -672px -624px;
}

.FRNT {
  width: 16px;
  height: 16px;
  background-position: -672px -640px;
}

.FROG {
  width: 16px;
  height: 16px;
  background-position: -672px -656px;
}

.FROGEX {
  width: 16px;
  height: 16px;
  background-position: -0 -672px;
}

.FROGGIES {
  width: 16px;
  height: 16px;
  background-position: -16px -672px;
}

.FRONT {
  width: 16px;
  height: 16px;
  background-position: -32px -672px;
}

.FROST {
  width: 16px;
  height: 16px;
  background-position: -48px -672px;
}

.FROSTEDCAKE {
  width: 16px;
  height: 16px;
  background-position: -64px -672px;
}

.FROSTYFLOKI {
  width: 16px;
  height: 16px;
  background-position: -80px -672px;
}

.FROYO {
  width: 16px;
  height: 16px;
  background-position: -96px -672px;
}

.FRR {
  width: 16px;
  height: 16px;
  background-position: -112px -672px;
}

.FRST {
  width: 16px;
  height: 16px;
  background-position: -128px -672px;
}

.FRT {
  width: 16px;
  height: 16px;
  background-position: -144px -672px;
}

.FRTM {
  width: 16px;
  height: 16px;
  background-position: -160px -672px;
}

.FRTS {
  width: 16px;
  height: 16px;
  background-position: -176px -672px;
}

.FRUIT {
  width: 16px;
  height: 16px;
  background-position: -192px -672px;
}

.FRY {
  width: 16px;
  height: 16px;
  background-position: -208px -672px;
}

.FRZSS {
  width: 16px;
  height: 16px;
  background-position: -224px -672px;
}

.FS {
  width: 16px;
  height: 16px;
  background-position: -240px -672px;
}

.FSAFE {
  width: 16px;
  height: 16px;
  background-position: -256px -672px;
}

.FSBT {
  width: 16px;
  height: 16px;
  background-position: -272px -672px;
}

.FSC {
  width: 16px;
  height: 16px;
  background-position: -288px -672px;
}

.FSCC {
  width: 16px;
  height: 16px;
  background-position: -304px -672px;
}

.FSCP {
  width: 16px;
  height: 16px;
  background-position: -320px -672px;
}

.FSD {
  width: 16px;
  height: 16px;
  background-position: -336px -672px;
}

.FSDCOIN {
  width: 16px;
  height: 16px;
  background-position: -352px -672px;
}

.FSH {
  width: 16px;
  height: 16px;
  background-position: -368px -672px;
}

.FSHIB {
  width: 16px;
  height: 16px;
  background-position: -384px -672px;
}

.FSHIBBY {
  width: 16px;
  height: 16px;
  background-position: -400px -672px;
}

.FSHN {
  width: 16px;
  height: 16px;
  background-position: -416px -672px;
}

.FSINU {
  width: 16px;
  height: 16px;
  background-position: -432px -672px;
}

.FSK {
  width: 16px;
  height: 16px;
  background-position: -448px -672px;
}

.FTMX {
  width: 16px;
  height: 16px;
  background-position: -464px -672px;
}

.FSM {
  width: 16px;
  height: 16px;
  background-position: -480px -672px;
}

.FSN {
  width: 16px;
  height: 16px;
  background-position: -496px -672px;
}

.FSP {
  width: 16px;
  height: 16px;
  background-position: -512px -672px;
}

.FST {
  width: 16px;
  height: 16px;
  background-position: -528px -672px;
}

.FSTAR {
  width: 16px;
  height: 16px;
  background-position: -544px -672px;
}

.FSW {
  width: 16px;
  height: 16px;
  background-position: -560px -672px;
}

.FSWAP {
  width: 16px;
  height: 16px;
  background-position: -576px -672px;
}

.FSXA {
  width: 16px;
  height: 16px;
  background-position: -592px -672px;
}

.FSXU {
  width: 16px;
  height: 16px;
  background-position: -608px -672px;
}

.FT {
  width: 16px;
  height: 16px;
  background-position: -624px -672px;
}

.FT1 {
  width: 16px;
  height: 16px;
  background-position: -640px -672px;
}

.FTB {
  width: 16px;
  height: 16px;
  background-position: -656px -672px;
}

.FTC {
  width: 16px;
  height: 16px;
  background-position: -672px -672px;
}

.FTD {
  width: 16px;
  height: 16px;
  background-position: -688px -0;
}

.FTE {
  width: 16px;
  height: 16px;
  background-position: -688px -16px;
}

.FTERRA {
  width: 16px;
  height: 16px;
  background-position: -688px -32px;
}

.FTF {
  width: 16px;
  height: 16px;
  background-position: -688px -48px;
}

.FTG {
  width: 16px;
  height: 16px;
  background-position: -688px -64px;
}

.FTH {
  width: 16px;
  height: 16px;
  background-position: -688px -80px;
}

.FTI {
  width: 16px;
  height: 16px;
  background-position: -688px -96px;
}

.FTL {
  width: 16px;
  height: 16px;
  background-position: -688px -112px;
}

.FTM {
  width: 16px;
  height: 16px;
  background-position: -688px -128px;
}

.FTML {
  width: 16px;
  height: 16px;
  background-position: -688px -144px;
}

.FTMO {
  width: 16px;
  height: 16px;
  background-position: -688px -160px;
}

.FTN {
  width: 16px;
  height: 16px;
  background-position: -688px -176px;
}

.FTO {
  width: 16px;
  height: 16px;
  background-position: -688px -192px;
}

.FTON {
  width: 16px;
  height: 16px;
  background-position: -688px -208px;
}

.FTP {
  width: 16px;
  height: 16px;
  background-position: -688px -224px;
}

.FTR {
  width: 16px;
  height: 16px;
  background-position: -688px -240px;
}

.FTRB {
  width: 16px;
  height: 16px;
  background-position: -688px -256px;
}

.FTS {
  width: 16px;
  height: 16px;
  background-position: -688px -272px;
}

.FTSY {
  width: 16px;
  height: 16px;
  background-position: -688px -288px;
}

.FTT {
  width: 16px;
  height: 16px;
  background-position: -688px -304px;
}

.FTV {
  width: 16px;
  height: 16px;
  background-position: -688px -320px;
}

.FTX {
  width: 16px;
  height: 16px;
  background-position: -688px -336px;
}

.FTXT {
  width: 16px;
  height: 16px;
  background-position: -688px -352px;
}

.FUBUKI {
  width: 16px;
  height: 16px;
  background-position: -688px -368px;
}

.FUCKMUSK {
  width: 16px;
  height: 16px;
  background-position: -688px -384px;
}

.FUD {
  width: 16px;
  height: 16px;
  background-position: -688px -400px;
}

.FUEL {
  width: 16px;
  height: 16px;
  background-position: -688px -416px;
}

.FUFI {
  width: 16px;
  height: 16px;
  background-position: -688px -432px;
}

.FUFU {
  width: 16px;
  height: 16px;
  background-position: -688px -448px;
}

.FUKU {
  width: 16px;
  height: 16px;
  background-position: -688px -464px;
}

.FULA {
  width: 16px;
  height: 16px;
  background-position: -688px -480px;
}

.FULLSEND {
  width: 16px;
  height: 16px;
  background-position: -688px -496px;
}

.FUM {
  width: 16px;
  height: 16px;
  background-position: -688px -512px;
}

.FUMA {
  width: 16px;
  height: 16px;
  background-position: -688px -528px;
}

.FUN {
  width: 16px;
  height: 16px;
  background-position: -688px -544px;
}

.FUND {
  width: 16px;
  height: 16px;
  background-position: -688px -560px;
}

.FUNDX {
  width: 16px;
  height: 16px;
  background-position: -688px -576px;
}

.FUNDZ {
  width: 16px;
  height: 16px;
  background-position: -688px -592px;
}

.FUNK {
  width: 16px;
  height: 16px;
  background-position: -688px -608px;
}

.FUPS {
  width: 16px;
  height: 16px;
  background-position: -688px -624px;
}

.FUR {
  width: 16px;
  height: 16px;
  background-position: -688px -640px;
}

.FURY {
  width: 16px;
  height: 16px;
  background-position: -688px -656px;
}

.FUSD {
  width: 16px;
  height: 16px;
  background-position: -688px -672px;
}

.fUSDT {
  width: 16px;
  height: 16px;
  background-position: -0 -688px;
}

.FUSE {
  width: 16px;
  height: 16px;
  background-position: -16px -688px;
}

.FUSII {
  width: 16px;
  height: 16px;
  background-position: -32px -688px;
}

.FUTURE {
  width: 16px;
  height: 16px;
  background-position: -48px -688px;
}

.FUU {
  width: 16px;
  height: 16px;
  background-position: -64px -688px;
}

.FUZE {
  width: 16px;
  height: 16px;
  background-position: -80px -688px;
}

.FUZZ {
  width: 16px;
  height: 16px;
  background-position: -96px -688px;
}

.FVP {
  width: 16px;
  height: 16px;
  background-position: -112px -688px;
}

.FVT {
  width: 16px;
  height: 16px;
  background-position: -128px -688px;
}

.FWATCH {
  width: 16px;
  height: 16px;
  background-position: -144px -688px;
}

.FWB {
  width: 16px;
  height: 16px;
  background-position: -160px -688px;
}

.FWC {
  width: 16px;
  height: 16px;
  background-position: -176px -688px;
}

.FWG {
  width: 16px;
  height: 16px;
  background-position: -192px -688px;
}

.FWT {
  width: 16px;
  height: 16px;
  background-position: -208px -688px;
}

.FWW {
  width: 16px;
  height: 16px;
  background-position: -224px -688px;
}

.FX {
  width: 16px;
  height: 16px;
  background-position: -240px -688px;
}

.FX1 {
  width: 16px;
  height: 16px;
  background-position: -256px -688px;
}

.FXBC {
  width: 16px;
  height: 16px;
  background-position: -272px -688px;
}

.FXC {
  width: 16px;
  height: 16px;
  background-position: -288px -688px;
}

.FXDX {
  width: 16px;
  height: 16px;
  background-position: -304px -688px;
}

.FXF {
  width: 16px;
  height: 16px;
  background-position: -320px -688px;
}

.FXP {
  width: 16px;
  height: 16px;
  background-position: -336px -688px;
}

.FXS {
  width: 16px;
  height: 16px;
  background-position: -352px -688px;
}

.FXT {
  width: 16px;
  height: 16px;
  background-position: -368px -688px;
}

.FXTE {
  width: 16px;
  height: 16px;
  background-position: -384px -688px;
}

.FYD {
  width: 16px;
  height: 16px;
  background-position: -400px -688px;
}

.FYN {
  width: 16px;
  height: 16px;
  background-position: -416px -688px;
}

.FYP {
  width: 16px;
  height: 16px;
  background-position: -432px -688px;
}

.FYZ {
  width: 16px;
  height: 16px;
  background-position: -448px -688px;
}

.FYZNFT {
  width: 16px;
  height: 16px;
  background-position: -464px -688px;
}

.FZ {
  width: 16px;
  height: 16px;
  background-position: -480px -688px;
}

.FZL {
  width: 16px;
  height: 16px;
  background-position: -496px -688px;
}

.FZS {
  width: 16px;
  height: 16px;
  background-position: -512px -688px;
}

.FZY {
  width: 16px;
  height: 16px;
  background-position: -528px -688px;
}
