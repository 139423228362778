.G_O_A_T,
.G,
.G1X,
.G2,
.G2N,
.G3CRV,
.G9,
.G9TRO,
.G50,
.G999,
.G\$,
.GA,
.GAAS,
.GABECOIN,
.GAC,
.GAD,
.GADOSHI,
.GAE,
.GAFA,
.GAFI,
.GAIA,
.GAIN,
.GAINS,
.GAINZ,
.GAJ,
.GAL,
.GALA,
.GALABET,
.GALAXY,
.GALAXYDOGE,
.GALE,
.GALEON,
.GALI,
.GALO,
.GALS,
.GAM,
.GAMBL,
.GAME,
.GAME1,
.GAMEBOX,
.GAMECRYPT,
.GAMEFI,
.GAMER,
.GAMES,
.GAMESAFE,
.GAMI,
.GAMINGDOGE,
.GAMINGSHIBA,
.GAMMA,
.GAN,
.GANG,
.GANJA,
.GANTZ,
.GAP,
.GAPT,
.GAR,
.GARD,
.GARFIELD,
.GARI,
.GARK,
.GART,
.GARUDA,
.GAS,
.GASG,
.GASP,
.GAT,
.GATE,
.GATSBYINU,
.GAU,
.GAUF,
.GAUR,
.GAX,
.GAXY,
.GAZE,
.Gazelle,
.GB,
.GBA,
.GBAG,
.GBC,
.GBCR,
.GBD,
.GBERN,
.GBET,
.GBEX,
.GBG,
.GBK,
.GBLN,
.GBOX,
.GBP,
.GBPU,
.GBPX,
.GBR,
.GBT,
.GBTC,
.GBTS,
.GBX,
.GBYTE,
.GC,
.GCAKE,
.GCAT,
.GCC,
.GCM,
.GCME,
.GCN,
.GCNX,
.GCOIN,
.GME,
.GCPH,
.GCR,
.GCUSDC,
.GCX,
.GDAO,
.GDC,
.GDDY,
.GDEFI,
.GDILDO,
.GDL,
.GDM,
.GDO,
.GDOGE,
.GDR,
.GDT,
.GEAR,
.GEC,
.GEEQ,
.GEFT,
.GEIST,
.GEL,
.GEM,
.GEMG,
.GEMIT,
.GEMMINE,
.GEMS,
.GEMZ,
.GEN,
.GENA,
.GENART,
.GENCAP,
.GENE,
.GENESIS,
.GENGAR,
.GENI,
.GENIETOKEN,
.GenIm,
.GENIUS,
.GENIX,
.GENK,
.GENRE,
.GENS,
.GENSHIN,
.GENT,
.GENX,
.GEO,
.GEO\$,
.GEON,
.GER,
.GERA,
.GERO,
.GPAY,
.GES,
.GET,
.GETH,
.GETX,
.GEX,
.GF,
.GFARM2,
.GFC,
.GFCE,
.GFCS,
.GFI,
.GFloki,
.GFN,
.GFNC,
.GFNCASH,
.GFR,
.GFS,
.GFSHIB,
.GFT,
.GFUN,
.GFX,
.GG,
.GGC,
.GGG,
.GGIVE,
.GGM,
.GGR,
.GGT,
.GGTK,
.GGTKN,
.GHC,
.GHD,
.GHO,
.GHOST,
.GHOSTBLADE,
.GHOSTFACE,
.GHR,
.GHSP,
.GHST,
.GHT,
.GHUB,
.GHX,
.GIC,
.GIFT,
.GIG,
.GIGA,
.GIL,
.GIN,
.GINOA,
.GRIM,
.GINSPIRIT,
.GINU,
.GINUX,
.GINZA,
.GIO,
.GIT,
.GIV,
.GIVE,
.GIVING,
.GIZA,
.GKCAKE,
.GKF,
.gKIMCHI,
.GL,
.GLA,
.GLAD,
.GLASS,
.GLB,
.GLBD,
.GLC,
.GLCH,
.GLD,
.GLDR,
.GLDX,
.GLDY,
.GLDZ,
.GLE,
.GLEEC,
.GLEX,
.GLF,
.GLIDE,
.GLINK,
.GLINT,
.GLM,
.GLMR,
.GLMS,
.GLO,
.GLOB,
.GLORY,
.GLORYD,
.GLOW,
.GlowV2,
.GLOX,
.GLQ,
.GLS,
.GLT,
.GLTO,
.GLV,
.GLX,
.GLXM,
.GLXY,
.GLY,
.GLYPH,
.GM,
.GMAT,
.GMB,
.GMBC,
.GMC,
.GMCN,
.GMCOIN,
.GMD,
.GMEE,
.gMELD,
.GMETA,
.GMEX,
.GMFLOKI,
.GMI,
.GMINES,
.GMINU,
.GMM,
.GMNG,
.GMPD,
.GMR,
.GMS,
.GMSOL,
.GMT,
.GMV,
.GMX,
.GMYX,
.GMZ,
.GN,
.GNAR,
.GNBT,
.GNBU,
.GNC,
.GNFT,
.GNL,
.GNLR,
.GNNX,
.GNO,
.GNOME,
.GNP,
.GNS,
.GNSH,
.GNT,
.GNTL,
.GNTO,
.GNX,
.GNY,
.GO,
.GOAL,
.GOALGO,
.GOAT,
.GOATM,
.GOB,
.GOBBLE,
.GOBI,
.GOBLIN,
.GOC,
.GOD,
.GODE,
.GODL,
.GODS,
.GODV,
.GODZ,
.GOF,
.GOFI,
.GOFX,
.GOG,
.GOGE,
.GOGETA,
.GOGO,
.GOHM,
.GOIN,
.GOJO,
.GOKEY,
.GOKU,
.GOL,
.GOLD,
.GOLD_NUGGET,
.GOLDR,
.GOLDUCK,
.GOLDX,
.GOLDY,
.GOLDZ,
.GOLF,
.GOLOS,
.GOM,
.GOM2,
.GOMA,
.GOMAX,
.GOMB,
.GOMINT,
.GOMT,
.GON,
.GON\+,
.GOO,
.GOOD,
.GoofyDoge,
.GOOGL,
.GOOREO,
.GORGEOUS,
.GORILLAINU,
.GOS,
.GOSH,
.GOSS,
.GOT,
.GOTEM,
.GOTG,
.GOUKI,
.GOV,
.GOVI,
.GOXT,
.GOZ,
.GP,
.GPAD,
.GPCX,
.GPKR,
.GPO,
.GPOOL,
.GPT,
.GPX,
.GPYX,
.GQ,
.GR,
.GRAIL,
.GRAIN,
.GRAM,
.GRAND,
.GRANX,
.GRAP,
.GRAPE,
.GRAPH,
.GRAT,
.GRAV,
.GRBE,
.GRBT,
.GRC,
.GRD,
.GREED,
.GREEN,
.GREENBIOX,
.GREENFLOKI,
.GREENFUEL,
.GREM,
.GREY,
.GREYHOUND,
.GRFT,
.GRG,
.GRID,
.GRILL,
.GRILLZ,
.Grimace,
.GRIMACE,
.GRIME,
.GRIM_EVO,
.GRIMEX,
.GRIMM,
.GRIN,
.GRLC,
.GRM,
.GRMD,
.GRMZilla,
.GRN,
.GRO,
.GROK,
.GROW,
.GRP,
.GRPH,
.GRPHT,
.GRPL,
.GRS,
.GRT,
.GRTC,
.GRUG,
.GRUMPY,
.GRUSH,
.GRV,
.GRVE,
.GRVS,
.GRVX,
.GRX,
.GS,
.GS1,
.GSA,
.GSAIL,
.GSC,
.GSCARAB,
.GSE,
.GSG,
.GSHARE,
.GSHIB,
.GSHIBA,
.GSK,
.GSKY,
.GSMT,
.GSONIC,
.GSPI,
.GSR,
.GSSIT,
.GST,
.GST2,
.GSTRM,
.GSTS,
.GSWAP,
.GSX,
.GT,
.GTC,
.GTCOIN,
.GTE,
.GTF,
.GTFO,
.GTH,
.GTM,
.GTN,
.GTO,
.GTON,
.GTP,
.GTPLUS,
.GTR,
.GTS,
.GTX,
.GUAP,
.GUARD,
.GucciV2,
.GUESS,
.GUH,
.GUILD,
.GULF,
.GULL,
.GUM,
.GUMMIE,
.GUMMY,
.GUMMYS,
.Gunclear,
.GUNS,
.GUNTHY,
.GUP,
.GURU,
.GUSD,
.GUSDT,
.GUT,
.GVC,
.GVE,
.GVR,
.GVT,
.GVY,
.GW,
.GWAR,
.GWG,
.GWP,
.GWS,
.GWSPP,
.GWT,
.GXC,
.GXE,
.GXP,
.GXPAD,
.GXT,
.GXX,
.GY,
.GYA,
.GYC,
.GYEN,
.GYFI,
.GYM,
.GYMNET,
.GYRO,
.GYSR,
.GZE,
.GZIL,
.GZILA,
.GZLR,
.GZM,
.GZONE,
.GZRO,
.GZX,
.H2E,
.H2O,
.H2OC,
.H3RO3S,
.H4G,
.HACHIKO,
.HADA,
.HADES,
.HAG,
.HAI,
.HAK,
.HAKA,
.HAKKA,
.HAKU,
.HALF,
.HALFSHIT,
.HTHEDGE,
.HTHALF,
.HALLO,
.HALO,
.HALV,
.HAM,
.HAMS,
.HAMTARO,
.HANA,
.HAND,
.HANDY,
.HANGRY,
.HANU,
.HANZO,
.HAPI,
.HAPPY,
.HAPY,
.HARAMBE,
.HARD,
.HARE,
.HARE_PLUS,
.HARL,
.HARON,
.HARPY,
.HARUNO,
.HASH,
.HASHI,
.HAT,
.HATAY,
.HATCH,
.HATE,
.HATOK,
.HATTER,
.HAUS,
.HAUT,
.HAVEN,
.HAVY,
.HAWK,
.HAY,
.HAZ,
.HAZE,
.HB,
.HBAR,
.HBARP,
.HBB,
.HBC,
.HBD,
.HBDC,
.HBEE,
.HBG,
.HBIT,
.HBN,
.HBO,
.HBOT,
.HBRS,
.HBT,
.HBTC,
.HBUSD,
.HBX,
.HC,
.HCA,
.HCASH,
.HCC,
.HCK,
.HCS,
.HCT,
.HD,
.HDAC,
.HDAO,
.HDD,
.HDDN,
.HDFL,
.HDG,
.HDI,
.HDL,
.HDLN,
.HDM,
.HDO,
.HDOG,
.HDP_ф,
.HDRN,
.HDV,
.HDX,
.HE,
.HEAD,
.HEADBANGERS,
.HEADZ,
.HEAL,
.HEARN,
.HEART,
.HEARTK,
.HEAT,
.HEBE,
.HEC,
.HECATE,
.HEDG,
.HEDGE,
.HTBULL,
.HEE,
.HEGEM,
.HEGG,
.HEGIC,
.HEIST,
.HEJJ,
.HEL,
.HELD,
.HELENA,
.HELIOS,
.HELLSING,
.HELMET,
.HELP,
.HELPS,
.HEM,
.HENTAI,
.HEP,
.HEPA,
.HER,
.HERA,
.HERB,
.HERC,
.HERO,
.HERODOGE,
.HEROEGG,
.HEROES,
.HERON,
.HEROS,
.HERPES,
.HES,
.HESH,
.HET,
.HETH,
.HETM,
.HEX,
.HEX2T,
.HEYSHIB,
.HEZ,
.HFI,
.HFIL,
.HFS,
.HFSP,
.HFT,
.HFUEL,
.HGET,
.HGH,
.HGHG,
.HGO,
.HGOLD,
.HGRO,
.HGRW,
.HGT,
.HHNFT,
.HHUB,
.HI,
.HIBIKI,
.HIBS,
.HID,
.HIDEOUS,
.HIFI,
.HIGGS,
.HIGH,
.HIKO,
.HIMA,
.HIMO,
.HINA,
.HINT,
.HINU,
.HIP,
.HIPPIE,
.HIPPO,
.HIRO,
.HIT,
.HITHOT,
.HITOP,
.HITX,
.HIVE,
.HIZ,
.HK,
.HKC,
.HKDX,
.HKLS,
.HKN,
.HKO,
.HKSM,
.HKUN,
.HL,
.HLAND,
.HLC,
.HLD,
.HLIX,
.HLM,
.HLP,
.HLS,
.HLT,
.HLTC,
.HLTH,
.HLX,
.HM,
.HMB,
.HMC,
.HMDX,
.HMETA,
.HMNG,
.HMOON,
.HMQ,
.HMR,
.HMRN,
.HMT,
.HMXC,
.HMZ,
.HN,
.HNB,
.HNC,
.HND,
.HNDC,
.HNG,
.HNS,
.HNST,
.HNT,
.HNW,
.HNY,
.HNYAN,
.HO,
.HOD,
.HODL,
.HODL_2_0,
.HODLX,
.HODM,
.HODO,
.HOFF,
.HOGE,
.HOGL,
.HOGS,
.HOGT,
.HOHOHO,
.HOHOHO_V2_0,
.HOKK,
.HOL,
.HOLD,
.HOLDEX,
.HOLE,
.HOLY,
.HOME,
.HOMI,
.HOMT,
.HON,
.HONEY,
.HONEYBADGER,
.HONEYD,
.HONK,
.HONOR,
.HONR,
.HOO,
.HOOD,
.HOODRAT,
.HOOF,
.HOOK,
.HOOP,
.HOP,
.HOPE,
.HOPR,
.HOR,
.HORD,
.HORDE,
.HORGI,
.HORN,
.HORNY,
.HORSE,
.HORUS,
.HOSKY,
.HOT,
.HOTCROSS,
.HOTDOGE,
.HOTMOON,
.HOTP,
.HOTZILLA,
.HOUSE,
.HOVER,
.HOWL,
.HOX,
.HP,
.HPAD,
.HPAY,
.HPB,
.HPD,
.HPL,
.HPLAY,
.HPNS,
.HPPOT,
.HPS,
.HPT,
.HPTF,
.HPW,
.HPX,
.HPY,
.HQT,
.HQX,
.HRB,
.HRC,
.HRD,
.HRIMP,
.HRO,
.HRS,
.HRTS,
.HRX,
.HRZ,
.HSC,
.HSF,
.HSHARE,
.HSHARES,
.HSI,
.HSN,
.HSS,
.HST,
.HT,
.HTA,
.HTB,
.HTC,
.HTD,
.HTDF,
.HTF,
.HTML,
.HTMOON,
.HTN,
.HTO,
.HTR,
.HTRE,
.HTX,
.HTZ,
.HUA,
.HUAHUA,
.HUB,
.HUDDL,
.HUDI,
.HUE,
.HUGO,
.HUH,
.HUKKU,
.HUM,
.HUMAN,
.HUMP,
.HUNGER,
.HUNGRY,
.HUNGRYDOGE,
.HUNNY,
.HUNT,
.HUNY,
.HUP,
.HUR,
.HUSD,
.HUSH,
.HUSKY,
.HUSKYX,
.HUSL,
.HUSTLE,
.HUSWP,
.HUT,
.HVCO,
.HVE2,
.HVI,
.HVILLE,
.HVLT,
.HVN,
.HVT,
.HWC,
.HWL,
.HX,
.HXB,
.HXN,
.HXP,
.HXRO,
.HXY,
.HY,
.HYB,
.HYBN,
.HYBRID,
.HYC,
.HYD,
.HYDRA,
.HYDRO,
.HYFI,
.HYMETEOR,
.HYN,
.HYP,
.HYPE,
.HYPED,
.HYPER,
.HYPERBOOST,
.HYPERR,
.HYPERRISE,
.HYPERSONIC,
.HYPR,
.HYPX,
.HYS,
.HYSS,
.HYT,
.HYVE,
.HZD,
.HZM,
.HZN,
.HZT,
.ʜᴏʟᴅᴇɴᴏᴍɪᴄs™,
.I9C,
.IADA,
.IAG,
.IAI,
.IAP,
.IB,
.IBANK,
.iBBT,
.IBCH,
.ibETH,
.IBEX,
.IBFK,
.iBFR,
.IBG,
.IBH,
.IBNB,
.IBP,
.IBS,
.IBT,
.IBTC,
.IBTC_FLI_P,
.IBVOL,
.IBXC,
.IBZ,
.IC,
.ICA,
.iCADE,
.ICAP,
.ICC,
.ICD,
.ICE,
.ICEBERG,
.ICEBRK,
.ICEX,
.ICH,
.ICHI,
.ICHIEMA,
.ICHIGO,
.ICHX,
.ICN,
.ICNQ,
.ICOB,
.ICOM,
.ICON,
.ICONS,
.ICOO,
.ICP,
.ICR,
.ICT,
.ICUBE,
.ICW,
.ICX,
.ICY,
.ID,
.IDALL,
.IDEA,
.IDEAL,
.IDEFI,
.IDEX,
.IDH,
.IDIA,
.IDK,
.IDL,
.IDLE,
.IDLEDAISAFE,
.IDLEDAIYIELD,
.IDLESUSDYIELD,
.IDLETUSDYIELD,
.IDLEUSDCSAFE,
.IDLEUSDCYIELD,
.IDLEUSDTSAFE,
.IDLEUSDTYIELD,
.IDLEWBTCYIELD,
.IDLI,
.IDM,
.IDNA,
.IDO,
.IDOGE,
.IDOL,
.IDOSCAN,
.IDOT,
.IDRT,
.IDS,
.IDT,
.IDTT,
.IDV,
.IDX,
.IDXM,
.IDYP,
.IEOS,
.IETH,
.IETH20SMACO,
.IF,
.IFC,
.IFEX,
.IFG,
.IFLT,
.IFO,
.IFOOD,
.IFP,
.IFR,
.IFT,
.IFUND,
.IFV,
.IFX24,
.IG,
.IGCH,
.IGF,
.IGG,
.IGIRL,
.IGL,
.IGN,
.IGNIS,
.IGO,
.IGR,
.IGT,
.IGU,
.IHC,
.IHF,
.IHT,
.IIC,
.IJC,
.IJZ,
.IKA,
.IKC,
.IKONIC,
.IKURA,
.ILA,
.ILAYER,
.ILC,
.ILG,
.ILINK,
.ILK,
.ILSI,
.ILTC,
.ILUS,
.ILV,
.IM,
.IMAGIC,
.IMATIC_FLI_P,
.imBTC,
.IMBU,
.IMC,
.IME,
.IMG,
.IMGC,
.IMI,
.IMM,
.IMMO,
.IMO,
.IMP,
.IMPACTX,
.IMPACTXP,
.IMPCN,
.IMPL,
.IMRTL,
.IMS,
.IMT,
.IMV,
.IMX,
.IN,
.INARI,
.INAZ,
.INB,
.InBit,
.INC,
.INCAKE,
.INCEPTION,
.INCNT,
.INCO,
.INCOME,
.IND,
.INDC,
.INDEX,
.INDI,
.INDIA,
.INDSHIB,
.INE,
.INET,
.INEX,
.INF,
.INF4,
.INFD,
.INFI,
.INFINITY,
.INFINITYADA,
.INFINITYDOGE,
.INFLEX,
.INFLUENCE,
.INFO,
.INFP,
.INFS,
.INFT,
.INFTEE,
.ING,
.INJ,
.INK,
.INKZ,
.INMES,
.INN,
.INNBC,
.INNBCL,
.INO,
.INP,
.INS,
.INSN,
.INST,
.INSTA,
.INSTANTXRP,
.INSTAR,
.INSTINCT,
.INSUR,
.INSURE,
.INT,
.INTER,
.INTL,
.INTO,
.INTR,
.INTRATIO,
.INTX,
.INU,
.INUB,
.INUBIS,
.INUGAMI,
.INUJUMP,
.INUS,
.INUYASHA,
.INV,
.INVC,
.INVE,
.INVERSE,
.INVEST,
.INVI,
.INVOX,
.INX,
.INXC,
.INXT,
.INZ,
.IO,
.IOC,
.IOEN,
.IOEX,
.IOG,
.IOI,
.ION,
.IONC,
.IONS,
.IONX,
.IOOX,
.IOSHIB,
.IOST,
.IOTE,
.IOTEXCHART,
.IOTF,
.IOTN,
.IOTW,
.IOTX,
.IOUX,
.IOV,
.iOWN,
.IPAD,
.IPAY,
.IPC,
.IPEGG,
.IPF,
.IPL,
.IPM,
.IPSX,
.IPT,
.IPX,
.IQ,
.IQC,
.IQCOIN,
.IQG,
.IQN,
.IQQ,
.IQT,
.IRA,
.IRD,
.IRENA,
.IRIS,
.IRL,
.IRON,
.IRONMAN,
.IRT,
.ISA,
.ISAL,
.ISDT,
.ISH,
.ISHND,
.ISIKC,
.ISKY,
.ISL,
.ISLA,
.ISLAINU,
.ISLAMI,
.ISLAND,
.ISLBYZ,
.ISLE,
.ISOLA,
.ISP,
.ISR,
.IST,
.ISTEP,
.IT,
.ITAM,
.ITAMCUBE,
.ITC,
.ITEN,
.ITFX,
.ITG,
.ITGR,
.ITH,
.ITHEUM,
.ITI,
.ITL,
.ITM,
.ITO,
.ITR,
.ITS,
.ITT,
.ITZ,
.IUP,
.IUSD,
.IUSDS,
.IUT,
.IUX,
.IV,
.IVBOL,
.IVC,
.IVG,
.IVN,
.IVO,
.IVORY,
.IVRY,
.IVY,
.IWAY,
.IWFT,
.IWR,
.IX,
.IXC,
.IXIR,
.IXMR,
.IXO,
.IXRP,
.IXS,
.IXT,
.IXTZ,
.IYF,
.IZA,
.IZE,
.IZER,
.IZI,
.IZUMI {
  display: inline-block;
  background: url('../img/tokens_logo_GtoEndI.png') no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  position: relative;
  top: 3px;
}

.G_O_A_T {
  width: 16px;
  height: 16px;
  background-position: -0 -0;
}

.G {
  width: 16px;
  height: 16px;
  background-position: -16px -0;
}

.G1X {
  width: 16px;
  height: 16px;
  background-position: -0 -16px;
}

.G2 {
  width: 16px;
  height: 16px;
  background-position: -16px -16px;
}

.G2N {
  width: 16px;
  height: 16px;
  background-position: -32px -0;
}

.G3CRV {
  width: 16px;
  height: 16px;
  background-position: -32px -16px;
}

.G9 {
  width: 16px;
  height: 16px;
  background-position: -0 -32px;
}

.G9TRO {
  width: 16px;
  height: 16px;
  background-position: -16px -32px;
}

.G50 {
  width: 16px;
  height: 16px;
  background-position: -32px -32px;
}

.G999 {
  width: 16px;
  height: 16px;
  background-position: -48px -0;
}

.G\$ {
  width: 16px;
  height: 16px;
  background-position: -48px -16px;
}

.GA {
  width: 16px;
  height: 16px;
  background-position: -48px -32px;
}

.GAAS {
  width: 16px;
  height: 16px;
  background-position: -0 -48px;
}

.GABECOIN {
  width: 16px;
  height: 16px;
  background-position: -16px -48px;
}

.GAC {
  width: 16px;
  height: 16px;
  background-position: -32px -48px;
}

.GAD {
  width: 16px;
  height: 16px;
  background-position: -48px -48px;
}

.GADOSHI {
  width: 16px;
  height: 16px;
  background-position: -64px -0;
}

.GAE {
  width: 16px;
  height: 16px;
  background-position: -64px -16px;
}

.GAFA {
  width: 16px;
  height: 16px;
  background-position: -64px -32px;
}

.GAFI {
  width: 16px;
  height: 16px;
  background-position: -64px -48px;
}

.GAIA {
  width: 16px;
  height: 16px;
  background-position: -0 -64px;
}

.GAIN {
  width: 16px;
  height: 16px;
  background-position: -16px -64px;
}

.GAINS {
  width: 16px;
  height: 16px;
  background-position: -32px -64px;
}

.GAINZ {
  width: 16px;
  height: 16px;
  background-position: -48px -64px;
}

.GAJ {
  width: 16px;
  height: 16px;
  background-position: -64px -64px;
}

.GAL {
  width: 16px;
  height: 16px;
  background-position: -80px -0;
}

.GALA {
  width: 16px;
  height: 16px;
  background-position: -80px -16px;
}

.GALABET {
  width: 16px;
  height: 16px;
  background-position: -80px -32px;
}

.GALAXY {
  width: 16px;
  height: 16px;
  background-position: -80px -48px;
}

.GALAXYDOGE {
  width: 16px;
  height: 16px;
  background-position: -80px -64px;
}

.GALE {
  width: 16px;
  height: 16px;
  background-position: -0 -80px;
}

.GALEON {
  width: 16px;
  height: 16px;
  background-position: -16px -80px;
}

.GALI {
  width: 16px;
  height: 16px;
  background-position: -32px -80px;
}

.GALO {
  width: 16px;
  height: 16px;
  background-position: -48px -80px;
}

.GALS {
  width: 16px;
  height: 16px;
  background-position: -64px -80px;
}

.GAM {
  width: 16px;
  height: 16px;
  background-position: -80px -80px;
}

.GAMBL {
  width: 16px;
  height: 16px;
  background-position: -96px -0;
}

.GAME {
  width: 16px;
  height: 16px;
  background-position: -96px -16px;
}

.GAME1 {
  width: 16px;
  height: 16px;
  background-position: -96px -32px;
}

.GAMEBOX {
  width: 16px;
  height: 16px;
  background-position: -96px -48px;
}

.GAMECRYPT {
  width: 16px;
  height: 16px;
  background-position: -96px -64px;
}

.GAMEFI {
  width: 16px;
  height: 16px;
  background-position: -96px -80px;
}

.GAMER {
  width: 16px;
  height: 16px;
  background-position: -0 -96px;
}

.GAMES {
  width: 16px;
  height: 16px;
  background-position: -16px -96px;
}

.GAMESAFE {
  width: 16px;
  height: 16px;
  background-position: -32px -96px;
}

.GAMI {
  width: 16px;
  height: 16px;
  background-position: -48px -96px;
}

.GAMINGDOGE {
  width: 16px;
  height: 16px;
  background-position: -64px -96px;
}

.GAMINGSHIBA {
  width: 16px;
  height: 16px;
  background-position: -80px -96px;
}

.GAMMA {
  width: 16px;
  height: 16px;
  background-position: -96px -96px;
}

.GAN {
  width: 16px;
  height: 16px;
  background-position: -112px -0;
}

.GANG {
  width: 16px;
  height: 16px;
  background-position: -112px -16px;
}

.GANJA {
  width: 16px;
  height: 16px;
  background-position: -112px -32px;
}

.GANTZ {
  width: 16px;
  height: 16px;
  background-position: -112px -48px;
}

.GAP {
  width: 16px;
  height: 16px;
  background-position: -112px -64px;
}

.GAPT {
  width: 16px;
  height: 16px;
  background-position: -112px -80px;
}

.GAR {
  width: 16px;
  height: 16px;
  background-position: -112px -96px;
}

.GARD {
  width: 16px;
  height: 16px;
  background-position: -0 -112px;
}

.GARFIELD {
  width: 16px;
  height: 16px;
  background-position: -16px -112px;
}

.GARI {
  width: 16px;
  height: 16px;
  background-position: -32px -112px;
}

.GARK {
  width: 16px;
  height: 16px;
  background-position: -48px -112px;
}

.GART {
  width: 16px;
  height: 16px;
  background-position: -64px -112px;
}

.GARUDA {
  width: 16px;
  height: 16px;
  background-position: -80px -112px;
}

.GAS {
  width: 16px;
  height: 16px;
  background-position: -96px -112px;
}

.GASG {
  width: 16px;
  height: 16px;
  background-position: -112px -112px;
}

.GASP {
  width: 16px;
  height: 16px;
  background-position: -128px -0;
}

.GAT {
  width: 16px;
  height: 16px;
  background-position: -128px -16px;
}

.GATE {
  width: 16px;
  height: 16px;
  background-position: -128px -32px;
}

.GATSBYINU {
  width: 16px;
  height: 16px;
  background-position: -128px -48px;
}

.GAU {
  width: 16px;
  height: 16px;
  background-position: -128px -64px;
}

.GAUF {
  width: 16px;
  height: 16px;
  background-position: -128px -80px;
}

.GAUR {
  width: 16px;
  height: 16px;
  background-position: -128px -96px;
}

.GAX {
  width: 16px;
  height: 16px;
  background-position: -128px -112px;
}

.GAXY {
  width: 16px;
  height: 16px;
  background-position: -0 -128px;
}

.GAZE {
  width: 16px;
  height: 16px;
  background-position: -16px -128px;
}

.Gazelle {
  width: 16px;
  height: 16px;
  background-position: -32px -128px;
}

.GB {
  width: 16px;
  height: 16px;
  background-position: -48px -128px;
}

.GBA {
  width: 16px;
  height: 16px;
  background-position: -64px -128px;
}

.GBAG {
  width: 16px;
  height: 16px;
  background-position: -80px -128px;
}

.GBC {
  width: 16px;
  height: 16px;
  background-position: -96px -128px;
}

.GBCR {
  width: 16px;
  height: 16px;
  background-position: -112px -128px;
}

.GBD {
  width: 16px;
  height: 16px;
  background-position: -128px -128px;
}

.GBERN {
  width: 16px;
  height: 16px;
  background-position: -144px -0;
}

.GBET {
  width: 16px;
  height: 16px;
  background-position: -144px -16px;
}

.GBEX {
  width: 16px;
  height: 16px;
  background-position: -144px -32px;
}

.GBG {
  width: 16px;
  height: 16px;
  background-position: -144px -48px;
}

.GBK {
  width: 16px;
  height: 16px;
  background-position: -144px -64px;
}

.GBLN {
  width: 16px;
  height: 16px;
  background-position: -144px -80px;
}

.GBOX {
  width: 16px;
  height: 16px;
  background-position: -144px -96px;
}

.GBP {
  width: 16px;
  height: 16px;
  background-position: -144px -112px;
}

.GBPU {
  width: 16px;
  height: 16px;
  background-position: -144px -128px;
}

.GBPX {
  width: 16px;
  height: 16px;
  background-position: -0 -144px;
}

.GBR {
  width: 16px;
  height: 16px;
  background-position: -16px -144px;
}

.GBT {
  width: 16px;
  height: 16px;
  background-position: -32px -144px;
}

.GBTC {
  width: 16px;
  height: 16px;
  background-position: -48px -144px;
}

.GBTS {
  width: 16px;
  height: 16px;
  background-position: -64px -144px;
}

.GBX {
  width: 16px;
  height: 16px;
  background-position: -80px -144px;
}

.GBYTE {
  width: 16px;
  height: 16px;
  background-position: -96px -144px;
}

.GC {
  width: 16px;
  height: 16px;
  background-position: -112px -144px;
}

.GCAKE {
  width: 16px;
  height: 16px;
  background-position: -128px -144px;
}

.GCAT {
  width: 16px;
  height: 16px;
  background-position: -144px -144px;
}

.GCC {
  width: 16px;
  height: 16px;
  background-position: -160px -0;
}

.GCM {
  width: 16px;
  height: 16px;
  background-position: -160px -16px;
}

.GCME {
  width: 16px;
  height: 16px;
  background-position: -160px -32px;
}

.GCN {
  width: 16px;
  height: 16px;
  background-position: -160px -48px;
}

.GCNX {
  width: 16px;
  height: 16px;
  background-position: -160px -64px;
}

.GCOIN {
  width: 16px;
  height: 16px;
  background-position: -160px -80px;
}

.GME {
  width: 16px;
  height: 16px;
  background-position: -160px -96px;
}

.GCPH {
  width: 16px;
  height: 16px;
  background-position: -160px -112px;
}

.GCR {
  width: 16px;
  height: 16px;
  background-position: -160px -128px;
}

.GCUSDC {
  width: 16px;
  height: 16px;
  background-position: -160px -144px;
}

.GCX {
  width: 16px;
  height: 16px;
  background-position: -0 -160px;
}

.GDAO {
  width: 16px;
  height: 16px;
  background-position: -16px -160px;
}

.GDC {
  width: 16px;
  height: 16px;
  background-position: -32px -160px;
}

.GDDY {
  width: 16px;
  height: 16px;
  background-position: -48px -160px;
}

.GDEFI {
  width: 16px;
  height: 16px;
  background-position: -64px -160px;
}

.GDILDO {
  width: 16px;
  height: 16px;
  background-position: -80px -160px;
}

.GDL {
  width: 16px;
  height: 16px;
  background-position: -96px -160px;
}

.GDM {
  width: 16px;
  height: 16px;
  background-position: -112px -160px;
}

.GDO {
  width: 16px;
  height: 16px;
  background-position: -128px -160px;
}

.GDOGE {
  width: 16px;
  height: 16px;
  background-position: -144px -160px;
}

.GDR {
  width: 16px;
  height: 16px;
  background-position: -160px -160px;
}

.GDT {
  width: 16px;
  height: 16px;
  background-position: -176px -0;
}

.GEAR {
  width: 16px;
  height: 16px;
  background-position: -176px -16px;
}

.GEC {
  width: 16px;
  height: 16px;
  background-position: -176px -32px;
}

.GEEQ {
  width: 16px;
  height: 16px;
  background-position: -176px -48px;
}

.GEFT {
  width: 16px;
  height: 16px;
  background-position: -176px -64px;
}

.GEIST {
  width: 16px;
  height: 16px;
  background-position: -176px -80px;
}

.GEL {
  width: 16px;
  height: 16px;
  background-position: -176px -96px;
}

.GEM {
  width: 16px;
  height: 16px;
  background-position: -176px -112px;
}

.GEMG {
  width: 16px;
  height: 16px;
  background-position: -176px -128px;
}

.GEMIT {
  width: 16px;
  height: 16px;
  background-position: -176px -144px;
}

.GEMMINE {
  width: 16px;
  height: 16px;
  background-position: -176px -160px;
}

.GEMS {
  width: 16px;
  height: 16px;
  background-position: -0 -176px;
}

.GEMZ {
  width: 16px;
  height: 16px;
  background-position: -16px -176px;
}

.GEN {
  width: 16px;
  height: 16px;
  background-position: -32px -176px;
}

.GENA {
  width: 16px;
  height: 16px;
  background-position: -48px -176px;
}

.GENART {
  width: 16px;
  height: 16px;
  background-position: -64px -176px;
}

.GENCAP {
  width: 16px;
  height: 16px;
  background-position: -80px -176px;
}

.GENE {
  width: 16px;
  height: 16px;
  background-position: -96px -176px;
}

.GENESIS {
  width: 16px;
  height: 16px;
  background-position: -112px -176px;
}

.GENGAR {
  width: 16px;
  height: 16px;
  background-position: -128px -176px;
}

.GENI {
  width: 16px;
  height: 16px;
  background-position: -144px -176px;
}

.GENIETOKEN {
  width: 16px;
  height: 16px;
  background-position: -160px -176px;
}

.GenIm {
  width: 16px;
  height: 16px;
  background-position: -176px -176px;
}

.GENIUS {
  width: 16px;
  height: 16px;
  background-position: -192px -0;
}

.GENIX {
  width: 16px;
  height: 16px;
  background-position: -192px -16px;
}

.GENK {
  width: 16px;
  height: 16px;
  background-position: -192px -32px;
}

.GENRE {
  width: 16px;
  height: 16px;
  background-position: -192px -48px;
}

.GENS {
  width: 16px;
  height: 16px;
  background-position: -192px -64px;
}

.GENSHIN {
  width: 16px;
  height: 16px;
  background-position: -192px -80px;
}

.GENT {
  width: 16px;
  height: 16px;
  background-position: -192px -96px;
}

.GENX {
  width: 16px;
  height: 16px;
  background-position: -192px -112px;
}

.GEO {
  width: 16px;
  height: 16px;
  background-position: -192px -128px;
}

.GEO\$ {
  width: 16px;
  height: 16px;
  background-position: -192px -144px;
}

.GEON {
  width: 16px;
  height: 16px;
  background-position: -192px -160px;
}

.GER {
  width: 16px;
  height: 16px;
  background-position: -192px -176px;
}

.GERA {
  width: 16px;
  height: 16px;
  background-position: -0 -192px;
}

.GERO {
  width: 16px;
  height: 16px;
  background-position: -16px -192px;
}

.GPAY {
  width: 16px;
  height: 16px;
  background-position: -32px -192px;
}

.GES {
  width: 16px;
  height: 16px;
  background-position: -48px -192px;
}

.GET {
  width: 16px;
  height: 16px;
  background-position: -64px -192px;
}

.GETH {
  width: 16px;
  height: 16px;
  background-position: -80px -192px;
}

.GETX {
  width: 16px;
  height: 16px;
  background-position: -96px -192px;
}

.GEX {
  width: 16px;
  height: 16px;
  background-position: -112px -192px;
}

.GF {
  width: 16px;
  height: 16px;
  background-position: -128px -192px;
}

.GFARM2 {
  width: 16px;
  height: 16px;
  background-position: -144px -192px;
}

.GFC {
  width: 16px;
  height: 16px;
  background-position: -160px -192px;
}

.GFCE {
  width: 16px;
  height: 16px;
  background-position: -176px -192px;
}

.GFCS {
  width: 16px;
  height: 16px;
  background-position: -192px -192px;
}

.GFI {
  width: 16px;
  height: 16px;
  background-position: -208px -0;
}

.GFloki {
  width: 16px;
  height: 16px;
  background-position: -208px -16px;
}

.GFN {
  width: 16px;
  height: 16px;
  background-position: -208px -32px;
}

.GFNC {
  width: 16px;
  height: 16px;
  background-position: -208px -48px;
}

.GFNCASH {
  width: 16px;
  height: 16px;
  background-position: -208px -64px;
}

.GFR {
  width: 16px;
  height: 16px;
  background-position: -208px -80px;
}

.GFS {
  width: 16px;
  height: 16px;
  background-position: -208px -96px;
}

.GFSHIB {
  width: 16px;
  height: 16px;
  background-position: -208px -112px;
}

.GFT {
  width: 16px;
  height: 16px;
  background-position: -208px -128px;
}

.GFUN {
  width: 16px;
  height: 16px;
  background-position: -208px -144px;
}

.GFX {
  width: 16px;
  height: 16px;
  background-position: -208px -160px;
}

.GG {
  width: 16px;
  height: 16px;
  background-position: -208px -176px;
}

.GGC {
  width: 16px;
  height: 16px;
  background-position: -208px -192px;
}

.GGG {
  width: 16px;
  height: 16px;
  background-position: -0 -208px;
}

.GGIVE {
  width: 16px;
  height: 16px;
  background-position: -16px -208px;
}

.GGM {
  width: 16px;
  height: 16px;
  background-position: -32px -208px;
}

.GGR {
  width: 16px;
  height: 16px;
  background-position: -48px -208px;
}

.GGT {
  width: 16px;
  height: 16px;
  background-position: -64px -208px;
}

.GGTK {
  width: 16px;
  height: 16px;
  background-position: -80px -208px;
}

.GGTKN {
  width: 16px;
  height: 16px;
  background-position: -96px -208px;
}

.GHC {
  width: 16px;
  height: 16px;
  background-position: -112px -208px;
}

.GHD {
  width: 16px;
  height: 16px;
  background-position: -128px -208px;
}

.GHO {
  width: 16px;
  height: 16px;
  background-position: -144px -208px;
}

.GHOST {
  width: 16px;
  height: 16px;
  background-position: -160px -208px;
}

.GHOSTBLADE {
  width: 16px;
  height: 16px;
  background-position: -176px -208px;
}

.GHOSTFACE {
  width: 16px;
  height: 16px;
  background-position: -192px -208px;
}

.GHR {
  width: 16px;
  height: 16px;
  background-position: -208px -208px;
}

.GHSP {
  width: 16px;
  height: 16px;
  background-position: -224px -0;
}

.GHST {
  width: 16px;
  height: 16px;
  background-position: -224px -16px;
}

.GHT {
  width: 16px;
  height: 16px;
  background-position: -224px -32px;
}

.GHUB {
  width: 16px;
  height: 16px;
  background-position: -224px -48px;
}

.GHX {
  width: 16px;
  height: 16px;
  background-position: -224px -64px;
}

.GIC {
  width: 16px;
  height: 16px;
  background-position: -224px -80px;
}

.GIFT {
  width: 16px;
  height: 16px;
  background-position: -224px -96px;
}

.GIG {
  width: 16px;
  height: 16px;
  background-position: -224px -112px;
}

.GIGA {
  width: 16px;
  height: 16px;
  background-position: -224px -128px;
}

.GIL {
  width: 16px;
  height: 16px;
  background-position: -224px -144px;
}

.GIN {
  width: 16px;
  height: 16px;
  background-position: -224px -160px;
}

.GINOA {
  width: 16px;
  height: 16px;
  background-position: -224px -176px;
}

.GRIM {
  width: 16px;
  height: 16px;
  background-position: -224px -192px;
}

.GINSPIRIT {
  width: 16px;
  height: 16px;
  background-position: -224px -208px;
}

.GINU {
  width: 16px;
  height: 16px;
  background-position: -0 -224px;
}

.GINUX {
  width: 16px;
  height: 16px;
  background-position: -16px -224px;
}

.GINZA {
  width: 16px;
  height: 16px;
  background-position: -32px -224px;
}

.GIO {
  width: 16px;
  height: 16px;
  background-position: -48px -224px;
}

.GIT {
  width: 16px;
  height: 16px;
  background-position: -64px -224px;
}

.GIV {
  width: 16px;
  height: 16px;
  background-position: -80px -224px;
}

.GIVE {
  width: 16px;
  height: 16px;
  background-position: -96px -224px;
}

.GIVING {
  width: 16px;
  height: 16px;
  background-position: -112px -224px;
}

.GIZA {
  width: 16px;
  height: 16px;
  background-position: -128px -224px;
}

.GKCAKE {
  width: 16px;
  height: 16px;
  background-position: -144px -224px;
}

.GKF {
  width: 16px;
  height: 16px;
  background-position: -160px -224px;
}

.gKIMCHI {
  width: 16px;
  height: 16px;
  background-position: -176px -224px;
}

.GL {
  width: 16px;
  height: 16px;
  background-position: -192px -224px;
}

.GLA {
  width: 16px;
  height: 16px;
  background-position: -208px -224px;
}

.GLAD {
  width: 16px;
  height: 16px;
  background-position: -224px -224px;
}

.GLASS {
  width: 16px;
  height: 16px;
  background-position: -240px -0;
}

.GLB {
  width: 16px;
  height: 16px;
  background-position: -240px -16px;
}

.GLBD {
  width: 16px;
  height: 16px;
  background-position: -240px -32px;
}

.GLC {
  width: 16px;
  height: 16px;
  background-position: -240px -48px;
}

.GLCH {
  width: 16px;
  height: 16px;
  background-position: -240px -64px;
}

.GLD {
  width: 16px;
  height: 16px;
  background-position: -240px -80px;
}

.GLDR {
  width: 16px;
  height: 16px;
  background-position: -240px -96px;
}

.GLDX {
  width: 16px;
  height: 16px;
  background-position: -240px -112px;
}

.GLDY {
  width: 16px;
  height: 16px;
  background-position: -240px -128px;
}

.GLDZ {
  width: 16px;
  height: 16px;
  background-position: -240px -144px;
}

.GLE {
  width: 16px;
  height: 16px;
  background-position: -240px -160px;
}

.GLEEC {
  width: 16px;
  height: 16px;
  background-position: -240px -176px;
}

.GLEX {
  width: 16px;
  height: 16px;
  background-position: -240px -192px;
}

.GLF {
  width: 16px;
  height: 16px;
  background-position: -240px -208px;
}

.GLIDE {
  width: 16px;
  height: 16px;
  background-position: -240px -224px;
}

.GLINK {
  width: 16px;
  height: 16px;
  background-position: -0 -240px;
}

.GLINT {
  width: 16px;
  height: 16px;
  background-position: -16px -240px;
}

.GLM {
  width: 16px;
  height: 16px;
  background-position: -32px -240px;
}

.GLMR {
  width: 16px;
  height: 16px;
  background-position: -48px -240px;
}

.GLMS {
  width: 16px;
  height: 16px;
  background-position: -64px -240px;
}

.GLO {
  width: 16px;
  height: 16px;
  background-position: -80px -240px;
}

.GLOB {
  width: 16px;
  height: 16px;
  background-position: -96px -240px;
}

.GLORY {
  width: 16px;
  height: 16px;
  background-position: -112px -240px;
}

.GLORYD {
  width: 16px;
  height: 16px;
  background-position: -128px -240px;
}

.GLOW {
  width: 16px;
  height: 16px;
  background-position: -144px -240px;
}

.GlowV2 {
  width: 16px;
  height: 16px;
  background-position: -160px -240px;
}

.GLOX {
  width: 16px;
  height: 16px;
  background-position: -176px -240px;
}

.GLQ {
  width: 16px;
  height: 16px;
  background-position: -192px -240px;
}

.GLS {
  width: 16px;
  height: 16px;
  background-position: -208px -240px;
}

.GLT {
  width: 16px;
  height: 16px;
  background-position: -224px -240px;
}

.GLTO {
  width: 16px;
  height: 16px;
  background-position: -240px -240px;
}

.GLV {
  width: 16px;
  height: 16px;
  background-position: -256px -0;
}

.GLX {
  width: 16px;
  height: 16px;
  background-position: -256px -16px;
}

.GLXM {
  width: 16px;
  height: 16px;
  background-position: -256px -32px;
}

.GLXY {
  width: 16px;
  height: 16px;
  background-position: -256px -48px;
}

.GLY {
  width: 16px;
  height: 16px;
  background-position: -256px -64px;
}

.GLYPH {
  width: 16px;
  height: 16px;
  background-position: -256px -80px;
}

.GM {
  width: 16px;
  height: 16px;
  background-position: -256px -96px;
}

.GMAT {
  width: 16px;
  height: 16px;
  background-position: -256px -112px;
}

.GMB {
  width: 16px;
  height: 16px;
  background-position: -256px -128px;
}

.GMBC {
  width: 16px;
  height: 16px;
  background-position: -256px -144px;
}

.GMC {
  width: 16px;
  height: 16px;
  background-position: -256px -160px;
}

.GMCN {
  width: 16px;
  height: 16px;
  background-position: -256px -176px;
}

.GMCOIN {
  width: 16px;
  height: 16px;
  background-position: -256px -192px;
}

.GMD {
  width: 16px;
  height: 16px;
  background-position: -256px -208px;
}

.GMEE {
  width: 16px;
  height: 16px;
  background-position: -256px -224px;
}

.gMELD {
  width: 16px;
  height: 16px;
  background-position: -256px -240px;
}

.GMETA {
  width: 16px;
  height: 16px;
  background-position: -0 -256px;
}

.GMEX {
  width: 16px;
  height: 16px;
  background-position: -16px -256px;
}

.GMFLOKI {
  width: 16px;
  height: 16px;
  background-position: -32px -256px;
}

.GMI {
  width: 16px;
  height: 16px;
  background-position: -48px -256px;
}

.GMINES {
  width: 16px;
  height: 16px;
  background-position: -64px -256px;
}

.GMINU {
  width: 16px;
  height: 16px;
  background-position: -80px -256px;
}

.GMM {
  width: 16px;
  height: 16px;
  background-position: -96px -256px;
}

.GMNG {
  width: 16px;
  height: 16px;
  background-position: -112px -256px;
}

.GMPD {
  width: 16px;
  height: 16px;
  background-position: -128px -256px;
}

.GMR {
  width: 16px;
  height: 16px;
  background-position: -144px -256px;
}

.GMS {
  width: 16px;
  height: 16px;
  background-position: -160px -256px;
}

.GMSOL {
  width: 16px;
  height: 16px;
  background-position: -176px -256px;
}

.GMT {
  width: 16px;
  height: 16px;
  background-position: -192px -256px;
}

.GMV {
  width: 16px;
  height: 16px;
  background-position: -208px -256px;
}

.GMX {
  width: 16px;
  height: 16px;
  background-position: -224px -256px;
}

.GMYX {
  width: 16px;
  height: 16px;
  background-position: -240px -256px;
}

.GMZ {
  width: 16px;
  height: 16px;
  background-position: -256px -256px;
}

.GN {
  width: 16px;
  height: 16px;
  background-position: -272px -0;
}

.GNAR {
  width: 16px;
  height: 16px;
  background-position: -272px -16px;
}

.GNBT {
  width: 16px;
  height: 16px;
  background-position: -272px -32px;
}

.GNBU {
  width: 16px;
  height: 16px;
  background-position: -272px -48px;
}

.GNC {
  width: 16px;
  height: 16px;
  background-position: -272px -64px;
}

.GNFT {
  width: 16px;
  height: 16px;
  background-position: -272px -80px;
}

.GNL {
  width: 16px;
  height: 16px;
  background-position: -272px -96px;
}

.GNLR {
  width: 16px;
  height: 16px;
  background-position: -272px -112px;
}

.GNNX {
  width: 16px;
  height: 16px;
  background-position: -272px -128px;
}

.GNO {
  width: 16px;
  height: 16px;
  background-position: -272px -144px;
}

.GNOME {
  width: 16px;
  height: 16px;
  background-position: -272px -160px;
}

.GNP {
  width: 16px;
  height: 16px;
  background-position: -272px -176px;
}

.GNS {
  width: 16px;
  height: 16px;
  background-position: -272px -192px;
}

.GNSH {
  width: 16px;
  height: 16px;
  background-position: -272px -208px;
}

.GNT {
  width: 16px;
  height: 16px;
  background-position: -272px -224px;
}

.GNTL {
  width: 16px;
  height: 16px;
  background-position: -272px -240px;
}

.GNTO {
  width: 16px;
  height: 16px;
  background-position: -272px -256px;
}

.GNX {
  width: 16px;
  height: 16px;
  background-position: -0 -272px;
}

.GNY {
  width: 16px;
  height: 16px;
  background-position: -16px -272px;
}

.GO {
  width: 16px;
  height: 16px;
  background-position: -32px -272px;
}

.GOAL {
  width: 16px;
  height: 16px;
  background-position: -48px -272px;
}

.GOALGO {
  width: 16px;
  height: 16px;
  background-position: -64px -272px;
}

.GOAT {
  width: 16px;
  height: 16px;
  background-position: -80px -272px;
}

.GOATM {
  width: 16px;
  height: 16px;
  background-position: -96px -272px;
}

.GOB {
  width: 16px;
  height: 16px;
  background-position: -112px -272px;
}

.GOBBLE {
  width: 16px;
  height: 16px;
  background-position: -128px -272px;
}

.GOBI {
  width: 16px;
  height: 16px;
  background-position: -144px -272px;
}

.GOBLIN {
  width: 16px;
  height: 16px;
  background-position: -160px -272px;
}

.GOC {
  width: 16px;
  height: 16px;
  background-position: -176px -272px;
}

.GOD {
  width: 16px;
  height: 16px;
  background-position: -192px -272px;
}

.GODE {
  width: 16px;
  height: 16px;
  background-position: -208px -272px;
}

.GODL {
  width: 16px;
  height: 16px;
  background-position: -224px -272px;
}

.GODS {
  width: 16px;
  height: 16px;
  background-position: -240px -272px;
}

.GODV {
  width: 16px;
  height: 16px;
  background-position: -256px -272px;
}

.GODZ {
  width: 16px;
  height: 16px;
  background-position: -272px -272px;
}

.GOF {
  width: 16px;
  height: 16px;
  background-position: -288px -0;
}

.GOFI {
  width: 16px;
  height: 16px;
  background-position: -288px -16px;
}

.GOFX {
  width: 16px;
  height: 16px;
  background-position: -288px -32px;
}

.GOG {
  width: 16px;
  height: 16px;
  background-position: -288px -48px;
}

.GOGE {
  width: 16px;
  height: 16px;
  background-position: -288px -64px;
}

.GOGETA {
  width: 16px;
  height: 16px;
  background-position: -288px -80px;
}

.GOGO {
  width: 16px;
  height: 16px;
  background-position: -288px -96px;
}

.GOHM {
  width: 16px;
  height: 16px;
  background-position: -288px -112px;
}

.GOIN {
  width: 16px;
  height: 16px;
  background-position: -288px -128px;
}

.GOJO {
  width: 16px;
  height: 16px;
  background-position: -288px -144px;
}

.GOKEY {
  width: 16px;
  height: 16px;
  background-position: -288px -160px;
}

.GOKU {
  width: 16px;
  height: 16px;
  background-position: -288px -176px;
}

.GOL {
  width: 16px;
  height: 16px;
  background-position: -288px -192px;
}

.GOLD {
  width: 16px;
  height: 16px;
  background-position: -288px -208px;
}

.GOLD_NUGGET {
  width: 16px;
  height: 16px;
  background-position: -288px -224px;
}

.GOLDR {
  width: 16px;
  height: 16px;
  background-position: -288px -240px;
}

.GOLDUCK {
  width: 16px;
  height: 16px;
  background-position: -288px -256px;
}

.GOLDX {
  width: 16px;
  height: 16px;
  background-position: -288px -272px;
}

.GOLDY {
  width: 16px;
  height: 16px;
  background-position: -0 -288px;
}

.GOLDZ {
  width: 16px;
  height: 16px;
  background-position: -16px -288px;
}

.GOLF {
  width: 16px;
  height: 16px;
  background-position: -32px -288px;
}

.GOLOS {
  width: 16px;
  height: 16px;
  background-position: -48px -288px;
}

.GOM {
  width: 16px;
  height: 16px;
  background-position: -64px -288px;
}

.GOM2 {
  width: 16px;
  height: 16px;
  background-position: -80px -288px;
}

.GOMA {
  width: 16px;
  height: 16px;
  background-position: -96px -288px;
}

.GOMAX {
  width: 16px;
  height: 16px;
  background-position: -112px -288px;
}

.GOMB {
  width: 16px;
  height: 16px;
  background-position: -128px -288px;
}

.GOMINT {
  width: 16px;
  height: 16px;
  background-position: -144px -288px;
}

.GOMT {
  width: 16px;
  height: 16px;
  background-position: -160px -288px;
}

.GON {
  width: 16px;
  height: 16px;
  background-position: -176px -288px;
}

.GON\+ {
  width: 16px;
  height: 16px;
  background-position: -192px -288px;
}

.GOO {
  width: 16px;
  height: 16px;
  background-position: -208px -288px;
}

.GOOD {
  width: 16px;
  height: 16px;
  background-position: -224px -288px;
}

.GoofyDoge {
  width: 16px;
  height: 16px;
  background-position: -240px -288px;
}

.GOOGL {
  width: 16px;
  height: 16px;
  background-position: -256px -288px;
}

.GOOREO {
  width: 16px;
  height: 16px;
  background-position: -272px -288px;
}

.GORGEOUS {
  width: 16px;
  height: 16px;
  background-position: -288px -288px;
}

.GORILLAINU {
  width: 16px;
  height: 16px;
  background-position: -304px -0;
}

.GOS {
  width: 16px;
  height: 16px;
  background-position: -304px -16px;
}

.GOSH {
  width: 16px;
  height: 16px;
  background-position: -304px -32px;
}

.GOSS {
  width: 16px;
  height: 16px;
  background-position: -304px -48px;
}

.GOT {
  width: 16px;
  height: 16px;
  background-position: -304px -64px;
}

.GOTEM {
  width: 16px;
  height: 16px;
  background-position: -304px -80px;
}

.GOTG {
  width: 16px;
  height: 16px;
  background-position: -304px -96px;
}

.GOUKI {
  width: 16px;
  height: 16px;
  background-position: -304px -112px;
}

.GOV {
  width: 16px;
  height: 16px;
  background-position: -304px -128px;
}

.GOVI {
  width: 16px;
  height: 16px;
  background-position: -304px -144px;
}

.GOXT {
  width: 16px;
  height: 16px;
  background-position: -304px -160px;
}

.GOZ {
  width: 16px;
  height: 16px;
  background-position: -304px -176px;
}

.GP {
  width: 16px;
  height: 16px;
  background-position: -304px -192px;
}

.GPAD {
  width: 16px;
  height: 16px;
  background-position: -304px -208px;
}

.GPCX {
  width: 16px;
  height: 16px;
  background-position: -304px -224px;
}

.GPKR {
  width: 16px;
  height: 16px;
  background-position: -304px -240px;
}

.GPO {
  width: 16px;
  height: 16px;
  background-position: -304px -256px;
}

.GPOOL {
  width: 16px;
  height: 16px;
  background-position: -304px -272px;
}

.GPT {
  width: 16px;
  height: 16px;
  background-position: -304px -288px;
}

.GPX {
  width: 16px;
  height: 16px;
  background-position: -0 -304px;
}

.GPYX {
  width: 16px;
  height: 16px;
  background-position: -16px -304px;
}

.GQ {
  width: 16px;
  height: 16px;
  background-position: -32px -304px;
}

.GR {
  width: 16px;
  height: 16px;
  background-position: -48px -304px;
}

.GRAIL {
  width: 16px;
  height: 16px;
  background-position: -64px -304px;
}

.GRAIN {
  width: 16px;
  height: 16px;
  background-position: -80px -304px;
}

.GRAM {
  width: 16px;
  height: 16px;
  background-position: -96px -304px;
}

.GRAND {
  width: 16px;
  height: 16px;
  background-position: -112px -304px;
}

.GRANX {
  width: 16px;
  height: 16px;
  background-position: -128px -304px;
}

.GRAP {
  width: 16px;
  height: 16px;
  background-position: -144px -304px;
}

.GRAPE {
  width: 16px;
  height: 16px;
  background-position: -160px -304px;
}

.GRAPH {
  width: 16px;
  height: 16px;
  background-position: -176px -304px;
}

.GRAT {
  width: 16px;
  height: 16px;
  background-position: -192px -304px;
}

.GRAV {
  width: 16px;
  height: 16px;
  background-position: -208px -304px;
}

.GRBE {
  width: 16px;
  height: 16px;
  background-position: -224px -304px;
}

.GRBT {
  width: 16px;
  height: 16px;
  background-position: -240px -304px;
}

.GRC {
  width: 16px;
  height: 16px;
  background-position: -256px -304px;
}

.GRD {
  width: 16px;
  height: 16px;
  background-position: -272px -304px;
}

.GREED {
  width: 16px;
  height: 16px;
  background-position: -288px -304px;
}

.GREEN {
  width: 16px;
  height: 16px;
  background-position: -304px -304px;
}

.GREENBIOX {
  width: 16px;
  height: 16px;
  background-position: -320px -0;
}

.GREENFLOKI {
  width: 16px;
  height: 16px;
  background-position: -320px -16px;
}

.GREENFUEL {
  width: 16px;
  height: 16px;
  background-position: -320px -32px;
}

.GREM {
  width: 16px;
  height: 16px;
  background-position: -320px -48px;
}

.GREY {
  width: 16px;
  height: 16px;
  background-position: -320px -64px;
}

.GREYHOUND {
  width: 16px;
  height: 16px;
  background-position: -320px -80px;
}

.GRFT {
  width: 16px;
  height: 16px;
  background-position: -320px -96px;
}

.GRG {
  width: 16px;
  height: 16px;
  background-position: -320px -112px;
}

.GRID {
  width: 16px;
  height: 16px;
  background-position: -320px -128px;
}

.GRILL {
  width: 16px;
  height: 16px;
  background-position: -320px -144px;
}

.GRILLZ {
  width: 16px;
  height: 16px;
  background-position: -320px -160px;
}

.Grimace {
  width: 16px;
  height: 16px;
  background-position: -320px -176px;
}

.GRIMACE {
  width: 16px;
  height: 16px;
  background-position: -320px -192px;
}

.GRIME {
  width: 16px;
  height: 16px;
  background-position: -320px -208px;
}

.GRIM_EVO {
  width: 16px;
  height: 16px;
  background-position: -320px -224px;
}

.GRIMEX {
  width: 16px;
  height: 16px;
  background-position: -320px -240px;
}

.GRIMM {
  width: 16px;
  height: 16px;
  background-position: -320px -256px;
}

.GRIN {
  width: 16px;
  height: 16px;
  background-position: -320px -272px;
}

.GRLC {
  width: 16px;
  height: 16px;
  background-position: -320px -288px;
}

.GRM {
  width: 16px;
  height: 16px;
  background-position: -320px -304px;
}

.GRMD {
  width: 16px;
  height: 16px;
  background-position: -0 -320px;
}

.GRMZilla {
  width: 16px;
  height: 16px;
  background-position: -16px -320px;
}

.GRN {
  width: 16px;
  height: 16px;
  background-position: -32px -320px;
}

.GRO {
  width: 16px;
  height: 16px;
  background-position: -48px -320px;
}

.GROK {
  width: 16px;
  height: 16px;
  background-position: -64px -320px;
}

.GROW {
  width: 16px;
  height: 16px;
  background-position: -80px -320px;
}

.GRP {
  width: 16px;
  height: 16px;
  background-position: -96px -320px;
}

.GRPH {
  width: 16px;
  height: 16px;
  background-position: -112px -320px;
}

.GRPHT {
  width: 16px;
  height: 16px;
  background-position: -128px -320px;
}

.GRPL {
  width: 16px;
  height: 16px;
  background-position: -144px -320px;
}

.GRS {
  width: 16px;
  height: 16px;
  background-position: -160px -320px;
}

.GRT {
  width: 16px;
  height: 16px;
  background-position: -176px -320px;
}

.GRTC {
  width: 16px;
  height: 16px;
  background-position: -192px -320px;
}

.GRUG {
  width: 16px;
  height: 16px;
  background-position: -208px -320px;
}

.GRUMPY {
  width: 16px;
  height: 16px;
  background-position: -224px -320px;
}

.GRUSH {
  width: 16px;
  height: 16px;
  background-position: -240px -320px;
}

.GRV {
  width: 16px;
  height: 16px;
  background-position: -256px -320px;
}

.GRVE {
  width: 16px;
  height: 16px;
  background-position: -272px -320px;
}

.GRVS {
  width: 16px;
  height: 16px;
  background-position: -288px -320px;
}

.GRVX {
  width: 16px;
  height: 16px;
  background-position: -304px -320px;
}

.GRX {
  width: 16px;
  height: 16px;
  background-position: -320px -320px;
}

.GS {
  width: 16px;
  height: 16px;
  background-position: -336px -0;
}

.GS1 {
  width: 16px;
  height: 16px;
  background-position: -336px -16px;
}

.GSA {
  width: 16px;
  height: 16px;
  background-position: -336px -32px;
}

.GSAIL {
  width: 16px;
  height: 16px;
  background-position: -336px -48px;
}

.GSC {
  width: 16px;
  height: 16px;
  background-position: -336px -64px;
}

.GSCARAB {
  width: 16px;
  height: 16px;
  background-position: -336px -80px;
}

.GSE {
  width: 16px;
  height: 16px;
  background-position: -336px -96px;
}

.GSG {
  width: 16px;
  height: 16px;
  background-position: -336px -112px;
}

.GSHARE {
  width: 16px;
  height: 16px;
  background-position: -336px -128px;
}

.GSHIB {
  width: 16px;
  height: 16px;
  background-position: -336px -144px;
}

.GSHIBA {
  width: 16px;
  height: 16px;
  background-position: -336px -160px;
}

.GSK {
  width: 16px;
  height: 16px;
  background-position: -336px -176px;
}

.GSKY {
  width: 16px;
  height: 16px;
  background-position: -336px -192px;
}

.GSMT {
  width: 16px;
  height: 16px;
  background-position: -336px -208px;
}

.GSONIC {
  width: 16px;
  height: 16px;
  background-position: -336px -224px;
}

.GSPI {
  width: 16px;
  height: 16px;
  background-position: -336px -240px;
}

.GSR {
  width: 16px;
  height: 16px;
  background-position: -336px -256px;
}

.GSSIT {
  width: 16px;
  height: 16px;
  background-position: -336px -272px;
}

.GST {
  width: 16px;
  height: 16px;
  background-position: -336px -288px;
}

.GST2 {
  width: 16px;
  height: 16px;
  background-position: -336px -304px;
}

.GSTRM {
  width: 16px;
  height: 16px;
  background-position: -336px -320px;
}

.GSTS {
  width: 16px;
  height: 16px;
  background-position: -0 -336px;
}

.GSWAP {
  width: 16px;
  height: 16px;
  background-position: -16px -336px;
}

.GSX {
  width: 16px;
  height: 16px;
  background-position: -32px -336px;
}

.GT {
  width: 16px;
  height: 16px;
  background-position: -48px -336px;
}

.GTC {
  width: 16px;
  height: 16px;
  background-position: -64px -336px;
}

.GTCOIN {
  width: 16px;
  height: 16px;
  background-position: -80px -336px;
}

.GTE {
  width: 16px;
  height: 16px;
  background-position: -96px -336px;
}

.GTF {
  width: 16px;
  height: 16px;
  background-position: -112px -336px;
}

.GTFO {
  width: 16px;
  height: 16px;
  background-position: -128px -336px;
}

.GTH {
  width: 16px;
  height: 16px;
  background-position: -144px -336px;
}

.GTM {
  width: 16px;
  height: 16px;
  background-position: -160px -336px;
}

.GTN {
  width: 16px;
  height: 16px;
  background-position: -176px -336px;
}

.GTO {
  width: 16px;
  height: 16px;
  background-position: -192px -336px;
}

.GTON {
  width: 16px;
  height: 16px;
  background-position: -208px -336px;
}

.GTP {
  width: 16px;
  height: 16px;
  background-position: -224px -336px;
}

.GTPLUS {
  width: 16px;
  height: 16px;
  background-position: -240px -336px;
}

.GTR {
  width: 16px;
  height: 16px;
  background-position: -256px -336px;
}

.GTS {
  width: 16px;
  height: 16px;
  background-position: -272px -336px;
}

.GTX {
  width: 16px;
  height: 16px;
  background-position: -288px -336px;
}

.GUAP {
  width: 16px;
  height: 16px;
  background-position: -304px -336px;
}

.GUARD {
  width: 16px;
  height: 16px;
  background-position: -320px -336px;
}

.GucciV2 {
  width: 16px;
  height: 16px;
  background-position: -336px -336px;
}

.GUESS {
  width: 16px;
  height: 16px;
  background-position: -352px -0;
}

.GUH {
  width: 16px;
  height: 16px;
  background-position: -352px -16px;
}

.GUILD {
  width: 16px;
  height: 16px;
  background-position: -352px -32px;
}

.GULF {
  width: 16px;
  height: 16px;
  background-position: -352px -48px;
}

.GULL {
  width: 16px;
  height: 16px;
  background-position: -352px -64px;
}

.GUM {
  width: 16px;
  height: 16px;
  background-position: -352px -80px;
}

.GUMMIE {
  width: 16px;
  height: 16px;
  background-position: -352px -96px;
}

.GUMMY {
  width: 16px;
  height: 16px;
  background-position: -352px -112px;
}

.GUMMYS {
  width: 16px;
  height: 16px;
  background-position: -352px -128px;
}

.Gunclear {
  width: 16px;
  height: 16px;
  background-position: -352px -144px;
}

.GUNS {
  width: 16px;
  height: 16px;
  background-position: -352px -160px;
}

.GUNTHY {
  width: 16px;
  height: 16px;
  background-position: -352px -176px;
}

.GUP {
  width: 16px;
  height: 16px;
  background-position: -352px -192px;
}

.GURU {
  width: 16px;
  height: 16px;
  background-position: -352px -208px;
}

.GUSD {
  width: 16px;
  height: 16px;
  background-position: -352px -224px;
}

.GUSDT {
  width: 16px;
  height: 16px;
  background-position: -352px -240px;
}

.GUT {
  width: 16px;
  height: 16px;
  background-position: -352px -256px;
}

.GVC {
  width: 16px;
  height: 16px;
  background-position: -352px -272px;
}

.GVE {
  width: 16px;
  height: 16px;
  background-position: -352px -288px;
}

.GVR {
  width: 16px;
  height: 16px;
  background-position: -352px -304px;
}

.GVT {
  width: 16px;
  height: 16px;
  background-position: -352px -320px;
}

.GVY {
  width: 16px;
  height: 16px;
  background-position: -352px -336px;
}

.GW {
  width: 16px;
  height: 16px;
  background-position: -0 -352px;
}

.GWAR {
  width: 16px;
  height: 16px;
  background-position: -16px -352px;
}

.GWG {
  width: 16px;
  height: 16px;
  background-position: -32px -352px;
}

.GWP {
  width: 16px;
  height: 16px;
  background-position: -48px -352px;
}

.GWS {
  width: 16px;
  height: 16px;
  background-position: -64px -352px;
}

.GWSPP {
  width: 16px;
  height: 16px;
  background-position: -80px -352px;
}

.GWT {
  width: 16px;
  height: 16px;
  background-position: -96px -352px;
}

.GXC {
  width: 16px;
  height: 16px;
  background-position: -112px -352px;
}

.GXE {
  width: 16px;
  height: 16px;
  background-position: -128px -352px;
}

.GXP {
  width: 16px;
  height: 16px;
  background-position: -144px -352px;
}

.GXPAD {
  width: 16px;
  height: 16px;
  background-position: -160px -352px;
}

.GXT {
  width: 16px;
  height: 16px;
  background-position: -176px -352px;
}

.GXX {
  width: 16px;
  height: 16px;
  background-position: -192px -352px;
}

.GY {
  width: 16px;
  height: 16px;
  background-position: -208px -352px;
}

.GYA {
  width: 16px;
  height: 16px;
  background-position: -224px -352px;
}

.GYC {
  width: 16px;
  height: 16px;
  background-position: -240px -352px;
}

.GYEN {
  width: 16px;
  height: 16px;
  background-position: -256px -352px;
}

.GYFI {
  width: 16px;
  height: 16px;
  background-position: -272px -352px;
}

.GYM {
  width: 16px;
  height: 16px;
  background-position: -288px -352px;
}

.GYMNET {
  width: 16px;
  height: 16px;
  background-position: -304px -352px;
}

.GYRO {
  width: 16px;
  height: 16px;
  background-position: -320px -352px;
}

.GYSR {
  width: 16px;
  height: 16px;
  background-position: -336px -352px;
}

.GZE {
  width: 16px;
  height: 16px;
  background-position: -352px -352px;
}

.GZIL {
  width: 16px;
  height: 16px;
  background-position: -368px -0;
}

.GZILA {
  width: 16px;
  height: 16px;
  background-position: -368px -16px;
}

.GZLR {
  width: 16px;
  height: 16px;
  background-position: -368px -32px;
}

.GZM {
  width: 16px;
  height: 16px;
  background-position: -368px -48px;
}

.GZONE {
  width: 16px;
  height: 16px;
  background-position: -368px -64px;
}

.GZRO {
  width: 16px;
  height: 16px;
  background-position: -368px -80px;
}

.GZX {
  width: 16px;
  height: 16px;
  background-position: -368px -96px;
}

.H2E {
  width: 16px;
  height: 16px;
  background-position: -368px -112px;
}

.H2O {
  width: 16px;
  height: 16px;
  background-position: -368px -128px;
}

.H2OC {
  width: 16px;
  height: 16px;
  background-position: -368px -144px;
}

.H3RO3S {
  width: 16px;
  height: 16px;
  background-position: -368px -160px;
}

.H4G {
  width: 16px;
  height: 16px;
  background-position: -368px -176px;
}

.HACHIKO {
  width: 16px;
  height: 16px;
  background-position: -368px -192px;
}

.HADA {
  width: 16px;
  height: 16px;
  background-position: -368px -208px;
}

.HADES {
  width: 16px;
  height: 16px;
  background-position: -368px -224px;
}

.HAG {
  width: 16px;
  height: 16px;
  background-position: -368px -240px;
}

.HAI {
  width: 16px;
  height: 16px;
  background-position: -368px -256px;
}

.HAK {
  width: 16px;
  height: 16px;
  background-position: -368px -272px;
}

.HAKA {
  width: 16px;
  height: 16px;
  background-position: -368px -288px;
}

.HAKKA {
  width: 16px;
  height: 16px;
  background-position: -368px -304px;
}

.HAKU {
  width: 16px;
  height: 16px;
  background-position: -368px -320px;
}

.HALF {
  width: 16px;
  height: 16px;
  background-position: -368px -336px;
}

.HALFSHIT {
  width: 16px;
  height: 16px;
  background-position: -368px -352px;
}

.HTHEDGE {
  width: 16px;
  height: 16px;
  background-position: -0 -368px;
}

.HTHALF {
  width: 16px;
  height: 16px;
  background-position: -16px -368px;
}

.HALLO {
  width: 16px;
  height: 16px;
  background-position: -32px -368px;
}

.HALO {
  width: 16px;
  height: 16px;
  background-position: -48px -368px;
}

.HALV {
  width: 16px;
  height: 16px;
  background-position: -64px -368px;
}

.HAM {
  width: 16px;
  height: 16px;
  background-position: -80px -368px;
}

.HAMS {
  width: 16px;
  height: 16px;
  background-position: -96px -368px;
}

.HAMTARO {
  width: 16px;
  height: 16px;
  background-position: -112px -368px;
}

.HANA {
  width: 16px;
  height: 16px;
  background-position: -128px -368px;
}

.HAND {
  width: 16px;
  height: 16px;
  background-position: -144px -368px;
}

.HANDY {
  width: 16px;
  height: 16px;
  background-position: -160px -368px;
}

.HANGRY {
  width: 16px;
  height: 16px;
  background-position: -176px -368px;
}

.HANU {
  width: 16px;
  height: 16px;
  background-position: -192px -368px;
}

.HANZO {
  width: 16px;
  height: 16px;
  background-position: -208px -368px;
}

.HAPI {
  width: 16px;
  height: 16px;
  background-position: -224px -368px;
}

.HAPPY {
  width: 16px;
  height: 16px;
  background-position: -240px -368px;
}

.HAPY {
  width: 16px;
  height: 16px;
  background-position: -256px -368px;
}

.HARAMBE {
  width: 16px;
  height: 16px;
  background-position: -272px -368px;
}

.HARD {
  width: 16px;
  height: 16px;
  background-position: -288px -368px;
}

.HARE {
  width: 16px;
  height: 16px;
  background-position: -304px -368px;
}

.HARE_PLUS {
  width: 16px;
  height: 16px;
  background-position: -320px -368px;
}

.HARL {
  width: 16px;
  height: 16px;
  background-position: -336px -368px;
}

.HARON {
  width: 16px;
  height: 16px;
  background-position: -352px -368px;
}

.HARPY {
  width: 16px;
  height: 16px;
  background-position: -368px -368px;
}

.HARUNO {
  width: 16px;
  height: 16px;
  background-position: -384px -0;
}

.HASH {
  width: 16px;
  height: 16px;
  background-position: -384px -16px;
}

.HASHI {
  width: 16px;
  height: 16px;
  background-position: -384px -32px;
}

.HAT {
  width: 16px;
  height: 16px;
  background-position: -384px -48px;
}

.HATAY {
  width: 16px;
  height: 16px;
  background-position: -384px -64px;
}

.HATCH {
  width: 16px;
  height: 16px;
  background-position: -384px -80px;
}

.HATE {
  width: 16px;
  height: 16px;
  background-position: -384px -96px;
}

.HATOK {
  width: 16px;
  height: 16px;
  background-position: -384px -112px;
}

.HATTER {
  width: 16px;
  height: 16px;
  background-position: -384px -128px;
}

.HAUS {
  width: 16px;
  height: 16px;
  background-position: -384px -144px;
}

.HAUT {
  width: 16px;
  height: 16px;
  background-position: -384px -160px;
}

.HAVEN {
  width: 16px;
  height: 16px;
  background-position: -384px -176px;
}

.HAVY {
  width: 16px;
  height: 16px;
  background-position: -384px -192px;
}

.HAWK {
  width: 16px;
  height: 16px;
  background-position: -384px -208px;
}

.HAY {
  width: 16px;
  height: 16px;
  background-position: -384px -224px;
}

.HAZ {
  width: 16px;
  height: 16px;
  background-position: -384px -240px;
}

.HAZE {
  width: 16px;
  height: 16px;
  background-position: -384px -256px;
}

.HB {
  width: 16px;
  height: 16px;
  background-position: -384px -272px;
}

.HBAR {
  width: 16px;
  height: 16px;
  background-position: -384px -288px;
}

.HBARP {
  width: 16px;
  height: 16px;
  background-position: -384px -304px;
}

.HBB {
  width: 16px;
  height: 16px;
  background-position: -384px -320px;
}

.HBC {
  width: 16px;
  height: 16px;
  background-position: -384px -336px;
}

.HBD {
  width: 16px;
  height: 16px;
  background-position: -384px -352px;
}

.HBDC {
  width: 16px;
  height: 16px;
  background-position: -384px -368px;
}

.HBEE {
  width: 16px;
  height: 16px;
  background-position: -0 -384px;
}

.HBG {
  width: 16px;
  height: 16px;
  background-position: -16px -384px;
}

.HBIT {
  width: 16px;
  height: 16px;
  background-position: -32px -384px;
}

.HBN {
  width: 16px;
  height: 16px;
  background-position: -48px -384px;
}

.HBO {
  width: 16px;
  height: 16px;
  background-position: -64px -384px;
}

.HBOT {
  width: 16px;
  height: 16px;
  background-position: -80px -384px;
}

.HBRS {
  width: 16px;
  height: 16px;
  background-position: -96px -384px;
}

.HBT {
  width: 16px;
  height: 16px;
  background-position: -112px -384px;
}

.HBTC {
  width: 16px;
  height: 16px;
  background-position: -128px -384px;
}

.HBUSD {
  width: 16px;
  height: 16px;
  background-position: -144px -384px;
}

.HBX {
  width: 16px;
  height: 16px;
  background-position: -160px -384px;
}

.HC {
  width: 16px;
  height: 16px;
  background-position: -176px -384px;
}

.HCA {
  width: 16px;
  height: 16px;
  background-position: -192px -384px;
}

.HCASH {
  width: 16px;
  height: 16px;
  background-position: -208px -384px;
}

.HCC {
  width: 16px;
  height: 16px;
  background-position: -224px -384px;
}

.HCK {
  width: 16px;
  height: 16px;
  background-position: -240px -384px;
}

.HCS {
  width: 16px;
  height: 16px;
  background-position: -256px -384px;
}

.HCT {
  width: 16px;
  height: 16px;
  background-position: -272px -384px;
}

.HD {
  width: 16px;
  height: 16px;
  background-position: -288px -384px;
}

.HDAC {
  width: 16px;
  height: 16px;
  background-position: -304px -384px;
}

.HDAO {
  width: 16px;
  height: 16px;
  background-position: -320px -384px;
}

.HDD {
  width: 16px;
  height: 16px;
  background-position: -336px -384px;
}

.HDDN {
  width: 16px;
  height: 16px;
  background-position: -352px -384px;
}

.HDFL {
  width: 16px;
  height: 16px;
  background-position: -368px -384px;
}

.HDG {
  width: 16px;
  height: 16px;
  background-position: -384px -384px;
}

.HDI {
  width: 16px;
  height: 16px;
  background-position: -400px -0;
}

.HDL {
  width: 16px;
  height: 16px;
  background-position: -400px -16px;
}

.HDLN {
  width: 16px;
  height: 16px;
  background-position: -400px -32px;
}

.HDM {
  width: 16px;
  height: 16px;
  background-position: -400px -48px;
}

.HDO {
  width: 16px;
  height: 16px;
  background-position: -400px -64px;
}

.HDOG {
  width: 16px;
  height: 16px;
  background-position: -400px -80px;
}

.HDP_ф {
  width: 16px;
  height: 16px;
  background-position: -400px -96px;
}

.HDRN {
  width: 16px;
  height: 16px;
  background-position: -400px -112px;
}

.HDV {
  width: 16px;
  height: 16px;
  background-position: -400px -128px;
}

.HDX {
  width: 16px;
  height: 16px;
  background-position: -400px -144px;
}

.HE {
  width: 16px;
  height: 16px;
  background-position: -400px -160px;
}

.HEAD {
  width: 16px;
  height: 16px;
  background-position: -400px -176px;
}

.HEADBANGERS {
  width: 16px;
  height: 16px;
  background-position: -400px -192px;
}

.HEADZ {
  width: 16px;
  height: 16px;
  background-position: -400px -208px;
}

.HEAL {
  width: 16px;
  height: 16px;
  background-position: -400px -224px;
}

.HEARN {
  width: 16px;
  height: 16px;
  background-position: -400px -240px;
}

.HEART {
  width: 16px;
  height: 16px;
  background-position: -400px -256px;
}

.HEARTK {
  width: 16px;
  height: 16px;
  background-position: -400px -272px;
}

.HEAT {
  width: 16px;
  height: 16px;
  background-position: -400px -288px;
}

.HEBE {
  width: 16px;
  height: 16px;
  background-position: -400px -304px;
}

.HEC {
  width: 16px;
  height: 16px;
  background-position: -400px -320px;
}

.HECATE {
  width: 16px;
  height: 16px;
  background-position: -400px -336px;
}

.HEDG {
  width: 16px;
  height: 16px;
  background-position: -400px -352px;
}

.HEDGE {
  width: 16px;
  height: 16px;
  background-position: -400px -368px;
}

.HTBULL {
  width: 16px;
  height: 16px;
  background-position: -400px -384px;
}

.HEE {
  width: 16px;
  height: 16px;
  background-position: -0 -400px;
}

.HEGEM {
  width: 16px;
  height: 16px;
  background-position: -16px -400px;
}

.HEGG {
  width: 16px;
  height: 16px;
  background-position: -32px -400px;
}

.HEGIC {
  width: 16px;
  height: 16px;
  background-position: -48px -400px;
}

.HEIST {
  width: 16px;
  height: 16px;
  background-position: -64px -400px;
}

.HEJJ {
  width: 16px;
  height: 16px;
  background-position: -80px -400px;
}

.HEL {
  width: 16px;
  height: 16px;
  background-position: -96px -400px;
}

.HELD {
  width: 16px;
  height: 16px;
  background-position: -112px -400px;
}

.HELENA {
  width: 16px;
  height: 16px;
  background-position: -128px -400px;
}

.HELIOS {
  width: 16px;
  height: 16px;
  background-position: -144px -400px;
}

.HELLSING {
  width: 16px;
  height: 16px;
  background-position: -160px -400px;
}

.HELMET {
  width: 16px;
  height: 16px;
  background-position: -176px -400px;
}

.HELP {
  width: 16px;
  height: 16px;
  background-position: -192px -400px;
}

.HELPS {
  width: 16px;
  height: 16px;
  background-position: -208px -400px;
}

.HEM {
  width: 16px;
  height: 16px;
  background-position: -224px -400px;
}

.HENTAI {
  width: 16px;
  height: 16px;
  background-position: -240px -400px;
}

.HEP {
  width: 16px;
  height: 16px;
  background-position: -256px -400px;
}

.HEPA {
  width: 16px;
  height: 16px;
  background-position: -272px -400px;
}

.HER {
  width: 16px;
  height: 16px;
  background-position: -288px -400px;
}

.HERA {
  width: 16px;
  height: 16px;
  background-position: -304px -400px;
}

.HERB {
  width: 16px;
  height: 16px;
  background-position: -320px -400px;
}

.HERC {
  width: 16px;
  height: 16px;
  background-position: -336px -400px;
}

.HERO {
  width: 16px;
  height: 16px;
  background-position: -352px -400px;
}

.HERODOGE {
  width: 16px;
  height: 16px;
  background-position: -368px -400px;
}

.HEROEGG {
  width: 16px;
  height: 16px;
  background-position: -384px -400px;
}

.HEROES {
  width: 16px;
  height: 16px;
  background-position: -400px -400px;
}

.HERON {
  width: 16px;
  height: 16px;
  background-position: -416px -0;
}

.HEROS {
  width: 16px;
  height: 16px;
  background-position: -416px -16px;
}

.HERPES {
  width: 16px;
  height: 16px;
  background-position: -416px -32px;
}

.HES {
  width: 16px;
  height: 16px;
  background-position: -416px -48px;
}

.HESH {
  width: 16px;
  height: 16px;
  background-position: -416px -64px;
}

.HET {
  width: 16px;
  height: 16px;
  background-position: -416px -80px;
}

.HETH {
  width: 16px;
  height: 16px;
  background-position: -416px -96px;
}

.HETM {
  width: 16px;
  height: 16px;
  background-position: -416px -112px;
}

.HEX {
  width: 16px;
  height: 16px;
  background-position: -416px -128px;
}

.HEX2T {
  width: 16px;
  height: 16px;
  background-position: -416px -144px;
}

.HEYSHIB {
  width: 16px;
  height: 16px;
  background-position: -416px -160px;
}

.HEZ {
  width: 16px;
  height: 16px;
  background-position: -416px -176px;
}

.HFI {
  width: 16px;
  height: 16px;
  background-position: -416px -192px;
}

.HFIL {
  width: 16px;
  height: 16px;
  background-position: -416px -208px;
}

.HFS {
  width: 16px;
  height: 16px;
  background-position: -416px -224px;
}

.HFSP {
  width: 16px;
  height: 16px;
  background-position: -416px -240px;
}

.HFT {
  width: 16px;
  height: 16px;
  background-position: -416px -256px;
}

.HFUEL {
  width: 16px;
  height: 16px;
  background-position: -416px -272px;
}

.HGET {
  width: 16px;
  height: 16px;
  background-position: -416px -288px;
}

.HGH {
  width: 16px;
  height: 16px;
  background-position: -416px -304px;
}

.HGHG {
  width: 16px;
  height: 16px;
  background-position: -416px -320px;
}

.HGO {
  width: 16px;
  height: 16px;
  background-position: -416px -336px;
}

.HGOLD {
  width: 16px;
  height: 16px;
  background-position: -416px -352px;
}

.HGRO {
  width: 16px;
  height: 16px;
  background-position: -416px -368px;
}

.HGRW {
  width: 16px;
  height: 16px;
  background-position: -416px -384px;
}

.HGT {
  width: 16px;
  height: 16px;
  background-position: -416px -400px;
}

.HHNFT {
  width: 16px;
  height: 16px;
  background-position: -0 -416px;
}

.HHUB {
  width: 16px;
  height: 16px;
  background-position: -16px -416px;
}

.HI {
  width: 16px;
  height: 16px;
  background-position: -32px -416px;
}

.HIBIKI {
  width: 16px;
  height: 16px;
  background-position: -48px -416px;
}

.HIBS {
  width: 16px;
  height: 16px;
  background-position: -64px -416px;
}

.HID {
  width: 16px;
  height: 16px;
  background-position: -80px -416px;
}

.HIDEOUS {
  width: 16px;
  height: 16px;
  background-position: -96px -416px;
}

.HIFI {
  width: 16px;
  height: 16px;
  background-position: -112px -416px;
}

.HIGGS {
  width: 16px;
  height: 16px;
  background-position: -128px -416px;
}

.HIGH {
  width: 16px;
  height: 16px;
  background-position: -144px -416px;
}

.HIKO {
  width: 16px;
  height: 16px;
  background-position: -160px -416px;
}

.HIMA {
  width: 16px;
  height: 16px;
  background-position: -176px -416px;
}

.HIMO {
  width: 16px;
  height: 16px;
  background-position: -192px -416px;
}

.HINA {
  width: 16px;
  height: 16px;
  background-position: -208px -416px;
}

.HINT {
  width: 16px;
  height: 16px;
  background-position: -224px -416px;
}

.HINU {
  width: 16px;
  height: 16px;
  background-position: -240px -416px;
}

.HIP {
  width: 16px;
  height: 16px;
  background-position: -256px -416px;
}

.HIPPIE {
  width: 16px;
  height: 16px;
  background-position: -272px -416px;
}

.HIPPO {
  width: 16px;
  height: 16px;
  background-position: -288px -416px;
}

.HIRO {
  width: 16px;
  height: 16px;
  background-position: -304px -416px;
}

.HIT {
  width: 16px;
  height: 16px;
  background-position: -320px -416px;
}

.HITHOT {
  width: 16px;
  height: 16px;
  background-position: -336px -416px;
}

.HITOP {
  width: 16px;
  height: 16px;
  background-position: -352px -416px;
}

.HITX {
  width: 16px;
  height: 16px;
  background-position: -368px -416px;
}

.HIVE {
  width: 16px;
  height: 16px;
  background-position: -384px -416px;
}

.HIZ {
  width: 16px;
  height: 16px;
  background-position: -400px -416px;
}

.HK {
  width: 16px;
  height: 16px;
  background-position: -416px -416px;
}

.HKC {
  width: 16px;
  height: 16px;
  background-position: -432px -0;
}

.HKDX {
  width: 16px;
  height: 16px;
  background-position: -432px -16px;
}

.HKLS {
  width: 16px;
  height: 16px;
  background-position: -432px -32px;
}

.HKN {
  width: 16px;
  height: 16px;
  background-position: -432px -48px;
}

.HKO {
  width: 16px;
  height: 16px;
  background-position: -432px -64px;
}

.HKSM {
  width: 16px;
  height: 16px;
  background-position: -432px -80px;
}

.HKUN {
  width: 16px;
  height: 16px;
  background-position: -432px -96px;
}

.HL {
  width: 16px;
  height: 16px;
  background-position: -432px -112px;
}

.HLAND {
  width: 16px;
  height: 16px;
  background-position: -432px -128px;
}

.HLC {
  width: 16px;
  height: 16px;
  background-position: -432px -144px;
}

.HLD {
  width: 16px;
  height: 16px;
  background-position: -432px -160px;
}

.HLIX {
  width: 16px;
  height: 16px;
  background-position: -432px -176px;
}

.HLM {
  width: 16px;
  height: 16px;
  background-position: -432px -192px;
}

.HLP {
  width: 16px;
  height: 16px;
  background-position: -432px -208px;
}

.HLS {
  width: 16px;
  height: 16px;
  background-position: -432px -224px;
}

.HLT {
  width: 16px;
  height: 16px;
  background-position: -432px -240px;
}

.HLTC {
  width: 16px;
  height: 16px;
  background-position: -432px -256px;
}

.HLTH {
  width: 16px;
  height: 16px;
  background-position: -432px -272px;
}

.HLX {
  width: 16px;
  height: 16px;
  background-position: -432px -288px;
}

.HM {
  width: 16px;
  height: 16px;
  background-position: -432px -304px;
}

.HMB {
  width: 16px;
  height: 16px;
  background-position: -432px -320px;
}

.HMC {
  width: 16px;
  height: 16px;
  background-position: -432px -336px;
}

.HMDX {
  width: 16px;
  height: 16px;
  background-position: -432px -352px;
}

.HMETA {
  width: 16px;
  height: 16px;
  background-position: -432px -368px;
}

.HMNG {
  width: 16px;
  height: 16px;
  background-position: -432px -384px;
}

.HMOON {
  width: 16px;
  height: 16px;
  background-position: -432px -400px;
}

.HMQ {
  width: 16px;
  height: 16px;
  background-position: -432px -416px;
}

.HMR {
  width: 16px;
  height: 16px;
  background-position: -0 -432px;
}

.HMRN {
  width: 16px;
  height: 16px;
  background-position: -16px -432px;
}

.HMT {
  width: 16px;
  height: 16px;
  background-position: -32px -432px;
}

.HMXC {
  width: 16px;
  height: 16px;
  background-position: -48px -432px;
}

.HMZ {
  width: 16px;
  height: 16px;
  background-position: -64px -432px;
}

.HN {
  width: 16px;
  height: 16px;
  background-position: -80px -432px;
}

.HNB {
  width: 16px;
  height: 16px;
  background-position: -96px -432px;
}

.HNC {
  width: 16px;
  height: 16px;
  background-position: -112px -432px;
}

.HND {
  width: 16px;
  height: 16px;
  background-position: -128px -432px;
}

.HNDC {
  width: 16px;
  height: 16px;
  background-position: -144px -432px;
}

.HNG {
  width: 16px;
  height: 16px;
  background-position: -160px -432px;
}

.HNS {
  width: 16px;
  height: 16px;
  background-position: -176px -432px;
}

.HNST {
  width: 16px;
  height: 16px;
  background-position: -192px -432px;
}

.HNT {
  width: 16px;
  height: 16px;
  background-position: -208px -432px;
}

.HNW {
  width: 16px;
  height: 16px;
  background-position: -224px -432px;
}

.HNY {
  width: 16px;
  height: 16px;
  background-position: -240px -432px;
}

.HNYAN {
  width: 16px;
  height: 16px;
  background-position: -256px -432px;
}

.HO {
  width: 16px;
  height: 16px;
  background-position: -272px -432px;
}

.HOD {
  width: 16px;
  height: 16px;
  background-position: -288px -432px;
}

.HODL {
  width: 16px;
  height: 16px;
  background-position: -304px -432px;
}

.HODL_2_0 {
  width: 16px;
  height: 16px;
  background-position: -320px -432px;
}

.HODLX {
  width: 16px;
  height: 16px;
  background-position: -336px -432px;
}

.HODM {
  width: 16px;
  height: 16px;
  background-position: -352px -432px;
}

.HODO {
  width: 16px;
  height: 16px;
  background-position: -368px -432px;
}

.HOFF {
  width: 16px;
  height: 16px;
  background-position: -384px -432px;
}

.HOGE {
  width: 16px;
  height: 16px;
  background-position: -400px -432px;
}

.HOGL {
  width: 16px;
  height: 16px;
  background-position: -416px -432px;
}

.HOGS {
  width: 16px;
  height: 16px;
  background-position: -432px -432px;
}

.HOGT {
  width: 16px;
  height: 16px;
  background-position: -448px -0;
}

.HOHOHO {
  width: 16px;
  height: 16px;
  background-position: -448px -16px;
}

.HOHOHO_V2_0 {
  width: 16px;
  height: 16px;
  background-position: -448px -32px;
}

.HOKK {
  width: 16px;
  height: 16px;
  background-position: -448px -48px;
}

.HOL {
  width: 16px;
  height: 16px;
  background-position: -448px -64px;
}

.HOLD {
  width: 16px;
  height: 16px;
  background-position: -448px -80px;
}

.HOLDEX {
  width: 16px;
  height: 16px;
  background-position: -448px -96px;
}

.HOLE {
  width: 16px;
  height: 16px;
  background-position: -448px -112px;
}

.HOLY {
  width: 16px;
  height: 16px;
  background-position: -448px -128px;
}

.HOME {
  width: 16px;
  height: 16px;
  background-position: -448px -144px;
}

.HOMI {
  width: 16px;
  height: 16px;
  background-position: -448px -160px;
}

.HOMT {
  width: 16px;
  height: 16px;
  background-position: -448px -176px;
}

.HON {
  width: 16px;
  height: 16px;
  background-position: -448px -192px;
}

.HONEY {
  width: 16px;
  height: 16px;
  background-position: -448px -208px;
}

.HONEYBADGER {
  width: 16px;
  height: 16px;
  background-position: -448px -224px;
}

.HONEYD {
  width: 16px;
  height: 16px;
  background-position: -448px -240px;
}

.HONK {
  width: 16px;
  height: 16px;
  background-position: -448px -256px;
}

.HONOR {
  width: 16px;
  height: 16px;
  background-position: -448px -272px;
}

.HONR {
  width: 16px;
  height: 16px;
  background-position: -448px -288px;
}

.HOO {
  width: 16px;
  height: 16px;
  background-position: -448px -304px;
}

.HOOD {
  width: 16px;
  height: 16px;
  background-position: -448px -320px;
}

.HOODRAT {
  width: 16px;
  height: 16px;
  background-position: -448px -336px;
}

.HOOF {
  width: 16px;
  height: 16px;
  background-position: -448px -352px;
}

.HOOK {
  width: 16px;
  height: 16px;
  background-position: -448px -368px;
}

.HOOP {
  width: 16px;
  height: 16px;
  background-position: -448px -384px;
}

.HOP {
  width: 16px;
  height: 16px;
  background-position: -448px -400px;
}

.HOPE {
  width: 16px;
  height: 16px;
  background-position: -448px -416px;
}

.HOPR {
  width: 16px;
  height: 16px;
  background-position: -448px -432px;
}

.HOR {
  width: 16px;
  height: 16px;
  background-position: -0 -448px;
}

.HORD {
  width: 16px;
  height: 16px;
  background-position: -16px -448px;
}

.HORDE {
  width: 16px;
  height: 16px;
  background-position: -32px -448px;
}

.HORGI {
  width: 16px;
  height: 16px;
  background-position: -48px -448px;
}

.HORN {
  width: 16px;
  height: 16px;
  background-position: -64px -448px;
}

.HORNY {
  width: 16px;
  height: 16px;
  background-position: -80px -448px;
}

.HORSE {
  width: 16px;
  height: 16px;
  background-position: -96px -448px;
}

.HORUS {
  width: 16px;
  height: 16px;
  background-position: -112px -448px;
}

.HOSKY {
  width: 16px;
  height: 16px;
  background-position: -128px -448px;
}

.HOT {
  width: 16px;
  height: 16px;
  background-position: -144px -448px;
}

.HOTCROSS {
  width: 16px;
  height: 16px;
  background-position: -160px -448px;
}

.HOTDOGE {
  width: 16px;
  height: 16px;
  background-position: -176px -448px;
}

.HOTMOON {
  width: 16px;
  height: 16px;
  background-position: -192px -448px;
}

.HOTP {
  width: 16px;
  height: 16px;
  background-position: -208px -448px;
}

.HOTZILLA {
  width: 16px;
  height: 16px;
  background-position: -224px -448px;
}

.HOUSE {
  width: 16px;
  height: 16px;
  background-position: -240px -448px;
}

.HOVER {
  width: 16px;
  height: 16px;
  background-position: -256px -448px;
}

.HOWL {
  width: 16px;
  height: 16px;
  background-position: -272px -448px;
}

.HOX {
  width: 16px;
  height: 16px;
  background-position: -288px -448px;
}

.HP {
  width: 16px;
  height: 16px;
  background-position: -304px -448px;
}

.HPAD {
  width: 16px;
  height: 16px;
  background-position: -320px -448px;
}

.HPAY {
  width: 16px;
  height: 16px;
  background-position: -336px -448px;
}

.HPB {
  width: 16px;
  height: 16px;
  background-position: -352px -448px;
}

.HPD {
  width: 16px;
  height: 16px;
  background-position: -368px -448px;
}

.HPL {
  width: 16px;
  height: 16px;
  background-position: -384px -448px;
}

.HPLAY {
  width: 16px;
  height: 16px;
  background-position: -400px -448px;
}

.HPNS {
  width: 16px;
  height: 16px;
  background-position: -416px -448px;
}

.HPPOT {
  width: 16px;
  height: 16px;
  background-position: -432px -448px;
}

.HPS {
  width: 16px;
  height: 16px;
  background-position: -448px -448px;
}

.HPT {
  width: 16px;
  height: 16px;
  background-position: -464px -0;
}

.HPTF {
  width: 16px;
  height: 16px;
  background-position: -464px -16px;
}

.HPW {
  width: 16px;
  height: 16px;
  background-position: -464px -32px;
}

.HPX {
  width: 16px;
  height: 16px;
  background-position: -464px -48px;
}

.HPY {
  width: 16px;
  height: 16px;
  background-position: -464px -64px;
}

.HQT {
  width: 16px;
  height: 16px;
  background-position: -464px -80px;
}

.HQX {
  width: 16px;
  height: 16px;
  background-position: -464px -96px;
}

.HRB {
  width: 16px;
  height: 16px;
  background-position: -464px -112px;
}

.HRC {
  width: 16px;
  height: 16px;
  background-position: -464px -128px;
}

.HRD {
  width: 16px;
  height: 16px;
  background-position: -464px -144px;
}

.HRIMP {
  width: 16px;
  height: 16px;
  background-position: -464px -160px;
}

.HRO {
  width: 16px;
  height: 16px;
  background-position: -464px -176px;
}

.HRS {
  width: 16px;
  height: 16px;
  background-position: -464px -192px;
}

.HRTS {
  width: 16px;
  height: 16px;
  background-position: -464px -208px;
}

.HRX {
  width: 16px;
  height: 16px;
  background-position: -464px -224px;
}

.HRZ {
  width: 16px;
  height: 16px;
  background-position: -464px -240px;
}

.HSC {
  width: 16px;
  height: 16px;
  background-position: -464px -256px;
}

.HSF {
  width: 16px;
  height: 16px;
  background-position: -464px -272px;
}

.HSHARE {
  width: 16px;
  height: 16px;
  background-position: -464px -288px;
}

.HSHARES {
  width: 16px;
  height: 16px;
  background-position: -464px -304px;
}

.HSI {
  width: 16px;
  height: 16px;
  background-position: -464px -320px;
}

.HSN {
  width: 16px;
  height: 16px;
  background-position: -464px -336px;
}

.HSS {
  width: 16px;
  height: 16px;
  background-position: -464px -352px;
}

.HST {
  width: 16px;
  height: 16px;
  background-position: -464px -368px;
}

.HT {
  width: 16px;
  height: 16px;
  background-position: -464px -384px;
}

.HTA {
  width: 16px;
  height: 16px;
  background-position: -464px -400px;
}

.HTB {
  width: 16px;
  height: 16px;
  background-position: -464px -416px;
}

.HTC {
  width: 16px;
  height: 16px;
  background-position: -464px -432px;
}

.HTD {
  width: 16px;
  height: 16px;
  background-position: -464px -448px;
}

.HTDF {
  width: 16px;
  height: 16px;
  background-position: -0 -464px;
}

.HTF {
  width: 16px;
  height: 16px;
  background-position: -16px -464px;
}

.HTML {
  width: 16px;
  height: 16px;
  background-position: -32px -464px;
}

.HTMOON {
  width: 16px;
  height: 16px;
  background-position: -48px -464px;
}

.HTN {
  width: 16px;
  height: 16px;
  background-position: -64px -464px;
}

.HTO {
  width: 16px;
  height: 16px;
  background-position: -80px -464px;
}

.HTR {
  width: 16px;
  height: 16px;
  background-position: -96px -464px;
}

.HTRE {
  width: 16px;
  height: 16px;
  background-position: -112px -464px;
}

.HTX {
  width: 16px;
  height: 16px;
  background-position: -128px -464px;
}

.HTZ {
  width: 16px;
  height: 16px;
  background-position: -144px -464px;
}

.HUA {
  width: 16px;
  height: 16px;
  background-position: -160px -464px;
}

.HUAHUA {
  width: 16px;
  height: 16px;
  background-position: -176px -464px;
}

.HUB {
  width: 16px;
  height: 16px;
  background-position: -192px -464px;
}

.HUDDL {
  width: 16px;
  height: 16px;
  background-position: -208px -464px;
}

.HUDI {
  width: 16px;
  height: 16px;
  background-position: -224px -464px;
}

.HUE {
  width: 16px;
  height: 16px;
  background-position: -240px -464px;
}

.HUGO {
  width: 16px;
  height: 16px;
  background-position: -256px -464px;
}

.HUH {
  width: 16px;
  height: 16px;
  background-position: -272px -464px;
}

.HUKKU {
  width: 16px;
  height: 16px;
  background-position: -288px -464px;
}

.HUM {
  width: 16px;
  height: 16px;
  background-position: -304px -464px;
}

.HUMAN {
  width: 16px;
  height: 16px;
  background-position: -320px -464px;
}

.HUMP {
  width: 16px;
  height: 16px;
  background-position: -336px -464px;
}

.HUNGER {
  width: 16px;
  height: 16px;
  background-position: -352px -464px;
}

.HUNGRY {
  width: 16px;
  height: 16px;
  background-position: -368px -464px;
}

.HUNGRYDOGE {
  width: 16px;
  height: 16px;
  background-position: -384px -464px;
}

.HUNNY {
  width: 16px;
  height: 16px;
  background-position: -400px -464px;
}

.HUNT {
  width: 16px;
  height: 16px;
  background-position: -416px -464px;
}

.HUNY {
  width: 16px;
  height: 16px;
  background-position: -432px -464px;
}

.HUP {
  width: 16px;
  height: 16px;
  background-position: -448px -464px;
}

.HUR {
  width: 16px;
  height: 16px;
  background-position: -464px -464px;
}

.HUSD {
  width: 16px;
  height: 16px;
  background-position: -480px -0;
}

.HUSH {
  width: 16px;
  height: 16px;
  background-position: -480px -16px;
}

.HUSKY {
  width: 16px;
  height: 16px;
  background-position: -480px -32px;
}

.HUSKYX {
  width: 16px;
  height: 16px;
  background-position: -480px -48px;
}

.HUSL {
  width: 16px;
  height: 16px;
  background-position: -480px -64px;
}

.HUSTLE {
  width: 16px;
  height: 16px;
  background-position: -480px -80px;
}

.HUSWP {
  width: 16px;
  height: 16px;
  background-position: -480px -96px;
}

.HUT {
  width: 16px;
  height: 16px;
  background-position: -480px -112px;
}

.HVCO {
  width: 16px;
  height: 16px;
  background-position: -480px -128px;
}

.HVE2 {
  width: 16px;
  height: 16px;
  background-position: -480px -144px;
}

.HVI {
  width: 16px;
  height: 16px;
  background-position: -480px -160px;
}

.HVILLE {
  width: 16px;
  height: 16px;
  background-position: -480px -176px;
}

.HVLT {
  width: 16px;
  height: 16px;
  background-position: -480px -192px;
}

.HVN {
  width: 16px;
  height: 16px;
  background-position: -480px -208px;
}

.HVT {
  width: 16px;
  height: 16px;
  background-position: -480px -224px;
}

.HWC {
  width: 16px;
  height: 16px;
  background-position: -480px -240px;
}

.HWL {
  width: 16px;
  height: 16px;
  background-position: -480px -256px;
}

.HX {
  width: 16px;
  height: 16px;
  background-position: -480px -272px;
}

.HXB {
  width: 16px;
  height: 16px;
  background-position: -480px -288px;
}

.HXN {
  width: 16px;
  height: 16px;
  background-position: -480px -304px;
}

.HXP {
  width: 16px;
  height: 16px;
  background-position: -480px -320px;
}

.HXRO {
  width: 16px;
  height: 16px;
  background-position: -480px -336px;
}

.HXY {
  width: 16px;
  height: 16px;
  background-position: -480px -352px;
}

.HY {
  width: 16px;
  height: 16px;
  background-position: -480px -368px;
}

.HYB {
  width: 16px;
  height: 16px;
  background-position: -480px -384px;
}

.HYBN {
  width: 16px;
  height: 16px;
  background-position: -480px -400px;
}

.HYBRID {
  width: 16px;
  height: 16px;
  background-position: -480px -416px;
}

.HYC {
  width: 16px;
  height: 16px;
  background-position: -480px -432px;
}

.HYD {
  width: 16px;
  height: 16px;
  background-position: -480px -448px;
}

.HYDRA {
  width: 16px;
  height: 16px;
  background-position: -480px -464px;
}

.HYDRO {
  width: 16px;
  height: 16px;
  background-position: -0 -480px;
}

.HYFI {
  width: 16px;
  height: 16px;
  background-position: -16px -480px;
}

.HYMETEOR {
  width: 16px;
  height: 16px;
  background-position: -32px -480px;
}

.HYN {
  width: 16px;
  height: 16px;
  background-position: -48px -480px;
}

.HYP {
  width: 16px;
  height: 16px;
  background-position: -64px -480px;
}

.HYPE {
  width: 16px;
  height: 16px;
  background-position: -80px -480px;
}

.HYPED {
  width: 16px;
  height: 16px;
  background-position: -96px -480px;
}

.HYPER {
  width: 16px;
  height: 16px;
  background-position: -112px -480px;
}

.HYPERBOOST {
  width: 16px;
  height: 16px;
  background-position: -128px -480px;
}

.HYPERR {
  width: 16px;
  height: 16px;
  background-position: -144px -480px;
}

.HYPERRISE {
  width: 16px;
  height: 16px;
  background-position: -160px -480px;
}

.HYPERSONIC {
  width: 16px;
  height: 16px;
  background-position: -176px -480px;
}

.HYPR {
  width: 16px;
  height: 16px;
  background-position: -192px -480px;
}

.HYPX {
  width: 16px;
  height: 16px;
  background-position: -208px -480px;
}

.HYS {
  width: 16px;
  height: 16px;
  background-position: -224px -480px;
}

.HYSS {
  width: 16px;
  height: 16px;
  background-position: -240px -480px;
}

.HYT {
  width: 16px;
  height: 16px;
  background-position: -256px -480px;
}

.HYVE {
  width: 16px;
  height: 16px;
  background-position: -272px -480px;
}

.HZD {
  width: 16px;
  height: 16px;
  background-position: -288px -480px;
}

.HZM {
  width: 16px;
  height: 16px;
  background-position: -304px -480px;
}

.HZN {
  width: 16px;
  height: 16px;
  background-position: -320px -480px;
}

.HZT {
  width: 16px;
  height: 16px;
  background-position: -336px -480px;
}

.ʜᴏʟᴅᴇɴᴏᴍɪᴄs™ {
  width: 16px;
  height: 16px;
  background-position: -352px -480px;
}

.I9C {
  width: 16px;
  height: 16px;
  background-position: -368px -480px;
}

.IADA {
  width: 16px;
  height: 16px;
  background-position: -384px -480px;
}

.IAG {
  width: 16px;
  height: 16px;
  background-position: -400px -480px;
}

.IAI {
  width: 16px;
  height: 16px;
  background-position: -416px -480px;
}

.IAP {
  width: 16px;
  height: 16px;
  background-position: -432px -480px;
}

.IB {
  width: 16px;
  height: 16px;
  background-position: -448px -480px;
}

.IBANK {
  width: 16px;
  height: 16px;
  background-position: -464px -480px;
}

.iBBT {
  width: 16px;
  height: 16px;
  background-position: -480px -480px;
}

.IBCH {
  width: 16px;
  height: 16px;
  background-position: -496px -0;
}

.ibETH {
  width: 16px;
  height: 16px;
  background-position: -496px -16px;
}

.IBEX {
  width: 16px;
  height: 16px;
  background-position: -496px -32px;
}

.IBFK {
  width: 16px;
  height: 16px;
  background-position: -496px -48px;
}

.iBFR {
  width: 16px;
  height: 16px;
  background-position: -496px -64px;
}

.IBG {
  width: 16px;
  height: 16px;
  background-position: -496px -80px;
}

.IBH {
  width: 16px;
  height: 16px;
  background-position: -496px -96px;
}

.IBNB {
  width: 16px;
  height: 16px;
  background-position: -496px -112px;
}

.IBP {
  width: 16px;
  height: 16px;
  background-position: -496px -128px;
}

.IBS {
  width: 16px;
  height: 16px;
  background-position: -496px -144px;
}

.IBT {
  width: 16px;
  height: 16px;
  background-position: -496px -160px;
}

.IBTC {
  width: 16px;
  height: 16px;
  background-position: -496px -176px;
}

.IBTC_FLI_P {
  width: 16px;
  height: 16px;
  background-position: -496px -192px;
}

.IBVOL {
  width: 16px;
  height: 16px;
  background-position: -496px -208px;
}

.IBXC {
  width: 16px;
  height: 16px;
  background-position: -496px -224px;
}

.IBZ {
  width: 16px;
  height: 16px;
  background-position: -496px -240px;
}

.IC {
  width: 16px;
  height: 16px;
  background-position: -496px -256px;
}

.ICA {
  width: 16px;
  height: 16px;
  background-position: -496px -272px;
}

.iCADE {
  width: 16px;
  height: 16px;
  background-position: -496px -288px;
}

.ICAP {
  width: 16px;
  height: 16px;
  background-position: -496px -304px;
}

.ICC {
  width: 16px;
  height: 16px;
  background-position: -496px -320px;
}

.ICD {
  width: 16px;
  height: 16px;
  background-position: -496px -336px;
}

.ICE {
  width: 16px;
  height: 16px;
  background-position: -496px -352px;
}

.ICEBERG {
  width: 16px;
  height: 16px;
  background-position: -496px -368px;
}

.ICEBRK {
  width: 16px;
  height: 16px;
  background-position: -496px -384px;
}

.ICEX {
  width: 16px;
  height: 16px;
  background-position: -496px -400px;
}

.ICH {
  width: 16px;
  height: 16px;
  background-position: -496px -416px;
}

.ICHI {
  width: 16px;
  height: 16px;
  background-position: -496px -432px;
}

.ICHIEMA {
  width: 16px;
  height: 16px;
  background-position: -496px -448px;
}

.ICHIGO {
  width: 16px;
  height: 16px;
  background-position: -496px -464px;
}

.ICHX {
  width: 16px;
  height: 16px;
  background-position: -496px -480px;
}

.ICN {
  width: 16px;
  height: 16px;
  background-position: -0 -496px;
}

.ICNQ {
  width: 16px;
  height: 16px;
  background-position: -16px -496px;
}

.ICOB {
  width: 16px;
  height: 16px;
  background-position: -32px -496px;
}

.ICOM {
  width: 16px;
  height: 16px;
  background-position: -48px -496px;
}

.ICON {
  width: 16px;
  height: 16px;
  background-position: -64px -496px;
}

.ICONS {
  width: 16px;
  height: 16px;
  background-position: -80px -496px;
}

.ICOO {
  width: 16px;
  height: 16px;
  background-position: -96px -496px;
}

.ICP {
  width: 16px;
  height: 16px;
  background-position: -112px -496px;
}

.ICR {
  width: 16px;
  height: 16px;
  background-position: -128px -496px;
}

.ICT {
  width: 16px;
  height: 16px;
  background-position: -144px -496px;
}

.ICUBE {
  width: 16px;
  height: 16px;
  background-position: -160px -496px;
}

.ICW {
  width: 16px;
  height: 16px;
  background-position: -176px -496px;
}

.ICX {
  width: 16px;
  height: 16px;
  background-position: -192px -496px;
}

.ICY {
  width: 16px;
  height: 16px;
  background-position: -208px -496px;
}

.ID {
  width: 16px;
  height: 16px;
  background-position: -224px -496px;
}

.IDALL {
  width: 16px;
  height: 16px;
  background-position: -240px -496px;
}

.IDEA {
  width: 16px;
  height: 16px;
  background-position: -256px -496px;
}

.IDEAL {
  width: 16px;
  height: 16px;
  background-position: -272px -496px;
}

.IDEFI {
  width: 16px;
  height: 16px;
  background-position: -288px -496px;
}

.IDEX {
  width: 16px;
  height: 16px;
  background-position: -304px -496px;
}

.IDH {
  width: 16px;
  height: 16px;
  background-position: -320px -496px;
}

.IDIA {
  width: 16px;
  height: 16px;
  background-position: -336px -496px;
}

.IDK {
  width: 16px;
  height: 16px;
  background-position: -352px -496px;
}

.IDL {
  width: 16px;
  height: 16px;
  background-position: -368px -496px;
}

.IDLE {
  width: 16px;
  height: 16px;
  background-position: -384px -496px;
}

.IDLEDAISAFE {
  width: 16px;
  height: 16px;
  background-position: -400px -496px;
}

.IDLEDAIYIELD {
  width: 16px;
  height: 16px;
  background-position: -416px -496px;
}

.IDLESUSDYIELD {
  width: 16px;
  height: 16px;
  background-position: -432px -496px;
}

.IDLETUSDYIELD {
  width: 16px;
  height: 16px;
  background-position: -448px -496px;
}

.IDLEUSDCSAFE {
  width: 16px;
  height: 16px;
  background-position: -464px -496px;
}

.IDLEUSDCYIELD {
  width: 16px;
  height: 16px;
  background-position: -480px -496px;
}

.IDLEUSDTSAFE {
  width: 16px;
  height: 16px;
  background-position: -496px -496px;
}

.IDLEUSDTYIELD {
  width: 16px;
  height: 16px;
  background-position: -512px -0;
}

.IDLEWBTCYIELD {
  width: 16px;
  height: 16px;
  background-position: -512px -16px;
}

.IDLI {
  width: 16px;
  height: 16px;
  background-position: -512px -32px;
}

.IDM {
  width: 16px;
  height: 16px;
  background-position: -512px -48px;
}

.IDNA {
  width: 16px;
  height: 16px;
  background-position: -512px -64px;
}

.IDO {
  width: 16px;
  height: 16px;
  background-position: -512px -80px;
}

.IDOGE {
  width: 16px;
  height: 16px;
  background-position: -512px -96px;
}

.IDOL {
  width: 16px;
  height: 16px;
  background-position: -512px -112px;
}

.IDOSCAN {
  width: 16px;
  height: 16px;
  background-position: -512px -128px;
}

.IDOT {
  width: 16px;
  height: 16px;
  background-position: -512px -144px;
}

.IDRT {
  width: 16px;
  height: 16px;
  background-position: -512px -160px;
}

.IDS {
  width: 16px;
  height: 16px;
  background-position: -512px -176px;
}

.IDT {
  width: 16px;
  height: 16px;
  background-position: -512px -192px;
}

.IDTT {
  width: 16px;
  height: 16px;
  background-position: -512px -208px;
}

.IDV {
  width: 16px;
  height: 16px;
  background-position: -512px -224px;
}

.IDX {
  width: 16px;
  height: 16px;
  background-position: -512px -240px;
}

.IDXM {
  width: 16px;
  height: 16px;
  background-position: -512px -256px;
}

.IDYP {
  width: 16px;
  height: 16px;
  background-position: -512px -272px;
}

.IEOS {
  width: 16px;
  height: 16px;
  background-position: -512px -288px;
}

.IETH {
  width: 16px;
  height: 16px;
  background-position: -512px -304px;
}

.IETH20SMACO {
  width: 16px;
  height: 16px;
  background-position: -512px -320px;
}

.IF {
  width: 16px;
  height: 16px;
  background-position: -512px -336px;
}

.IFC {
  width: 16px;
  height: 16px;
  background-position: -512px -352px;
}

.IFEX {
  width: 16px;
  height: 16px;
  background-position: -512px -368px;
}

.IFG {
  width: 16px;
  height: 16px;
  background-position: -512px -384px;
}

.IFLT {
  width: 16px;
  height: 16px;
  background-position: -512px -400px;
}

.IFO {
  width: 16px;
  height: 16px;
  background-position: -512px -416px;
}

.IFOOD {
  width: 16px;
  height: 16px;
  background-position: -512px -432px;
}

.IFP {
  width: 16px;
  height: 16px;
  background-position: -512px -448px;
}

.IFR {
  width: 16px;
  height: 16px;
  background-position: -512px -464px;
}

.IFT {
  width: 16px;
  height: 16px;
  background-position: -512px -480px;
}

.IFUND {
  width: 16px;
  height: 16px;
  background-position: -512px -496px;
}

.IFV {
  width: 16px;
  height: 16px;
  background-position: -0 -512px;
}

.IFX24 {
  width: 16px;
  height: 16px;
  background-position: -16px -512px;
}

.IG {
  width: 16px;
  height: 16px;
  background-position: -32px -512px;
}

.IGCH {
  width: 16px;
  height: 16px;
  background-position: -48px -512px;
}

.IGF {
  width: 16px;
  height: 16px;
  background-position: -64px -512px;
}

.IGG {
  width: 16px;
  height: 16px;
  background-position: -80px -512px;
}

.IGIRL {
  width: 16px;
  height: 16px;
  background-position: -96px -512px;
}

.IGL {
  width: 16px;
  height: 16px;
  background-position: -112px -512px;
}

.IGN {
  width: 16px;
  height: 16px;
  background-position: -128px -512px;
}

.IGNIS {
  width: 16px;
  height: 16px;
  background-position: -144px -512px;
}

.IGO {
  width: 16px;
  height: 16px;
  background-position: -160px -512px;
}

.IGR {
  width: 16px;
  height: 16px;
  background-position: -176px -512px;
}

.IGT {
  width: 16px;
  height: 16px;
  background-position: -192px -512px;
}

.IGU {
  width: 16px;
  height: 16px;
  background-position: -208px -512px;
}

.IHC {
  width: 16px;
  height: 16px;
  background-position: -224px -512px;
}

.IHF {
  width: 16px;
  height: 16px;
  background-position: -240px -512px;
}

.IHT {
  width: 16px;
  height: 16px;
  background-position: -256px -512px;
}

.IIC {
  width: 16px;
  height: 16px;
  background-position: -272px -512px;
}

.IJC {
  width: 16px;
  height: 16px;
  background-position: -288px -512px;
}

.IJZ {
  width: 16px;
  height: 16px;
  background-position: -304px -512px;
}

.IKA {
  width: 16px;
  height: 16px;
  background-position: -320px -512px;
}

.IKC {
  width: 16px;
  height: 16px;
  background-position: -336px -512px;
}

.IKONIC {
  width: 16px;
  height: 16px;
  background-position: -352px -512px;
}

.IKURA {
  width: 16px;
  height: 16px;
  background-position: -368px -512px;
}

.ILA {
  width: 16px;
  height: 16px;
  background-position: -384px -512px;
}

.ILAYER {
  width: 16px;
  height: 16px;
  background-position: -400px -512px;
}

.ILC {
  width: 16px;
  height: 16px;
  background-position: -416px -512px;
}

.ILG {
  width: 16px;
  height: 16px;
  background-position: -432px -512px;
}

.ILINK {
  width: 16px;
  height: 16px;
  background-position: -448px -512px;
}

.ILK {
  width: 16px;
  height: 16px;
  background-position: -464px -512px;
}

.ILSI {
  width: 16px;
  height: 16px;
  background-position: -480px -512px;
}

.ILTC {
  width: 16px;
  height: 16px;
  background-position: -496px -512px;
}

.ILUS {
  width: 16px;
  height: 16px;
  background-position: -512px -512px;
}

.ILV {
  width: 16px;
  height: 16px;
  background-position: -528px -0;
}

.IM {
  width: 16px;
  height: 16px;
  background-position: -528px -16px;
}

.IMAGIC {
  width: 16px;
  height: 16px;
  background-position: -528px -32px;
}

.IMATIC_FLI_P {
  width: 16px;
  height: 16px;
  background-position: -528px -48px;
}

.imBTC {
  width: 16px;
  height: 16px;
  background-position: -528px -64px;
}

.IMBU {
  width: 16px;
  height: 16px;
  background-position: -528px -80px;
}

.IMC {
  width: 16px;
  height: 16px;
  background-position: -528px -96px;
}

.IME {
  width: 16px;
  height: 16px;
  background-position: -528px -112px;
}

.IMG {
  width: 16px;
  height: 16px;
  background-position: -528px -128px;
}

.IMGC {
  width: 16px;
  height: 16px;
  background-position: -528px -144px;
}

.IMI {
  width: 16px;
  height: 16px;
  background-position: -528px -160px;
}

.IMM {
  width: 16px;
  height: 16px;
  background-position: -528px -176px;
}

.IMMO {
  width: 16px;
  height: 16px;
  background-position: -528px -192px;
}

.IMO {
  width: 16px;
  height: 16px;
  background-position: -528px -208px;
}

.IMP {
  width: 16px;
  height: 16px;
  background-position: -528px -224px;
}

.IMPACTX {
  width: 16px;
  height: 16px;
  background-position: -528px -240px;
}

.IMPACTXP {
  width: 16px;
  height: 16px;
  background-position: -528px -256px;
}

.IMPCN {
  width: 16px;
  height: 16px;
  background-position: -528px -272px;
}

.IMPL {
  width: 16px;
  height: 16px;
  background-position: -528px -288px;
}

.IMRTL {
  width: 16px;
  height: 16px;
  background-position: -528px -304px;
}

.IMS {
  width: 16px;
  height: 16px;
  background-position: -528px -320px;
}

.IMT {
  width: 16px;
  height: 16px;
  background-position: -528px -336px;
}

.IMV {
  width: 16px;
  height: 16px;
  background-position: -528px -352px;
}

.IMX {
  width: 16px;
  height: 16px;
  background-position: -528px -368px;
}

.IN {
  width: 16px;
  height: 16px;
  background-position: -528px -384px;
}

.INARI {
  width: 16px;
  height: 16px;
  background-position: -528px -400px;
}

.INAZ {
  width: 16px;
  height: 16px;
  background-position: -528px -416px;
}

.INB {
  width: 16px;
  height: 16px;
  background-position: -528px -432px;
}

.InBit {
  width: 16px;
  height: 16px;
  background-position: -528px -448px;
}

.INC {
  width: 16px;
  height: 16px;
  background-position: -528px -464px;
}

.INCAKE {
  width: 16px;
  height: 16px;
  background-position: -528px -480px;
}

.INCEPTION {
  width: 16px;
  height: 16px;
  background-position: -528px -496px;
}

.INCNT {
  width: 16px;
  height: 16px;
  background-position: -528px -512px;
}

.INCO {
  width: 16px;
  height: 16px;
  background-position: -0 -528px;
}

.INCOME {
  width: 16px;
  height: 16px;
  background-position: -16px -528px;
}

.IND {
  width: 16px;
  height: 16px;
  background-position: -32px -528px;
}

.INDC {
  width: 16px;
  height: 16px;
  background-position: -48px -528px;
}

.INDEX {
  width: 16px;
  height: 16px;
  background-position: -64px -528px;
}

.INDI {
  width: 16px;
  height: 16px;
  background-position: -80px -528px;
}

.INDIA {
  width: 16px;
  height: 16px;
  background-position: -96px -528px;
}

.INDSHIB {
  width: 16px;
  height: 16px;
  background-position: -112px -528px;
}

.INE {
  width: 16px;
  height: 16px;
  background-position: -128px -528px;
}

.INET {
  width: 16px;
  height: 16px;
  background-position: -144px -528px;
}

.INEX {
  width: 16px;
  height: 16px;
  background-position: -160px -528px;
}

.INF {
  width: 16px;
  height: 16px;
  background-position: -176px -528px;
}

.INF4 {
  width: 16px;
  height: 16px;
  background-position: -192px -528px;
}

.INFD {
  width: 16px;
  height: 16px;
  background-position: -208px -528px;
}

.INFI {
  width: 16px;
  height: 16px;
  background-position: -224px -528px;
}

.INFINITY {
  width: 16px;
  height: 16px;
  background-position: -240px -528px;
}

.INFINITYADA {
  width: 16px;
  height: 16px;
  background-position: -256px -528px;
}

.INFINITYDOGE {
  width: 16px;
  height: 16px;
  background-position: -272px -528px;
}

.INFLEX {
  width: 16px;
  height: 16px;
  background-position: -288px -528px;
}

.INFLUENCE {
  width: 16px;
  height: 16px;
  background-position: -304px -528px;
}

.INFO {
  width: 16px;
  height: 16px;
  background-position: -320px -528px;
}

.INFP {
  width: 16px;
  height: 16px;
  background-position: -336px -528px;
}

.INFS {
  width: 16px;
  height: 16px;
  background-position: -352px -528px;
}

.INFT {
  width: 16px;
  height: 16px;
  background-position: -368px -528px;
}

.INFTEE {
  width: 16px;
  height: 16px;
  background-position: -384px -528px;
}

.ING {
  width: 16px;
  height: 16px;
  background-position: -400px -528px;
}

.INJ {
  width: 16px;
  height: 16px;
  background-position: -416px -528px;
}

.INK {
  width: 16px;
  height: 16px;
  background-position: -432px -528px;
}

.INKZ {
  width: 16px;
  height: 16px;
  background-position: -448px -528px;
}

.INMES {
  width: 16px;
  height: 16px;
  background-position: -464px -528px;
}

.INN {
  width: 16px;
  height: 16px;
  background-position: -480px -528px;
}

.INNBC {
  width: 16px;
  height: 16px;
  background-position: -496px -528px;
}

.INNBCL {
  width: 16px;
  height: 16px;
  background-position: -512px -528px;
}

.INO {
  width: 16px;
  height: 16px;
  background-position: -528px -528px;
}

.INP {
  width: 16px;
  height: 16px;
  background-position: -544px -0;
}

.INS {
  width: 16px;
  height: 16px;
  background-position: -544px -16px;
}

.INSN {
  width: 16px;
  height: 16px;
  background-position: -544px -32px;
}

.INST {
  width: 16px;
  height: 16px;
  background-position: -544px -48px;
}

.INSTA {
  width: 16px;
  height: 16px;
  background-position: -544px -64px;
}

.INSTANTXRP {
  width: 16px;
  height: 16px;
  background-position: -544px -80px;
}

.INSTAR {
  width: 16px;
  height: 16px;
  background-position: -544px -96px;
}

.INSTINCT {
  width: 16px;
  height: 16px;
  background-position: -544px -112px;
}

.INSUR {
  width: 16px;
  height: 16px;
  background-position: -544px -128px;
}

.INSURE {
  width: 16px;
  height: 16px;
  background-position: -544px -144px;
}

.INT {
  width: 16px;
  height: 16px;
  background-position: -544px -160px;
}

.INTER {
  width: 16px;
  height: 16px;
  background-position: -544px -176px;
}

.INTL {
  width: 16px;
  height: 16px;
  background-position: -544px -192px;
}

.INTO {
  width: 16px;
  height: 16px;
  background-position: -544px -208px;
}

.INTR {
  width: 16px;
  height: 16px;
  background-position: -544px -224px;
}

.INTRATIO {
  width: 16px;
  height: 16px;
  background-position: -544px -240px;
}

.INTX {
  width: 16px;
  height: 16px;
  background-position: -544px -256px;
}

.INU {
  width: 16px;
  height: 16px;
  background-position: -544px -272px;
}

.INUB {
  width: 16px;
  height: 16px;
  background-position: -544px -288px;
}

.INUBIS {
  width: 16px;
  height: 16px;
  background-position: -544px -304px;
}

.INUGAMI {
  width: 16px;
  height: 16px;
  background-position: -544px -320px;
}

.INUJUMP {
  width: 16px;
  height: 16px;
  background-position: -544px -336px;
}

.INUS {
  width: 16px;
  height: 16px;
  background-position: -544px -352px;
}

.INUYASHA {
  width: 16px;
  height: 16px;
  background-position: -544px -368px;
}

.INV {
  width: 16px;
  height: 16px;
  background-position: -544px -384px;
}

.INVC {
  width: 16px;
  height: 16px;
  background-position: -544px -400px;
}

.INVE {
  width: 16px;
  height: 16px;
  background-position: -544px -416px;
}

.INVERSE {
  width: 16px;
  height: 16px;
  background-position: -544px -432px;
}

.INVEST {
  width: 16px;
  height: 16px;
  background-position: -544px -448px;
}

.INVI {
  width: 16px;
  height: 16px;
  background-position: -544px -464px;
}

.INVOX {
  width: 16px;
  height: 16px;
  background-position: -544px -480px;
}

.INX {
  width: 16px;
  height: 16px;
  background-position: -544px -496px;
}

.INXC {
  width: 16px;
  height: 16px;
  background-position: -544px -512px;
}

.INXT {
  width: 16px;
  height: 16px;
  background-position: -544px -528px;
}

.INZ {
  width: 16px;
  height: 16px;
  background-position: -0 -544px;
}

.IO {
  width: 16px;
  height: 16px;
  background-position: -16px -544px;
}

.IOC {
  width: 16px;
  height: 16px;
  background-position: -32px -544px;
}

.IOEN {
  width: 16px;
  height: 16px;
  background-position: -48px -544px;
}

.IOEX {
  width: 16px;
  height: 16px;
  background-position: -64px -544px;
}

.IOG {
  width: 16px;
  height: 16px;
  background-position: -80px -544px;
}

.IOI {
  width: 16px;
  height: 16px;
  background-position: -96px -544px;
}

.ION {
  width: 16px;
  height: 16px;
  background-position: -112px -544px;
}

.IONC {
  width: 16px;
  height: 16px;
  background-position: -128px -544px;
}

.IONS {
  width: 16px;
  height: 16px;
  background-position: -144px -544px;
}

.IONX {
  width: 16px;
  height: 16px;
  background-position: -160px -544px;
}

.IOOX {
  width: 16px;
  height: 16px;
  background-position: -176px -544px;
}

.IOSHIB {
  width: 16px;
  height: 16px;
  background-position: -192px -544px;
}

.IOST {
  width: 16px;
  height: 16px;
  background-position: -208px -544px;
}

.IOTE {
  width: 16px;
  height: 16px;
  background-position: -224px -544px;
}

.IOTEXCHART {
  width: 16px;
  height: 16px;
  background-position: -240px -544px;
}

.IOTF {
  width: 16px;
  height: 16px;
  background-position: -256px -544px;
}

.IOTN {
  width: 16px;
  height: 16px;
  background-position: -272px -544px;
}

.IOTW {
  width: 16px;
  height: 16px;
  background-position: -288px -544px;
}

.IOTX {
  width: 16px;
  height: 16px;
  background-position: -304px -544px;
}

.IOUX {
  width: 16px;
  height: 16px;
  background-position: -320px -544px;
}

.IOV {
  width: 16px;
  height: 16px;
  background-position: -336px -544px;
}

.iOWN {
  width: 16px;
  height: 16px;
  background-position: -352px -544px;
}

.IPAD {
  width: 16px;
  height: 16px;
  background-position: -368px -544px;
}

.IPAY {
  width: 16px;
  height: 16px;
  background-position: -384px -544px;
}

.IPC {
  width: 16px;
  height: 16px;
  background-position: -400px -544px;
}

.IPEGG {
  width: 16px;
  height: 16px;
  background-position: -416px -544px;
}

.IPF {
  width: 16px;
  height: 16px;
  background-position: -432px -544px;
}

.IPL {
  width: 16px;
  height: 16px;
  background-position: -448px -544px;
}

.IPM {
  width: 16px;
  height: 16px;
  background-position: -464px -544px;
}

.IPSX {
  width: 16px;
  height: 16px;
  background-position: -480px -544px;
}

.IPT {
  width: 16px;
  height: 16px;
  background-position: -496px -544px;
}

.IPX {
  width: 16px;
  height: 16px;
  background-position: -512px -544px;
}

.IQ {
  width: 16px;
  height: 16px;
  background-position: -528px -544px;
}

.IQC {
  width: 16px;
  height: 16px;
  background-position: -544px -544px;
}

.IQCOIN {
  width: 16px;
  height: 16px;
  background-position: -560px -0;
}

.IQG {
  width: 16px;
  height: 16px;
  background-position: -560px -16px;
}

.IQN {
  width: 16px;
  height: 16px;
  background-position: -560px -32px;
}

.IQQ {
  width: 16px;
  height: 16px;
  background-position: -560px -48px;
}

.IQT {
  width: 16px;
  height: 16px;
  background-position: -560px -64px;
}

.IRA {
  width: 16px;
  height: 16px;
  background-position: -560px -80px;
}

.IRD {
  width: 16px;
  height: 16px;
  background-position: -560px -96px;
}

.IRENA {
  width: 16px;
  height: 16px;
  background-position: -560px -112px;
}

.IRIS {
  width: 16px;
  height: 16px;
  background-position: -560px -128px;
}

.IRL {
  width: 16px;
  height: 16px;
  background-position: -560px -144px;
}

.IRON {
  width: 16px;
  height: 16px;
  background-position: -560px -160px;
}

.IRONMAN {
  width: 16px;
  height: 16px;
  background-position: -560px -176px;
}

.IRT {
  width: 16px;
  height: 16px;
  background-position: -560px -192px;
}

.ISA {
  width: 16px;
  height: 16px;
  background-position: -560px -208px;
}

.ISAL {
  width: 16px;
  height: 16px;
  background-position: -560px -224px;
}

.ISDT {
  width: 16px;
  height: 16px;
  background-position: -560px -240px;
}

.ISH {
  width: 16px;
  height: 16px;
  background-position: -560px -256px;
}

.ISHND {
  width: 16px;
  height: 16px;
  background-position: -560px -272px;
}

.ISIKC {
  width: 16px;
  height: 16px;
  background-position: -560px -288px;
}

.ISKY {
  width: 16px;
  height: 16px;
  background-position: -560px -304px;
}

.ISL {
  width: 16px;
  height: 16px;
  background-position: -560px -320px;
}

.ISLA {
  width: 16px;
  height: 16px;
  background-position: -560px -336px;
}

.ISLAINU {
  width: 16px;
  height: 16px;
  background-position: -560px -352px;
}

.ISLAMI {
  width: 16px;
  height: 16px;
  background-position: -560px -368px;
}

.ISLAND {
  width: 16px;
  height: 16px;
  background-position: -560px -384px;
}

.ISLBYZ {
  width: 16px;
  height: 16px;
  background-position: -560px -400px;
}

.ISLE {
  width: 16px;
  height: 16px;
  background-position: -560px -416px;
}

.ISOLA {
  width: 16px;
  height: 16px;
  background-position: -560px -432px;
}

.ISP {
  width: 16px;
  height: 16px;
  background-position: -560px -448px;
}

.ISR {
  width: 16px;
  height: 16px;
  background-position: -560px -464px;
}

.IST {
  width: 16px;
  height: 16px;
  background-position: -560px -480px;
}

.ISTEP {
  width: 16px;
  height: 16px;
  background-position: -560px -496px;
}

.IT {
  width: 16px;
  height: 16px;
  background-position: -560px -512px;
}

.ITAM {
  width: 16px;
  height: 16px;
  background-position: -560px -528px;
}

.ITAMCUBE {
  width: 16px;
  height: 16px;
  background-position: -560px -544px;
}

.ITC {
  width: 16px;
  height: 16px;
  background-position: -0 -560px;
}

.ITEN {
  width: 16px;
  height: 16px;
  background-position: -16px -560px;
}

.ITFX {
  width: 16px;
  height: 16px;
  background-position: -32px -560px;
}

.ITG {
  width: 16px;
  height: 16px;
  background-position: -48px -560px;
}

.ITGR {
  width: 16px;
  height: 16px;
  background-position: -64px -560px;
}

.ITH {
  width: 16px;
  height: 16px;
  background-position: -80px -560px;
}

.ITHEUM {
  width: 16px;
  height: 16px;
  background-position: -96px -560px;
}

.ITI {
  width: 16px;
  height: 16px;
  background-position: -112px -560px;
}

.ITL {
  width: 16px;
  height: 16px;
  background-position: -128px -560px;
}

.ITM {
  width: 16px;
  height: 16px;
  background-position: -144px -560px;
}

.ITO {
  width: 16px;
  height: 16px;
  background-position: -160px -560px;
}

.ITR {
  width: 16px;
  height: 16px;
  background-position: -176px -560px;
}

.ITS {
  width: 16px;
  height: 16px;
  background-position: -192px -560px;
}

.ITT {
  width: 16px;
  height: 16px;
  background-position: -208px -560px;
}

.ITZ {
  width: 16px;
  height: 16px;
  background-position: -224px -560px;
}

.IUP {
  width: 16px;
  height: 16px;
  background-position: -240px -560px;
}

.IUSD {
  width: 16px;
  height: 16px;
  background-position: -256px -560px;
}

.IUSDS {
  width: 16px;
  height: 16px;
  background-position: -272px -560px;
}

.IUT {
  width: 16px;
  height: 16px;
  background-position: -288px -560px;
}

.IUX {
  width: 16px;
  height: 16px;
  background-position: -304px -560px;
}

.IV {
  width: 16px;
  height: 16px;
  background-position: -320px -560px;
}

.IVBOL {
  width: 16px;
  height: 16px;
  background-position: -336px -560px;
}

.IVC {
  width: 16px;
  height: 16px;
  background-position: -352px -560px;
}

.IVG {
  width: 16px;
  height: 16px;
  background-position: -368px -560px;
}

.IVN {
  width: 16px;
  height: 16px;
  background-position: -384px -560px;
}

.IVO {
  width: 16px;
  height: 16px;
  background-position: -400px -560px;
}

.IVORY {
  width: 16px;
  height: 16px;
  background-position: -416px -560px;
}

.IVRY {
  width: 16px;
  height: 16px;
  background-position: -432px -560px;
}

.IVY {
  width: 16px;
  height: 16px;
  background-position: -448px -560px;
}

.IWAY {
  width: 16px;
  height: 16px;
  background-position: -464px -560px;
}

.IWFT {
  width: 16px;
  height: 16px;
  background-position: -480px -560px;
}

.IWR {
  width: 16px;
  height: 16px;
  background-position: -496px -560px;
}

.IX {
  width: 16px;
  height: 16px;
  background-position: -512px -560px;
}

.IXC {
  width: 16px;
  height: 16px;
  background-position: -528px -560px;
}

.IXIR {
  width: 16px;
  height: 16px;
  background-position: -544px -560px;
}

.IXMR {
  width: 16px;
  height: 16px;
  background-position: -560px -560px;
}

.IXO {
  width: 16px;
  height: 16px;
  background-position: -576px -0;
}

.IXRP {
  width: 16px;
  height: 16px;
  background-position: -576px -16px;
}

.IXS {
  width: 16px;
  height: 16px;
  background-position: -576px -32px;
}

.IXT {
  width: 16px;
  height: 16px;
  background-position: -576px -48px;
}

.IXTZ {
  width: 16px;
  height: 16px;
  background-position: -576px -64px;
}

.IYF {
  width: 16px;
  height: 16px;
  background-position: -576px -80px;
}

.IZA {
  width: 16px;
  height: 16px;
  background-position: -576px -96px;
}

.IZE {
  width: 16px;
  height: 16px;
  background-position: -576px -112px;
}

.IZER {
  width: 16px;
  height: 16px;
  background-position: -576px -128px;
}

.IZI {
  width: 16px;
  height: 16px;
  background-position: -576px -144px;
}

.IZUMI {
  width: 16px;
  height: 16px;
  background-position: -576px -160px;
}
